import React, { useEffect, useState } from "react";
import { Button } from "../../../../../Components/Helper";
import {
  isEmailValid,
  validatePanNumber,
  validatePassword,
  validatePhoneNumber,
} from "./../../../../../ReusableFunctions/reusableFunctions";
import {
  sendVerificationOTP,
  verifyOTP,
} from "../../../../../apis/verification";
import OtpInput from "react-otp-input";
import FileUploader from "../../../../../ReusableComponents/FileUploader";
import {
  GLOBAL_STYLES,
  IDENTITY_CARD_TYPES,
  LIMITS,
} from "../../../../../appConstants";
import Modal from "../../../../../ReusableComponents/Modal";
import Input from "../../../../../ReusableComponents/Input";
import Check from "../../../../../assets/CheckCircle.svg";
import Identity from "../../../../../assets/IdentificationBadge.svg";
import { useNavigate } from "react-router-dom";
import { login } from "../../../../../apis/authentication";
import Popup from "../../../../../ReusableFunctions/Popup";
import Loading from "../../../../../ReusableFunctions/Loader";
import OTPVerification from "../../../../../ReusableComponents/OTPVerification";
import { uploadProfilePic } from "../../../../../apis/users";
import { checkDSExistWithSamePAN } from "../../../../../apis/operator";
import _debounce from 'lodash/debounce';

const KYCVerification = ({ next }) => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    id: "",
    name: "",
    email: "",
    phone: null,
    address: null,
    aadhar_number: null,
    pan_number: null,
    is_admin: true,
    location_id: null,
    dsp_id: "",
    date_created: "",
    aadhar_url: null,
    pan_url: null,
    profile_url: null,
    dsp: {
      id: "",
      name: "",
      logo_url: "",
      owner_id: "",
      date_created: "",
      is_verified: false,
      is_active: true,
      registration_number: null,
      address: null,
      information: null,
      blob_container: "",
    },
  });
  const [error, setError] = useState({ message: "" });
  const [selctedIdentites, setSelectedIdentities] = useState([]);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [checkPanNumberLoading, setcheckPanNumberLoading] = useState(false);

  useEffect(() => {
    login().then((user) => {
      if (user?.dsp?.is_verified) navigate("/");
      else {
        setUserDetails(user);
        const userid = localStorage.getItem("user");
        if (userid === user.id) {
          const phone = localStorage.getItem("phone-verified");
          if (phone) {
            setPhoneVerified(true);
            setUserDetails((prev) => ({ ...prev, phone }));
          }
          const email = localStorage.getItem("email-verified");
          if (email) {
            setEmailVerified(true);
            setUserDetails((prev) => ({ ...prev, email }));
          }
        }
      }
    });
  }, []);

  const checkDSPAlreadyExist = _debounce(async (pan_number) => {
    try {
        const res = await checkDSExistWithSamePAN(pan_number);
        setError((prevErrors) => ({
            ...prevErrors,
            message: '',
        }));
        setcheckPanNumberLoading(false);
    } catch (error) {
      setcheckPanNumberLoading(false);
        console.error("Error in checkDSPExist:", error);
        setError({ message:"PAN number already exists",label:"pan" });
    }
}, 500)
  
  const handleOnChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "phone") {
      value = value?.substring(0, 10)
    }
    setError({ message: "" });
    if (e.target.value) {
      switch (name) {
        case "phone":
          if (!validatePhoneNumber(value)) {
            setError({ message: "Please enter valid phone number" });
          }
          break;
        case "adhaarCardNo":
          break;
        case "pan_number":
          if(!validatePanNumber(value)){
            setError(()=>({message:"please enter valid pan number",label:"pan"}))
          }else {
            setcheckPanNumberLoading(true);
            checkDSPAlreadyExist(value)
          }
          break;
        case "password":
          const message = validatePassword(value);
          setError({ ...error, message });
          break;
        case "verifyPassword":
          if (value !== userDetails.password) {
            setError({
              message: "Please enter password again",
            });
          }
          break;
        default:
          setError({ message: "" });
          break;
      }
    }
    if (name === "fname") {
      value = value.replace(/[^a-zA-Z]/g, '')
      value = value + " " + userDetails.name.split(" ")[1];
      setUserDetails((prev) => {
        return { ...prev, name: value };
      });
    }
    if (name === "lname") {
      value = value.replace(/[^a-zA-Z]/g, '')
      value = userDetails.name.split(" ")[0] + " " + value;
      setUserDetails((prev) => {
        return { ...prev, name: value };
      });
    } else
      setUserDetails((prev) => {
        return { ...prev, [name]: value };
      });
  };

  const handleSave = async (e) => {
    if (e) e.preventDefault();
    Loading.set(true);
    try {
      if (localStorage.getItem("user-details")) {
        localStorage.removeItem("user-details");
      }
      localStorage.setItem("user-details", JSON.stringify(userDetails));
      Loading.set(false);
      next({ userDetails, selctedIdentites });
    } catch (err) {
      Loading.set(false);
      console.log(err);
    }
  };
  const isKYCCompleted = () => {
    return (
      validatePhoneNumber(userDetails.phone) &&
      phoneVerified &&
      emailVerified &&
      isEmailValid(userDetails.email) &&
      userDetails.name &&
      userDetails.profile_url?.name &&
      userDetails.pan_number&&
      validatePanNumber(userDetails?.pan_number)&&
      selctedIdentites.length > 0 &&
      !checkPanNumberLoading &&
      error.message==''
    );
  };
  const showPreviewIdentity = (type, file) => {
    setSelectedIdentities((prev) => [...prev, { type, file }]);
  };
  const handleDspProfilepic = (file) => {
    setUserDetails((prev) => ({
      ...prev,
      profile_url: file
    }))
    if (localStorage.getItem("user-details")) {
      localStorage.removeItem("user-details");
    }
    localStorage.setItem("user-details", JSON.stringify(userDetails));
  };
  useEffect(() => {
    const enterPress = (e) => {
      if (e.key === "Enter" && isKYCCompleted()) {
        handleSave();
      }
    };
    window.addEventListener("keydown", enterPress);
    return () => window.removeEventListener("keydown", enterPress);

  }, [])

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        paddingTop: "20px",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (isKYCCompleted()) handleSave();
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "20px",
          padding: "10px 40px 70px",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM_HEADER,
            fontWeight: "500",
            marginBottom: "0px",
          }}
        >
          Profile
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            gap: "10px",
          }}
        >
          {/* firstname */}

          <Input
            style={{ height: '35px' }}
            maxLength={20}
            mimLength={3}
            id="fname"
            onChange={handleOnChange}
            value={userDetails.name.split(" ")[0]}
            name="fname"
            placeholder="Enter firstname"
            icon={Identity}
            type={'text'}
          />
          {/* lastname */}

          <Input
            style={{ height: '35px' }}
            maxLength={20}
            mimLength={3}
            type={'text'}
            id="lname"
            onChange={handleOnChange}
            value={userDetails.name.split(" ")[1]}
            name="lname"
            placeholder="Enter lastname"
            icon={Identity}
          />
        </div>

        {/* email */}
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              columnGap: "10px",
            }}
          >
            <Input
              value={userDetails.email}
              disabled
              style={{ height: '35px' }}
            />
            {emailVerified ? (
              <div
                style={{
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  height: '24px'
                }}
              >
                <img src={Check} />
              </div>
            ) : (
              <OTPVerification userId={userDetails.id} sendOtpTo={userDetails.email} btnText={"Verify"} type={"email"} callback={(verified) => {
                setEmailVerified(verified)
              }} />
            )}
          </div>
        </div>

        {/* phone number */}
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              columnGap: "10px",
            }}
          >
            <Input
              style={{ height: '35px' }}
              maxLength={LIMITS.PHONE_NUMBER}
              id="phone-number"
              disabled={phoneVerified}
              onChange={handleOnChange}
              value={userDetails.phone}
              name="phone"
              type='number'
              placeholder="Enter your 10 digit mobile number"
            />
            {phoneVerified ? (
              <div
                style={{
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <img src={Check} />
              </div>
            ) : validatePhoneNumber(userDetails.phone) ? (
              <OTPVerification userId={userDetails.id} sendOtpTo={userDetails.phone} btnText={"Verify"} type={"mobile"} callback={(verified) => {
                setPhoneVerified(verified)
              }} />
            ) : (
              <div style={{ width: "50px", textAlign: "left" }}>
                <div
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    textDecoration: "underline",
                    opacity: "0.5",
                  }}
                  className="form-link"
                  onClick={() => { }}
                >
                  Verify
                </div>
              </div>
            )}
          </div>
        </div>

        {/* upload pan card */}
        {(
          <FileUploader
            uploaderId={"fileInput"}
            placeholder={"Upload your identity"}
            accept={"application/pdf, image/jpeg, image/jpg, image/png"}
            onFileSelect={(file) =>
              showPreviewIdentity(IDENTITY_CARD_TYPES.PAN_CARD, file)
            }
            onClear={() => setSelectedIdentities([])}
          />
        )}

        {(
          <FileUploader
            uploaderId={"fileInput1"}
            placeholder={"Upload your profile picture"}
            onFileSelect={handleDspProfilepic}
            accept={"image/jpeg, image/jpg, image/png, application/pdf"}
            onClear={() => setUserDetails((prev) => ({ ...prev, profile_url: null }))}
          />
        )}
        <div className="flex flex-col w-[100%]">
        <Input
              style={{ height: '35px' }}
              maxLength={LIMITS.PAN_NUMBER}
              id="pan_number"
              onChange={handleOnChange}
              value={userDetails.pan_number}
              name="pan_number"
              type='text'
              placeholder="Enter your pan no."
            />
          {error && error.label==="pan" && <div style={{fontSize:"12px",color:"crimson"}} className="error">{error.message}</div>}
        </div>
        <Button
          className="form-btn"
          text={"Next"}
          isDisabled={!isKYCCompleted()}
          style={{ marginTop: "0px", height: "30px" }}
        />
      </form>
    </div>
  );
};

export default KYCVerification;
