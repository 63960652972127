import React, { useEffect, useState } from "react";
import MainContentWrapper from "../../Components/MainContentWrapper";
import DashBoardCard from "../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard";
import Loading from "../../ReusableFunctions/Loader";
import { getSalesDashboardOverViewData } from "../../apis/BhuMeetTeams/Sales/Dashboard";
import { PDRL_SALES_ROUTES } from "../../routes";

export default function SalesDashboard() {
    const [dashboardOveriewData, setDashboardOveriewData] = useState({
        storeCount:0,
    })

    const getSalesDashboardOverViewDataFunc = async() =>{
        try{
         Loading.set(true);
         const overViewData=await   getSalesDashboardOverViewData();
         setDashboardOveriewData(overViewData);
         Loading.set(false);
        
        }catch(err){
          Loading.set(false);
          console.log(err);
        }
    }

    useEffect(()=>{
        getSalesDashboardOverViewDataFunc();
        localStorage.removeItem("salesStoresFilters")
    },[])


  return (
    <MainContentWrapper>
      <div
        className="dashboard-first-section-second-div"
        style={{ width: "100%" }}
      >
        <div
          className="dashboard-first-section-second-div-one"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
          }}
        >
          <DashBoardCard
            loading={false}
            title="Stores"
            data={dashboardOveriewData.storeCount}
            view={PDRL_SALES_ROUTES.SALES_STORES}
          />
        </div>
      </div>
    </MainContentWrapper>
  );
}
