import React, { useEffect, useState } from "react";
import Modal from "../../../ReusableComponents/Modal";
import LoaderComponent from "../../../ReusableComponents/LoaderComponent";
import DateTimePicker from "../../../ReusableComponents/DateTimePicker";
import {
  GLOBAL_STYLES,
  STATUS,
  AEROMEGH_SERVICES,
  PAYMENT_METHODS,
  PAYMENT_STATUSES,
} from "../../../appConstants";
import { Button } from "../../../Components/Helper";
import Tippy from "@tippyjs/react";
import CloseIcon from "../../../assets/closebtn.svg";
import Filter from "../../../assets/filter.svg";
import { reduceLongName } from "../../../ReusableFunctions/reusableFunctions";
import SingleFilter from "../../../features/MyRequests/SubComponents/FilterRequest/SingleFilter";
import CustomTooltip from "../../../ReusableComponents/CustomTooltip";
import Popup from "../../../ReusableFunctions/Popup";
import { getAllDSPs } from "../../../apis/adminAPIs/dsp";
import dateFormat from "dateformat";
import { IsBhuMeetCouponApplied, PaymentMethodRadioButtons } from ".";
import DurationFilters from "../../../features/Revenue/SubComponents/DurationFilters";

const AdminFilterRequest = ({
  open,
  isHideDSPFilter,
  onClose,
  onFilterChange,
  setOpenFilter,
  filters,
  screenWidth,
  loading,
  isFromUserDetails,
}) => {
  const [tempFilters, setTempFilters] = useState(filters);
  const [isScrollable, setIsScrollable] = useState(false);
  const [dsps, setDsps] = useState([]);
  const [formattedDate, setFormattedDate] = useState('');
  const [appliedFilters, setAppliedFilters] = useState();
  const [chipInputSize, setChipInputSize] = useState(0);
  const durationOptions = [
    { id: 6, placeholder: "All", interval: "all" },
    { id: 1, placeholder: "Today", interval: "day" },
    { id: 2, placeholder: "This Week", interval: "week" },
    { id: 3, placeholder: "This Month", interval: "month" },
    { id: 4, placeholder: "This Year", interval: "year" },
    {
      id: 5,
      placeholder: "Custom",
      interval: "custom",
      cb: () => {
        setOpenFilter(true);
      },
    },

  ];
  const handleOnChangeStatus = (e) => {
    const value = e.target.value;
    let newStatuses = tempFilters.statuses;
    if (e.target.checked) {
        if (newStatuses.length < 3) {
          newStatuses = [...newStatuses, value];
        } else {
            Popup.alert("WARNING",'You can only select up to 3 status at time','ERROR')
           return;
        }
      } else {
        newStatuses = newStatuses.filter((status) => status !== value);
      }
    newStatuses = Array.from(new Set(newStatuses));
    setTempFilters((prev) => ({
      ...prev,
      statuses: newStatuses,
      isFilterByServiceTime:
        newStatuses?.length > 0 ? false : prev.isFilterByServiceTime,
    }));
  };

  const handleOnChangeLocation = (e) => {
    const value = e.target.value;
    let dsps = tempFilters.dsps;
    if (e.target.checked) {
        if(dsps.length < 5){
            dsps = [...tempFilters.dsps, value];
        }else {
            Popup.alert("WARNING",'You can only select up to 5 dsp at time','ERROR')
           return;
        }
    } else dsps = dsps.filter((dsp) => dsp !== value);
    dsps = Array.from(new Set(dsps));
    setTempFilters((prev) => ({ ...prev, dsps: dsps }));
  };

  const getDspsFunc = async () => {
    try {
      const dsps = await getAllDSPs();
      setDsps(dsps?.dsps);
    } catch (err) {
      Popup.alert("Error", err, "ERROR");
    }
  };

  const clearFilter = async (filter) => {
    switch (filter.type) {
      case "service":
        if (filter.services.length !== 0) {
          onFilterChange({
            services: filters?.services?.filter((s) => s != filter?.name),
          });
        }
        break;
      case "status":
        if (filters.statuses.length !== 0) {
          onFilterChange({
            statuses: filters?.statuses?.filter((s) => s != filter?.name),
            paymentMethods:
              filter.name === PAYMENT_STATUSES.PAID
                ? null
                : filters.paymentMethods,
          });
        }
        break;
      case "dsp":
        if (dsps.length !== 0) {
          const { dsp_id } = dsps?.find(
            (dsp) => dsp?.dsp_name === filter?.name
          );
          onFilterChange({
            dsps: filters?.dsps?.filter((id) => id != dsp_id),
          });
        }
        break;
      case "date":
        onFilterChange({
          fromDate: null,
          toDate: null,
          isFilterByServiceTime: false,
          isFilterByDateCreated: false,
          interval: "all",
        });
        break;
      case "paymentMethods":
        onFilterChange({
          paymentMethods: null,
        });
        break;
      case "onlyAppliedBhuMeetCouponsRequests":
        onFilterChange({
          onlyAppliedBhuMeetCouponsRequests: false,
        });
        break;
      case "isFilterByServiceTime":
        onFilterChange({
          isFilterByServiceTime: false,
        });
        break;
      case "isFilterByDateCreated":
        onFilterChange({
          isFilterByDateCreated: false,
        });
        break;
      default:
        break;
    }
  };

  const getFormattedFromAndToDate=(interval)=>{
    let formattedDate=''
     switch(interval){
        case 'day':
        formattedDate=`Date: ${dateFormat(
          filters.fromDate,
          "dd/mm/yyyy "
        )}`
        break;
        case "week":
        case "month":
        case "year":
          formattedDate=`Date: ${dateFormat(filters.fromDate,"dd/mm/yyyy ")} 
          to ${dateFormat(filters.toDate, "dd/mm/yyyy ")}`
          break;
          case "custom":
            formattedDate=`Date: ${dateFormat(filters.fromDate,"dd/mm/yyyy hh:MM TT ")} 
          to ${dateFormat(filters.toDate, "dd/mm/yyyy hh:MM TT")}`
         break;
        default:
            formattedDate=''
     }

     setFormattedDate(formattedDate);

  }
  useEffect(() => {
    const container = document.getElementsByClassName("filetrs-container")[0];
    if (container) {
      if (container.scrollWidth > container.clientWidth) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
    }
  }, [appliedFilters]);

  useEffect(() => {
    setChipInputSize(getChipInputSize(screenWidth));
  }, [screenWidth]);

  useEffect(() => {
    setTempFilters(() => filters);
    let selectedServices = filters?.services?.map((ser) => ({
        name: ser,
        type: "service"
    }))
    let selectedStatus = filters?.statuses?.map((st) => ({
      name: st,
      type: "status",
    }));
    let selectedLocations = isHideDSPFilter
      ? []
      : (filters?.dsps ?? []).map((dspId) => {
          const dsp = dsps?.find((dsp) => dsp?.dsp_id === dspId);
          return { name: dsp?.dsp_name, type: "dsp" };
        });

    const filtersToBeShown = [...selectedStatus, ...selectedLocations];

    getFormattedFromAndToDate(filters?.interval);

    if (filters.paymentMethods && filters.paymentMethods.length === 1) {
      filtersToBeShown.push({
        name:
          filters.paymentMethods[0] === PAYMENT_METHODS.OFFLINE
            ? "Cash"
            : filters.paymentMethods[0],
        type: `paymentMethods`,
      });
    }
    if (filters.onlyAppliedBhuMeetCouponsRequests) {
      filtersToBeShown.push({
        name: `Coupons Applied Requests (BhuMeet)`,
        type: `onlyAppliedBhuMeetCouponsRequests`,
      });
    }

    if (filters.isFilterByServiceTime) {
      filtersToBeShown.push({
        name: `Scheduled Date`,
        type: `isFilterByServiceTime`,
      });
    }

    if (filters.isFilterByDateCreated) {
      filtersToBeShown.push({
        name: `Filter By Date Created`,
        type: `isFilterByDateCreated`,
      });
    }

    setAppliedFilters(filtersToBeShown);
  }, [filters, dsps]);

  const getChipInputSize = (screenSize) => {
    if (isFromUserDetails) {
      switch (screenSize) {
        case "x-large":
          return 6;
        case "large":
          return 5;
        case "medium":
          return 3;
        case "small":
          return 2;
        default:
          return 6;
      }
    } else {
      switch (screenSize) {
        case "x-large":
          return 6;
        case "large":
          return 4;
        case "medium":
          return 3;
        case "small":
          return 2;
        default:
          return 6;
      }
    }
  };

  useEffect(() => {
    getDspsFunc();
  }, []);

  return (
    <>
<div className="flex flex-col gap-1">
     <div className="w-[100%] max-w-[100%] flex justify-between">
       <div className="flex items-center gap-8">
        <div className="date-range-Filters">
          <DurationFilters
            options={durationOptions}
            interval={filters.interval}
            handleFilterChanged={(filters)=>{
              onFilterChange({...filters,pageNumber:1})
            }}
          />
        </div>
        <div className="text-[15px] font-[700] text-[#3B3B3B] ">
           <span>{formattedDate}</span>
        </div>
        </div>
        <div>
          <Modal
            header={"Filter"}
            contentStyle={{ width: "850px", height: "600px" }}
            headingStyle={{ fontSize: "18px", fontWeight: 600 }}
            headerContainerStyle={{ padding: "20px" }}
            open={open}
            onClose={() => {
              setTempFilters(filters);
              onClose();
            }}
          >
            <div
              style={{ width: "100%", borderTop: "0.5px solid lightgray" }}
            ></div>
            {loading ? (
              <LoaderComponent />
            ) : (
              <div
                style={{
                  fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR,
                  height: "calc(100% - 120px)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    padding: "18px 32px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: "10%",
                  }}
                >
                  <DateTimePicker
                    title="From"
                    hideNxt={new Date()}
                    minTime={new Date().setHours(0, 0, 0, 0)}
                    maxTime={new Date().setHours(23, 59)}
                    selectedDateTime={tempFilters.fromDate}
                    onChange={(date) => {
                      setTempFilters({
                        ...tempFilters,
                        fromDate: date,
                        interval:"custom"
                      });
                    }}
                  />
                  <DateTimePicker
                    title="To"
                    hidePrev={tempFilters.fromDate}
                    selectedDateTime={tempFilters.toDate}
                    minTime={
                      new Date(tempFilters?.fromDate).toDateString() ===
                      new Date(tempFilters?.toDate).toDateString()
                        ? new Date(
                            new Date(tempFilters.fromDate).setMinutes(
                              new Date(tempFilters.fromDate).getMinutes() + 1
                            )
                          )
                        : new Date().setHours(0, 0, 0, 0)
                    }
                    maxTime={new Date().setHours(23, 59)}
                    onChange={(date) => {
                      setTempFilters({
                        ...tempFilters,
                        toDate: !tempFilters.toDate
                          ? new Date(date).setHours(23, 59)
                          : new Date(date),
                        interval:"custom"
                      });
                    }}
                    isDisable={!tempFilters.fromDate}
                  />
                </div>
                <PaymentMethodRadioButtons
                  onChangePaymentMethods={(pm) =>
                    setTempFilters({ ...tempFilters, paymentMethods: pm })
                  }
                  activePaymentMethods={tempFilters.paymentMethods}
                  isDisabled={
                    !(
                      tempFilters?.statuses &&
                      tempFilters.statuses.length === 1 &&
                      tempFilters.statuses[0] === PAYMENT_STATUSES.PAID
                    )
                  }
                />

                <div
                  style={{
                    padding: "5px 30px",
                    display: "grid",
                    gridTemplateColumns: "1.5fr 1fr 1.5fr",
                    height: "65%",
                    rowGap: "10px",
                    fontSize: "14px",
                    columnGap: "10px",
                  }}
                >
                  <div style={{ fontSize: "16px", fontWeight: 500 }}>DSPs</div>
                  <div style={{ fontSize: "16px", fontWeight: 500 }}>
                    Status
                  </div>
                  <div style={{ fontSize: "16px", fontWeight: 500 }}>
                    Other Settings
                  </div>
                  {/* <div style={{ fontSize: "16px", fontSize: '14px', display: "flex", flexDirection: "column", rowGap: "23px", height: "100%", color: "#3B3B3B", fontWeight: 500, maxHeight: "240px" }}>
                                {Object.keys(AEROMEGH_SERVICES).slice(0, 2).map((key) => {
                                    return <SingleFilter
                                        isChecked={tempFilters.services.filter(status => status == AEROMEGH_SERVICES[key]).length > 0}
                                        onChange={handleOnChangeServices}
                                        value={AEROMEGH_SERVICES[key]}
                                        title={AEROMEGH_SERVICES[key].split(' ')[0]}
                                    />
                                })}
                            </div> */}
                  <div
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "8px",
                      overflowY: "auto",
                      height: "100%",
                      color: "#3B3B3B",
                      fontWeight: 500,
                      maxHeight: "240px",
                    }}
                  >
                    {dsps?.map(
                      (dsp) =>
                        dsp.dsp_is_active && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <div
                              style={{
                                width: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <input
                                disabled={isHideDSPFilter}
                                checked={
                                  tempFilters?.dsps?.filter(
                                    (loc) => loc === dsp.dsp_id
                                  ).length > 0
                                }
                                onChange={handleOnChangeLocation}
                                value={dsp.dsp_id}
                                type={"checkbox"}
                                className="input-checkbox"
                                style={{ width: "16px" }}
                              />
                            </div>
                            <Tippy content={dsp.dsp_name}>
                              <span
                                style={{
                                  lineHeight: 1.1,
                                  fontSize: "15px",
                                  fontWeight: 500,
                                }}
                              >
                                {reduceLongName(dsp.dsp_name, 40)}
                              </span>
                            </Tippy>
                          </div>
                        )
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "8px",
                      color: "#3B3B3B",
                      fontWeight: 500,
                      maxHeight: "240px",
                      overflowY: "auto",
                    }}
                  >
                    {Object.keys(STATUS).map((key) => {
                      return (
                        <SingleFilter
                          isChecked={
                            tempFilters?.statuses?.filter(
                              (status) => status == STATUS[key]
                            ).length > 0
                          }
                          onChange={handleOnChangeStatus}
                          value={STATUS[key]}
                          title={STATUS[key]}
                        />
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      rowGap: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ margin: "0 10px", width: "16px" }}
                        checked={tempFilters?.isFilterByServiceTime}
                        disabled={
                          !(tempFilters?.fromDate && tempFilters?.toDate)
                        }
                        onChange={(e) =>
                          setTempFilters({
                            ...tempFilters,
                            isFilterByDateCreated: false,
                            isFilterByServiceTime: e.target.checked,
                            statuses: e.target.checked
                              ? []
                              : tempFilters.statuses,
                            paymentMethods: e.target.checked
                              ? null
                              : tempFilters.paymentMethods,
                          })
                        }
                        value={tempFilters?.isFilterByServiceTime}
                        type={"checkbox"}
                        className="input-checkbox"
                      />
                      <div
                        style={{
                          color: "#3B3B3B",
                          fontSize: "15px",
                          fontWeight: 500,
                        }}
                      >
                        Filter by scheduled date
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ margin: "0 10px", width: "16px" }}
                        checked={tempFilters?.isFilterByDateCreated}
                        disabled={
                          !(tempFilters?.fromDate && tempFilters?.toDate)
                        }
                        onChange={(e) =>
                          setTempFilters({
                            ...tempFilters,
                            isFilterByServiceTime: false,
                            isFilterByDateCreated: e.target.checked,
                          })
                        }
                        value={tempFilters?.isFilterByDateCreated}
                        type={"checkbox"}
                        className="input-checkbox"
                      />
                      <div
                        style={{
                          color: "#3B3B3B",
                          fontSize: "15px",
                          fontWeight: 500,
                        }}
                      >
                        Filter by Date created
                      </div>
                    </div>
                    <IsBhuMeetCouponApplied
                      onlyAppliedBhuMeetCouponsRequests={
                        tempFilters.onlyAppliedBhuMeetCouponsRequests
                      }
                      onChangeOnlyAppliedBhuMeetCouponsRequests={(value) =>
                        setTempFilters({
                          ...tempFilters,
                          onlyAppliedBhuMeetCouponsRequests: value,
                        })
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "20px 30px",
                    borderTop: "0.5px solid #BFC8D9",
                  }}
                >
                  <div
                    onClick={async () => {
                      if (isHideDSPFilter) {
                        setTempFilters({
                          ...filters,
                          services: [],
                          statuses: [],
                          fromDate: null,
                          toDate: null,
                          paymentMethods: null,
                          onlyAppliedBhuMeetCouponsRequests: false,
                          isFilterByServiceTime: false,
                          interval:"all",
                        });
                      } else {
                        setTempFilters({
                          dsps: [],
                          services: [],
                          statuses: [],
                          fromDate: null,
                          toDate: null,
                          paymentMethods: null,
                          onlyAppliedBhuMeetCouponsRequests: false,
                          isFilterByServiceTime: false,
                          interval:"all",
                        });
                      }
                    }}
                    style={{
                      width: "100px",
                      color: GLOBAL_STYLES.BG_ACTIVE,
                      fontWeight: 600,
                      fontSize: "14px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    Clear all
                  </div>
                  <Button
                    text={"Apply"}
                    style={{
                      width: "130px",
                      height: "38px",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                    onClick={async () => {
                        let updatedFilters={...tempFilters}
                      onFilterChange(updatedFilters);
                      onClose();
                    }}
                  />
                </div>
              </div>
            )}
          </Modal>
          <div style={{ display: "flex " }}>
            
            {
              <Button
                innerClassName={"filter-btn-inner"}
                iconStyle={{ width: "18px", marginRight: "10px" }}
                text={"Filter"}
                icon={Filter}
                className={"filter-btn"}
                onClick={() => {
                  setOpenFilter(true);
                }}
                textClassName={"user-details-filter-text"}
              />
            }
          </div>
        </div>
        </div>
        <div className="flex justify-end gap-3 mr-1 w-[100%] pt-[10px] pb-[3px] ">
              <div className="admin_request_chip_input">
                
                <div
                  className="filetrs-container"
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap:"wrap",
                    maxWidth:"100%",
                    overflow:"auto",
                    width:"100%",
                  }}
                >
                  {appliedFilters?.length > 0 &&
                    appliedFilters.map((filter) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            fontSize: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                            background: "#0F5EFF1A",
                            border: "1px solid #0F5EFF",
                            height: "25px",
                            width: "100px",
                            minWidth:"fit-content",
                            borderRadius: "5px",
                            color: "#0F5EFF",
                            padding: "0 5px",
                          }}
                        >
                          <Tippy
                            allowHTML={true}
                            content={
                              <CustomTooltip
                                content={filter.name}
                                customStyle={{ maxWidth: "230px" }}
                              />
                            }
                            placement="top-start"
                          >
                            <span>
                              {filter.name?.length > 25
                                ? reduceLongName(filter.name, 25)
                                : filter.name}
                            </span>
                          </Tippy>
                          <img
                            className="cursor-pointer"
                            onClick={() => clearFilter(filter)}
                            src={CloseIcon}
                            width={15}
                            alt="closebtn"
                          />
                        </div>
                      );
                    })}
                </div>
                
              </div>
            </div>
    </div>
    </>
  );
};

export default AdminFilterRequest;
