import React, { useState } from 'react'
import { useLocation } from 'react-router';
import ViewStoreDetails from '../../Operations/ViewStoreDetails';
import ViewOnBoardedUsers from '../../Operations/ViewOnBoardedUsers';
import ViewRequestIncentives from '../../Operations/ViewRequestIncentives';
import ViewSettlements from '../../Operations/ViewSettlements';

export default function SalesStoreDetails() {
    const [activeTab, setActiveTab] = useState('details');
    const location = useLocation();
    const [store, setStore] = useState(location.state?.store)
    const tabs = ['details', 'Onboarded Users', 'Request Incentives', "Settlements"];

    return (
        <div style={{ backgroundColor: "rgb(236, 239, 243)", overflow: "hidden" }} className='flex flex-col gap-5 h-[100%]  px-[20px] py-[50px]'>
            <div className="drone-tabs ">
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
            </div>
            <div className="flex-grow overflow-y-auto">
                {activeTab === "details" ? (
                    <ViewStoreDetails hideBankDetails={true} store={store} callback={(store) => {
                        setStore(store);
                    }} />
                ) : activeTab === "Onboarded Users" ? <ViewOnBoardedUsers store={store} />
                    : activeTab === "Request Incentives" ? <ViewRequestIncentives store={store} /> :
                        activeTab === "Settlements" ? <ViewSettlements store={store} /> :
                            null}
            </div>
        </div>
    );
}
