import React, { useEffect, useState } from 'react';
import MainContentWrapper from '../../Components/MainContentWrapper';
import Popup from '../../ReusableFunctions/Popup';
import Loading from '../../ReusableFunctions/Loader';
import { getAllRequests } from '../../apis/adminAPIs/requests';
import ViewRequest from './SubComponents/ViewRequest';
import AdminRequestTable from './SubComponents/AdminRequestTable';
import { getStatusWiseRequestCount } from '../../apis/adminAPIs/overview';
import StatusCountContainer from './SubComponents/StatusCountContainer';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from "react-router-dom";
import { PDRL_SUCCESS_ENGI_ROUTES } from '../../routes';
import { getOrdersStatsAPI } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/orders';
import InteractiveGraph from '../../BhuMeetTeams/BhuMeetFinance/Orders/SubComponents/OrdersGraph';
import { getRequestsCountInInterval } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/request';
import useWindowSize from '../../hooks/useWindowSize';
import AdminFilterRequest from './SubComponents/AdminFilterRequest';


const Requests = ({ dsp }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const [loading, setLoading] = useState(true)
    const [requests, setRequests] = useState([])

    const [filters, setFilters] = useState(() => {
        if (dsp) {
            return {
                services: [],
                statuses: [],
                locations: [],
                isDspActive: undefined,
                dsps: dsp ? [dsp.dsp_id] : [],
                fromDate: undefined,
                toDate: undefined,
                searchQuery: "",
                pageSize: 15,
                pageNumber: 1,
            }
        }
        const savedFilters = localStorage.getItem('requestFilters')
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters)
            return {
                ...parsedFilters,
                searchQuery: "",
                fromDate: parsedFilters.fromDate ? new Date(parsedFilters.fromDate) : null,
                toDate: parsedFilters.toDate ? new Date(parsedFilters.toDate) : null,
            }
        }
        return {
            services: [],
            statuses: [],
            locations: [],
            isDspActive: true,
            dsps: dsp ? [dsp.dsp_id] : [],
            fromDate: new Date().setHours(0, 0, 0, 0),
            toDate: new Date(),
            isFilterByServiceTime: false,
            searchQuery: "",
            pageSize: 15,
            pageNumber: 1,
            interval: "day",
        }
    })
    const [allRequestCount, setAllRequestCount] = useState(0)
    const [view, setView] = useState("tableView");
    const [statusWiseRequestsCount, setStatusWiseRequestsCount] = useState([])
    const [requestsGraphData, setrequestsGraphData] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const {currentWidth}=useWindowSize();
    const navigate = useNavigate();

    const getAllRequestsFunc = async () => {
        try {
            const data = await getAllRequests({ ...filters, isDspActive: true, services: ["Spraying service"] },
                filters.isFilterByServiceTime,
                filters.isFilterByDateCreated
            )
            setRequests(data?.requests)
            setAllRequestCount(data?.requests[0]?.total_count)
            return
        } catch (err) {
            setLoading(false)
            Popup.alert("Error", err, "ERROR")
            return
        }
    }

    const getStatusWiseRequestCountFunc = async () => {
        try {
            const statusRequestCount = await getOrdersStatsAPI({ ...filters, isDspActive: true, services: ["Spraying service"] })
            let statsInfo = statusRequestCount?.stats?.map(s => {
                return {
                    status: s.order_status,
                    count: s.total_orders,
                    amount: s.total_amount,
                };
            }).filter(stat => stat.status !== 'Total Orders');
            setStatusWiseRequestsCount(statsInfo)
            return
        } catch (error) {
            Popup.alert("Error", error, "ERROR")
            return
        }
    }

    const filterChanged = async () => {
        try {
            setLoading(true)
            setRequests([])
            await getStatusWiseRequestCountFunc()
            await getRequestsGraphFunc()
            await getAllRequestsFunc()
            setLoading(false)
        } catch (e) {
            setLoading(false)
            Popup.alert("Error", e, "ERROR")
        }
    }

    const getRequestsGraphFunc = async () => {
        try {
            const data = await getRequestsCountInInterval(
                { ...filters, pageNumber: undefined, pageSize: undefined, services: ["Spraying service"], isDspActive: true, searchQuery : undefined },
                filters.isFilterByServiceTime,undefined,
                "Periodic"
            );
            setrequestsGraphData(data?.requests)
            return
        } catch (err) {
            console.log("err", err);
            return
        }
    };

    const handleFilterChanged=(updatedFilters)=>{
      setFilters(updatedFilters);
    }

    useEffect(() => {
        filterChanged()
        localStorage.setItem("requestFilters", JSON.stringify(filters))
    }, [filters, navigate, dsp])

    return (
        <MainContentWrapper
            containerStyle={{
                padding: "36px 53px"
            }}
        >
           
            <StatusCountContainer
                requestsStatuses={statusWiseRequestsCount}
            />
            <div className='request-table-header-title-div' style={{ fontSize: "1.5rem", fontWeight: 700,marginBottom:"15px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }} >
                    <div style={{minWidth:"150px"}} >

                    {`${'Requests'} (${requests[0]?.total_count || 0})`}
                    </div>
                </div>
            </div>
             <AdminFilterRequest
                isHideDSPFilter={dsp ? true : false}
                open={openFilter}
                filters={filters}
                onClose={() => setOpenFilter(false)}
                onFilterChange={(filterToBeApply) => {
                    let updatedFilters={...filters,...filterToBeApply,pageNumber:1}
                    handleFilterChanged(updatedFilters);
                }}
                setOpenFilter={setOpenFilter}
                screenWidth={currentWidth}
                loading={loading}
               />
            <InteractiveGraph
                showZoom
                data={requestsGraphData}
                lineType={0}
            />
            <AdminRequestTable
                tabelContainerHeight={"570px"}
                pageNumber={filters.pageNumber}
                requests={requests}
                onFilterChange={(filterToBeApply) => {
                    const updatedFilters = { ...filters, ...filterToBeApply, pageNumber: filterToBeApply.pageNumber ?? 1 }
                   handleFilterChanged(updatedFilters);
                }}
                isHideDSPFilter={dsp ? true : false}
                filters={filters}
                loading={loading}
                totalCount={requests[0]?.total_count || 0}
                pageSize={filters.pageSize}
                allCount={allRequestCount}
                onClickRow={(request) => {
                    request = { ...request, serviceType: request?.serviceType?.props?.content }
                    navigate(`${PDRL_SUCCESS_ENGI_ROUTES.REQUESTDETAILS}`, { state: { request: { type: request.type, id: request.id } } })
                }}
                view={view}
                setView={setView}
                onSearch={(searchQuery) => {
                    setFilters((prevFilters) => ({
                        ...prevFilters,
                        searchQuery,
                        pageNumber: 1,
                    }))
                }}
            />
        </MainContentWrapper>
    )
}

export default Requests