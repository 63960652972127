import React, { useEffect, useState } from "react";
import { getAllUsers, getAllCustomersCount } from "../../apis/users";
import { logoutUser } from "../../apis/authentication";
import LogOut from "../../assets/logout.png";
import { GLOBAL_STYLES } from "../../appConstants";
import { useNavigate } from "react-router-dom";
import Popup from "../../ReusableFunctions/Popup";
import Loading from "../../ReusableFunctions/Loader";
import NotFounds from "../../ReusableComponents/NotFounds";
import { ROUTES } from "../../routes";
import Search from "../../ReusableComponents/Search";
import { clearProfileFilters, getFormatedDate } from "../../ReusableFunctions/reusableFunctions";
import MainContentWrapper from "../MainContentWrapper";
import { Table, TableHeader } from "../../ReusableComponents/Table";
import Pagination from "../../ReusableComponents/Pagination";
import LoaderComponent from "../../ReusableComponents/LoaderComponent";
import TableView from "../../ReusableComponents/TableView/TableView";
const PAGE_SIZE = 20;

const UsersList = () => {
  const [users, setUsers] = useState(null);
  const [currentPage, setCurrentPage] = useState(()=>{
      let savedPageNumber= localStorage.getItem("dspCustomersPage");
            if(savedPageNumber){
                let pageNumber = JSON.parse(savedPageNumber);
                return pageNumber;
            }
       return 1;
  });
  const [customerCount, setCustomerCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const getAllCustomersFunc = async (searchString) => {
    try {
      const customers = await getAllUsers(PAGE_SIZE, currentPage, searchString);
      setUsers(customers)
      Loading.set(false)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      Loading.set(false)
      Popup.alert("Error", err, "ERROR")
    }
  }
  const getAllCustomersCountFunc = async (searchString) => {
    try {
      const usersCount = await getAllCustomersCount(searchString)
      setCustomerCount(Number(usersCount))
      getAllCustomersFunc(searchString)
    } catch (err) {
      Loading.set(false)
      Popup.alert("Error", err, "ERROR")
    }
  }

  const clearLocalStorageData = () => {
    localStorage.removeItem("requestFilters");
    localStorage.removeItem("currentRequestPage");
    localStorage.removeItem("filterDateTime")
    localStorage.removeItem("userRequestsCurrentPage")
  }

  useEffect(() => {
    Loading.set(true);
    document.title = "BhuMeet - My Customers";
    getAllCustomersCountFunc(searchQuery);
    clearProfileFilters();
    clearLocalStorageData();
  }, []);

  useEffect(() => {
    getAllCustomersCountFunc(searchQuery)
    localStorage.setItem("dspCustomersPage",currentPage);
  }, [searchQuery,currentPage])

  return (
    <>
      <MainContentWrapper header={'Customers'} rightContent={
        <Search searchValue={searchQuery} onChange={async (value) => {
        setLoading(true);
        setCurrentPage(()=>1)
        setSearchQuery(()=>value)
      }} />}>
        <TableView
          showSrNo
          loadingHeight={"639px"}
          isLoading={loading}
          onStateChange={async (changedState) => {
            setCurrentPage(changedState.pageNumber)
          }}
          onClickRow={(user) => {
            navigate(ROUTES.CUSTOMER_DETAILS, { state: { userId: user.id } })
          }}
          columns={[
            { Header: "Customer Name", accessor: "name", width: "8%" },
            { Header: "Contact No", accessor: "mobile_number", width: "8%" },
            { Header: "Paid Requests", accessor: "total_paid_requests", width: "10%" },
            { Header: "Completed Requests", accessor: "total_completed_requests", width: "10%" },
            { Header: "Accepted Requests", accessor: "total_accepted_requests", width: "10%" },
            { Header: "Cancelled Requests", accessor: "total_cancelled_requests", width: "10%" },
            { Header: "Total Requests", accessor: "total_requests", width: "10%" },

          ]}
          count={customerCount}
          pageSize={PAGE_SIZE}
          pageNumber={currentPage || undefined}
          data={users?.map((u) => ({
            ...u,
            total_requests: (Number(u.spraying_requests) + Number(u.survey_requests) + Number(u.training_requests))
          }))}
          tableContainerHeight={'681px'}
        />
      </MainContentWrapper>
    </>
  );
};

const UsersListView = ({ users, srNo, loading }) => {
  const navigate = useNavigate();
  return (<>
    {
      users && users.length > 0 ?
        <Table>
          <TableHeader>
            <th style={{ textAlign: "center" }}>Sr No.</th>
            <th>Customer Name</th>
            <th style={{ textAlign: "center" }}>Total Request</th>
            <th style={{ textAlign: "center" }}>Last Service Date</th>
          </TableHeader>
          {loading ?
            <LoaderComponent
              width={"2%"}
              top={"-8px"}
            /> :
            users.map((user, i) => (
              <tr key={user.id} className="table-row-hover" onClick={(e) => {
                navigate(ROUTES.CUSTOMER_DETAILS, { state: { userId: user.id } })
              }}>
                <td style={{ textAlign: "center" }}>{++srNo}</td>
                <td >{user.name || "-"}</td>
                <td style={{ textAlign: "center" }}>{(Number(user.spraying_requests) + Number(user.survey_requests) + Number(user.training_requests))}</td>
                <td style={{ textAlign: "center" }}>{user.last_request_date ? getFormatedDate(user.last_request_date) : "-"}</td>
              </tr>
            ))}
        </Table> :
        users && <NotFounds msg={"OOPS,No Users Found!"} hideBtn />
    }
  </>
  );
};

const LogoutButton = () => {
  return (
    <div
      onClick={logoutUser}
      style={{
        cursor: "pointer",
        background: GLOBAL_STYLES.BG_ACTIVE,
        padding: "10px",
        borderRadius: "50%",
      }}
    >
      <img src={LogOut} style={{ width: "10px" }} />
    </div>
  );
};
export { UsersList, UsersListView, LogoutButton };
