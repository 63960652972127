import { useEffect, useState } from "react";
import MainContentWrapper from "../../../Components/MainContentWrapper";
import TableView from "../../../ReusableComponents/TableView/TableView";
import {getEnquiryByDroneId,getDroneManufactures } from "../../../apis/BhuMeetTeams/Oparations/droneStore";
import Popup from "../../../ReusableFunctions/Popup";
import { PDRL_ADMIN_ROUTES, PDRL_SUCCESS_ENGI_ROUTES } from "../../../routes";
import { useNavigate } from 'react-router'
import { useLocation } from "react-router-dom";
import Search from '../../../ReusableComponents/Search'
import SelectComponent from "../../../ReusableComponents/SelectComponent";
import DroneEnquiriesDetails from "./droneEnquiryDetail";


const DroneEnquiries = ({ store, callback, }) => {
    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [selectedEnquiry,setSelectedEnquiry] = useState('')
    const [open,setOpen] = useState(false)
    const [manufactureList,setmanufactureList] = useState([])
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 15,
        fromDate: undefined,
        toDate: undefined,
        selectedManufacture: { value: null, label: "All" }
    })

    const getAllManufacturers = async()=>{
        try {
            let allmanufacturer = await getDroneManufactures()
            console.log(allmanufacturer);
            
            setmanufactureList([{ value: null, label: "All" }, ...allmanufacturer.map(p => ({
                value: p.id,
                label: `${p.name}`
            }))])
            
            

        } catch (err) {

            Popup.alert("Error", err, 'ERROR')

            
        }
    }


    const getEnquiryFunc = async (drone_model_id) => {
        try {

            setLoading(true)
            console.log(drone_model_id,store)
            const { fromDate, toDate, pageSize, pageNumber } = filters
            let stores 
            if(!store || drone_model_id){
                stores = await getEnquiryByDroneId(pageSize,pageNumber,drone_model_id == "All" ? '' : drone_model_id,true)

            }else{
            stores = await getEnquiryByDroneId(pageSize,pageNumber,store ? store.id : '',false)

            }
            // console.log(stores)
            
            setStores(stores);
            // setStores({...stores,toShow:Location.pathname})
            setLoading(false)
        } catch (err) {
            setStores([]);
            setLoading(false)
            // Popup.alert("Error", err, 'ERROR')
        }
    }

    
    useEffect(() => {
        console.log(store)
        if(store){
            getEnquiryFunc(store.id)
        }else{
        getEnquiryFunc();
        getAllManufacturers()

        }
    }, [])

    
    useEffect(() => {
        if(!store){
        getEnquiryFunc(filters.selectedManufacture.value == null ? 'All' : filters.selectedManufacture.value)

        }
    }, [filters.pageNumber])




    return (
        <MainContentWrapper header={`Enquiries (${stores[0]?.count ?? 0})`} 
        rightContent={

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* {JSON.stringify(store)} */}

            {!store &&
                <SelectComponent
                controlStyle={{ height: "40px", width: "200px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                isSearchable={true}
                placeholder={"Select pilot..."}
                menuPlacement={"bottom"}
                list={manufactureList}
                value={filters.selectedManufacture}
                onChange={(drone_model_id) => {
                    console.log(drone_model_id)

                    getEnquiryFunc(drone_model_id.value == null ? 'All' : drone_model_id.value)
                    setFilters({ ...filters, selectedManufacture: drone_model_id,pageNumber:1 });

                }}
            />
            }
            
        </div>
        }>
            <div style={{
                margin: "20px 0",
                height: "90%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"240px"}
                    isLoading={loading}
                    onStateChange={async (changedState) => {
                        const { pageNumber } = changedState;
                        console.log("pageNumber", pageNumber);
                        setFilters({ ...filters, pageNumber });
                        console.log(filters.selectedManufacture)
                        
                    }}
                    columns={[
                        { Header: "Full Name", accessor: "full_name", width: "9%" },
                        { Header: "Drone Model", accessor: "drone_model", width: "9%" },
                        { Header: "Manufacturer", accessor: "manufacture_name", width: "9%" },
                        // { Header: "Comment", accessor: "comment", width: "9%" },
                        
                        { Header: "Added Date", accessor: "date_added", isDate: true, format: "dd/mm/yyyy", width: "10%" },
                        // { Header: "Status", accessor: "active_label", width: "5%", showColor: true },
                    ]}
                    count={stores[0]?.count ?? 0}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={stores.map(st => {
                        return {
                            ...st,
                            active_label: st.is_active ? "Active" : "Inactive",
                            color: st.is_active ? 'green' : 'red'
                        }
                    })}
                    onClickRow={(value)=>{
                        setSelectedEnquiry(value)
                        console.log(value)
                        setOpen(true)
                    }}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                />


                <DroneEnquiriesDetails open={open} order={selectedEnquiry}  onClose={()=>{setOpen(false)}}/>
            </div>
        </MainContentWrapper>
    )
}

export default DroneEnquiries