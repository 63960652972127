import React, { useEffect, useState } from "react";
import MainContentWrapper from "../../Components/MainContentWrapper";
import DashBoardCard from "../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard";
import { getTelecallerDashboardOverViewData } from "../../apis/BhuMeetTeams/Telecaller/dashboard";
import { getRequestQueue, reShuffleRequestQueue } from "../../apis/BhuMeetTeams/Telecaller/requestqueue";
import TableView from "../../ReusableComponents/TableView/TableView";
import Loading from "../../ReusableFunctions/Loader";
import { useNavigate } from "react-router";
import { PDRL_SUCCESS_ENGI_ROUTES } from "../../routes";
import { useSelector } from "react-redux";
import { fi } from "date-fns/locale";
import { Button } from "../../Components/Helper";
import Filter from "../../assets/filter.svg";
import TeleDashboardFilter from "./teleCallerFilter";
import StatusCountContainer from "../../AdminComponents/Requests/SubComponents/StatusCountContainer";
import Popup from "../../ReusableFunctions/Popup";

const InvoicesStatCard = ({
  title,
  statNumber,
  isSelected,
  onClick,
  isDisabled,
  isArray
}) => {
  return (
    <DashBoardCard
      isDisabled={isDisabled}
      title={title}
      data={statNumber}
      onClick={onClick}
      backGround={isSelected ? "green" : undefined}
      isArray={isArray}
    />
  );
};

const InvoiceStats = ({ invoicesStats, filters, changeStatusFilter, ArrayData }) => {
  return (
    <>
      {filters &&
        <div style={{ display: "flex", columnGap: "2%", marginBottom: "10px" }}>


          <InvoicesStatCard
            title={`All Request`}
            statNumber={invoicesStats?.total}
            isSelected={filters.Status === ""}
            onClick={() =>
              changeStatusFilter({
                Status: "",
                Query: "",
                count: invoicesStats?.total,
                currentPage: 1
              })
            }
          />



          <InvoicesStatCard
            title={`Open`}
            statNumber={invoicesStats?.open_count}
            isSelected={filters.Status === "open"}
            onClick={() =>
              changeStatusFilter({
                Status: "open",
                Query: "open",
                count: invoicesStats?.open_count,
                currentPage: 1
              })
            }
          />



          <InvoicesStatCard
            title={`Close`}
            statNumber={invoicesStats?.close_count}
            isSelected={filters.Status === "close"}
            onClick={() =>
              changeStatusFilter({
                Status: "close",
                Query: "close",
                count: invoicesStats?.close_count,
                currentPage: 1
              })
            }
            isArray={ArrayData && filters.Status == 'close' ? ArrayData : null}
          />


        </div>}
    </>

  );
};

export const TeleCallerDashBoard = () => {
  const userDetails = useSelector(state => state.user?.user)

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  // const [PageNumber, setPageNumber] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);

  const [overviewData, setOverviewData] = useState({});
  const [RequestQueueData, setRequestQueueData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callHistoryOverview, setCallHistoryOverview] = useState([]);

  const [filter, setFilter] = useState(() => {
    const getFilters = localStorage.getItem("telecallerFilter");
    return getFilters ? JSON.parse(getFilters) : {
      fromDate: null,
      toDate: null,
      Status: "open",
      pageSize: 15,
      count: 0,
      currentPage: 1,
      Substatus: [],
      selectedCaller: ""
    };
  });



  const getOverViewData = () => {
    if (filter) {

      getTelecallerDashboardOverViewData(userDetails.bhumeet_role == 'Admin' ? "" : userDetails.id, JSON.stringify(filter)).then((res) => {
        setOverviewData(res.overviewData);
        // setFilter({...filter,count:res.overviewData.total})
        if (filter.Status == 'close') {
          const output = Object.entries(res.callHistoryOverviewData).map(([status, count]) => ({
            status,
            count: Number(count)
          }));
          // console.log(output)
          setCallHistoryOverview(output);
        }

      });
    }

  }

  useEffect(() => {
    getOverViewData()
    getTelecallers();
    // setCurrentPage(1)

    localStorage.setItem('telecallerFilter', JSON.stringify(filter))
    // console.log(filters)


  }, [filter]);

  // useEffect(()=>{
  //   getTelecallers();

  // },[currentPage])

  useEffect(() => {
    setCurrentPage(1)
  }, [filter.Status])

  const getTelecallers = () => {
    // console.log(filter)
    if (userDetails && filter != null) {
      setLoading(true);
      getRequestQueue(
        JSON.stringify(filter),
        userDetails.bhumeet_role == 'Admin' ? "" : userDetails.id,
        filter.currentPage,
        filter.pageSize || 15
      ).then((res) => {
        console.log(res);

        setRequestQueueData(res);
        setLoading(false);
      });
    }
  };

  const paginationCount = () => {
    // console.log(filter)
    if (filter.Status == '') {
      return Number(overviewData.total)
    } else if (filter.Status == 'open') {
      return Number(overviewData.open_count)
    } else {
      return Number(overviewData.close_count)

    }
  }

  const reshuffle = ()=>{
    reShuffleRequestQueue().then(res=>{
      // console.log(res)
      return Popup.alert("SUCCESS","Successfully Shuffled all request","SUCCESS")
    })
  }

  return (
    <MainContentWrapper header={"TeleCaller Dashboard"}

      rightContent={<>
      <div style={{
        display:"flex",
        justifyContent:"space-between",
        gap:"5px"
      }}>
        {userDetails.bhumeet_role == 'Admin' && 
          <Button
          text={"Reshuffle"}
          onClick={() => {
            reshuffle()
          }}
          style={{ width: "130px", height: "38px", fontSize: "15px", marginRight: "10px" }}

        />  
        }
      
        <Button
          innerClassName={"filter-btn-inner"}
          iconStyle={{ width: "18px", marginRight: "10px" }}
          text={"Filter"}
          icon={Filter}
          className={"filter-btn"}
          onClick={() => {
            setOpenFilter(true)
          }}

        />
        </div>

        
      </>


      }



    >
      {/* {callHistoryOverview.length > 0 && filter.Status == 'close' && 
      <StatusCountContainer
      requestsStatuses={callHistoryOverview}
      />
      } */}

      {openFilter &&
        <TeleDashboardFilter
          filter={filter}
          onFilterChange={(filter) => {
            // console.log(filter)
            setCurrentPage(1)
            setOpenFilter(false)
            setFilter(filter)

          }}
          onClose={() => setOpenFilter(false)}
          open={openFilter}
        />
      }
      {userDetails.bhumeet_role == 'Admin' &&
        <InvoiceStats
          invoicesStats={overviewData}
          filters={filter}
          // ArrayData = {callHistoryOverview}
          changeStatusFilter={(statusFiler) => {
            // console.log(statusFiler);
            setFilter({
              ...filter,
              count: statusFiler.count,
              Status: statusFiler.Status,
              currentPage: statusFiler.currentPage
            });
          }}
        />
      }


      <div>
        {
          callHistoryOverview && filter.Status == 'close' &&
          <>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "inherit", width: "31%", marginLeft: "69%", marginBottom: "10px" }}>
              {
                callHistoryOverview.map((item, index) => {

                  return (
                    <>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "11px", height: "90%" }}>
                        <div style={{ color: "blue" }} className="dashboard-card-parent-inner1 dashboard-cards-heading" >{item.status.replace("_", " ")
                          .split(" ")
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(" ").replace("Coudnt", "Couldn't")}
                        </div>
                        <div style={{ marginLeft: '50%', fontSize: "medium" }} className="dashboard-card-parent-inner2 dashboard-cards-main-data"> {item || item.count === 0 ? item.count : ''}</div>
                      </div>
                    </>
                  )
                })
              }
            </div>
          </>
        }

      </div>

      <TableView
        onStateChange={async (changedState) => {
          const { pageNumber } = changedState;
          // console.log(changedState);
          setCurrentPage(() => pageNumber);
          setFilter({ ...filter, currentPage: pageNumber })

        }}
        onClickRow={(row) => {
          
          // if((RequestQueueData[0].queueid == row.queueid && filter.currentPage == 1) || userDetails.bhumeet_role == 'Admin'){
            navigate(`${PDRL_SUCCESS_ENGI_ROUTES.REQUESTDETAILS}`, {
              state: { request: { type: "Spraying service", id: row.reqid, show: true, Data: row } },
            });
          // }else{
          //   return Popup.alert("WARNING","Please select the first row to view the request details","ERROR")
          // }
          
        }}
        isLoading={loading}
        tableContainerHeight={"75%"}
        loaderHeight={"500px"}
        columns={[
          { Header: "Request Number", accessor: "req_number", width: "15%" },
          ...(userDetails.bhumeet_role === "Admin"
            ? [{ Header: "Assignee", accessor: "name", width: "15%" }]
            : []),
          { Header: "Note", accessor: "note" },
          { Header: "Status", accessor: "status" },
          { Header: "Date", accessor: "dateadded", isDate: true },
        ]}
        data={RequestQueueData}
        pageNumber={filter.currentPage ?? 1}
        pageSize={15}
        count={paginationCount()}

      // activities={{ onClickDelete: (coupon) => Popup.alert("WARNING", "Are you sure , you want to delete this coupon?", "WARNING", () => deleteCoupon(coupon)), onClickEdit: onEdit, canEdit: true }}
      />
    </MainContentWrapper>
  );
};
