import React, { useState } from "react";
import AddLocation from "./AddLocation";
import { GLOBAL_STYLES } from "../../../appConstants";
import Toggle from "react-toggle";
import editIcon from "../../../assets/editIcon.svg";
import Tippy from '@tippyjs/react';
import { addLeadingZero, reduceLongName } from '../../../ReusableFunctions/reusableFunctions'
import LoaderComponent from "../../../ReusableComponents/LoaderComponent"
import Popup from "../../../ReusableFunctions/Popup";
import { updateLocation } from "../../../apis/BhuMeetTeams/Oparations/locations";
const LocationCard = ({ location, onUpdateLocation, isEditDisabled }) => {
  const [openUpdateLocationPopup, setOpenUpdateLocationPopup] = useState(false);
  const [loading, setLoading] = useState(false)
  const locationUpdated = async (location) => {
    return new Promise(async (resolve, reject) => {

      try {
        await updateLocation(location);
        onUpdateLocation(location);
        resolve()
        setLoading(false)
      } catch (e) {
        resolve()
        setLoading(false)
        Popup.alert("Error", e, "ERROR");
      }
    })
  };

  return (
    <>
      {openUpdateLocationPopup && (
        <AddLocation
          location={location}
          open={openUpdateLocationPopup}
          onClose={() => setOpenUpdateLocationPopup(false)}
          onUpdateLocation={onUpdateLocation}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "320px",
          border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
          borderRadius: "15px",
          padding: "0px",
          height: "340px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          className="location-img"
          style={{ objectFit: "cover" }}
          onClick={() => setOpenUpdateLocationPopup(!isEditDisabled)}
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${location.lat + "," + location.lng
            }&zoom=10&size=300x200&maptype=hybrid&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY
            }`}
        ></img>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "0px",
            position: "absolute",
            left: 0,
            bottom: 0,
            zIndex: 1,
            width: "100%",
            height: "158px",
            zIndex: 2,
            fontFamily: "Manrope",
            background: "white",
            padding: "17px 15px",
            fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL,
          }}
        >
          {/* upper row */}
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            minHeight: '43px',
          }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: '10px',
                  fontWeight: 500,
                  color: "#1E1E1E",
                  maxWidth: '150px',
                  lineHeight: 1.1
                }}
              >
                {location.name.split(",")[0]}
              </div>
              <div
                style={{
                  color: location.is_active
                    ? GLOBAL_STYLES.ACTIVE
                    : GLOBAL_STYLES.INACTIVE_GREY_COLOR,
                  fontWeight: 600,
                  fontSize: '10px',
                  letterSpacing: '0.2px',
                }}
              >
                {location.is_active ? "Active" : "Inactive"}
              </div>
            </div>
            <div style={{ marginTop: "0px" }}>
              <Tippy content={location?.address}>
                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB, color: '#3B3B3B', maxHeight: "20px", fontSize: "10px", fontWeight: 400, lineHeight: 1.3 }}>
                  {reduceLongName(location?.address, 45)}
                </div>
              </Tippy>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              minHeight: '25px',
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >

            <div style={{ position: "relative", pointerEvents: isEditDisabled ? "none" : undefined, }}>

              <div
                style={{
                  display: "flex",
                  columnGap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "15.5px",
                  letterSpacing: "0.1px",
                  fontWeight: 500,
                }}
              >
                <label style={{ fontSize: "12px", color: "#3c3c3c" }}>Disable</label>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <Toggle
                    checked={location.is_active}
                    style={{ height: "5px" }}
                    icons={false}
                    onChange={async (e) => {
                      console.log("onChnaged called ",e.target.checked);
                      setLoading(true)
                      await locationUpdated({ ...location, is_active: e.target.checked });
                    }}
                  />
                </label>
                <labe style={{ fontSize: "12px", color: "#3c3c3c" }}>Enable</labe>
              </div>
              {loading && <LoaderComponent left={'70%'} top={"-5px"} height={"10%"} width={"10%"} />}
            </div>
            <div>
              <img
                src={editIcon}
                style={{ height: "21px", cursor: isEditDisabled ? undefined : "pointer", pointerEvents: isEditDisabled ? "none" : undefined }}
                onClick={() => {
                  setOpenUpdateLocationPopup(true);
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: '4px 8px',
              textAlign: 'center',
              justifyContent: "space-between",
              minHeight: "75px",
              marginTop: isEditDisabled ? "10px" : "5px",


            }}
          >
            <div
              style={{
                color: "#727272",
                fontSize: "16px",
                fontWeight: 400,
                display: "flex",
                flexDirection: "column",
                gap: "0.2rem",
              }}>
              <div style={{ fontSize: "12px" }}>Orders</div>
              <div style={{
                fontSize: "20px",
                color: location.total_requests == 0 ? "#727272" : "#000000",
                fontWeight: 500
              }}
              >
                {addLeadingZero(location.total_requests)}
              </div>
            </div>
            <div
              style={{
                height: "95%",
                width: "0.8px",
                margin: '0px 4px',
                background: GLOBAL_STYLES.BORDER_COLOR,
              }}
            ></div>
            <div
              style={{
                color: "#727272",
                fontSize: "16px",
                fontWeight: 400,
                display: "flex",
                flexDirection: "column",
                gap: "0.2rem",
              }}
            >
              <div style={{ fontSize: "12px" }}>Pilots</div>
              <div style={{
                fontSize: "20px",
                color: location.pilots_count == 0 ? "#727272" : "#000000",
                fontWeight: 500
              }}>{addLeadingZero(location.pilots_count)}</div>
            </div>
            <div
              style={{
                height: "95%",
                width: "0.8px",
                margin: '0px 4px',
                background: GLOBAL_STYLES.BORDER_COLOR,
              }}
            ></div>
            <div
              style={{
                color: "#727272",
                fontSize: "16px",
                fontWeight: 400,
                display: "flex",
                flexDirection: "column",
                gap: "0.2rem",
              }}
            >
              <div style={{ fontSize: "12px" }}>Drones</div>
              <div style={{
                fontSize: "20px",
                fontWeight: 500,
                color: location.drones_count == 0 ? "#727272" : "#000000"
              }}>
                {addLeadingZero(location.drones_count)}
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationCard;
