import { useEffect, useState } from "react";
import MainContentWrapper from "../../Components/MainContentWrapper";
import TableView from "../../ReusableComponents/TableView/TableView";
import { getAllStores } from "../../apis/BhuMeetTeams/Oparations/store";
import Popup from "../../ReusableFunctions/Popup";
import { PDRL_ADMIN_ROUTES, PDRL_SUCCESS_ENGI_ROUTES } from "../../routes";
import { useNavigate } from 'react-router'
import { useLocation } from "react-router-dom";
import search from "../../assets/search.svg";


const Stores = () => {
    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState("Individual")
    const [individualTabDisabled, setIndividualTabDisabled] = useState(false);
    const [retailerTabDisabled, setRetailerTabDisabled] = useState(false);
    const navigate = useNavigate();
    const [path,setPath] = useState('')
    const [filters, setFilters] = useState(()=>{
        const savedFilters = localStorage.getItem('adminStoresFilters')
        if (savedFilters) {
            const parsesavedFilters = JSON.parse(savedFilters)
            return {...parsesavedFilters, searchQuery:""};  
        }
       return {
        pageNumber: 1,
        pageSize: 15,
        fromDate: undefined,
        toDate: undefined,
        type:"Individual",
        searchQuery:"",
    }
    })

    const Location = useLocation()


    const getAllStoresFunc = async (filters) => {
        try {
            setLoading(true)
            if (filters?.type === 'Individual') {
                setRetailerTabDisabled(true);
            } else {
                setIndividualTabDisabled(true);
            }
            const { fromDate, toDate, pageSize, pageNumber,type,searchQuery} = filters
            let stores
            if(Location.pathname === '/bhumeet_team/cse/store'){
                stores = await getAllStores(fromDate, toDate, pageSize, pageNumber,type,searchQuery,'/bhumeet_team/customerSuccessEngineer/stores')
                setPath(PDRL_SUCCESS_ENGI_ROUTES.CUSTSTOREDETAILS)
                stores.forEach(x=>{
                    x['toShow'] = false
                })
            }else{
                stores = await getAllStores(fromDate, toDate, pageSize, pageNumber,type,searchQuery,'/bhumeet_team/admin/stores')
                setPath(PDRL_ADMIN_ROUTES.STOREDETAILS)
                stores.forEach(x=>{
                    x['toShow'] = true
                })
            }
            
            setStores(stores);
            // setStores({...stores,toShow:Location.pathname})
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Popup.alert("Error", err, 'ERROR')
        } finally {
            setTimeout(() => {
                setRetailerTabDisabled(false);
                setIndividualTabDisabled(false);
            }, 1200);
        }
    }

    useEffect(() => {
        getAllStoresFunc(filters);
        localStorage.setItem("adminStoresFilters",JSON.stringify(filters));
    }, [filters])

    useEffect(()=>{
        console.log(Location)
    },[])

    const handleTabSwitch = async (tabType) => {
        if (tabType === 'Individual') {
            setActiveTab("Individual")
        } else {
            setActiveTab("Retailer")
        }
        setFilters(prevFilters => ({ ...prevFilters,pageNumber:1 ,type: tabType }));
    };

    return (
        <MainContentWrapper header={`Stores (${stores[0]?.total_stores ? stores[0]?.total_stores : 0})`} rightContent={
        <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px",gap:"1rem" ,alignItems:"center",marginTop:"20px"}}>
            <div style={{ position: "relative", height: "40px", width: "400px" }}>
                    <img src={search} alt='search icon' style={{ position: 'absolute', left: "20px", top: "10px" }} />
                    <input onChange={(e) =>{
                        setFilters(prevFilters => ({...prevFilters, searchQuery: e.target.value ,pageNumber:1}));
                    }}
                        placeholder='Search store by name,code,mobileNo...'
                        style={{ width: "100%", height: '100%', borderRadius: "4px", border: "1px solid #BFC8D9", padding: "10px 10px 10px 60px", fontSize: "12px" }} />
            </div>
             <div style={{display:"flex",marginTop:"-5px"}}>
                <div
                    style={{
                        fontSize: '13px',
                        color: 'rgb(59, 59, 59)',
                        marginTop: '10px',
                        whiteSpace: 'nowrap',
                        marginRight: '10px',
                    }}
                >
                    Type
                </div>
                <div className="inline-flex rounded-full border border-gray-300 w-full">
                    <button
                    disabled={individualTabDisabled}
                        type="button"
                        className={`px-4 py-2 text-sm rounded-full transition-colors w-1/2 ${filters.type === 'Individual'
                                ? 'bg-blue-100 text-blue-800 border border-[#0F5EFF]'
                                : 'bg-transparent text-gray-700 hover:bg-gray-100'
                            }`}
                        onClick={()=>handleTabSwitch("Individual")}  
                    >
                        Individual
                    </button>
                    <button
                    disabled={retailerTabDisabled}
                        type="button"
                        className={`px-4 py-2 text-sm rounded-full transition-colors w-1/2 ${filters.type === 'Retailer'
                                ? 'bg-blue-100 text-blue-800 border border-[#0F5EFF]'
                                : 'bg-transparent text-gray-700 hover:bg-gray-100'
                            }`}
                            onClick={()=>handleTabSwitch("Retailer")}  
                    >
                        Retailer
                    </button>
                </div>
            </div>
            
        </div>
        }>
            <div style={{
                margin: "20px 0",
                height: "83%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"500px"}
                    isLoading={loading}
                    onStateChange={async (changedState) => {
                        const { pageNumber } = changedState;
                        console.log("pageNumber", pageNumber);
                        setFilters({ ...filters, pageNumber });
                    }}
                    onClickRow={(store) => {
                        console.log("store", store);
                        navigate(path, { state: { store: store } })
                    }}
                    columns={[
                        { Header: "Store No.", accessor: "store_code", width: "5%" },
                        { Header: "Store Name", accessor: "store_name", width: "9%" },
                        { Header: "Owner Name", accessor: "owner_name", width: "9%" },
                        { Header: "Incentive Amount", accessor: "store_incentive_balance", width: "9%" },
                        { Header: "Address", accessor: "address", width: "20%" },
                        { Header: "Status", accessor: "active_label", width: "5%", showColor: true },
                    ]}
                    count={stores[0]?.total_stores}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={stores.map(st => {
                        return {
                            ...st,
                            active_label: st.is_active ? "Active" : "Inactive",
                            color: st.is_active ? 'green' : 'red'
                        }
                    })}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                />
            </div>
        </MainContentWrapper>
    )
}

export default Stores