import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../Components/Helper'
import { ROUTES } from '../../../routes'
import { capitalizeFirstLetter, reduceLongName, squareKilometersToAcres } from '../../../ReusableFunctions/reusableFunctions'
import { AEROMEGH_SERVICES, STATUS, STATUS_MESSAGES } from '../../../appConstants'
import dateFormat from 'dateformat';
import useWindowSize from '../../../hooks/useWindowSize'
import MainContentWrapper from '../../../Components/MainContentWrapper'
import { useLocation, useNavigate } from 'react-router'
import VerticalStepper from '../../../ReusableComponents/VerticalStepper'
import { cancelRequest, getRequest } from '../../../apis/requests'
import RequestDiscount from '../../../ReusableComponents/RequestDiscount'
import Loading from '../../../ReusableFunctions/Loader'
import L from 'leaflet'
import roadMapView from '../../../assets/roadMapView.png'
import sateliteView from '../../../assets/sateliteView.png'
import Map from "../../../features/LeafletMap/Map"
import Popup from "../../../ReusableFunctions/Popup";
import MarkerIcon from "../../../assets/marker.png";
import { MAP_URLS } from "../../../appConstants";
import fullScreenIcon from '../../../assets/fullScreenIcon.png';
import collapseIcon from '../../../assets/collapse.png';
import pilotIcon from "../../../assets/pilot.svg";
import TableView from '../../../ReusableComponents/TableView/TableView'
import { getTelecallingHistory } from '../../../apis/BhuMeetTeams/Telecaller/dashboard'
import { AddcallHistory } from '../../../BhuMeetTeams/BhumeetTeleCaller/createCallHistory'
import { useSelector } from 'react-redux'
import { CallHistoryTable } from '../../../BhuMeetTeams/BhumeetTeleCaller/callHistoryTable'
import { getCallhistorybyCampid, InitiateCall, updateCallingHistoryStatus } from '../../../apis/BhuMeetTeams/Telecaller/callingHistory'
import { getCallreport } from '../../../apis/BhuMeetTeams/Telecaller/requestqueue'

let baseLayer;

const ViewRequestDetails = () => {
    const userDetails = useSelector(state => state.user?.user)

    const { state } = useLocation();
    const [steps, setSteps] = useState([])
    const [request, setRequest] = useState({})
    const navigate = useNavigate();
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isTrainingService = state.request?.type === AEROMEGH_SERVICES.TRAINING
    const isSprayingService = state.request?.type === AEROMEGH_SERVICES.SPRAYING;
    const isSurveyService = state.request?.type === AEROMEGH_SERVICES.SURVEY;
    const [activeBaseLayer, setActiveBaseLayer] = useState(baseLayer)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [opencallHistory, setopencallHistory] = useState(false);
    const { pathname } = useLocation();
    const [refresh, setRefresh] = useState(0);
    const [center, setCenter] = useState(
        { lat: 19.993982, lng: 73.790416 }
    );
    const [callHistory, setcallHistory] = useState([]);
    const mapContainerRef = useRef(null);
    const [map, setMap] = useState(null)

    const [fieldData, setFieldData] = useState([]);

    const [campid, setCampid] = useState(null)
    const [lastRecord, setlastRecord] = useState(false)
    const [callActive, setCallActive] = useState(false)

    const checkExistingCalls = (data,refresh) => {
        // console.log(data)
        let Count = data.filter(x => {
            return x.callingQueueID = state.request.Data.queueid
        })
        console.log(Count)
        if (Count.length >= 2) {
            setlastRecord(true)
            if(refresh) setRefresh((prev) => prev + 1);

            return true
        } else {
            setlastRecord(false)
            return false
        }
    }

    useEffect(() => {
        // console.log(campid)
        setRefresh((prev) => prev + 1);

    }, [campid])


    const handleCallClick = (contactValue) => {
        // console.log(userDetails,contactValue)
        if (contactValue) {


            let check = checkExistingCalls(callHistory)
            // console.log(check)
            if (!check) {
                setCallActive(true)
                InitiateCall(Number(contactValue), state.request.Data, userDetails.phone).then(res => {
                    // console.log(res)
                   
                    
                    checkCallReport(res.callDetail.campid)

                    return Popup.alert("SUCCESS", res.message, "SUCCESS")
                }).catch(err => {
                    console.log(err)
                    setCallActive(false)
                    return Popup.alert("ERROR", err, "ERROR")
                })
            }
            // setlastRecord(false)


            // You can integrate further logic like API calls, etc.
        } else {
            return Popup.alert("ERROR", "No contact number available.", "ERROR")

        }


    };

    const Field = ({ label, value, overflow, onCallClick }) => {
        const isContactNo = label === "Contact No.";
        // console.log(label,value)
        return (
            <div className="single_field_wrapper" style={{ lineHeight: overflow ? 1.15 : 1.1 }}>
                {isContactNo && userDetails.bhumeet_role == 'Telecaller' ? (
                    <>
                        <div className='single_field_label' >{label}</div>
                        
                        <Button style={{ whiteSpace: "nowrap", marginBottom: "5px", width: "100px" }}
                            type="submit"
                            // icon={Plus}
                            isDisabled={callActive || lastRecord}
                            text={callActive ? "Call InProgress" : "Click to Call"}
                            onClick={() => { onCallClick(value); }}
                        />
                    </>
                ) : (
                    <div className='single_field_wrapper' onClick={() => {
                        if ((label === "Customer Name" || label === "Contact No.") && userDetails.bhumeet_role != 'Telecaller') {
                            navigate('/userDetails', { state: { user: request.user } });
                        }
                    }} style={{ cursor: (label === "Customer Name" && userDetails.bhumeet_role != 'Telecaller') || label === "Contact No." ? "pointer" : "default", lineHeight: overflow ? 1.15 : 1.1 }}>
                        <div className='single_field_label' >{label}</div>
                        <div className='single_field_value' style={{
                            maxHeight: overflow && value?.length > 120 ? "100px" : "none",
                            overflowY: overflow && value?.length > 120 ? "auto" : "hidden"
                        }}>
                            {value || "-"}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const checkCallReport = async (campid) => {
        try {

            const interval = setInterval(async () => {
                const CallReport = await getCallreport()

                let findspecificCall = CallReport.filter(x => x.sid_id == campid)

                if (findspecificCall[0]?.sid_id) {
                    // console.log("Found",findspecificCall)
                    setCampid(findspecificCall[0]);
                    // setlastRecord(true)
                    setRefresh((prev) => prev + 1);
                    
                    // Stop further executions as the condition is met
                    setCallActive(false)
                    clearInterval(interval)


                }

            }, 5000)


        } catch (err) {
            console.log(err)

        }
    }

    const getRequestDetails = async () => {
        let requestData = await getRequest({ type: state.request.type, id: state.request.id }, true, true)

        setRequest({ ...request, ...requestData.request })
        setRequest((prev) => ({ ...prev, type: prev.invoice.request_type }))

        if (requestData.request.status === 'Placed') {
            setIsButtonVisible(true)
        } else {
            setIsButtonVisible(false)
        }
        setSteps(requestData.request.status_history.map(record => {
            return {
                label: record.updated_status,
                content: <>
                    <div style={{
                        color: "rgb(59, 59, 59)",
                        fontSize: "13px",
                        fontWeight: "500",
                        wordBreak: "break-word",
                        marginBottom: "0px",
                    }}>{record.updated_status == STATUS.PLACED ? STATUS_MESSAGES.PLACED :
                        record.updated_status == STATUS.ACCEPTED ? STATUS_MESSAGES.ACCEPTED :
                            record.updated_status == STATUS.RESCHEDULED ? STATUS_MESSAGES.RESCHEDULED :
                                record.updated_status == STATUS.OUT_FOR_SERVICE ? STATUS_MESSAGES.OUT_FOR_SERVICE :
                                    record.updated_status == STATUS.INPROGRESS ? STATUS_MESSAGES.INPROGRESS :
                                        record.updated_status == STATUS.COMPLETED ? STATUS_MESSAGES.COMPLETED :
                                            record.updated_status == STATUS.PAID ? STATUS_MESSAGES.PAID :
                                                record.updated_status == STATUS.CANCELLED ? STATUS_MESSAGES.CANCELLED :
                                                    record.updated_status == STATUS.HOLD ? STATUS_MESSAGES.HOLD :
                                                        ""
                        }</div>
                    {record?.note && <div style={{ lineHeight: '16px', margin: '5px 0px', fontSize: '13px', }}>
                        <span style={{ marginRight: '3px', fontWeight: 500, }}>Note :</span><span style={{ color: "#3b3b3b", fontWeight: 500 }}>{record.note}</span>
                        <br />
                        <span style={{ marginRight: '3px', fontWeight: 500, }}>Updated by :</span><span style={{ color: "#3b3b3b", fontWeight: 500 }}>{record.updated_by}</span>
                    </div>}
                    <div style={{ color: "#727272", fontSize: "13px", fontWeight: "600" }}>{`${dateFormat(new Date(record.time), "h : MM TT")}, ${dateFormat(new Date(record.time), "d mmmm yyyy")}`}</div>
                </>,
                statusColor: record.updated_status == STATUS.RESCHEDULED ? "#E27444" : record.updated_status == STATUS.CANCELLED ? "#EB0000" : "#32B338",
                highlightStatus: record.updated_status == STATUS.RESCHEDULED || record.updated_status == STATUS.CANCELLED
            }
        }))
    }
    const markerIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [24, 32],
    });
    const PilotIcon = L.icon({
        iconUrl: pilotIcon,
        iconSize: [30, 36],
    });

    const initMap = (map) => {
        setMap(map)
        // console.log(request)

    };



    const switchBaseLayer = () => {
        const activeLayer = activeBaseLayer == "satelite" ? "terrain" : "satelite"
        setActiveBaseLayer(activeLayer)
        if (baseLayer) {
            baseLayer.setUrl(activeLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
        }
    }

    const initMapData = () => {
        var markerOptions = {
            clickable: true,
            draggable: false,
            icon: markerIcon,
        };
        // console.log(request)

        const center = { lat: request.field_lat, lng: request.field_lng };
        let marker = new L.marker(center, markerOptions);
        marker.on('click', function () {
            map.flyTo(center, Math.min(map.getZoom() + 1, map.getMaxZoom()));
        });
        const completedStatus = request.status_history?.find(status => status.updated_status === STATUS.COMPLETED);
        if (completedStatus && completedStatus.pilot_lat && completedStatus.pilot_lng) {
            const pilotMarkerOptions = {
                clickable: true,
                draggable: false,
                icon: PilotIcon,
            };
            const pilotLocation = { lat: completedStatus.pilot_lat, lng: completedStatus.pilot_lng };
            let pilotMarker = new L.marker(pilotLocation, pilotMarkerOptions);
            pilotMarker.addTo(map);
        }
        map?.addLayer(marker);
        const bounds = L.latLngBounds([center]);
        map?.fitBounds(bounds, { maxZoom: 16 })
    }
    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            mapContainerRef.current.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        } else {
            document.exitFullscreen();
        }
    }



    useEffect(() => {

        Loading.set(true)
        getRequestDetails()


    }, [])

    useEffect(()=>{
        setRefresh((prev) => prev + 1);

    },[lastRecord])

    useEffect(() => {
        // console.log(state)
        if (request.field_lat && request.field_lng) {
            initMapData();

            // console.log(isSprayingService || isSurveyService)
            const InitialfieldData = [
                { label: 'Sales By', value: request?.sales_by },
                { label: 'DSP Name', value: request?.owner?.name },
                { label: 'Request Number', value: request?.req_number },
                { label: 'Customer Name', value: request?.user?.name },
                { label: 'Location', value: request?.location_address, overflow: true },
                { label: 'Contact No.', value: request?.user?.mobile_number },
                // { label: 'Service', value: state.request.type },
                { label: 'Service Location', value: request?.field_address, overflow: true },
                (isSprayingService || isSurveyService) && { label: 'Drone Type', value: request?.type },
                isSprayingService && { label: 'Crop', value: request?.crop_name },
                isSprayingService && { label: 'Agro Chemicals', value: request?.pesticides },
                (isSprayingService || isSurveyService) && { label: 'Drone UIN', value: request?.drone_uin },
                isTrainingService && { label: 'Date', value: request?.date_created ? dateFormat(request.date_created, "dd/mm/yyyy") : "-" },
                isTrainingService && { label: 'Time', value: request?.date_created ? dateFormat(request.date_created, "hh:MM TT") : "-" },
                (isSprayingService || isSurveyService) && { label: 'Date', value: request?.time ? dateFormat(request.time, "dd/mm/yyyy") : "-" },
                // (isSprayingService || isSurveyService) && { label: 'Time', value: request?.time ? dateFormat(request.time, "hh:MM TT") : "-" },
                (isSprayingService || isSurveyService) && {
                    label: 'Pilot',
                    value: request?.pilot_name ? reduceLongName(request.pilot_name, 20) : "-"
                },
                {
                    label: `Status`,
                    value: `${request?.status} ${request.status === STATUS.PAID ? `(${request.payment_method === "Offline" ? "Cash" : "UPI"})` : ""}`
                },
                isTrainingService && { label: 'Price', value: request?.price ? request.price + " Rs." : "-" },
                (isSprayingService) && { label: request?.price_by === "acre" ? `Requested Area` : "Requested No of Tank ", value: request?.price_by === "tank" ? request?.field_area : request?.price_by === "acre" ? Number(squareKilometersToAcres(request?.field_area)).toFixed(2) + " Acre" : "-" },
                (isSurveyService) && { label: 'Requested Area', value: request?.field_area ? Number(request?.field_area).toFixed(4) + " Sq/km" : "-" },
                ((isSprayingService && request?.service_completed_area) && {
                    label: request.price_by === "acre" ? "Completed Area" : "Completed No Of Tank", value: (request.price_by === "acre" ? Number(squareKilometersToAcres(request.service_completed_area)).toFixed(4) + " Acre" : request.service_completed_area) || "-"
                })
            ].filter(Boolean);
            setFieldData(InitialfieldData)
        }

        Loading.set(false);
        checkExistingCalls(callHistory)
    }, [request])

    return (<><MainContentWrapper rightContent={
        <>
            <Button
                type="submit"
                // icon={Plus}
                text={"Add News"}
                onClick={() => {
                    // setSelectedManufacturer(null)
                    // setOpenManufacturerPopup(true)
                }}
            />
        </>
    } >


        {state.request.show &&
            <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {opencallHistory && <AddcallHistory open={opencallHistory} data={state.request.Data} onClose={() => {
                        setopencallHistory(false);
                        setRefresh((prev) => prev + 1);
                    }} />
                    }

                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "12%",
                            fontSize: "1rem",
                            alignItems: "center",
                            color: "#1E1E1E",
                            fontWeight: "500",
                        }}
                    >
                        <div>Calling History</div>
                    </div>


                </div>
                 <CallHistoryTable lastRecord={lastRecord} key={refresh} id={state.request.id} campid={campid} onClose={() => { setCampid(null); checkExistingCalls(callHistory) }} callhistoryData={(e) => { setcallHistory(e) }} />

            </>
        }





        <div style={{ marginTop: "10px", background: "white", height: "auto", width: "100%", }}>

            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "12%",
                    padding: "20px 30px",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.24)",
                    fontSize: "1rem",
                    display: "flex",
                    justifyContent: "space-between", // This aligns left and right
                    alignItems: "center",
                    color: "#1E1E1E",
                    fontWeight: "500",
                }}
            >
                {/* Left content */}
                <div>Request Details</div>
            </div>



            <div style={{ background: "white", position: "relative", display: "flex", width: "100%" }}>
                <div style={{ width: "100%", height: "auto", borderRight: "1px solid #8080803d" }}>
                    <div style={{ position: "relative", padding: "20px 30px", height: "auto", width: "100%", }}>
                        <div className='request_fields_wrapper'>
                            {fieldData.map((field, index) => (
                                <Field key={index} label={field.label} value={field.value} overflow={field.overflow}

                                    onCallClick={() => handleCallClick(field.value)} />
                            ))}
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <div style={{ width: "35%", margin: "25px 25px 30px 25px" }}>
                            {request?.invoice && <RequestDiscount
                                hideToggle
                                oldRequest={request || {}}
                                request={request}
                            />}

                        </div>
                        <div style={{ width: "50%", }} className=''>
                            <div ref={mapContainerRef} style={{ position: "relative", zIndex: 999, right: 10, bottom: 0, width: "450px", height: "200px", padding: "5px", borderRadius: "8px" }}>
                                <Map
                                    setBaseLayerToState={(bL) => {
                                        baseLayer = bL
                                    }}
                                    initZoom={6}
                                    initCenter={center}
                                    handleMapClick={() => { }}
                                    maxZoom={25}
                                    style={{
                                        height: "100%",
                                        borderRadius: "10px",
                                        boxShadow: '0px 0px 8.9px 3px rgba(114, 114, 114, 0.15)',
                                    }}
                                    mapInitialized={(map) => {
                                        initMap(map);
                                    }}
                                />
                                <div style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', height: '25px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '999', position: "absolute", right: "10px", bottom: '24px' }}
                                    onClick={switchBaseLayer}>
                                    <img style={{ width: '80px', height: '25px', borderRadius: '4px' }} src={activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
                                </div>
                                <div style={{ position: "absolute", bottom: -10, left: "15px", height: '50px', zIndex: '999', cursor: "pointer" }}>
                                    <img src={isFullScreen ? collapseIcon : fullScreenIcon} onClick={toggleFullScreen} alt="" width={isFullScreen ? "25px" : "20px"} height={"25px"} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {steps.length > 0 && <div style={{ position: "relative", height: "100%", width: "30%", }}>
                    <VerticalStepper steps={steps} />
                </div>}


            </div>
        </div>
    </MainContentWrapper > </>);
};
export default ViewRequestDetails;