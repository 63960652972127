import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import SelectComponent from '../../ReusableComponents/SelectComponent'
import { getDrones } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/drones'
import Loading from '../../ReusableFunctions/Loader'
import TableView from '../../ReusableComponents/TableView/TableView'
import Popup from '../../ReusableFunctions/Popup'
import { getAllDSPs } from '../../apis/adminAPIs/dsp'
import DroneDetails from './DroneDetails'

const Drones = ({
    dsp
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [dsps, setDsps] = useState([{ dsp_id: null, dsp_name: "All" }])
    const [drones, setDrones] = useState([])
    const [hideInfo, setHideInfo] = useState(false);
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 15,
        selectedDSP: { value: null, label: "All" }
    })
    const [isDroneDetails, setIsDroneDetails] = useState(false)
    const [selectedDrone, setSelectedDrone] = useState({})

    const getActiveDSPs = async () => {
        try {
            setIsLoading(true)
            Loading.set(true)
            const data = await getAllDSPs(null, null, null, null, null, true)
            setDsps([...dsps, ...data.dsps])
            Loading.set(false)
            setIsLoading(false)
        } catch (e) {
            console.error(e)
            setIsLoading(false)
            Loading.set(false)
            Popup.alert("ERROR", "We cant load invoices right now, Please try again later.")
        }
    }

    const getDronesFunc = async () => {
        try {
            setIsLoading(true)
            Loading.set(true)
            const drones = await getDrones(dsp?.dsp_id || filters.selectedDSP.value, filters.pageSize, filters.pageNumber)
            setDrones(drones)
            Loading.set(false)
            setIsLoading(false)
        } catch (error) {
            console.log("error", error);
            Loading.set(false)
            setIsLoading(false)
            Popup.alert("ERROR", "We cant get drones right now, Please try again later.")
        }
    }

    useEffect(() => {
        if (dsp?.dsp_id) setHideInfo(true);
        getDronesFunc()
    }, [filters.pageNumber, filters.pageSize, filters.selectedDSP])

    useEffect(() => {
        getActiveDSPs()
    }, [])

    return (
        <MainContentWrapper header={`Drones ${!hideInfo ? `(${filters.selectedDSP.label})` : `(${drones[0]?.drones_count || 0})`} `} rightContent={<div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>
            {!hideInfo && (<SelectComponent
                controlStyle={{ height: "40px", width: "200px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                isSearchable={true}
                placeholder={"Select DSP..."}
                menuPlacement={"bottom"}
                list={dsps.map(dsp => {
                    return ({
                        value: dsp.dsp_id,
                        label: dsp.dsp_name
                    })
                })}
                value={filters.selectedDSP}
                onChange={(dsp) => {
                    setFilters({ ...filters, selectedDSP: dsp,pageNumber:1 })
                }}
            />)}
        </div>
        }>
            {isDroneDetails && selectedDrone &&
                <DroneDetails
                    droneId={selectedDrone?.id}
                    open={isDroneDetails}
                    onClose={() => {
                        setIsDroneDetails(false)
                        setSelectedDrone({})
                    }}
                />
            }
            <div style={{
                margin: "20px 0",
                height: "90%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"500px"}
                    isLoading={isLoading}
                    onStateChange={async (changedState) => {
                        const { pageNumber } = changedState;
                        setFilters({ ...filters, pageNumber });
                    }}
                    onClickRow={(drone) => {
                        setSelectedDrone(drone)
                        setIsDroneDetails(true)
                    }}
                    columns={[
                        { Header: "Name.", accessor: "name" },
                        { Header: "UIN", accessor: "drone_uin" },
                        { Header: "Model Name", accessor: "model_name" },
                        { Header: "Type", accessor: "type" },
                        { Header: "DSP Name", accessor: "dsp_name" },
                        { Header: "Location Name", accessor: "location_name" },
                        { Header: "Date Added", accessor: "date_added", isDate: true, format: "dd/mm/yyyy" }
                    ]}
                    count={drones[0]?.drones_count}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={drones}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                />
            </div>
        </MainContentWrapper>
    )
}

export default Drones