import React, { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import KYCVerification from "./SubComponents/KYCVerification";
import checkWhite from "../../../../assets/check2.png";
import CompanyDetails from "./SubComponents/CompanyDetails";
import { GLOBAL_STYLES } from "../../../../appConstants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProfileSetup = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  useEffect(() => {
    if (user?.dsp?.is_verfied) navigate("/");
  }, []);
  const [step, setStep] = useState({ stepData: {}, stepValue: 1 });
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            height: "565px",
            width: "360px",
            boxShadow: "0px 1px 4px 2px #76767626",
            background: "white",
            border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
            paddingTop: "40px",
          }}
        >
          {step.stepValue !== 3 && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Stepper
                className="form-stepper transition"
                connectorStateColors
                connectorStyleConfig={{
                  activeColor: GLOBAL_STYLES.BG_ACTIVE,
                  completedColor: GLOBAL_STYLES.BG_ACTIVE,
                  size: 1,
                }}
                styleConfig={{
                  activeBgColor: GLOBAL_STYLES.BG_ACTIVE,
                  completedBgColor: GLOBAL_STYLES.BG_ACTIVE,
                  inactiveBgColor: "#e0e0e0",
                  activeTextColor: "white",
                  completedTextColor: "white",
                  inactiveTextColor: "white",
                  labelFontSize: GLOBAL_STYLES.FONT_SIZE_SMALL,
                }}
              >
                <Step
                  className="transition"
                  completed={step.stepValue > 1}
                  active={step.stepValue === 1}
                  disabled={step.stepValue < 1}
                  label="Profile"
                >
                  01
                </Step>
                <Step
                  className="transition"
                  completed={step.stepValue > 2}
                  active={step.stepValue === 2}
                  disabled={step.stepValue < 2}
                  label="Company Details"
                >
                  02
                </Step>
                <Step
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="transition"
                  completed={step.stepValue > 2}
                  active={step.stepValue === 3}
                  disabled={step.stepValue < 3}
                  label="Completed"
                >
                  <img
                    src={checkWhite}
                    alt="tick"
                    style={{ width: "15px", paddingTop: "3px" }}
                  />
                </Step>
              </Stepper>
            </div>
          )}

          {step.stepValue === 1 && (
            <KYCVerification
              next={(stepData) =>
                setStep((prev) => ({
                  ...prev,
                  stepData,
                  stepValue: prev.stepValue + 1,
                }))
              }
            />
          )}
          {step.stepValue === 2 && (
            <CompanyDetails
              stepData={step.stepData}
              prev={() =>
                setStep((prev) => ({ ...prev, stepValue: prev.stepValue - 1 }))
              }
              next={() =>
                setStep((prev) => ({ ...prev, stepValue: prev.stepValue + 1 }))
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileSetup;
