import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getAllStores = async ({pageSize,pageNumber,type,searchQuery}) => {
    try {
        const token = getToken();
        const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/sales/stores`, {
            pageSize: pageSize && pageNumber ? pageSize : undefined,
            pageNumber: pageSize && pageNumber ? pageNumber : undefined,
            type:type?type:undefined,
            searchQuery:searchQuery?searchQuery:undefined,
        });
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res.stores;
    } catch (err) {
        throw err;
    }
}
