import React, { useEffect, useState } from 'react'
import SelectComponent from '../../../ReusableComponents/SelectComponent'
import TotalRevenueGraph from "../SubComponents/TotalRevenueGraph";
import {  getDronePilots } from "../../../apis/dronePilots";
import Loading from '../../../ReusableFunctions/Loader';
import { getRevenueGraphData } from '../../../apis/revenue';
import { formatToIndianRupees } from '../../../ReusableFunctions/reusableFunctions';
import Popup from '../../../ReusableFunctions/Popup';
import zIndex from '@mui/material/styles/zIndex';
export default function PilotRevenue({
  fromDate,
  toDate,
  interval
}) {
    const [pilots, setpilots] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPilot, setSelectedPilot] = useState({label: "",value:undefined})
    
    const getAllPilotsFunc = async() => { 
     try{
      Loading.set(true);
      let {dronePilots} =await getDronePilots();
      setpilots(dronePilots);
      if(dronePilots && dronePilots?.length>0){
          setSelectedPilot(()=>({
            value: dronePilots[0]?.id,
            label: `${dronePilots[0]?.first_name} ${dronePilots[0]?.last_name}`
          }))
      }
      Loading.set(false);
     }catch(err){
      Popup.alert("Pilots", "Something Went wrong while pilots!", 'ERROR')
      Loading.set(false);
     }
    }
    useEffect(()=>{
      getAllPilotsFunc()
    },[])

    useEffect(()=>{
      console.log("called pilotRevenue ",interval,fromDate,toDate);
     fetchRevenueData();
    },[interval,selectedPilot.value])


    const fetchRevenueData=async()=>{
      try{
      setLoading(true);
      const data =await getRevenueGraphData({
        fromDate:fromDate?.toISOString(),
        toDate:toDate?.toISOString(),
       interval:interval?.toLowerCase(),
       pilotId:selectedPilot?.value
      })
        setRevenueData(data);
        setLoading(false);
      }catch(err){
      console.log("error",err);
      setLoading(false);
      }
    }

  return (
    <div className="">
    <div
      className=""
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "2px",
      }}
    >
      <div>
        <span style={{ fontSize: "18px", fontWeight: 700 }}>
          Pilot Revenue -{" "}
        </span>
        <span style={{ fontSize: "20px", color: "blue", fontWeight: 700 }}>
        ₹ {formatToIndianRupees(revenueData[0]?.total_revenue)}
        </span>
      </div>
      <div>
        <SelectComponent
          controlStyle={{
            height: "30px",
            width: "200px",
            minHeight: "20px",
            border: "none",
            boxShadow: "0px 2px 5px 1px #6273943d",
            color: "hsl(0deg 0% 20% / 64%)",
            fontSize: "10px",
          }}
          menuStyles={{
             zIndex:"1000"
          }}
          isSearchable={true}
          placeholder={"Select Pilot..."}
          menuPlacement={"bottom"}
          list={[ ...pilots.map(p => ({
            value: p.id,
            label: `${p.first_name} ${p.last_name}`
        }))]}
          value={selectedPilot.label ? { value: selectedPilot.value, label: selectedPilot.label } : ""}
          onChange={(e) => {
            setSelectedPilot({
                label: e.label,
                value: e.value,
            })
          }}
        />
      </div>
    </div>
  
    <TotalRevenueGraph
    interval={interval}
      showZoom={true}
      title={"total Revenue "}
      lineType={2}
      loading={loading}
      data={revenueData}
    />
  </div>
  
  )
}
