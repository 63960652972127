import React, { useState } from 'react';
import { useLocation } from 'react-router';
import ViewStoreDetails from '../../Operations/ViewStoreDetails';


function StoreDetailsDashBoard() {
    const [activeTab, setActiveTab] = useState('details');
    const location = useLocation();
    const [store, setStore] = useState(location.state?.store)
    const tabs = ['details'];

    return (
        <div style={{ backgroundColor: "rgb(236, 239, 243)", overflow: "hidden" }} className='flex flex-col gap-5 h-[100%]  px-[20px] py-[50px]'>
                    <ViewStoreDetails  store={{...store,toShow:false}} callback={(store) => {
                        setStore(store);
                    }} />
        </div>
    );  
}

export default StoreDetailsDashBoard;