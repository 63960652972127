import React, { useEffect, useState } from "react";
import MainContentWrapper from "../../../Components/MainContentWrapper";
import Popup from "../../../ReusableFunctions/Popup";
import {
  getAllRequests,
  getRequestsGraphStats,
} from "../../../apis/adminAPIs/requests";
import StatusCountAndAmountContainer from "./SubComponents/StatusCountAndAmountContainer";
import { useNavigate } from "react-router";
import { PDRL_FINANCE_ROUTES } from "../../../routes";
import { getOrdersStatsAPI } from "../../../apis/adminAPIs/finance/orders";
import AdminRequestTable from "../../../AdminComponents/Requests/SubComponents/AdminRequestTable";
import InteractiveGraph from "./SubComponents/OrdersGraph";
import { PAYMENT_STATUSES, STATUS } from "../../../appConstants";
import formatNumber from "../../../ReusableFunctions/convertLongNumToShort";
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import NotFounds from "../../../ReusableComponents/NotFounds";
import LoaderComponent from "../../../ReusableComponents/LoaderComponent";
import { formatToIndianRupees, generateShadesOfBlue } from "../../../ReusableFunctions/reusableFunctions";
import search from "../../../assets/search.svg"
import AdminFilterRequest from "../../../AdminComponents/Requests/SubComponents/AdminFilterRequest";
import useWindowSize from "../../../hooks/useWindowSize";
ChartJS.register(ArcElement, Tooltip, Legend)

const Orders = ({ dsp }) => {
  const [loading, setLoading] = useState(true);
  const [graphLoading, setGraphLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
    const {currentWidth}=useWindowSize();
  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem("requestFilters");
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      return {
        ...parsedFilters,
        searchQuery:"",
        fromDate: parsedFilters.fromDate
          ? new Date(parsedFilters.fromDate)
          : null,
        toDate: parsedFilters.toDate ? new Date(parsedFilters.toDate) : null,
      };
    }
    return {
      services: [],
      statuses: [],
      locations: [],
      isDspActive: true,
      dsps: dsp ? [dsp.dsp_id] : [],
      isFilterByServiceTime: false,
      searchQuery: "",
      pageSize: 15,
      pageNumber: 1,
      interval:"all",
    };
  });
  const [allRequestCount, setAllRequestCount] = useState(0);
  const [view, setView] = useState("tableView");
  const [cumilativeRequestsGraphData, setCumilativeRequestGraphData] = useState([]);
  const [periodicRequestGraphData, setPeriodicRequestGraphData] = useState([])
  const [statusWiseRequestsAmount, setStatusWiseRequestsAmount] = useState([]);
  const [pieData, setPieData] = useState()

  const navigate = useNavigate();

  const getAllRequestsFunc = async () => {
    setLoading(true);
    try {
      const data = await getAllRequests(
        { ...filters, isDspActive: true, services: ["Spraying service"] },
        filters.isFilterByServiceTime,
        filters.isFilterByDateCreated
      );
      setRequests(data?.requests);
      setAllRequestCount(data?.requests[0]?.total_count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Popup.alert("Error", err, "ERROR");
      return;
    }
  };

  const getStatusWiseRequestCountFunc = async () => {
    try {
      const statusRequestCount = await getOrdersStatsAPI(filters);
      for (let i in statusRequestCount?.stats) {
        statusRequestCount.stats[i].total_amount = formatNumber(Number(statusRequestCount?.stats[i].total_amount))
      }
      setStatusWiseRequestsAmount(statusRequestCount.stats);

      return;
    } catch (error) {
      Popup.alert("Error", error, "ERROR");
      return;
    }
  };

  const getRequestsGraphFunc = async () => {
    setGraphLoading(true);
    try {
      const data = await getRequestsGraphStats(
        { ...filters, pageNumber: undefined, pageSize: undefined, services: ["Spraying service"], isDspActive: true, searchQuery : undefined },
        filters.isFilterByServiceTime,
        filters.isFilterByDateCreated
      );
      setCumilativeRequestGraphData(data?.cumilativeRequestGraphData)
      setPeriodicRequestGraphData(data?.periodicRequestGraphData)
      if (data.topPerformingDsps && data.topPerformingDsps.length) {
        const topPerfomingDspsLabels = [];
        const topPerfomingDspsRequest = [];
        const totalRequests = data.topPerformingDsps.reduce((sum, dsp) => sum + dsp.total_requests, 0);

        data.topPerformingDsps.map((dsp) => {
          const percentage = ((dsp.total_requests / totalRequests) * 100).toFixed(2);
          topPerfomingDspsLabels.push(`${percentage}% - ${dsp.name}`);
          topPerfomingDspsRequest.push(dsp.total_requests);
        });

        const pieData = {
          labels: topPerfomingDspsLabels,
          datasets: [
            {
              data: topPerfomingDspsRequest,
              backgroundColor: generateShadesOfBlue('#0f5eff', topPerfomingDspsRequest.length),
            },
          ],
        };

        setPieData(pieData);
      } else {
        setPieData(null);
      }
      setGraphLoading(false);
    } catch (err) {
      setGraphLoading(false);
      console.log("err", err);
    }
  };

  const filterChanged = async (filters) => {
    try {
      setGraphLoading(true);
      setLoading(true);
      setRequests([])
      await getStatusWiseRequestCountFunc();
      await getRequestsGraphFunc();
      await getAllRequestsFunc(filters);
      setGraphLoading(false);
    } catch (e) {
      setGraphLoading(false);
      Popup.alert("Error", e, "ERROR");
    }
  };

  const checkStatusExistsInFilters = (filtersStatuses, status) => {
    return filtersStatuses.includes(status);
  };

  const handleFilterChanged=(updatedFilters)=>{
    setFilters(updatedFilters);
  }

console.log("requests",requests)


  useEffect(() => {
    filterChanged(filters);
    localStorage.setItem("requestFilters", JSON.stringify(filters));
  }, [filters, filters.fromDate, filters.toDate, filters.statuses, filters.dsps, filters.services, filters.pageNumber, navigate]);

  return (
    <MainContentWrapper
      containerStyle={{
        padding: "36px 53px",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <StatusCountAndAmountContainer
          requestsStatuses={statusWiseRequestsAmount}
          statusFilters={filters?.statuses}
          callback={(status) => {
            let updatedStatuses;
            if (status === "Total Orders") {
              setFilters((prevFilters) => ({
                ...prevFilters,
                fromDate: null,
                toDate: null,
                pageNumber: 1,
                services: [],
                statuses: [],
                locations: [],
                isDspActive: true,
                dsps: dsp ? [dsp.dsp_id] : [],
                paymentMethods: null,
                isFilterByServiceTime: false,
                searchQuery: ""
              }));
              return;
            }
            if (checkStatusExistsInFilters(filters.statuses, status)) {
              updatedStatuses = filters.statuses.filter((s) => s !== status);
            } else {
              updatedStatuses = [status];
            }
            setFilters((prevFilters) => ({
              ...prevFilters,
              pageNumber:1,
              statuses: updatedStatuses,
              isFilterByServiceTime: updatedStatuses?.length > 0 ? false : prevFilters.isFilterByServiceTime,
              paymentMethods: updatedStatuses && updatedStatuses.length === 1 && updatedStatuses[0] === PAYMENT_STATUSES.PAID ? prevFilters.paymentMethods : null
            }));
          }}
        />
        <div className='w-[100%] gap-[50px] flex items-center pt-4' >
           <div  style={{ fontSize: "1.5rem", fontWeight: 700,marginBottom:"12px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }} >
                    <div style={{minWidth:"100px"}} >

                    {`${'Requests'} (${requests[0]?.total_count || 0})`}
                    </div>
                </div>
            </div>
            { requests[0]?.total_amount_filtered_requests && <div style={{ color: '#3B3B3B', fontSize: "15px",fontWeight:700, display:"flex", justifyContent: "space-between", width:"500px" ,marginBottom:"10px"}}>
                                <div>Total : ₹ {formatToIndianRupees(Number(requests[0]?.total_amount_filtered_requests))}</div>
                                {filters.statuses.some(s => s === STATUS.COMPLETED || s === STATUS.PAID) && <>
                                <div>Credit note: ₹ {formatToIndianRupees(requests[0]?.total_credit_note_amount)}</div>
                                <div>Paid : ₹ {formatToIndianRupees(Number(requests[0]?.total_amount_filtered_requests) - Number(requests[0]?.total_credit_note_amount))}</div>
                                </>}
                     </div>}
        </div>
        
        <div  >
        <AdminFilterRequest
          isHideDSPFilter={dsp ? true : false}
          open={openFilter}
          filters={filters}
          onClose={() => setOpenFilter(false)}
          onFilterChange={(filterToBeApply) => {
              let updatedFilters={...filters,...filterToBeApply,pageNumber:1}
              handleFilterChanged(updatedFilters);
          }}
          setOpenFilter={setOpenFilter}
          screenWidth={currentWidth}
          loading={loading}
         />

        </div>
        <InteractiveGraph
          showZoom
          data={periodicRequestGraphData}
          lineType={0}
          title={"Periodic Performance"}
          loading={graphLoading}
        />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {/* Cumulative Performance Graph */}
          <InteractiveGraph
            showZoom
            data={cumilativeRequestsGraphData}
            lineType={0}
            style={{ width: "49%" }}
            title="Cumulative Performance"
            loading={graphLoading}
            loaderWidth="5%"
            loaderHeight="5%"
            isCummulative
          />

          {/* Pie Chart Container */}
          <div
            style={{
              height: "500px",
              width: "49%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              background: "white",
              padding: "15px",
            }}
          >
            <div style={{ width: "100%", height: "100%" }}>
              {graphLoading ? (
                <LoaderComponent />
              ) : pieData ? (
                <>
                  <div
                    className="flight-graph-container-title"
                    style={{
                      paddingBottom: "10px",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    DSP Performance
                  </div>
                  <Pie
                    data={pieData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      animation: {
                        animateScale: true,
                        animateRotate: true,
                      },
                      plugins: {
                        legend: {
                          display: true,
                          position: "right",
                          labels: {
                            color: "#1E1E1E",
                            font: {
                              size: 15,
                              weight: "500",
                            },
                          },
                        },
                      },
                      layout: {
                        padding: {
                          top: 50,
                          bottom: 50,
                          left: 50,
                          right: 50,
                        },
                      },
                    }}
                  />
                </>
              ) : (
                <NotFounds hideBtn={true} />
              )}
            </div>
          </div>
        </div>
        {/* <div className="flex justify-end  left-0 mt-5 mb-2">
          <div style={{ position: "relative", height: "40px", width: "400px" }}>
            <img src={search} alt='search icon' style={{ position: 'absolute', left: "20px", top: "10px" }} />
            <input onChange={(e) => {
              let updatedFilters = { ...filters, searchQuery: e.target.value, pageNumber: 1 };
              setFilters(updatedFilters);
              getAllRequestsFunc(updatedFilters);
            }}
              placeholder='Search request by request no...'
              style={{ width: "100%", height: '100%', borderRadius: "4px", border: "1px solid #BFC8D9", padding: "10px 10px 10px 60px", fontSize: "12px" }} />
          </div>

        </div> */}
        <AdminRequestTable
          title={"Orders"}
          hideTypeofDsp={true}
          hideServices={true}
          tabelContainerHeight={"400px"}
          pageNumber={filters.pageNumber}
          requests={requests}
          onFilterChange={(filterToBeApply) => {
            const updatedFilters = {
              ...filters,
              ...filterToBeApply,
              pageNumber: filterToBeApply.pageNumber ?? 1,
            };
            setFilters(updatedFilters);
          }}
          onSearch={(searchQuery)=>{
          let updatedFilters={...filters,searchQuery:searchQuery,pageNumber:1}
          setFilters(updatedFilters)
          }}
          isHideDSPFilter={dsp ? true : false}
          filters={filters}
          loading={loading}
          totalCount={requests[0]?.total_count || 0}
          pageSize={filters.pageSize}
          allCount={allRequestCount}
          totalOrdersValue={statusWiseRequestsAmount?.find(st => st.order_status === "Total Orders")?.total_amount}
          onClickRow={(request) => {
            request = {
              ...request,
              serviceType: request?.serviceType?.props?.content,
            };
            navigate(`${PDRL_FINANCE_ROUTES.REQUESTDETAILS}`, {
              state: { request: request },
            });
          }}
          view={view}
          isOrderTable={true}
          setView={setView}
        />
      </div>
    </MainContentWrapper>
  );
};

export default Orders;
