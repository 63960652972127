import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getSalesDashboardOverViewData = async () => {
    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/sales/dashboard`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res.dashboardOveriewData;
    } catch (err) {
        throw err;
    }
}
