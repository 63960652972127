import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import { PDRL_ADMIN_ROUTES } from '../../routes'

const AdminDashBoard = () => {
    useEffect(() => {
        localStorage.setItem('dsp_id', "admin")
        localStorage.removeItem("operationsrequestFilters")
        localStorage.removeItem("UserInvoicesFilters")
        localStorage.removeItem("dspInvoicesFilters")
        localStorage.removeItem("telecallerFilter")
    }, [])

    return <MainContentWrapper>
        <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
            <div className="dashboard-first-section-second-div-one" style={{
      display: "grid",
      gridTemplateColumns: "repeat(5, minmax(200px, 1fr))",
      gap: "17px",
    }} >


                <DashBoardCard
                    loading={false}
                    title="Finance"
                    // icon={droneCardIcon}
                    view={PDRL_ADMIN_ROUTES.FINANCE_DASHBOARD}
                />

                <DashBoardCard
                    loading={false}
                    title="Customer Success"
                    // icon={droneCardIcon}
                    view={PDRL_ADMIN_ROUTES.SUCCESS_ENGI_DASHBOARD}
                />

                <DashBoardCard
                    loading={false}
                    title="Operations"
                    // icon={droneCardIcon}
                    view={PDRL_ADMIN_ROUTES.OPEARTIONSDASHBOARD}
                />

                <DashBoardCard
                    view={PDRL_ADMIN_ROUTES.TEAMS}
                    loading={false}
                    title="BhuMeet Teams"
                // icon={droneCardIcon}
                />
                <DashBoardCard
                    view={PDRL_ADMIN_ROUTES.MARKETINGDASHBOARD}
                    loading={false}
                    title="Marketing"
                // icon={droneCardIcon}
                />
                   <DashBoardCard
                    view={PDRL_ADMIN_ROUTES.SALES_DASHBOARD}
                    loading={false}
                    title="Sales"
                />

                    <DashBoardCard
                    view={PDRL_ADMIN_ROUTES.TelecallerDashboard}
                    loading={false}
                    title="TeleCaller"
                />  
            </div>
        </div>
    </MainContentWrapper>
}

export default AdminDashBoard