import { useLocation } from "react-router";
import bhumeet from "../../../assets/bhumeet.svg";
import { useEffect, useState } from "react";
import Loading from "../../../ReusableFunctions/Loader";
import Popup from "../../../ReusableFunctions/Popup";
import dateFormat from "dateformat";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  formatToIndianRupees,
  squareKilometersToAcres,
} from "../../../ReusableFunctions/reusableFunctions";
import { getUserInvoiceDetails } from "../../../apis/adminAPIs/finance/userInvoices";
import { getInvoiceInDetail } from "../../../ReusableFunctions/RequestInvoice";
import { AEROMEGH_SERVICES } from "../../../appConstants";

const UserInvoiceDetails = () => {
  const { dsp, invoice } = useLocation()?.state;
  const [settInvoice, setSettInvoice] = useState({});
  const [userInvoice, setUserInvoice] = useState({});
  const [isDownload, setIsDownload] = useState(false);

  const getUserInvoiceDetailsFunc = async () => {
    try {
      Loading.set(true);
      const userInvoice = await getUserInvoiceDetails(invoice?.request_id);
      if (
        userInvoice?.request?.service_completed_area &&
        userInvoice?.request_type === AEROMEGH_SERVICES.SPRAYING
      ) {
        const sprayedAcres =
          userInvoice?.request?.price_by === "acre"
            ? `${squareKilometersToAcres(
                userInvoice?.request?.service_completed_area
              )}`
            : Number(userInvoice?.request?.service_completed_area);
        userInvoice.perAcreServiceAmount =
          Number(userInvoice.service_amount) / sprayedAcres;
        userInvoice.sprayedAcres = sprayedAcres;
      }
      setUserInvoice(userInvoice);
      const {
        pesticide_per_acre_subsidy,
        service_amount,
        total_amount,
        cgst,
        sgst,
        pesticides_subsidy,
        service_amount_with_subsidy,
        applied_coupons,
        applied_discounts,
      } = userInvoice;
      const { field_area, service_completed_area, price_by } =
        userInvoice?.request;
      const involiceDetails = await getInvoiceInDetail(
        field_area,
        service_completed_area,
        price_by,
        pesticide_per_acre_subsidy,
        service_amount,
        total_amount,
        cgst,
        sgst,
        pesticides_subsidy,
        service_amount_with_subsidy,
        applied_coupons,
        applied_discounts
      );
      setSettInvoice(involiceDetails);
      Loading.set(false);
    } catch (error) {
      console.log("error:", error);
      Loading.set(false);
      Popup.alert("Error", error, "ERROR");
    }
  };

  const waitForChanges = async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
  };

  const downloadPDF = async () => {
    setIsDownload(true);
    Loading.set(true);
    await waitForChanges();
    const input = document.querySelector(".myDiv1");

    html2canvas(input, {
      scale: 2,
      useCORS: true,
      backgroundColor: null,
    }).then((canvas) => {
      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const heightLeft = imgHeight;

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true,
      });

      let position = 0;

      pdf.addImage(
        canvas.toDataURL("image/jpeg", 0.6),
        "JPEG",
        0,
        position,
        imgWidth,
        imgHeight
      );

      let remainingHeight = heightLeft;
      while (remainingHeight > pageHeight) {
        position -= pageHeight;
        pdf.addPage();
        pdf.addImage(
          canvas.toDataURL("image/jpeg", 0.6),
          "JPEG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        remainingHeight -= pageHeight;
      }

      pdf.save("invoice.pdf");
      setIsDownload(false);
      Loading.set(false);
    });
  };

  useEffect(() => {
    getUserInvoiceDetailsFunc();
  }, []);

  const TermsOfService = () => {
    return (
      <div style={{ fontSize: "13px", color: "#848484", fontWeight: 700 }}>
          <p style={{paddingBottom:"8px",fontSize:"12px"}}>BhuMeet billing terms and conditions apply. Refer to website for more details.</p>
        <h2 style={{ textAlign: "left", color: "#000", marginBottom: "5px",paddingBottom:"10px" }}>
          Terms of Service
        </h2>
        <div style={{color:"#1E1E1E",fontWeight:600,fontSize:"12px"}}>
             <p>
          1. BhuMeet acts solely as a platform to connect farmers with drone
          service providers and does not directly offer or perform any
          agricultural services.
        </p>
        <p>
          2. BhuMeet collects payment from farmers on behalf of the service
          providers, which is transferred to the providers upon service
          completion.
        </p>
        <p>
          3. BhuMeet does not guarantee the quality, effectiveness, or safety of
          the services performed by the drone service providers.
        </p>
        <p>
          4. Farmers are responsible for verifying the agrochemicals used,
          including type and quantity, to ensure they meet their requirements
          and regulatory standards.
        </p>
        <p>
          5. The drone service providers are independent entities, and any
          issues or disputes regarding service quality should be addressed
          directly with the provider.
        </p>
        <p>
          6. Farmers are encouraged to provide honest feedback on the service
          received. Feedback will help enhance the reliability of the platform
          and assist other users in making informed decisions.
        </p>
        <p>
          7. BhuMeet is not liable for any adverse effects, crop damage, or
          other outcomes resulting from the services provided by the drone
          operators.
        </p>
        </div>
       
      </div>
    );
  };

  const CreditNotesContainer = ({ creditNote }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
          pageBreakBefore: "always",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            marginTop: "10px",
          }}
        >
          <span style={{ fontSize: "18px", fontWeight: 700 }}>Credit Note</span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(215, 218, 224, 0.6)",
              padding: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.8rem",
                maxWidth: "60%",
              }}
            >
              <div style={{ marginTop: "8px" }}>
                <p style={{ margin: "0", fontWeight: 500 }}>From</p>
                <p
                  style={{ margin: "0", fontWeight: "bold", color: "#1E1E1E" }}
                >
                  {creditNote?.from?.name}
                </p>
                <p style={{ margin: "0" }}>{creditNote?.from?.address}</p>
                {creditNote?.from?.gstin && (
                  <p style={{ margin: "0" }}>{creditNote?.from?.gstin}</p>
                )}
              </div>
              <div style={{ marginBottom: "5px" }}>
                <p style={{ margin: "0", fontWeight: 500 }}>To</p>
                <p
                  style={{ margin: "0", fontWeight: "bold", color: "#1E1E1E" }}
                >
                  {creditNote?.to?.name || "-"}
                </p>
                <p style={{ margin: "0" }}>{creditNote?.to?.address || "-"}</p>
                {creditNote?.to?.mobileNumber && (
                  <p style={{ margin: "0" }}>{creditNote?.to?.mobileNumber}</p>
                )}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  gap: "2px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "13px",
                    marginTop: "2px",
                  }}
                >
                  Date -
                </p>
                <p>{dateFormat(creditNote?.date_credited, "dd mmm yyyy")}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "13px",
                    marginTop: "2px",
                  }}
                >
                  C.N. NO. -
                </p>
                <p>{creditNote?.cr_number}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "13px",
                    marginTop: "2px",
                  }}
                >
                  Invoice NO. -
                </p>
                <p>{userInvoice?.invoice_number} </p>
              </div>
            </div>
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: 700,
              padding: "10px 0px 40px 0px  ",
            }}
          >
            <p>
              This is credit note made against invoice No.
              {userInvoice?.invoice_number}
            </p>
          </div>
        </div>
        <div style={{ marginBottom: "30px" }}>
          <div
            style={{
              fontSize: "15px",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 700,
              borderTop: "1px solid rgba(215, 218, 224, 0.6) ",
              borderBottom: "1px solid rgba(215, 218, 224, 0.6)",
              padding: "10px 0px ",
            }}
          >
            <p>Description</p>
            <p>Amount</p>
          </div>
          <div
            style={{
              padding: "15px 0px ",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>{creditNote?.note}</p>
              <p style={{ fontWeight: 700 }}>
                {formatToIndianRupees(creditNote?.amount)}
              </p>
            </div>
          </div>
          <div style={{ borderTop: "1px solid rgba(215, 218, 224, 0.6)" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
                gap: "2rem",
                fontWeight: 700,
              }}
            >
              <p>Total</p>
              <p>{formatToIndianRupees(creditNote?.amount)}</p>
            </div>
          </div>
        </div>
        <div style={{}}>
          <div
            style={{
              fontSize: "15px",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 700,
              borderTop: "1px solid rgba(215, 218, 224, 0.6) ",
              borderBottom: "1px solid rgba(215, 218, 224, 0.6)",
              padding: "10px 0px ",
            }}
          >
            <p>Total Amount to be paid </p>
          </div>
          <div
            style={{
              padding: "15px 0px ",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Invoice No. {userInvoice?.invoice_number} </p>
              <p style={{ fontWeight: 700 }}>
                {" "}
                ₹{" "}
                {formatToIndianRupees(
                  userInvoice?.total_amount_without_bhumeet_coupon || 0
                )}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Credit Note No. {creditNote?.cr_number} </p>
              <p style={{ fontWeight: 700, color: "#2dc653" }}>
                {" "}
                -₹ {formatToIndianRupees(creditNote?.amount)}
              </p>
            </div>
          </div>
          <div style={{ borderTop: "1px solid rgba(215, 218, 224, 0.6)" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
                gap: "2rem",
                fontWeight: 700,
              }}
            >
              <p>Total</p>
              <p>{formatToIndianRupees(userInvoice?.total_amount)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return settInvoice ? (
    <div style={{ overflow: "auto", position: "relative" }}>
      <a
        onClick={downloadPDF}
        style={{
          position: "absolute",
          top: "10px",
          right: "340px",
          textDecoration: "underline",
          color: "#007BFF",
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        Download Invoice
      </a>
      <div
        style={{
          width: "620px",
          margin: "40px auto",
          border: "1px solid rgba(215, 218, 224, 0.6)",
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          color: "#3B3B3B",
          backgroundColor: "#fff",
        }}
      >
        <div className="myDiv1">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              margin: "0px 30px",
              padding: "20px 0px 15px 0",
              borderBottom: "1px solid rgba(215, 218, 224, 0.3)",
              fontSize: "14px",
            }}
          >
            <div>
              <img src={bhumeet} alt="bhumeet" width={"75px"} />
            </div>
           <p style={{fontSize:"20px",marginTop:"-10px",color:"#1E1E1E",fontWeight:800}}>Tax Invoice</p>
            <div style={{ textAlign: "right" }}>
              <p style={{ margin: "0", fontSize: "11px" }}>
                <span style={{ fontWeight: 600, color: "#1A1C21" }}>No :</span>{" "}
                {invoice?.invoice_number || "-"}
              </p>
              <p style={{ margin: "0", fontSize: "11px" }}>
                <span style={{ fontWeight: 600, color: "#1A1C21" }}>
                  Date :
                </span>{" "}
                {dateFormat(
                  userInvoice?.service_completed_time ||
                    userInvoice?.created_at,
                  "dd mmm yyyy"
                )}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              padding: "15px 30px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#727272",
                fontSize: "14px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between",width:"100%" }}>
                <div style={{ width: "60%",maxWidth:"60%" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <p style={{ margin: "0", fontWeight: 500 }}>Invoice from</p>
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "bold",
                        color: "#1E1E1E",
                      }}
                    >
                      {userInvoice?.from?.name || "-"}
                    </p>
                    {/* {userInvoice?.to?.mobileNumber && <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{userInvoice?.to?.mobileNumber}</p>} */}
                    <p style={{ margin: "0" }}>
                      {userInvoice?.from?.address || "-"}
                    </p>
                    <p style={{ margin: "0" }}>
                      {userInvoice?.from?.gstin || "-"}
                    </p>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <p style={{ margin: "0", fontWeight: 500 }}>To</p>
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "bold",
                        color: "#1E1E1E",
                      }}
                    >
                      {userInvoice?.to?.name || "-"}
                    </p>
                    {/* {userInvoice?.to?.mobileNumber && <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{userInvoice?.to?.mobileNumber}</p>} */}
                    <p style={{ margin: "0" }}>
                      {userInvoice?.to?.address || "-"}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                    justifyContent: "end",
                    maxWidth:"40%"
                  }}
                >
                  <p style={{ margin: "0", fontWeight: 500 }}>
                    Service provided by
                  </p>
                  <p
                    style={{
                      margin: "0",
                      fontWeight: "bold",
                      color: "#1E1E1E",
                    }}
                  >
                    {userInvoice?.serviced_by?.name}
                  </p>
                  <p style={{ margin: "0" }}>
                    {userInvoice?.serviced_by?.address}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "15px 30px" }}>
            <div
              style={{ borderTop: "1px solid rgba(215, 218, 224, 0.6)" }}
            ></div>
            <div style={{ marginTop: "30px" }}>
              <span style={{ fontWeight: 700 , color : '#1E1E1E'}}>SERVICE DETAILS</span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "15px",
                    fontWeight: 600,
                    justifyItems: "center",
                  }}
                >
                  <p>Crop</p>
                  <p style={{ fontSize: "14px" }}>
                    {userInvoice?.request?.crop_name || "N/A"}
                  </p>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "15px",
                    fontWeight: 600,
                    justifyItems: "center",
                  }}
                >
                  <p>Crop Stage</p>
                  <p style={{ fontSize: "14px" }}>
                    {userInvoice?.request?.crop_stage || "N/A"}
                  </p>
                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "15px",
                    fontWeight: 600,
                    justifyItems: "center",
                  }}
                >
                  <p>Agrochemical</p>
                  <p style={{ fontSize: "14px" }}>
                    {userInvoice?.request?.pesticides || "N/A"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "15px",
                    fontWeight: 600,
                    justifyItems: "center",
                  }}
                >
                  <p>{userInvoice?.request?.price_by === "acre" ? 'Area' : 'Tanks'}</p>
                  <p style={{ fontSize: "14px" }}>
                    {userInvoice?.request?.price_by === "acre"
                      ? (squareKilometersToAcres(
                          userInvoice?.request?.service_completed_area
                        ) + " acre(s)")
                      : userInvoice?.request?.service_completed_area || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "10px 25px",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 700,
              color: "#1E1E1E",
              height: "40px",
              alignItems: "center",
              fontSize: "14px",
              marginTop: "20px",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: 700 }}>
              PAYMENT SUMMARY
            </span>
            <span style={{ fontSize: "16px", fontWeight: 700 }}>RATES</span>
          </div>

          <div style={{ padding: "10px 30px 50px", fontSize: "14px" }}>
            <div style={{ marginBottom: "5px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: 500,
                }}
              >
                <span>Service Charges</span>
                <span>
                  ₹ {formatToIndianRupees(userInvoice?.service_amount || 0)}
                </span>
              </div>
              {userInvoice?.sprayedAcres && (
                <div style={{ fontSize: "12px", color: "#848484" }}>
                  {userInvoice?.sprayedAcres} {userInvoice?.request?.price_by}(s) x{" "}
                  {formatToIndianRupees(userInvoice?.perAcreServiceAmount)} Rs. per {userInvoice?.request?.price_by}
                </div>
              )}
            </div>
            {userInvoice?.pesticides_subsidy > 0 ?  (
              <div style={{ marginBottom: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: 500,
                  }}
                >
                  <span>Total Subsidy</span>
                  <span style={{ color: "#2dc653" }}>
                    - ₹{" "}
                    {formatToIndianRupees(userInvoice?.pesticides_subsidy || 0)}
                  </span>
                </div>
                <div style={{ fontSize: "12`px", color: "#848484" }}>
                  {userInvoice?.sprayedAcres} {userInvoice?.request?.price_by}(s) x{" "}
                  {formatToIndianRupees(userInvoice?.pesticide_per_acre_subsidy)} Rs. per {userInvoice?.request?.price_by}
                </div>
              </div>
            ):<div style={{minHeight:"20px"}} ></div>}

            {userInvoice?.total_discounted_amount_via_dsp > 0 ? (
              <div style={{ marginBottom: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: 500,
                  }}
                >
                  <span>Service Provider Discount</span>
                  <span style={{ color: "#2dc653" }}>
                    - ₹{" "}
                    {formatToIndianRupees(
                      userInvoice?.total_discounted_amount_via_dsp || 0
                    )}
                  </span>
                </div>
              </div>
            ):<div style={{minHeight:"20px"}} ></div>}
            {userInvoice?.applied_dsp_coupon_total_amount > 0 ? (
              <div style={{ marginBottom: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: 500,
                  }}
                >
                  <span>Provider Coupon Discount</span>
                  <span style={{ color: "#2dc653" }}>
                    - ₹{" "}
                    {formatToIndianRupees(
                      userInvoice?.applied_dsp_coupon_total_amount || 0
                    )}
                  </span>
                </div>
              </div>
            ):<div style={{minHeight:"20px"}} ></div>}
            {userInvoice?.applied_csgt_amount > 0 ? (
              <div style={{ marginBottom: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: 500,
                  }}
                >
                  <span>CGST</span>
                  <span style={{}}>
                    + ₹{" "}
                    {formatToIndianRupees(
                      userInvoice?.applied_csgt_amount || 0
                    )}
                  </span>
                </div>
              </div>
            ):<div style={{minHeight:"20px"}} ></div>}
            {userInvoice?.applied_sgst_amount > 0 ? (
              <div style={{ marginBottom: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: 500,
                  }}
                >
                  <span>SGST</span>
                  <span style={{}}>
                    + ₹{" "}
                    {formatToIndianRupees(
                      userInvoice?.applied_sgst_amount || 0
                    )}
                  </span>
                </div>
              </div>
            ):<div style={{minHeight:"20px"}} ></div>}
            {/* <img src={dashLine} alt="dashLine" style={{ maxWidth: "103%" }} /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
                marginTop: "15px",
                fontWeight: "bold",
                borderTop: "1px solid rgba(215, 218, 224, 0.6)",
                borderBottom: "1px solid rgba(215, 218, 224, 0.6)",
                paddingBottom: "45px",
                fontSize: "16px",
              }}
            >
              <span>Total Amount</span>
              <span>
                ₹{" "}
                {formatToIndianRupees(
                  userInvoice?.total_amount_without_bhumeet_coupon || 0
                )}
              </span>
            </div>
            <br />
            {userInvoice?.credit_note !== null && (
              <div style={{ marginTop: isDownload ? "150px" : 0 }}>
                <CreditNotesContainer creditNote={userInvoice.credit_note} />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px 0px",
                fontSize: "16px",
                marginBottom:"20px",
                fontWeight: 700,
              }}
            >
              <span>
                Total amount to be paid :{" "}
                <span>₹ {formatToIndianRupees(userInvoice?.total_amount)}</span>{" "}
              </span>
            </div>
            <br />
            <div style={{ marginTop: isDownload ? "100px " : 0 }}>
               
              <TermsOfService />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default UserInvoiceDetails;
