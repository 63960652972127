import React, { useEffect, useState } from 'react'
import InputField from '../../ReusableComponents/InputField'
import Modal from '../../ReusableComponents/Modal'
import Form from '../../ReusableComponents/Form'
import { Button } from '../../Components/Helper'
import Loading from '../../ReusableFunctions/Loader'
import Popup from '../../ReusableFunctions/Popup'
import { selectFieldValidation } from '../../utils/inputValidations'
import { INECNTIVE_INVOICES_STATUS } from '../../appConstants'
import { getBesInvoiceDetails, settleBesInvoice } from '../../apis/adminAPIs/BesInvoices'

const BesSettlementForm = ({ invoice, open, onClose, onUpdate, toBankDetails }) => {
    const [settInvoice, setSettInvoice] = useState({})

    const getSettelementInvoiceDetailsFunc = async () => {
        try {
            Loading.set(true)
            const settleInvoice = await getBesInvoiceDetails(invoice?.id)
            setSettInvoice({
                ...settleInvoice,
                ...invoice,
                time:new Date(settInvoice?.generated_time),
                invoice_id: invoice?.id,
                generated_time: new Date(settleInvoice?.generated_time)
            })
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            Popup.alert("Error", error, "ERROR")
        }
    }

    const handleInvoiceSettlement = async (invoice) => {
        try {
            Loading.set(true)
            await settleBesInvoice(invoice)
            onUpdate({ ...invoice, status: INECNTIVE_INVOICES_STATUS.PAID })
            Loading.set(false)
            Popup.alert("Success", "Invoice settled successfully!", "SUCCESS");
        } catch (error) {
            Loading.set(false)
            Popup.alert("Error", error, "ERROR")
        }
    }

    useEffect(() => {
        getSettelementInvoiceDetailsFunc()
    }, [])

    return (
        <Modal
            header={invoice?.status === "unsettled" ? "Mark As Paid" : "Settlled Transaction"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "640px", height: "auto", borderRadius: "10px" }}
            headerContainerStyle={{ padding: "25px 30px", }}
        >
            <Form
                onSubmitForm={(invoice) => {
                    handleInvoiceSettlement(invoice)
                }}
                initialValues={settInvoice}
            >
                {(onSubmit) => (
                    <div className="addDrone_form_wrapper" >
                        <div className="add_drone_form" style={{ padding: "10px 30px" }}>
                            <InputField
                                label={"Transaction Id"}
                                type={"text"}
                                id={"transaction_id"}
                                name={"transaction_id"}
                                placeholder={"Please Enter Transaction Id"}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                                disabled={settInvoice?.status === INECNTIVE_INVOICES_STATUS.PAID}
                                validation={selectFieldValidation}
                            />
                            
                            <InputField
                                label={"Mode"}
                                type={"text"}
                                id={"mode"}
                                placeholder={"Please Enter Mode"}
                                name={"mode"}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                                disabled={settInvoice?.status === INECNTIVE_INVOICES_STATUS.PAID}
                                validation={selectFieldValidation}
                            />
                            <InputField
                                label={"Note"}
                                type={"text"}
                                id={"note"}
                                placeholder={"Please Enter Note"}
                                name={"note"}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                                disabled={settInvoice?.status === INECNTIVE_INVOICES_STATUS.PAID}
                            />
                        </div>
                        {(settInvoice?.status !== INECNTIVE_INVOICES_STATUS.PAID || !toBankDetails?.id) &&
                            <div className="buttons_wrapper" style={{ padding: "10px 30px" }}>
                                <Button
                                    className={"btn-large"}
                                    text={"Done"}
                                    onClick={onSubmit}
                                    style={{ width: "100px" }}
                                />
                                <Button
                                    className={"btn-large lg-cancel-btn "}
                                    text={"Cancel"}
                                    onClick={onClose}
                                    isBtnNotActive={true}
                                />
                            </div>
                        }
                    </div>
                )}
            </Form>
        </Modal>
    )
}

export default BesSettlementForm