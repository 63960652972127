import React, { useCallback, useEffect, useRef, useState } from "react";
import Map from "../../../features/LeafletMap/Map";
import Modal from "../../../ReusableComponents/Modal";
import MarkerIcon from "../../../assets/marker.png";
import L from "leaflet";
import { Button } from "../../../Components/Helper";
import { GLOBAL_STYLES, LIMITS } from "../../../appConstants";
import Input from "../../../ReusableComponents/Input";
import Popup from "../../../ReusableFunctions/Popup";
import Loading from "../../../ReusableFunctions/Loader";
import useWindowSize from "../../../hooks/useWindowSize";
import SearchLocation from "../../../ReusableComponents/SearchLocation";
import debounce from 'lodash/debounce';
import { updateLocation } from "../../../apis/BhuMeetTeams/Oparations/locations";
let mapInState = undefined;
let mapMarker = undefined;
let markerRadius = undefined
const AddLocation = ({
  location,
  open,
  onClose,
  onUpdateLocation,
  showCrops,
}) => {
  const locationCropsManagerRef = useRef(null)

  const [locationId, setLocationId] = useState(location?.id)
  const [markerR, setmarkerRadius] = useState()
  const [map, setMap] = useState()
  const lastFetchTime = useRef(0);
  const [center, setCenter] = useState(
    location
      ? { lat: location.lat, lng: location.lng }
      : { lat: 19.993982, lng: 73.790416 }
  );
  const [locationName, setLocationName] = useState({
    name: location?.name || "Nashik",
    address: location?.address ||
      "Maulana Abdul Kalam Azad Rd, Renuka Nagar, Nashik, Maharashtra"
  });
  const [radius, setRadius] = useState(location?.radius || 1);
  const { currentHeight } = useWindowSize();

  const changeMarkerPosition = (mark) => {
    mapMarker.setLatLng(mark);
    markerRadius.setLatLng(mark);
  };


  function getLocationString(locationString) {
    const cleanedString = locationString.replace(/^\S+\s/, '').trim();

    return cleanedString;
  }
  const onchangePlace = (e) => {
    setLocationName((prev) => ({ ...prev, name: "", address: e.target.value }))
    e.stopPropagation();
  };


  const initMap = (map) => {
    setMap(map)
    var markerOptions = {
      title: "MyLocation",
      clickable: true,
      draggable: true,
      icon: new L.Icon({
        iconUrl: MarkerIcon,
        iconSize: [20, 28],
        iconAnchor: [10, 28],
      }),
    };
    let marker1 = new L.Marker(center, markerOptions)
      .addTo(map)
    let circle = new L.circle(center, {
      color: GLOBAL_STYLES.BG_ACTIVE,
      fillColor: GLOBAL_STYLES.BG_ACTIVE,
      fillOpacity: 0.3,
      radius: radius * 1000
    }).addTo(map);

    setmarkerRadius(circle)
    markerRadius = circle
    mapMarker = marker1;
    map.fitBounds(circle.getBounds())


    marker1.on("drag", (e) => {
      const latlng = e.target._latlng;
      setCenter(latlng);
      // map.fitBounds(markerRadius.getBounds(), { maxZoom: map.getZoom() });
      changeMarkerPosition(latlng);
    })

    marker1.on("dragend", (e) => {
      const latlng = e.target._latlng;
      setCenter(latlng);
      changeMarkerPosition(latlng);
      map.fitBounds(markerRadius.getBounds())
    })
    map.on("click", (e) => {
      const latlng = e.latlng;
      setCenter(() => latlng)
      changeMarkerPosition(latlng);
      map.fitBounds(markerRadius.getBounds());
    });
  };


  const handleUpdateLocation = async () => {
    const loc = {
      ...location,
      name: locationName.name,
      address: locationName.address,
      lat: center.lat,
      lng: center.lng,
      radius,
    };
    setLocationId(location.id);

    try {
      Loading.set(true);
      await updateLocation(loc);
      Popup.alert(
        "Location",
        "Location updated successfully",
        "SUCCESS",
        onClose
      );
      onUpdateLocation(loc);
    } catch (err) {
      Popup.alert("ERROR", err || "Something went wrong while updating location ", "ERROR")
      throw err;
    } finally {
      Loading.set(false);
    }
  };

  const updateLocationAPI = async () => {
    try {
      await handleUpdateLocation();
    } catch (err) {
      Popup.alert("ERROR", err || "Something went wrong while updating location ", "ERROR")
    }
  };

  const cooldownPeriod = 4000;

  const getAddress = useCallback(async ({ lat, lng }) => {
    const now = Date.now();
    if (now - lastFetchTime.current < cooldownPeriod) {
      return;
    }

    try {
      lastFetchTime.current = now;
      const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&location_type=ROOFTOP&result_type=street_address&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
      const { results } = await res.json();
      let addressName = results[0]?.plus_code?.compound_code;
      addressName = getLocationString(addressName);
      setLocationName((prev) => ({ name: addressName, address: results[0]?.formatted_address }));
    } catch (err) {
      console.error("Error fetching address:", err);
    }
  }, []);

  const debouncedGetAddress = useCallback(
    debounce((center) => {
      getAddress(center);
    }, 1000),
    [getAddress]
  );

  useEffect(() => {
    debouncedGetAddress(center);
    return () => {
      debouncedGetAddress.cancel();
    };
  }, [center, debouncedGetAddress]);



  useEffect(() => {
    if (location?.id) {
      setLocationId(location?.id)
    }
  }, [location?.id])
  return (
    <>
      <Modal
        header={location?.id ? "Update Location" : "Add Location"}
        open={open}
        headerContainerStyle={{ padding: "20px 30px" }}
        onClose={onClose}
        closeOnDocumentClick={false}
        contentStyle={{ height: "65%", width: showCrops ? "65%" : "55%", boxShadow: " 0px 1px 4px 3px #76767626", border: "1px solid #BFC8D9", borderRadius: "8px", position: "relative" }}
      >
        <>
          <form style={{ width: "100%", display: 'flex', height: 'calc(100% - 65px)' }}>
            <div style={{ width: showCrops ? "50%" : "100%" }}>
              <div
                style={{
                  display: "flex",
                  zIndex: 1000,
                  padding: "0 30px",
                  justifyContent: "space-between",
                  position: "absolute",
                  width: showCrops ? "50%" : "100%",
                  top: "80px",
                }}
              >
                <SearchLocation
                  style={{
                    position: "initial",
                    width: '65%'
                  }}
                  value={locationName.address}
                  defaultValue={locationName.address}
                  placeHolder="Search location"
                  onChangePlace={(e) => onchangePlace(e)}
                  onPlaceSelected={(place) => {
                    try {
                      let lat = place.geometry.location.lat();
                      let lng = place.geometry.location.lng();
                      let cntr = {};
                      cntr.lat = parseFloat(lat);
                      cntr.lng = parseFloat(lng);
                      setCenter(cntr);
                      setLocationName({ name: place.address_components.find(loc => loc.types[0] === "locality")?.long_name || place.formatted_address.split(",")[0], address: place.formatted_address });
                      changeMarkerPosition(cntr);
                      mapInState.setView(cntr);
                    } catch { }
                  }}
                />
                <div style={{
                  width: "30%",
                  position: "relative",
                }}>
                  <Input
                    maxLength={3}
                    name={"radius"}
                    type={"number"}
                    style={{
                      width: "100%",
                      padding: "5px 0px 5px 10px",
                      height: "38px",
                      borderRadius: '5px',
                      background: "white",
                    }}
                    inputStyle={{ background: "white" }}
                    placeholder={"Radius"}
                    value={radius}
                    onChange={(e) => {
                      const enteredValue = e.target.value.trim();
                      const truncatedValue = enteredValue.slice(0, 3);

                      if (/^\d{1,3}$/.test(truncatedValue) && truncatedValue <= LIMITS.LOCATION_RADIUS) {
                        setRadius(truncatedValue);

                        if (markerR) {
                          markerR.setRadius((Number(truncatedValue) || 0) * 1000);
                          map.fitBounds(markerR.getBounds());
                        }
                      } else {
                        setRadius("");
                      }
                    }}
                  />
                  <div style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "14px",
                    width: "45px",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid #BFC8D9",
                    color: "#1E1E1E",
                  }}>
                    <p>Km</p>
                  </div>
                </div>
              </div>


              <Map
                initCenter={[center?.lat, center?.lng]}
                initZoom={13}
                handleMapClick={() => { }}
                maxZoom={23}
                style={{
                  height: "98.2%",
                  borderRadius: "0 0 5px 5px",
                }}
                mapInitialized={(map) => {
                  mapInState = map;
                  initMap(map);
                }}
              />
            </div>
            <Button
              type="button"
              text={location ? "Update" : "Add"}
              style={{
                textAlign: "center",
                position: "absolute",
                zIndex: "1000",
                bottom: "20px",
                right: "30px",
                padding: "4px, 24px, 4px, 24px",
                height: currentHeight < 661 ? "30px" : "32px",
                width: "110px",
                fontSize: "14px",
                fontWeight: 500
              }}
              isDisabled={!(locationName.name && radius > 0)}
              onClick={() => updateLocationAPI()}
            />
          </form>
        </>
      </Modal>
    </>
  );
};

export default AddLocation;
