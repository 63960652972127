import React, { useEffect, useState } from 'react';
import MainContentWrapper from '../../Components/MainContentWrapper';
import Popup from '../../ReusableFunctions/Popup';
import { deleteRequest, getAllRequests } from '../../apis/adminAPIs/requests';
import StatusCountContainer from '../../AdminComponents/Requests/SubComponents/StatusCountContainer';
import { useNavigate } from 'react-router';
import { PDRL_SUCCESS_ENGI_ROUTES } from '../../routes';
import { getOrdersStatsAPI } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/orders';
import InteractiveGraph from '../BhuMeetFinance/Orders/SubComponents/OrdersGraph';
import { getRequestsCountInInterval } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/request';
import AdminRequestTable from '../../AdminComponents/Requests/SubComponents/AdminRequestTable';
import useWindowSize from '../../hooks/useWindowSize';
import AdminFilterRequest from '../../AdminComponents/Requests/SubComponents/AdminFilterRequest';
import Modal from '../../ReusableComponents/Modal';
import Form from '../../ReusableComponents/Form';
import InputField from '../../ReusableComponents/InputField';
import { GLOBAL_STYLES } from '../../appConstants';
import { Button } from '../../Components/Helper';
import { DeleteRequestNoteValidation } from '../../utils/inputValidations';
const OperationsRequests = ({ dsp }) => {
    const [loading, setLoading] = useState(true)
    const [requests, setRequests] = useState([])
    const [openFilter, setOpenFilter] = useState(false);
    const [isNotePopupOpen, setIsNotePopupOpen] = useState(false);
    const [noteInfo, setNoteInfo] = useState({
        note:""
    });
    const [deleteRequestInfo, setDeleteRequestInfo] = useState({});
    const [noteLoading, setNoteLoading] = useState(false);
    const {currentWidth}=useWindowSize();
    const [filters, setFilters] = useState(() => {
        if (dsp) {
            return {
                services: [],
                statuses: [],
                locations: [],
                isDspActive: undefined,
                dsps: dsp ? [dsp.dsp_id] : [],
                fromDate: undefined,
                toDate: undefined,
                searchQuery: "",
                pageSize: 15,
                pageNumber: 1,
                interval:"day",
            }
        }
        const savedFilters = localStorage.getItem('operationsrequestFilters')
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters)
            return {
                ...parsedFilters,
                fromDate: parsedFilters.fromDate ? new Date(parsedFilters.fromDate) : null,
                toDate: parsedFilters.toDate ? new Date(parsedFilters.toDate) : null,
            }
        }
        return {
            services: [],
            statuses: [],
            locations: [],
            isDspActive: true,
            dsps: dsp ? [dsp.dsp_id] : [],
            fromDate: new Date().setHours(0, 0, 0, 0),
            toDate: new Date(),
            isFilterByServiceTime: false,
            searchQuery: "",
            pageSize: 15,
            pageNumber: 1,
            interval:"day",
        }
    })
    const [allRequestCount, setAllRequestCount] = useState(0)
    const [view, setView] = useState("tableView");
    const [statusWiseRequestsCount, setStatusWiseRequestsCount] = useState([])
    const [requestsGraphData, setrequestsGraphData] = useState([]);
    const navigate = useNavigate();

    const getAllRequestsFunc = async () => {
        setLoading(true);
        try {
            const data = await getAllRequests({ ...filters, isDspActive: true, services: ["Spraying service"] },
                filters.isFilterByServiceTime,
                filters.isFilterByDateCreated
            )
            setRequests(data?.requests)
            setAllRequestCount(data?.requests[0]?.total_count)
            setLoading(false);
        } catch (err) {
            setLoading(false)
            Popup.alert("Error", err, "ERROR")
            return
        }
    }

    const getStatusWiseRequestCountFunc = async () => {
        try {
            const statusRequestCount = await getOrdersStatsAPI({ ...filters, isDspActive: true, services: ["Spraying service"] })
            let statsInfo = statusRequestCount?.stats?.map(s => {
                return {
                    status: s.order_status,
                    count: s.total_orders,
                    amount: s.total_amount,
                };
            }).filter(stat => stat.status !== 'Total Orders');
            setStatusWiseRequestsCount(statsInfo)
            return
        } catch (error) {
            Popup.alert("Error", error, "ERROR")
            return
        }
    }

    const filterChanged = async () => {
        try {
            setLoading(true)
            setRequests([])
            await getStatusWiseRequestCountFunc()
            await getRequestsGraphFunc()
            await getAllRequestsFunc()
            setLoading(false)
        } catch (e) {
            setLoading(false)
            Popup.alert("Error", e, "ERROR")
        }
    }

    const onDeleteRequest = async (request) => {
        try {
            setLoading(true)
            let result = await deleteRequest(request?.id,noteInfo?.note);
            setIsNotePopupOpen(false);
            setNoteInfo({
                note:""
            })
            if (result) {
                await getStatusWiseRequestCountFunc()
                await getRequestsGraphFunc()
                await getAllRequestsFunc()
                setLoading(false)
                Popup.alert("Success", result, "SUCCESS");
            }
        } catch (e) {
            setLoading(false)
            Popup.alert("Error", e, "ERROR")
        }
    }

    const getRequestsGraphFunc = async () => {
        try {
            const data = await getRequestsCountInInterval(
                { ...filters,searchQuery:undefined, pageNumber: undefined, pageSize: undefined, services: ["Spraying service"], isDspActive: true },
                filters.isFilterByServiceTime,
                filters.isFilterByDateCreated,
                "Periodic"
            );
            setrequestsGraphData(data?.requests)
        } catch (err) {
            console.log("err", err);
        }
    };

    const handleFilterChanged=(updatedFilters)=>{
        setFilters(updatedFilters);
      }

    useEffect(() => {
        filterChanged()
        localStorage.setItem("operationsrequestFilters", JSON.stringify({...filters,searchQuery:""}))
    }, [filters, navigate, dsp])

    return (
        <MainContentWrapper
            containerStyle={{
                padding: "36px 53px"
            }}
        >
            {isNotePopupOpen && (
                <>
                 <Modal
                onClose={() => {
                    setNoteInfo({
                        note:""
                    })
                    setIsNotePopupOpen(false)
                }}
                open={isNotePopupOpen}
                header={"Enter Reason To Delete Request"}
            >
                <Form initialValues={noteInfo}  onSubmitForm={() => {
                      onDeleteRequest(deleteRequestInfo)
                }}
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: 'calc(100% - 75px)', padding: "30px" }} >
                    {(onsubmit) => (
                 <div className='w-full flex flex-col h-full justify-between '>
                     <InputField
                    label={"Note"}
                    width={"100%"}
                    height={"35px"}
                    name={"note"}
                    value={noteInfo?.note}
                    validation={DeleteRequestNoteValidation}
                    handleChange={(value)=>{
                    setNoteInfo((prev)=>({
                        ...prev,
                        note:value,
                    }))
                    }}
                />
                <div className='w-full flex justify-end gap-4 '>
                <Button 
                style={{
                    width:"90px",
                    height:"30px",
                    background:"transparent",
                    border:"1px solid gray",
                    color:GLOBAL_STYLES.BG_ACTIVE,
                    fontSize:"13px"
                }}
                text={"Cancel"}
                type={"button"}
                isDisabled={noteLoading}
                onClick={()=>{
                    setNoteInfo({
                        note:""
                    })
                    setIsNotePopupOpen(false);
                }} 
                />
                <Button 
                style={{width:"90px",height:"30px",fontSize:"13px"}}
                 text={"Delet" + (noteLoading ? "ing..." : "e")}
                 type={"submit"} 
                 isDisabled={noteLoading}
                 onClick={onsubmit} 
                />
             </div>
           </div>)}
        </Form>
            </Modal>
                </>
            )}
            <StatusCountContainer
                requestsStatuses={statusWiseRequestsCount}
            />
            <div className='request-table-header-title-div' style={{ fontSize: "1.5rem", fontWeight: 700,marginBottom:"12px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }} >
                    <div style={{minWidth:"150px"}} >

                    {`${'Requests'} (${requests[0]?.total_count || 0})`}
                    </div>
                </div>
            </div>
              <AdminFilterRequest
                            isHideDSPFilter={dsp ? true : false}
                            open={openFilter}
                            filters={filters}
                            onClose={() => setOpenFilter(false)}
                            onFilterChange={(filterToBeApply) => {
                                let updatedFilters={...filters,...filterToBeApply,pageNumber:1}
                                handleFilterChanged(updatedFilters);
                            }}
                            setOpenFilter={setOpenFilter}
                            screenWidth={currentWidth}
                            loading={loading}
               />
            <InteractiveGraph
                showZoom
                data={requestsGraphData}
                lineType={0}
            />
            <AdminRequestTable
                onDeleteRequest={(request)=>{
                     setDeleteRequestInfo(request)
                    Popup.alert("WARNING", "Are you sure , you want to delete this request?", "WARNING", () => {
                        setIsNotePopupOpen(true);
                    })
                }
            }
                tabelContainerHeight={"570px"}
                pageNumber={filters.pageNumber}
                requests={requests}
                onFilterChange={(filterToBeApply) => {
                    const updatedFilters = { ...filters, ...filterToBeApply, pageNumber: filterToBeApply.pageNumber ?? 1 }
                    setFilters(updatedFilters)
                }}
                onSearch={(search)=>{
                 setFilters((prev)=>({
                  ...prev,
                  pageNumber:1,
                  searchQuery:search,
                 }))
                }}
                isHideDSPFilter={dsp ? true : false}
                filters={filters}
                loading={loading}
                totalCount={requests[0]?.total_count || 0}
                pageSize={filters.pageSize}
                allCount={allRequestCount}
                onClickRow={(request) => {
                    request = { ...request, serviceType: request?.serviceType?.props?.content }
                    navigate(`${PDRL_SUCCESS_ENGI_ROUTES.OPERATIONS_REQUESTDETAILS}`, { state: { request: request } })
                }}
                view={view}
                setView={setView}
            />
        </MainContentWrapper>
    )
}

export default OperationsRequests
