import React from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import { PDRL_ADMIN_ROUTES } from '../../routes'
import SendTextMessage from './SendTextMessage'

export default function MarketingDashBoard() {
    return (
        <MainContentWrapper>
            <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
                <div className="dashboard-first-section-second-div-one" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.NOTIFICATIONS}
                        title="Notifications"
                        icon={""}
                    />
                    <SendTextMessage />
                </div>
            </div>
        </MainContentWrapper>
    )
}
