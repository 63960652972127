import React, { useState } from 'react';
import './DspDetails.css';
import MainContentWrapper from '../../Components/MainContentWrapper';
import { useLocation } from 'react-router';
import Drones from '../BhuMeetCustomerSuccess/Drones';
import ViewOperationDsp from './ViewOperationDsp';
import Requests from '../../AdminComponents/Requests/Requests';
import OperationsPilots from './OperationsPilots';
import Locations from './Locations/Locations';

function OperationDspDetails() {
    const [activeTab, setActiveTab] = useState('details');
    const location = useLocation();
    const [dsp, setDsp] = useState(location.state?.dsp)

    const tabs = ['details', 'drones', 'pilots', 'requests',"locations"];

    return (
        <div style={{ backgroundColor: "rgb(236, 239, 243)", overflow: "hidden" }} className='flex flex-col gap-5 h-[100%]  px-[20px] py-[50px]'>
            <div className="drone-tabs ">
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
            </div>
            <div className="flex-grow overflow-y-auto">
                {activeTab === "details" ? (
                    <ViewOperationDsp dsp={dsp} callback={(dsp) => {
                        setDsp(dsp);
                    }} />
                ) : activeTab === "drones" ? (
                    <Drones dsp={dsp} />
                ) : activeTab === "requests" ? (
                    <Requests dsp={dsp} />
                ) : activeTab === "pilots" ? (
                    <OperationsPilots dsp={dsp} />
                ): activeTab === "locations" ? (
                    <Locations dsp={dsp} />
                ) : null}
            </div>
        </div>
    );
}

export default OperationDspDetails;