import Popup from "../../../ReusableFunctions/Popup";
import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getDroneOverview = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/overview', {
                user_type : 'admin'
            })
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data?.droneManufactures);
            } else {
                reject(
                    data?.message || "Something went wrong while getting stores."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting stores.");
        }
    });
};


export const getDroneManufactures = (pageSize,pageNumber,searchString=undefined) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/getAllManufacturers', {
                user_type : 'admin',
                pageNumber : pageNumber,
                pageSize : pageSize,
                searchQuery : searchString
            })
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response)

            if (response.status == 200) {
                const data = await response.json();

                resolve(data?.droneManufactures);
            } else if(response.status == 204) {
                // console.log(response)
                resolve([])
            }
        } catch (error) {
            reject("Something went wrong while getting stores.");
        }
    });
};

export const getDrones = (pageSize,pageNumber,id=undefined,search) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/dronemodels', {
                user_type : 'admin',
                pageNumber : pageNumber,
                pageSize : pageSize,
                manufacturerId : id,
                search : search
            })
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response)
            
            if (response.status === 200) {
                const data = await response.json();
                resolve(data?.droneModels);
            } else if(response.status === 204){
                reject(
                    "No Drone Found"
                );
            }
        } catch (error) {
            console.log(error)
            reject("Something went wrong while getting drones");
        }
    });
};


export const getDronesDetails = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/dronemodels/details', {
                user_type : 'admin',
                id : id
            })
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response)
            
            if (response.status === 200) {
                const data = await response.json();
                resolve(data?.droneModelDetails);
            } else if(response.status === 204){
                reject(
                    "No Drone Found"
                );
            }
        } catch (error) {
            console.log(error)
            reject("Something went wrong while getting drones");
        }
    });
};

export const getEnquiryByDroneId = (pageSize,pageNumber,id=undefined,isManufacture) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/inquiry', {
                user_type : 'admin',
                pageNumber : pageNumber,
                pageSize : pageSize,
                drone_id : id,
                isManufacture : isManufacture
            })
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response)
            
            if (response.status === 200) {
                const data = await response.json();
                resolve(data?.enquiry);
            } else if(response.status === 204){
                reject(
                    "No Enquiry Found"
                );
            }
        } catch (error) {
            console.log(error)
            reject("Something went wrong while getting Enquiry");
        }
    });
};


export const PostManufacture = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            // const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/post')
            const response = await fetch(SERVER_URLS.BACKEND_SERVER + '/store/drones/post?user_type=admin',
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body:formData

                }
            );
            // console.log(response)
            
            if (response.status === 200) {
                const data = await response.json();
                resolve(data);
            } else if(response.status === 204){
                reject(
                    "Something went wrong"
                );
            }
        } catch (error) {
            console.log(error)
            reject("Something went wrong while inserting Manufacturer");
        }
    });
};

export const updateManufacture = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            // const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/post')
            const response = await fetch(SERVER_URLS.BACKEND_SERVER + '/store/drones/update?user_type=admin',
                {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body:formData

                }
            );
            // console.log(response)
            
            if (response.status === 200) {
                const data = await response.json();
                resolve(data);
            } else if(response.status === 204){
                reject(
                    "Something went wrong"
                );
            }
        } catch (error) {
            console.log(error)
            reject("Something went wrong while inserting Manufacturer");
        }
    });
};

export const PostDroneModel = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            // const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/post')
            const response = await fetch(SERVER_URLS.BACKEND_SERVER + '/store/drones/dronemodels?user_type=admin',
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body:formData

                }
            );
            // console.log(response)
            
            if (response.status === 200) {
                const data = await response.json();
                resolve(data);
            } else if(response.status === 204){
                reject(
                    "Failed to insert"
                );
            }
        } catch (error) {
            console.log(error)
            reject("Something went wrong while inserting Drone Model");
        }
    });
};



export const updateDroneModel = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            // const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/post')
            const response = await fetch(SERVER_URLS.BACKEND_SERVER + '/store/drones/dronemodels/update?user_type=admin',
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body:formData

                }
            );
            // console.log(response)
            
            if (response.status === 200) {
                const data = await response.json();
                resolve(data);
            } else if(response.status === 204){
                reject(
                    "Failed to insert"
                );
            }else{
                return Popup.alert("ERROR","Something went wrong while updating drone model","ERROR")

            }
        } catch (error) {
            console.log("error")
            return Popup.alert("ERROR","Something went wrong while updating drone model","ERROR")
        }
    });
};


export const deleteManufacturewithdrone = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/delete', {
                user_type : 'admin',
                id : id
            })
            const response = await fetch(url,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response)
            
            if (response.status === 200) {
                const data = await response.json();
                resolve(data?.droneModels);
            } else if(response.status === 204){
                reject(
                    "No Deleted Found"
                );
            }
        } catch (error) {
            console.log(error)
            reject("Something went wrong while deleting Manufacture and drones");
        }
    });
};



export const deletedroneModel = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/store/drones/dronemodels', {
                user_type : 'admin',
                id : id
            })
            const response = await fetch(url,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response)
            
            if (response.status === 200) {
                const data = await response.json();
                resolve(data?.droneModels);
            } else if(response.status === 204){
                reject(
                    "No Deleted Found"
                );
            }
        } catch (error) {
            console.log(error)
            reject("Something went wrong while deleting Manufacture and drones");
        }
    });
};