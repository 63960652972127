import React, { useEffect, useState } from 'react'
import LoaderComponent from '../../../../ReusableComponents/LoaderComponent'
import { GLOBAL_STYLES, STATUS } from '../../../../appConstants'
import RequestProgressCard from '../RequestProgressCard/RequestProgressCard'
import { addCommasToAmount, getFormatedDate } from '../../../../ReusableFunctions/reusableFunctions'
import { getRequestsOverview } from '../../../../apis/requests'
import Popup from '../../../../ReusableFunctions/Popup'
import InProgress from "../../../../assets/inprogress.svg"
import Received from "../../../../assets/recieved.svg"
import Completed from "../../../../assets/completed.svg"
import Paid from "../../../../assets/paid.svg"
import Location from "../../../../assets/MapPinOutline.svg"
import Revenue from "../../../../assets/money.svg"
import Orders from '../../../HomePage/SubComponents/Graphs/Orders'
import eclipseIcon from '../../../../assets/eclipseicon.svg';

const RequestOverView = () => {
    const [overViewLoading, setOverViewLoading] = useState(true)
    const [overViewData, setOverViewData] = useState({
        requests: { Completed: 0, InProgress: 0, Placed: 0, Paid: 0 },
        latestLocations: [],
        revenue: 0
    })
    const getRequestsOverviewFunc = async () => {
        try {
            const { totalRequestCount, requests, latestLocations, revenue, locationWiseRequestsCount } = await getRequestsOverview()
            setOverViewData(prev => ({ ...prev, requests, latestLocations, revenue }))
            setOverViewLoading(false)
        } catch (err) {
            setOverViewLoading(false)
            Popup.alert("Error", err.errorMessage, "ERROR");
        }
    }

    console.log("overviewLoading ",overViewLoading);
    useEffect(() => {
        getRequestsOverviewFunc()

    }, [])
    return (
        <div className='request-over-view-wrapper' >
            {/* 1st content */}
            <div style={{ borderRadius: "10px", width: "49.1%", maxWidth: "49.1%", display: "flex", flexDirection: "column", rowGap: "23px", height: "100%", position: "relative" }}>
                {overViewLoading ? <LoaderComponent /> : (
                    <>
                        <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", height: "170px", }}>
                            {/* 1st card */}
                            <RequestProgressCard img={Received} count={overViewData.requests.Placed || 0} status={STATUS.PLACED} />
                            {/* 2nd card */}
                            <RequestProgressCard img={InProgress} count={overViewData.requests.InProgress || 0} status={STATUS.INPROGRESS} />
                            {/* 3rd card */}
                            <RequestProgressCard img={Completed} count={overViewData.requests.Completed || 0} status={STATUS.COMPLETED} />
                            {/* 4th card */}
                            <RequestProgressCard img={Paid} count={overViewData.requests.Paid || 0} status={STATUS.PAID} />


                        </div>
                        {/* 4th card */}
                        <div style={{
                            display: "flex", flexDirection: "column", background: "#FCFCFC", padding: "15px 18px", borderRadius: "8px",
                            rowGap: "12px", boxShadow: ' 0px 0px 8px 1px rgba(117, 117, 117, 0.15)', height: "150px", justifyContent: "space-between",
                            overflow: "hidden"
                        }}>
                            {/* 1st div */}
                            < div style={{ position: "relative", display: "flex", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL, justifyContent: "space-between" }}>

                                <img className='absolute w-[250px] h-[250px] top-[-70px] left-[38%]' src={eclipseIcon} alt='' />

                                {/* location div */}
                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <img src={Location} style={{ width: "32px" }} />
                                    <div style={{ color: "#3b3b3b", fontSize: "12px" }}>Latest Location</div>
                                </div>
                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <img src={Revenue} style={{ width: "32px" }} />
                                    <div style={{ color: "#3b3b3b", fontSize: "12px" }}>Total Revenue</div>
                                </div>
                            </div>

                            {/* 2nd div */}
                            <div div style={{ display: "flex", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, justifyContent: "space-between", alignItems: "baseline" }}>
                                {/* address div */}
                                <div>
                                    <div style={{ color: "#1E1E1E", fontSize: '13px', fontWeight: 500 }}>{overViewData.latestLocations.map(loc => loc.name.split(" ")[0].split(",")[0]).join(" , ")}</div>
                                    <div style={{ fontSize: "10px", fontWeight: "500", color: 'rgb(114 114 114 / 74%)', }}>{"(3 recently added location)"}</div>
                                </div>
                                <div style={{ textAlign: "right" }}>
                                    <div style={{ fontSize: '19px', fontWeight: 500 }}>{`Rs. ${addCommasToAmount(overViewData.revenue)}`}</div>
                                    <div style={{ fontSize: "10px", fontWeight: "500", color: 'rgb(114 114 114 / 74%)', }}>{`(Till ${getFormatedDate(new Date(), true)})`}</div>
                                </div>
                            </div>

                        </div>
                    </>

                )
                }
            </div>

            {/* 2nd content */}
            <Orders showAll filter width={"49.1%"} maxWidth={"49.1%"} />
        </div>
    )
}

export default RequestOverView