import React, { useEffect, useState } from 'react'
import { deleteDSPOperator, getDSPOperators, getDSPOperatorsCount } from '../../apis/operator'
import { login } from '../../apis/authentication';
import Loading from '../../ReusableFunctions/Loader';
import Popup from '../../ReusableFunctions/Popup';
import { useNavigate } from 'react-router';
import { GLOBAL_STYLES } from '../../appConstants';
import { Button, OptionMenu } from '../../Components/Helper';
import Plus from "../../assets/Plus.svg";
import NotFounds from '../../ReusableComponents/NotFounds';
import { Link } from "react-router-dom";
import AddDSPOpeartor from '../../Components/AddDSPOpeartor';
import { getLocations } from '../../apis/locations';
import more from "../../assets/more.png"
import Pagination from '../../ReusableComponents/Pagination';
import "../../index.css";
import LoaderComponent from "../../ReusableComponents/LoaderComponent.js"
import { ROUTES } from '../../routes.js';
import { clearProfileFilters, reduceLongName } from '../../ReusableFunctions/reusableFunctions';
import MainContentWrapper from '../../Components/MainContentWrapper';
import { Table, TableHeader } from '../../ReusableComponents/Table.js';
import Tippy from '@tippyjs/react';
import TableView from '../../ReusableComponents/TableView/TableView.js';
const PAGE_SIZE = 14

const ManageTeam = () => {
    let count = 0;
    const navigate = useNavigate();

    const [user, setUser] = useState();
    const [operators, setOperators] = useState([]);
    const [openTeamsPopup, setOpenTeamsPopup] = useState(false)
    const [operatorId, setOperatorId] = useState();
    const [locations, setLocations] = useState();
    const [activityPopup, setActivityPopup] = useState({
        id: null,
        isOpen: false
    })
    const [currentPage, setCurrentPage] = useState(1)
    let srNo = (currentPage - 1) * PAGE_SIZE
    const [operatorCount, setOperatorCount] = useState(0)
    const [loading, setLoading] = useState(true)

    const handleOnOperatorAdd = (operator) => {
        setOpenTeamsPopup(false)
        if (operatorId) {
            Popup.alert("Team Member", "Team member updated successfully", "SUCCESS")
            setOperators(operators.map((op) => {
                return (op.id === operatorId) ? operator : op
            }))
        }
        else {
            Popup.alert("Team Member", "Team member added successfully", "SUCCESS")
            setOperators(
                [
                    operator,
                    ...operators
                ])
            setOperatorCount(operatorCount + 1)
        }

    }

    const onDelete = (operator) => {
        Popup.alert("Team Member", "Are you sure, you want to delete this member from your team?", "DELETE", () => {
            deleteOprator(operator.id)
        });
    }

    const onEdit = (operator) => {
        setOperatorId(operator.id)
        setOpenTeamsPopup(true)
    }

    const getOperatorLocation = (locationId) => {
        if (locationId) {
            const location = locations?.find(l => l.id === locationId)
            return location?.name;
        }
        return "-";
    }
    const deleteOprator = (id) => {
        Loading.set(true);
        deleteDSPOperator(id)
            .then((res) => {
                Loading.set(false);
                Popup.alert("Team Member", "Team Member successfully deleted.", "SUCCESS");
                const afterDeleteOprators = operators.filter(op => op.id !== id)
                if (afterDeleteOprators.length == 0 && currentPage > 1) {
                    setCurrentPage(currentPage - 1)
                    getDspOperatorsByPage(currentPage - 1)
                } else {
                    getDspOperatorsByPage(currentPage)
                }
                setOperatorCount(operatorCount - 1)
            })
            .catch((err) => {
                Loading.set(false);
                Popup.alert("Team Member", err, "ERROR");
            })
    }
    const openActicityPopup = (id) => {
        setActivityPopup((prev) => ({
            id: id,
            isOpen: prev.id !== id || !prev.isOpen, // Close the current popup and open the clicked row
        }));
    }

    const closeMenu = (e) => {
        if (e.target.id !== 'menu')
            setActivityPopup({
                id: null,
                isOpen: false
            })
    }
    const getDspOperatorsByPage = (pageNumber) => {
        setLoading(true)
        getDSPOperators(pageNumber, PAGE_SIZE)
            .then((res) => {
                setOperators(res.dspOperators)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err);
                Popup.alert("Error", err, "ERROR");
            })
    }
    useEffect(() => {
        window.addEventListener('click', closeMenu)
        Loading.set(true);
        document.title = "BhuMeet - Teams";
        clearProfileFilters();
        window.history.replaceState(null, null, "/");
        login()
            .then((user) => {
                if (!user.is_admin) navigate("/")
                else {
                    setUser(user);
                    if (user) {
                        if (user?.dsp && !user?.dsp?.is_verified) {
                            navigate(ROUTES.PROFILE);
                        }
                    }
                    getDSPOperators(currentPage, PAGE_SIZE)
                        .then((res) => {
                            setOperators(res.dspOperators)
                            setLoading(false)
                        })
                        .catch((err) => {
                            Loading.set(false);
                            console.log(err);
                            Popup.alert("Error", err, "ERROR");
                        })
                    getDSPOperatorsCount()
                        .then((res) => {
                            setOperatorCount(Number(res.operatorcount))
                            setLoading(false)
                            Loading.set(false);
                        })
                        .catch((err) => {
                            Loading.set(false);
                            console.log(err);
                            Popup.alert("Error", err, "ERROR");
                        })
                    getLocations()
                        .then((res) => {
                            setLocations(res)
                            Loading.set(false);
                        })
                        .catch((err) => {
                            Loading.set(false);
                            console.log(err);
                            Popup.alert("Error", err, "ERROR");
                        })
                }
            })
            .catch((err) => {
                Loading.set(false);
                console.log(err);
                Popup.alert("Error", err, "ERROR");
            });
        return () => {
            document.removeEventListener('click', closeMenu)
        }
    }, []);
    return (
        <>
            {
                openTeamsPopup &&
                <AddDSPOpeartor
                    open={openTeamsPopup}
                    onClose={() => {
                        setOpenTeamsPopup(false)
                        setOperatorId(null)
                    }}
                    onOperatorAdd={handleOnOperatorAdd}
                    operatorId={operatorId || null}
                    user={user}
                />
            }
            <MainContentWrapper header={`My Team (${operators.length > 0 ? operators.length : 0})`}
                rightContent={operators && operators.length > 0 && <Button type="submit" icon={Plus} text={"Add New"} onClick={() => {
                    setOpenTeamsPopup(true)
                }} style={{ overflow: "hidden", padding: "4px, 24px, 4px, 24px", height: "35px", width: "120px", fontSize: "14px", fontWeight: 500 }} />}>
                <div
                    style={{
                        width: "100%",
                        height: "93%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {operators && operators.length > 0 ?
                        <>
                            <TableView
                                showSrNo
                                onStateChange={async (changedState) => {
                                    console.log("chnagedstate", changedState);
                                    const { pageNumber } = changedState;
                                    setCurrentPage(() => pageNumber);
                                    getDspOperatorsByPage(pageNumber);
                                }}
                                onClickRow={(request) => {

                                }}
                                isLoading={loading}
                                loaderHeight={'500px'}
                                columns={[
                                    { Header: "Name", accessor: "name", },
                                    { Header: "Location", accessor: "address", },
                                    { Header: "Role", accessor: "is_admin", },
                                    { Header: "Assigned Location", accessor: "op_location" },
                                    { Header: "Status", accessor: "is_active", showColor: true },
                                ]}
                                data={operators.map((op) => ({ ...op, is_admin: op.is_admin ? "Admin" : "Location Manager", op_location: op.location_id ? reduceLongName(getOperatorLocation(op.location_id)) : "All", is_active: op.is_active ? "Active" : "InActive", color: op.is_active ? "#39A506" : "#3B3B3B" }))}
                                pageNumber={currentPage}
                                count={operatorCount}
                                activities={{ onClickDelete: onDelete, onClickEdit: onEdit, canEdit: true }}

                            />
                        </>
                        :
                        operators && operators.length === 0 && (
                            <NotFounds msg={"OOPS, No team members found !"} onBtnClick={() => {
                                setOpenTeamsPopup(true)
                            }} />
                        )
                    }
                </div>
            </MainContentWrapper>
        </>
    )
}

export default ManageTeam