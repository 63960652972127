import React, { useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { Button } from '../../Components/Helper';
import TextAreaField from '../../ReusableComponents/TextAreaInput';
import { sendNotifications } from '../../apis/BhuMeetTeams/Marketing/notifications';
import Popup from '../../ReusableFunctions/Popup';
import useWindowSize from '../../hooks/useWindowSize'

export default function SendNotifications() {
    const [notificationToSend, setNotificationToSend] = useState({
        Hindi: "",
        Marathi: "",
        Punjabi: "",
        Kannada: "",
        Gujarati: "",
        Tamil: "",
        Telugu: "",
        English: "",
        Malyalam: ""
    })
    const [error, setError] = useState({
        Hindi: "",
        Marathi: "",
        Punjabi: "",
        Kannada: "",
        Gujarati: "",
        Tamil: "",
        Telugu: "",
        English: "",
        Malyalam: ""

    })

    const { currentHeight, currentWidth } = useWindowSize();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNotificationToSend((prev) => ({
            ...prev,
            [name]: value,
        }))
        if (value.length < 5 && value !== "") {
            setError((prev) => ({
                ...prev,
                [name]: `message must be at least 5 characters long.`
            }));
        } else if (value.length >= 5) {
            setError((prev) => ({
                ...prev,
                [name]: ""
            }));
        } else if (value === "") {
            setError((prev) => ({
                ...prev,
                [name]: ""
            }));
        }
    }

    const isEveryFieldFilled = () => {
        const isEnglishFilled = notificationToSend.English !== "";
        const isAnyLanguageFilled = Object.values(notificationToSend).some(
            (value) => value !== ""
        );
        const hasErrors = Object.values(error).some((err) => err !== "");
        return isEnglishFilled && isAnyLanguageFilled && !hasErrors
    }

    const SendNotification = async (notificationToSend) => {
        try {
            await sendNotifications(notificationToSend)
            Popup.alert("Notifications", "Notifications sent to the users !", 'SUCCESS')
        } catch (e) {
            Popup.alert("Error", e, 'ERROR')
        }
    }
    return (
        <MainContentWrapper headerStyle={{ marginLeft: "20px" }} header={"Send Notifications"}>

            <div
                id='coupen-form'
                className='send_notifications_wrapper'
            >
                <TextAreaField
                    label={"English"}
                    value={notificationToSend.English}
                    isMandotory
                    name={"English"}
                    onChange={handleInputChange}
                    placeholder={`Enter message in english`}
                    error={error.English}
                    height={currentHeight < 850 ? "130px" : ""}
                />
                <TextAreaField
                    label={"Hindi"}
                    value={notificationToSend.Hindi}
                    name={"Hindi"}
                    onChange={handleInputChange}
                    placeholder={`Enter message in hindi`}
                    error={error.Hindi}
                    height={currentHeight < 850 ? "130px" : ""}
                />
                <TextAreaField
                    label={"Marathi"}
                    value={notificationToSend.Marathi}
                    name={"Marathi"}
                    onChange={handleInputChange}
                    placeholder={`Enter message in marathi`}
                    error={error.Marathi}
                    height={currentHeight < 850 ? "130px" : ""}
                />
                <TextAreaField
                    label={"Punjabi"}
                    value={notificationToSend.Punjabi}
                    name={"Punjabi"}
                    onChange={handleInputChange}
                    placeholder={`Enter message in Punjabi`}
                    error={error.Punjabi}
                    height={currentHeight < 850 ? "130px" : ""}
                />

                <TextAreaField
                    label={"Kannada"}
                    value={notificationToSend.Kannada}
                    name={"Kannada"}
                    onChange={handleInputChange}
                    placeholder={`Enter message in Kannada`}
                    error={error.Kannada}
                    height={currentHeight < 850 ? "130px" : ""}
                />
                <TextAreaField
                    label={"Gujarati"}
                    value={notificationToSend.Gujarati}
                    name={"Gujarati"}
                    onChange={handleInputChange}
                    placeholder={`Enter message in Gujarati`}
                    error={error.Gujarati}
                    height={currentHeight < 850 ? "130px" : ""}
                />
                <TextAreaField
                    label={"Tamil"}
                    value={notificationToSend.Tamil}
                    name={"Tamil"}
                    onChange={handleInputChange}
                    placeholder={`Enter message in Tamil`}
                    error={error.Tamil}
                    height={currentHeight < 850 ? "130px" : ""}
                />
                <TextAreaField
                    label={"Telugu"}
                    value={notificationToSend.Telugu}
                    name={"Telugu"}
                    onChange={handleInputChange}
                    placeholder={`Enter message in Telugu`}
                    error={error.Telugu}
                    height={currentHeight < 850 ? "130px" : ""}
                />
                <TextAreaField
                    label={"Malyalam"}
                    value={notificationToSend.Malyalam}
                    name={"Malyalam"}
                    onChange={handleInputChange}
                    placeholder={`Enter message in Malyalam`}
                    error={error.Malyalam}
                    height={currentHeight < 850 ? "130px" : ""}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    padding: "10px 0px 15px",
                    gap: "20px",
                }}
            >
                <Button
                    text={"Send"}
                    className={"btn-large"}
                    isDisabled={!isEveryFieldFilled()}
                    style={{ height: "40px", width: "100px" }}
                    onClick={() => SendNotification(notificationToSend)}
                />
            </div>

        </MainContentWrapper>
    )
}
