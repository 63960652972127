import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const postCallingHistory = async (Data) => {
    try {
        // console.log(Data)
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/telecaller/callHistory`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(Data),
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res.RequestQueue;
    } catch (err) {
        throw err;
    }
}

export const updateCallingHistory = async (Data,id) => {
    try {
        // console.log(Data)
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/telecaller/callHistory/updateCallhistory?id=${id}`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(Data),
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res.RequestQueue;
    } catch (err) {
        throw err;
    }
}


export const updateCallingHistoryStatus = async (Data) => {
    try {
        // console.log(Data)
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/telecaller/callHistory/updateCallHistorystatus`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(Data),
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res.CallHistory;
    } catch (err) {
        throw err;
    }
}

export const InitiateCall = async(toCallNumber,RequestDetails,fromCall)=>{
    try {
        const token = getToken();

        let checkService = await checklocalMicroSip(fromCall);

        if(!checkService.vpn){
            throw "Please start Your VPN service.";
        }else if(!checkService.microSip){
            throw "Please start Your MicroSip application.";
        }

        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/telecaller/callHistory/InitiateCall`,
            {
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body:JSON.stringify({toCall:toCallNumber,RequestDetails,fromCall})
            }
        )

        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res;
    } catch (err) {

        throw err;
        
    }
}


export const checklocalMicroSip = async(fromCall)=>{
    try {

        const response = await fetch(`${SERVER_URLS.BHUMEET_TELECALLER_LOCAL_SERVICE}/check-user-sip?agentNumber=${fromCall}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            }
        )

        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res.result;
    } catch (err) {

        throw err;
        
    }
}


export const getCallhistorybyCampid = async(campid)=>{
    try {
        const token = getToken();

        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/telecaller/callHistory/getCallhistorybyCampid?campid=${campid}`,
            {
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            }
        )

        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res.CallHistory;
    } catch (err) {

        throw err;
        
    }
}