import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getDashBoardData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      let url = SERVER_URLS.BACKEND_SERVER + "/dsp/overview"
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while getting dashboard data."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting dashboard data.");
    }
  });
};

export const getOrdersGraphAPI = (service_type, interval) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/graphs/orders?service_type=${service_type == 'All' ? '' : service_type}&interval=${interval}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data.orders);
      } else {
        reject(
          data?.message || "Something went wrong while getting orders graph data."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting orders graph data.");
    }
  });

}

export const getResourceUtilGraphAPI = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/graphs/resourceUtil`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while getting resource graph data."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting resource graph data.");
    }
  });

}