import React, { useEffect, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSize';
import Modal from '../../ReusableComponents/Modal';
import Form from '../../ReusableComponents/Form';
import { emailValidation, nameValidation, passwordValidation, selectFieldValidation } from '../../utils/inputValidations';
import InputField from '../../ReusableComponents/InputField';
import { Button } from '../../Components/Helper';
import { BHUMEET_TEAM_ROLES } from '../../appConstants';
import SelectField from '../../ReusableComponents/SelectField';
import { addTeamMember, updateTeamMember } from '../../apis/adminAPIs/teams';
import Loading from '../../ReusableFunctions/Loader';
import Popup from '../../ReusableFunctions/Popup';
import Tippy from '@tippyjs/react';
import { reShuffleRequestQueue } from '../../apis/BhuMeetTeams/Telecaller/requestqueue';

export default function AddTeamMember({
    open,
    onClose,
    onOperatorAdd,
    operator
}) {
    const [operatorInfo, setOperatorInfo] = useState(operator?.id ?
        {
            ...operator,
            bhumeet_role: { label: operator.bhumeet_role, value: operator.bhumeet_role },
            assignStatus: operator.assigneStatus,
            MobileNumber : operator.phone || ''

        }
        : {
            name: "",
            email: "",
            password: "",
            bhumeet_role: "",
            assignStatus: [],
            MobileNumber:""
        });
    const [reShuffle,setReshuffle] = useState(false)
    const { currentHeight, currentWidth } = useWindowSize();
    const teamRolesOptions = Object.values(BHUMEET_TEAM_ROLES).map(role => ({
        label: role.NAME,
        value: role.NAME,
    }));



    const handleOnChangeAssignee = (e) => {

        const value = e.target.value
        // console.log(value)
        let assignee = operatorInfo.assignStatus ?? []
        if (e.target.checked) {
            assignee = [...assignee, value]
        } else {
            assignee = assignee.filter(dsp => dsp !== value)
        }
        assignee = Array.from(new Set(assignee))
        setOperatorInfo(prev => ({ ...prev, assignStatus: assignee }))
    }

    const teleSubvalue = ['Accepted', 'Completed', 'Paid','Placed','Out for Service','InProgress','Cancelled','On Hold']


    const addTeamMemberFunc = async (operator) => {
        Loading.set(true);
        try {
            const addedMember = await addTeamMember(operator);
            if(reShuffle){
                const reshuffled = await reShuffleRequestQueue()

                Popup.alert("SUCCESS","Successfully Reshuffled Request And Team Member Added","SUCCESS")
            }else{
                Popup.alert("Team Member", "Team member added successfully", "SUCCESS")
            }
            
            onOperatorAdd(addedMember)
            Loading.set(false);
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const updateTeamMemberFunc = async (operator) => {
        Loading.set(true);
        try {
            const updatedOperator = await updateTeamMember(operator?.id, operator);
            Popup.alert("Team Member", "Team member updated successfully", "SUCCESS")
            onOperatorAdd(updatedOperator)
            Loading.set(false);
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }
    return (
        <Modal
            header={operator ? "Edit Team Member" : "Add Team Member"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "640px", height: "auto", borderRadius: "10px" }}
            headerContainerStyle={{ padding: currentHeight < 661 ? "15px 30px" : "25px 30px", }}
        >
            <Form
                onSubmitForm={(operatorDetails) => {
                    const operatorInfo = {
                        ...operatorDetails,
                        bhumeet_role: operatorDetails?.bhumeet_role?.value
                    }
                    operator?.id ? updateTeamMemberFunc(operatorInfo) : addTeamMemberFunc(operatorInfo);
                }}
                initialValues={operatorInfo}
            >
                {(onSubmit, formValues) => (
                    <div className="addDrone_form_wrapper" >
                        <div className="add_drone_form" style={{ padding: currentHeight < 661 ? "5px 30px" : "10px 30px" }}>
                            <InputField
                                defaultValue={operatorInfo.name}
                                label={"Name"}
                                type={"text"}
                                id={"type"}
                                placeholder={"Please Enter Name"}
                                name={"name"}
                                handleChange={(e) => {
                                    // console.log(e)
                                    setOperatorInfo((prev) => ({ ...prev, name: e }))
                                }}
                                validation={nameValidation}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />
                            <InputField
                                defaultValue={operatorInfo.email}
                                label={"Email"}
                                type={"text"}
                                id={"type"}
                                placeholder={"Please Enter Email"}
                                name={"email"}
                                validation={emailValidation}
                                handleChange={(e) => {
                                    // console.log(e)
                                    setOperatorInfo((prev) => ({ ...prev, email: e }))
                                }}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />
                            {!operator?.id && (
                                <InputField
                                    defaultValue={operatorInfo.password}
                                    label={"Password"}
                                    type={"text"}
                                    id={"type"}
                                    placeholder={"Please Enter Password"}
                                    name={"password"}
                                    handleChange={(e) => {
                                        // console.log(e)
                                        setOperatorInfo((prev) => ({ ...prev, password: e }))
                                    }}
                                    validation={passwordValidation}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        minHeight: "80px",
                                    }}
                                />
                            )}

                            <SelectField
                                handleChange={(selectedOption) => {
                                    setOperatorInfo((prevState) => ({
                                        ...prevState,
                                        bhumeet_role: selectedOption
                                    }));

                                }}
                                name={"bhumeet_role"}
                                label={"Role"}
                                list={teamRolesOptions}
                                styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                                validation={selectFieldValidation}
                                placeholderText={"Select role"}
                                defaultValue={operator?.id ? { label: operatorInfo?.bhumeet_role, value: operatorInfo?.bhumeet_role } : null}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />

                            {operatorInfo.bhumeet_role.label == 'Telecaller' &&

                                <>

                                {!operator && 
                                
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                        <div style={{ width: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <input
                                                // disabled={isHideDSPFilter}
                                                // checked={tempFilters?.dsps?.filter(loc => loc === dsp.dsp_id).length > 0}
                                                onChange={(e)=>{
                                                    setReshuffle(e.target.checked)
                                                }}
                                                // value={dsp.dsp_id}
                                                type={"checkbox"}
                                                className="input-checkbox"
                                                style={{ width: "16px" }}
                                            />
                                        </div>
                                        <Tippy content={true}>
                                            <span style={{ lineHeight: 1.1, fontSize: "15px", fontWeight: 500 }}>{"Reshuffle Request"}</span>
                                        </Tippy>
                                    </div>}

                                <InputField
                                defaultValue={operatorInfo.MobileNumber}
                                label={"Mobile Number"}
                                type={"number"}
                                id={"type"}
                                placeholder={"Please Enter Number"}
                                name={"MobileNumber"}
                                handleChange={(e) => {
                                    // console.log(e)
                                    setOperatorInfo((prev) => ({ ...prev, MobileNumber: e }))
                                }}
                                validation={{
                                    required: {
                                    value: true,
                                    message: 'Required',
                                  },
                                  pattern:{
                                    value:/^(\+\d{1,3}[- ]?)?\d{10}$/,
                                    message:"Required 10 digit"
                                }}
                                }
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />

                                    <div style={{ fontSize: '14px', display: "flex", flexDirection: "column", rowGap: "8px", overflowY: "auto", height: "100%", color: "#3B3B3B", fontWeight: 500, maxHeight: "240px" }}>


                                        <div style={{ fontSize: "16px", fontWeight: 500 }}>Assign status :</div>

                                        {teleSubvalue?.map(dsp =>
                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                <div style={{ width: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <input
                                                        // disabled={isHideDSPFilter}
                                                        checked={operatorInfo?.assignStatus?.filter(assign => assign === dsp).length > 0}
                                                        onChange={handleOnChangeAssignee}
                                                        value={dsp}
                                                        type={"checkbox"}
                                                        className="input-checkbox"
                                                        style={{ width: "16px" }}
                                                    />
                                                </div>
                                                <Tippy content={dsp}>
                                                    <span style={{ lineHeight: 1.1, fontSize: "15px", fontWeight: 500 }}>{dsp}</span>
                                                </Tippy>
                                            </div>)}
                                    </div>
                                </>

                            }




                        </div>
                        <div
                            className="buttons_wrapper"
                            style={{ padding: currentHeight < 661 ? "5px 30px" : "10px 30px" }}
                        >
                            <Button
                                className={"btn-large"}
                                text={operator?.id ? "Update" : "Add"}
                                onClick={onSubmit}
                                style={{ width: "100px" }}
                            />
                            <Button
                                className={"btn-large lg-cancel-btn "}
                                text={"Cancel"}
                                onClick={onClose}
                                isBtnNotActive={true}
                            />
                        </div>
                    </div>
                )}
            </Form>
        </Modal>
    )
}
