import React, { useCallback, useEffect, useRef, useState } from "react";
import Map from "../../LeafletMap/Map";
import Modal from "../../../ReusableComponents/Modal";
import MarkerIcon from "../../../assets/marker.png";
import L from "leaflet";
import { Button, CrossCloseButton } from "../../../Components/Helper";
import { GLOBAL_STYLES, LIMITS } from "../../../appConstants";
import { addLocation, updateLocation } from "../../../apis/locations";
import Input from "../../../ReusableComponents/Input";
import Popup from "../../../ReusableFunctions/Popup";
import Loading from "../../../ReusableFunctions/Loader";
import searchIcon from "../../../assets/searchIcon.png";
import { LocationCropsManager } from "../../MyServices/SubComponents/AddService/SubComponents/LocationCropsManager";
import { addLocationCropCost, deleteLocationCrop, updateLocationCropCost } from "../../../apis/crops";
import useWindowSize from "../../../hooks/useWindowSize";
import Locations from "../../../AdminComponents/Locations/Locations";
import SearchLocation from "../../../ReusableComponents/SearchLocation";
import debounce from 'lodash/debounce';
let mapInState = undefined;
let mapMarker = undefined;
let markerRadius = undefined
const SelectLocation = ({
    onLocationChange
}) => {
    const [map, setMap] = useState()
    const [center, setCenter] = useState({ lat: 19.993982, lng: 73.790416 });
    const [locationName, setLocationName] = useState({
        name: "Nashik",
        address:
            "Maulana Abdul Kalam Azad Rd, Renuka Nagar, Nashik, Maharashtra"
    });
    const changeMarkerPosition = (mark) => {
        mapMarker.setLatLng(mark);
    };


    function getLocationString(locationString) {
        const cleanedString = locationString.replace(/^\S+\s/, '').trim();

        return cleanedString;
    }
    const onchangePlace = (e) => {
        setLocationName((prev) => ({ ...prev, name: "", address: e.target.value }))
        e.stopPropagation();
    };

    const initMap = (map) => {
        setMap(map)
        var markerOptions = {
            title: "MyLocation",
            icon: new L.Icon({
                iconUrl: MarkerIcon,
                iconSize: [20, 28],
                iconAnchor: [10, 28],
            }),
        };
        let marker1 = new L.Marker(center, markerOptions)
            .addTo(map)
        var bounds = L.latLngBounds([[center.lat, center.lng]]);

        // Fit the map to the marker bounds
        map.fitBounds(bounds, { maxZoom: 16 });
        mapMarker = marker1;
    };

    return (
        <>
            <form onSubmit={(e)=>e.preventDefault()} style={{ width: "100%", display: 'flex', height: 'calc(100% - 65px)', position: 'relative' }} className="create-request-location">
                <div style={{ width: "100%", position: 'relative' }}>
                    <SearchLocation
                        value={locationName.address}
                        defaultValue={locationName.address}
                        onChangePlace={onchangePlace}
                        onPlaceSelected={(place) => {
                            try {
                                let lat = place.geometry.location.lat();
                                let lng = place.geometry.location.lng();
                                let cntr = {};
                                cntr.lat = parseFloat(lat);
                                cntr.lng = parseFloat(lng);
                                setCenter(cntr);
                                setLocationName({ name: place.address_components.find(loc => loc.types[0] === "locality")?.long_name || place.formatted_address.split(",")[0], address: place.formatted_address });
                                changeMarkerPosition(cntr);
                                mapInState.setView(cntr);
                                onLocationChange({ lat: cntr.lat, lng: cntr.lng, name: place.address_components.find(loc => loc.types[0] === "locality")?.long_name || place.formatted_address.split(",")[0], address: place.formatted_address });
                            } catch { }
                        }}
                    />

                    <Map
                        initCenter={[center?.lat, center?.lng]}
                        initZoom={13}
                        handleMapClick={() => { }}
                        maxZoom={23}
                        style={{
                            height: "98.5%",
                            borderRadius: "0 0 5px 5px",
                        }}
                        mapInitialized={(map) => {
                            mapInState = map;
                            initMap(map);
                        }}
                    />
                </div>
            </form>
        </>
    );
};

export default SelectLocation;
