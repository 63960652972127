import React, { useState } from 'react';
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import DashboardIcon from '../SidebarIcons/DashboardIcon';
import MenuActive from '../SidebarIcons/MenuActive';
import { PDRL_ADMIN_ROUTES, PDRL_FINANCE_ROUTES, PDRL_MARKETING_ROUTES, PDRL_SALES_ROUTES, PDRL_SUCCESS_ENGI_ROUTES,PDRL_Telecaller_ROUTES,ROUTES } from '../routes';
import ServiceIcon from '../SidebarIcons/ServiceIcon';
import LocationIcon from '../SidebarIcons/LocationIcon';
import TeamsIcon from '../SidebarIcons/TeamsIcon';
import RequestIcon from '../SidebarIcons/RequestIcon';
import PilotIcon from '../SidebarIcons/PilotIcon';
import RevenueIcon from '../SidebarIcons/RevenueIcon';
import DroneIcon from '../SidebarIcons/DroneIcon';
import CustomersIcon from '../SidebarIcons/CustomersIcon';
import { getDSPId } from '../ReusableFunctions/reusableFunctions';
import { useSelector } from 'react-redux';
import closeIcon from "../assets/sidebaricons/closeIcon.svg";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import CouponsLogo from '../SidebarIcons/CouponsLogo';
import WalletLogo from '../SidebarIcons/WalletIcon';
import { BHUMEET_TEAM_ROLES } from '../appConstants';

const ProSidebar = () => {
    const location = useLocation();
    const userReduxUpdate = useSelector(state => state.user)
    const [collapse, setCollapse] = useState(true)

    const checkTypeOfAdminDashboard = () => {
        if (userReduxUpdate?.user?.bhumeet_role == BHUMEET_TEAM_ROLES.ADMIN.NAME) return PDRL_ADMIN_ROUTES.ADMIN_DASHBOARD
        if (userReduxUpdate?.user?.bhumeet_role == BHUMEET_TEAM_ROLES.FINANCE.NAME) return PDRL_FINANCE_ROUTES.FINANCE_DASHBOARD
        if (userReduxUpdate?.user?.bhumeet_role == BHUMEET_TEAM_ROLES.CUSTOMER_SUPPORT_ENGINEER.NAME) return PDRL_SUCCESS_ENGI_ROUTES.SUCCESS_ENGI_DASHBOARD
        if (userReduxUpdate?.user?.bhumeet_role == BHUMEET_TEAM_ROLES.MARKETING.NAME) return PDRL_MARKETING_ROUTES.MARKETINGDASHBOARD
        if (userReduxUpdate?.user?.bhumeet_role == BHUMEET_TEAM_ROLES.SALES.NAME) return PDRL_SALES_ROUTES.SALES_DASHBOARD
        if (userReduxUpdate?.user?.bhumeet_role == BHUMEET_TEAM_ROLES.TELECALLER.NAME) return PDRL_Telecaller_ROUTES.TelecallerDashboard

    }
    const adminDashboardRoute = checkTypeOfAdminDashboard()
    return (
        <>
            {location.pathname !== "/login" && (userReduxUpdate?.user?.dsp?.is_verified || userReduxUpdate?.user?.is_pdrl_admin) ?
                <>
                    {collapse ?
                        <></> :
                        <div style={{ height: '100vh', display: 'flex', width: "83px" }}></div>
                    }
                    <div style={{ height: '100vh', display: 'flex', position: collapse ? "" : "fixed", width: collapse ? "71px" : "100%", zIndex: "99" }}>
                        <Sidebar
                            collapsed={collapse}
                            backgroundColor='#020A1A'
                            rootStyles={{
                                height: "100%",
                                boxShadow: "0px 0px 8px 2px rgba(75, 75, 75, 0.15)"
                            }}
                            className='pro_sidebar_wrapper'
                            transitionDuration={500}
                            collapsedWidth='72px'
                        >
                            {userReduxUpdate?.user?.is_pdrl_admin ?
                                <Menu>
                                    <MenuItem icon={<MenuActive />} onClick={() => {
                                        setCollapse(!collapse)
                                    }}></MenuItem>
                                    <MenuItem
                                        className="sideBarOptions"
                                        style={{
                                            background: location.pathname === adminDashboardRoute ? "rgba(255, 255, 255, 0.2)" : "",
                                            borderLeft: location.pathname === adminDashboardRoute ? '3px solid #0F5EFF' : '3px solid transparent',
                                            color: location.pathname === adminDashboardRoute ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                            fontWeight: location.pathname === adminDashboardRoute ? '700' : '500',
                                            width: "100%"
                                        }}
                                        onClick={() => { setCollapse(true) }}
                                        icon={<DashboardIcon location={location.pathname} />}
                                        component={<Link
                                            to={adminDashboardRoute} />
                                        }>
                                        Admin Dashboard
                                    </MenuItem>
                                    {(getDSPId() !== "admin" && !userReduxUpdate?.user?.is_pdrl_admin) &&
                                        <MenuItem
                                            className="sideBarOptions"
                                            style={{
                                                background: location.pathname === "/" ? "rgba(255, 255, 255, 0.2)" : "",
                                                borderLeft: location.pathname === "/" ? '3px solid #0F5EFF' : '3px solid transparent',
                                                color: location.pathname === "/" ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                                fontWeight: location.pathname === "/" ? '700' : '500',
                                                width: "100%"
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<DashboardIcon location={location.pathname} />}
                                            component={<Link
                                                to={"/"} />
                                            }>
                                            User Dashboard
                                        </MenuItem>
                                    }
                                </Menu>
                                :
                                <Menu className={`${collapse ? "collapse_menu_wrapper" : "extended_menu_wrapper"}`}>
                                    <div style={{ height: "59.3px", display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                                        <div onClick={() => setCollapse(!collapse)} className={`${!collapse ? "hidden" : "flex"} px-2 cursor-pointer ml-1`}>
                                            <MenuActive />
                                        </div>
                                        <div onClick={() => setCollapse(!collapse)} className={`${collapse ? "hidden" : "flex"} px-2 cursor-pointer ml-auto transition ease-in`}>
                                            <img src={closeIcon} alt="menu" style={{ height: "15px" }} />
                                        </div>
                                    </div>
                                    <div className='mb-4 w-[110%]'>
                                        <hr className='border border-[#0F192D] mb-[5px]' />
                                    </div>
                                    <Tippy content='Dashboard' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                        <MenuItem
                                            className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                            style={{
                                                background: location.pathname === "/" ? "rgba(255, 255, 255, 0.2)" : "transparent",
                                                color: location.pathname === "/" ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)",
                                                fontWeight: location.pathname === "/" ? '700' : '500',
                                                display: collapse && "flex",
                                                alignItems: collapse && "center",
                                                justifyContent: collapse && "center",
                                                borderRadius: "4px",
                                                width: "100%"
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<DashboardIcon location={location.pathname} />}
                                            component={<Link
                                                to={"/"} />
                                            }>
                                            Dashboard
                                        </MenuItem>
                                    </Tippy>
                                    <Tippy content='Services' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                        <MenuItem
                                            className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                            style={{
                                                background: location.pathname === ROUTES.MYSERVICES ? "rgba(255, 255, 255, 0.2)" : "transparent",
                                                color: location.pathname === ROUTES.MYSERVICES ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                                fontWeight: location.pathname === ROUTES.MYSERVICES ? '700' : '500',
                                                display: collapse && "flex",
                                                alignItems: collapse && "center",
                                                justifyContent: collapse && "center",
                                                borderRadius: "4px",
                                                width: "100%"
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<ServiceIcon location={location.pathname} />}
                                            component={<Link
                                                to={ROUTES.MYSERVICES} />
                                            }>
                                            Services
                                        </MenuItem>
                                    </Tippy>
                                    <Tippy content='Locations' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                        <MenuItem
                                            className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                            style={{
                                                background: location.pathname === ROUTES.MYLOCATIONS ? "rgba(255, 255, 255, 0.2)" : "transparent",
                                                color: location.pathname === ROUTES.MYLOCATIONS ? 'rgba(255, 255, 255, 1)' : '#FFFFFF80',
                                                fontWeight: location.pathname === ROUTES.MYLOCATIONS ? '700' : '500',
                                                display: collapse && "flex",
                                                alignItems: collapse && "center",
                                                justifyContent: collapse && "center",
                                                borderRadius: "4px",
                                                width: "100%",
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<LocationIcon location={location.pathname} />}
                                            component={<Link
                                                to={ROUTES.MYLOCATIONS} />
                                            }>
                                            Locations
                                        </MenuItem>
                                    </Tippy>
                                    <Tippy content='Drones' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                        <MenuItem
                                            className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                            style={{
                                                background: location.pathname === ROUTES.MYDRONE ? "rgba(255, 255, 255, 0.2)" : "transparent",
                                                display: collapse && "flex",
                                                alignItems: collapse && "center",
                                                justifyContent: collapse && "center",
                                                color: location.pathname === ROUTES.MYDRONE ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                                fontWeight: location.pathname === ROUTES.MYDRONE ? '700' : '500',
                                                width: "100%",
                                                borderRadius: "4px",
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<DroneIcon style={{ width: "62px", height: "max-content" }} location={location.pathname} />}
                                            component={<Link
                                                to={ROUTES.MYDRONE} />
                                            }>
                                            Drones
                                        </MenuItem>
                                    </Tippy>
                                    <Tippy content='Pilots' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                        <MenuItem
                                            className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                            style={{
                                                background: location.pathname === ROUTES.DRONE_PILOTS ? "rgba(255, 255, 255, 0.2)" : "transparent",
                                                display: collapse && "flex",
                                                alignItems: collapse && "center",
                                                justifyContent: collapse && "center",
                                                color: location.pathname === ROUTES.DRONE_PILOTS ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                                fontWeight: location.pathname === ROUTES.DRONE_PILOTS ? '700' : '500',
                                                width: "100%",
                                                borderRadius: "4px",
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<PilotIcon location={location.pathname} />}
                                            component={<Link
                                                to={ROUTES.DRONE_PILOTS} />
                                            }>
                                            Pilots
                                        </MenuItem>
                                    </Tippy>
                                    <Tippy content='Requests' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                        <MenuItem
                                            className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                            style={{
                                                background: (location.pathname === ROUTES.MYREQUESTS || location.pathname === ROUTES.REQUESTDETAILS || location.pathname === ROUTES.VIEWREQUEST) ? "rgba(255, 255, 255, 0.2)" : "transparent",
                                                display: collapse && "flex",
                                                alignItems: collapse && "center",
                                                justifyContent: collapse && "center",
                                                color: (location.pathname === ROUTES.MYREQUESTS || location.pathname === ROUTES.REQUESTDETAILS || location.pathname === ROUTES.VIEWREQUEST) ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                                fontWeight: (location.pathname === ROUTES.MYREQUESTS || location.pathname === ROUTES.REQUESTDETAILS || location.pathname === ROUTES.VIEWREQUEST) ? '700' : '500',
                                                borderRadius: "4px",
                                                width: "100%"
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<RequestIcon location={location.pathname} />}
                                            component={<Link
                                                to={ROUTES.MYREQUESTS} />
                                            }>
                                            Requests
                                        </MenuItem>
                                    </Tippy>
                                    <Tippy content='Customers' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                        <MenuItem
                                            className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                            style={{
                                                background: location.pathname === ROUTES.CUSTOMERS ? "rgba(255, 255, 255, 0.2)" : "transparent",
                                                display: collapse && "flex",
                                                alignItems: collapse && "center",
                                                justifyContent: collapse && "center",
                                                color: location.pathname === ROUTES.CUSTOMERS ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                                fontWeight: location.pathname === ROUTES.CUSTOMERS ? '700' : '500',
                                                width: "100%",
                                                borderRadius: "4px",
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<CustomersIcon location={location.pathname} />}
                                            component={<Link
                                                to={ROUTES.CUSTOMERS} />
                                            }>
                                            Customers
                                        </MenuItem>
                                    </Tippy>

                                    {userReduxUpdate?.user.is_admin &&
                                        <Tippy content='My Team' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                            <MenuItem
                                                className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                                style={{
                                                    background: location.pathname === ROUTES.MANAGE_TEAM ? "rgba(255, 255, 255, 0.2)" : "transparent",

                                                    color: location.pathname === ROUTES.MANAGE_TEAM ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                                    fontWeight: location.pathname === ROUTES.MANAGE_TEAM ? '700' : '500',
                                                    width: "100%",
                                                    display: collapse && "flex",
                                                    alignItems: collapse && "center",
                                                    justifyContent: collapse && "center",
                                                    borderRadius: "4px",
                                                }}
                                                onClick={() => { setCollapse(true) }}
                                                icon={<TeamsIcon location={location.pathname} />}
                                                component={<Link
                                                    to={ROUTES.MANAGE_TEAM} />
                                                }>
                                                My Team
                                            </MenuItem>
                                        </Tippy>
                                    }
                                    <Tippy content='Revenue' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                        <MenuItem
                                            className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                            style={{
                                                background: location.pathname === ROUTES.MYREVENUE ? "rgba(255, 255, 255, 0.2)" : "transparent",
                                                display: collapse && "flex",
                                                alignItems: collapse && "center",
                                                justifyContent: collapse && "center",
                                                color: location.pathname === ROUTES.MYREVENUE ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                                fontWeight: location.pathname === ROUTES.MYREVENUE ? '700' : '500',
                                                width: "100%",
                                                borderRadius: "4px",
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<RevenueIcon location={location.pathname} />}
                                            component={<Link
                                                to={ROUTES.MYREVENUE} />
                                            }>
                                            Revenue
                                        </MenuItem>
                                    </Tippy>
                                    <Tippy content='Coupons' className='sidebar_tooltip_custom' disabled={!collapse} placement='right' offset={[0, 20]}>
                                        <MenuItem
                                            className={`${!collapse ? "expand-sidebar" : "collapse-sidebar"} sideBarOptions`}
                                            style={{
                                                background: location.pathname === ROUTES.COUPON ? "rgba(255, 255, 255, 0.2)" : "transparent",
                                                display: collapse && "flex",
                                                alignItems: collapse && "center",
                                                justifyContent: collapse && "center",
                                                color: location.pathname === ROUTES.COUPON ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                                                fontWeight: location.pathname === ROUTES.COUPON ? '700' : '500',
                                                width: "100%",
                                                borderRadius: "4px",
                                            }}
                                            onClick={() => { setCollapse(true) }}
                                            icon={<CouponsLogo location={location.pathname} />}
                                            component={<Link
                                                to={ROUTES.COUPON} />
                                            }>
                                            Coupons
                                        </MenuItem>
                                    </Tippy>
                                </Menu>}
                        </Sidebar>
                        {!collapse &&
                            <div style={{ width: "100%", height: '100vh', background: "rgba(0, 0, 0, 0.1)", cursor: "pointer" }} onClick={() => {
                                setCollapse(!collapse)
                            }}></div>
                        }
                    </div>
                </>
                : <></>}
        </>
    )
}

export default ProSidebar