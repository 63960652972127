import React, { useState } from "react";
import "./DurationsFilters.css";
export default function DurationFilters({
  interval,
  handleFilterChanged,
  options,
}) {
  const handleDurationFiltersChanged = (filterType, value) => {
    const now = new Date();
    let fromDate = new Date();
    let toDate = new Date();

    switch (value) {
      case "day":
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(23, 59, 59, 999);
        break;
      case "week":
        fromDate = new Date(now.setDate(now.getDate() - now.getDay()));
        fromDate.setHours(0, 0, 0, 0);
        toDate = new Date(now.setDate(now.getDate() - now.getDay() + 6));
        toDate.setHours(23, 59, 59, 999);
        break;
      case "month":
        fromDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 1, 0, 0);
        toDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        toDate.setHours(23, 59, 59, 999);
        break;
      case "year":
        fromDate = new Date(now.getFullYear(), 0, 1, 0, 1, 0, 0);
        toDate = new Date(now.getFullYear(), 11, 31);
        toDate.setHours(23, 59, 59, 999);
        break;
      case "custom":
        break;

      case "all":
        fromDate = null;
        toDate = null;
        break;
    }

    if(value !== "custom")handleFilterChanged({
      fromDate,
      toDate,
      interval: value,
    });
  };

  return (
    <div className="duration_selectors" style={{ fontSize: "14px" }}>
      {options.map((options, i) => (
        <button
          style={{
            borderLeft:
              i === 0 && interval != options.interval && "1px solid #C9C9C9",
            borderRight: interval != options.interval && "1px solid #C9C9C9",
          }}
          className={interval === options.interval ? "active" : ""}
          onClick={() => {
            handleDurationFiltersChanged("interval", options.interval);
            if (options.cb) {
              options.cb();
            }
          }}
        >
          {options.placeholder}
        </button>
      ))}
    </div>
  );
}
