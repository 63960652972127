import { useEffect, useState } from "react";
import Modal from "../../ReusableComponents/Modal";
import DateTimePicker from "../../ReusableComponents/DateTimePicker";
import { Button } from "../../Components/Helper";
import { GLOBAL_STYLES } from "../../appConstants";
import SelectComponent from "../../ReusableComponents/SelectComponent";
import Tippy from "@tippyjs/react";
import { getTelecallerTeams } from "../../apis/adminAPIs/teams";
import { useSelector } from "react-redux";

const TeleDashboardFilter = ({ filter, onFilterChange, open, onClose }) => {

  const userDetails = useSelector(state => state.user?.user)

    const [Filter, setFilter] = useState(filter);
    const [Telecaller, setTelecaller] = useState([]);

    const handleFilterChange = (updatedFilter) => {
        setFilter(updatedFilter);
    };

    useEffect(()=>{
        console.log(filter)
        setFilter((prev)=>({...prev,Substatus:filter.Substatus}))
        getAllTeleCaller()
    },[])
    

    const getAllTeleCaller =async ()=>{
        
         getTelecallerTeams().then(res=>{
            
            // console.log(res)
            let filteredData = res.map(x=>{
                return {label:x.name,value:x.id}
            })
            filteredData = [{label:"All",value:""}].concat(filteredData)
            // console.log(filteredData)
            setTelecaller(filteredData)
         })
    }

    let status = [{ label: "Completed", value: "Completed" }, { label: "Disconnected", value: "Disconnected" }
        , { label: "Incomplete", value: "Incomplete" }, { label: "No Response", value: "No Response" }, { label: "Couldn't Connect", value: "Couldn't connect" }
    ]

    const handleToggle = (newValue) => {
        setFilter((prevFilter) => {
            // Check if the value already exists in the Substatus array
            const exists = prevFilter.Substatus.includes(newValue);
    
            return {
                ...prevFilter,
                Substatus: exists
                    ? prevFilter.Substatus.filter((item) => item !== newValue) // Remove the value if it exists
                    : [...prevFilter.Substatus, newValue], // Add the value if it doesn't exist
            };
        });
    };
    

    return (
        <Modal
            header={"Filter"}
            contentStyle={{ width: "730px", height: Filter.Status == 'close' ? "570px" : userDetails.bhumeet_role !='Admin' ? "302px" : "364px" }}
            headingStyle={{ fontSize: "18px", fontWeight: 600 }}
            headerContainerStyle={{ padding: "20px" }}
            open={open}
            onClose={onClose}
        >
            <div style={{ padding: "20px 30px", display: "flex", flexDirection: "column", width: "100%", gap: "20px" }}>
                {/* Date Filter Section */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: "10%" }}>
                    <DateTimePicker
                        title="From"
                        hideNxt={new Date()}
                        selectedDateTime={Filter.fromDate}
                        minTime={new Date().setHours(0, 0, 0, 0)}
                        maxTime={new Date().setHours(23, 59)}
                        onChange={(date) => {
                            handleFilterChange({
                                ...Filter,
                                fromDate: new Date(date).toISOString()
                            });
                        }}
                    />
                    <DateTimePicker
                        title="To"
                        hidePrev={Filter.fromDate}
                        selectedDateTime={Filter.toDate}
                        minTime={new Date(Filter?.fromDate).toDateString() === new Date(Filter?.toDate).toDateString()
                            ? new Date(new Date(Filter.fromDate).setMinutes(new Date(Filter.fromDate).getMinutes() + 1))
                            : new Date().setHours(0, 0, 0, 0)}
                        maxTime={new Date().setHours(23, 59)}
                        onChange={(date) => {
                            if (new Date(Filter.fromDate) < new Date(date)) {
                                console.log(date)
                                if(!Filter.toDate){
                                    let dates = new Date(date).setHours(23,59)
                                    handleFilterChange({
                                        ...Filter,
                                        toDate: new Date(dates).toISOString()
                                    });
                                }else{
                                    handleFilterChange({
                                        ...Filter,
                                        toDate: new Date(date).toISOString()
                                    });
                                }
                                
                            } else {
                                
                                handleFilterChange({
                                    ...Filter,
                                    toDate: Filter.toDate ? new Date(Filter.toDate) : new Date()
                                })
                            }
                        }}
                        isDisable={!(Filter.fromDate)}
                    />
                </div>

                {/* Active/Inactive/All Filter Section */}
                <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", gap: "20px" }}>
                    {userDetails.bhumeet_role == 'Admin' && 
                    <div >
                    <h4 style={{ marginBottom: '10px', fontWeight: 600 }}>Status Filter</h4>

                    <button
                        style={{
                            padding: "10px 20px",
                            borderRadius: "5px",
                            border: "1px solid",
                            borderColor: Filter.Status === '' ? "#007bff" : "#ccc",
                            backgroundColor: Filter.Status === '' ? "#007bff" : "#fff",
                            color: Filter.Status === '' ? "#fff" : "#000",
                            cursor: "pointer",
                            transition: "all 0.3s ease",
                            fontWeight: Filter.Status === '' ? "600" : "400"
                        }}
                        onClick={() => handleFilterChange({ ...Filter, Status: '' })}
                    >
                        All
                    </button>
                    <button
                        style={{
                            padding: "10px 20px",
                            borderRadius: "5px",
                            border: "1px solid",
                            borderColor: Filter.Status === 'open' ? "#28a745" : "#ccc",
                            backgroundColor: Filter.Status === 'open' ? "#28a745" : "#fff",
                            color: Filter.Status === 'open' ? "#fff" : "#000",
                            cursor: "pointer",
                            transition: "all 0.3s ease",
                            fontWeight: Filter.Status === 'open' ? "600" : "400"
                        }}
                        onClick={() => handleFilterChange({ ...Filter, Status: 'open' })}
                    >
                        Open
                    </button>
                    <button
                        style={{
                            padding: "10px 20px",
                            borderRadius: "5px",
                            border: "1px solid",
                            borderColor: Filter.Status === 'close' ? "#dc3545" : "#ccc",
                            backgroundColor: Filter.Status === 'close' ? "#dc3545" : "#fff",
                            color: Filter.Status === 'close' ? "#fff" : "#000",
                            cursor: "pointer",
                            transition: "all 0.3s ease",
                            fontWeight: Filter.Status === 'close' ? "600" : "400"
                        }}
                        onClick={() => handleFilterChange({ ...Filter, Status: 'close' })}
                    >
                        Close
                    </button>
                </div>
                    }
                    
                    {userDetails.bhumeet_role == 'Admin' && 
                    <div style={{ marginBlockStart: "auto" }}>
                    <SelectComponent
                        controlStyle={{ height: "47px", width: "300px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                        isSearchable={true}
                        placeholder={"Select caller..."}
                        menuPlacement={"bottom"}
                        list={Telecaller}
                        value={Telecaller.filter(x=>{return x.value == Filter.selectedCaller})[0]}
                        onChange={(caller) => {

                            // getEnquiryFunc(drone_model_id.value == null ? 'All' : drone_model_id.value)
                            setFilter({ ...Filter, selectedCaller: caller.value, pageNumber: 1 });

                        }}

                    />

                </div>}
                    
                </div>

                {Filter.Status === 'close' && 
                <div style={{ fontSize: '14px', display: "flex", flexDirection: "column", rowGap: "8px", overflowY: "auto", height: "100%", color: "#3B3B3B", fontWeight: 500, maxHeight: "240px" }}>


                <div style={{ fontSize: "16px", fontWeight: 500 }}>status :</div>

                {status?.map(stats =>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <div style={{ width: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <input
                                // disabled={isHideDSPFilter}
                                checked={Filter.Substatus.includes(stats.value)}
                                onChange={(e) => {

                                    const newValue = stats.value // Get the string value from the input
                                    handleToggle(newValue)

                                }}
                                
                                
                                value={stats.label}
                                type={"checkbox"}
                                className="input-checkbox"
                                style={{ width: "16px" }}
                            />
                        </div>
                        <Tippy content={stats.label}>
                            <span style={{ lineHeight: 1.1, fontSize: "15px", fontWeight: 500 }}>{stats.label}</span>
                        </Tippy>
                    </div>)}

            </div >
            }
                



            </div>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 30px", borderTop: '0.5px solid #BFC8D9' }}>
                <div
                    onClick={async () => {
                        setFilter({
                            fromDate: null,
                            toDate: null,
                            Status: 'open',
                            Substatus:[],
                            currentPage:1
                        })
                    }} style={{ width: "100px", color: GLOBAL_STYLES.BG_ACTIVE, fontWeight: 600, fontSize: "14px", cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "start" }}>
                    Clear all
                </div>
                <Button
                    text={"Apply"}
                    style={{ width: "130px", height: "38px", fontSize: "15px", marginRight: "10px" }}
                    onClick={() => {
                        onFilterChange(Filter)
                    }}
                />
            </div>
        </Modal>
    );
};

export default TeleDashboardFilter;