import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getDspLocations = async ({dspId,pageNumber,pageSize}) => {
    try {
            const token = getToken();
            const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/customerSuccessEngineer/locations`, {
                pageSize: pageSize && pageNumber ? pageSize : undefined,
                pageNumber: pageSize && pageNumber ? pageNumber : undefined,
                dspId: dspId ? dspId : undefined,
            });
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token} `,
                    },
                }
            );
    
            const res = await response.json();
            if (!response.ok) {
                throw (res.message || "Something went wrong while getting coupons");
            }
            return res?.locations;
    } catch (err) {
        throw (err)
    }
}