import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router'
import Loading from './ReusableFunctions/Loader';
import { login } from './apis/authentication';

export default function Authentication() {
    const navigate=useNavigate();
    useEffect(()=>{
      initData();
    },[navigate])

    const initData=async()=>{
     try{
     let user=await login();
     if(!user){
        navigate('/login');
     }
     }catch(err){
      console.log("err",err);
      Loading.set(false);
     }
    }
  return (
    <div></div>
  )
}
