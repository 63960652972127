import React, { useEffect, useState } from 'react'
import WalletCard from './subcomponent/WalletCard'
import AddBankAccCard from './subcomponent/AddBankAccCard'
import calender from "../../assets/blueCalendar.svg";
import DatePicker from 'react-datepicker';
import { getDspWalletBalance, getWalletTransactions, getWalletTransactionsCount } from '../../apis/wallet';
import TableViewPagination from '../../ReusableComponents/TableView/TableViewPagination';
import AddBankAccountPopup from './subcomponent/AddBankAccountPopup';
import { getBankDetails } from '../../apis/bankDetails';
import Popup from '../../ReusableFunctions/Popup';
import moment from 'moment';
import LoaderComponent from '../../ReusableComponents/LoaderComponent';
import Loading from '../../ReusableFunctions/Loader';
import { addLeadingZero } from '../../ReusableFunctions/reusableFunctions';
import NotFounds from '../../ReusableComponents/NotFounds';
import emptyTransactions from '../../assets/emptyTransactions.svg'
import ClearFilterButton from '../../ReusableComponents/TableView/ClearFilterButton';
export default function WalletInfo() {
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([])
    const [openBankDetailsPopup, setOpenBankDetailsPopup] = useState(false);
    const [walletInfo, setwalletInfo] = useState(undefined);
    const [tableLoading, settableLoading] = useState(false);
    const [bankId, setBankId] = useState(null)
    const [totalCount, setTotalCount] = useState(null);
    const [bankDetails, setbankDetails] = useState({
        id: "",
        account_holder_name: "",
        bank_name: "",
        ifsc_code: "",
        account_no: "",
        status: true,
        confirm_account_no: "",
    })
    const [filters, setFilters] = useState({
        from_date: undefined,
        to_date: undefined,
        pageNumber: 1,
        searchString: undefined,
        pageSize: 15
    })
    const [openDatePicker, setOpenDatePicker] = useState({
        fromDate: false,
        toDate: false,
    })

    const getTransactions = async (filters) => {
        setLoading(true);
        settableLoading(true);
        try {
            const transactions = await getWalletTransactions(filters);
            setTransactions(transactions);
            setLoading(false)
            settableLoading(false);
        } catch (err) {
            setLoading(false)
            settableLoading(false);
            console.log("err", err);
        }
    }

    const fetchInitialData = async () => {
        settableLoading(true);
        Loading.set(true);
        try {
            const transactions = await getWalletTransactions(filters);
            const walletInfo = await getDspWalletBalance();
            const bank = await getBankDetails();
            const transaction_count = await getWalletTransactionsCount();
            setTotalCount(transaction_count)
            setbankDetails(() => (bank?.bankDetails));
            setBankId(bank?.bankDetails?.id)
            setTransactions(transactions);
            setwalletInfo(walletInfo)
            settableLoading(false);
            Loading.set(false);
        } catch (err) {
            settableLoading(false)
            Loading.set(false);
            Popup.alert("ERROR", err || "Something went wrong !", "ERROR");
        }
    }

    useEffect(() => {
        fetchInitialData();
    }, [])

    useEffect(() => {
        getTransactions(filters)
    }, [filters?.from_date, filters?.to_date, filters?.pageNumber])

    const clearWalletFilters=()=>{
        setFilters((prevFilter)=>({
            ...prevFilter,
            from_date:undefined,
            to_date:undefined,
        }))
    }

    return (
        <>
            {
                openBankDetailsPopup &&
                <AddBankAccountPopup
                    onClose={() => {
                        setBankId(null)
                        setOpenBankDetailsPopup(false)
                    }}
                    onBankDetailsAdd={(bankDetails) => {
                        setbankDetails(bankDetails)
                        setOpenBankDetailsPopup(false)
                    }}
                    bankdetails={bankDetails}
                />
            }
            <div className='wallet_details' >
                <div className='flex gap-5 w-[100%]'>
                    <WalletCard
                        walletInfo={walletInfo}
                        loading={loading}
                    />
                    <AddBankAccCard
                        loading={loading}
                        bankdetails={bankDetails}
                        setOpenbankdetailsPopup={setOpenBankDetailsPopup}
                    />
                </div>
                <div className='transaction_history_wrapper'>
                    <div className='transaction_history_wrapper_header'>
                        <h4>Transaction History</h4>
                    </div>
                    <div className='filters_section_wrapper'>
                        <div>
                        <ClearFilterButton isVisible={filters.from_date || filters.to_date} onClick={clearWalletFilters} />
                        </div>
                    <div className='date_Filters'>
                        <div
                            className="input-date "
                            style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "34px", width: "150px", position: "relative", background: "transparent" }}
                            onClick={() => setOpenDatePicker((prev) => ({ ...prev, fromDate: true }))}
                        >
                            <DatePicker
                                onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, fromDate: false }))}
                                open={openDatePicker.fromDate}
                                placeholderText='From Date'
                                maxDate={filters.to_date ? new Date(Math.min(new Date(), new Date(filters.to_date))) : new Date()}
                                readOnly
                                className="input-date-hidden"
                                selected={filters.from_date ? new Date(filters.from_date) : null}
                                onChange={date => {
                                    if (date) {
                                        let fromDate = new Date(date).setHours(0, 0, 0, 0)
                                        fromDate = new Date(fromDate).toISOString();
                                        setFilters((prev) => ({ ...prev, from_date: fromDate,pageNumber:1}))
                                    }
                                }} />
                            <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                        </div>
                        <div className='text-xl'>-</div>
                        <div
                            className="input-date  "
                            style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "34px", width: "150px", position: "relative", background: "transparent" }}
                            onClick={() => {
                                if (filters?.from_date) {
                                    setOpenDatePicker((prev) => ({ ...prev, toDate: true }))
                                }
                            }}
                        >
                            <DatePicker
                                placeholderText='To Date'
                                onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, toDate: false }))}
                                open={openDatePicker.toDate}
                                disabled={!filters.from_date}
                                minDate={filters?.from_date && new Date(filters?.from_date)}
                                readOnly
                                className="input-date-hidden "
                                selected={filters.to_date ? new Date(filters.to_date) : null}
                                onChange={date => {
                                    if (date) {
                                        let todate = new Date(date).setHours(23, 59, 59, 999)
                                        todate = new Date(todate).toISOString();
                                        setFilters(prev => ({ ...prev, to_date: todate,pageNumber:1 }));
                                    }
                                }} />
                            <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                        </div>
                    </div>
                    </div>
                </div>
                {!tableLoading && transactions.length > 0 ? <div style={{ width: "100%" }}>
                    <table className='transactionTable'>
                        <thead className='w-full'>
                            <tr className='text-[#3B3B3B] text-[14px]'>
                                <th>Date</th>
                                <th>Time</th>
                                <th style={{ width: "45%" }}>Details</th>
                                <th >Amount</th>
                            </tr>
                        </thead>
                        <div className='transaction_table_body'>
                            <tbody >
                                {transactions.map((transaction, index) => (
                                    <tr key={index}>
                                        <td>{moment(transaction?.created_date).format("DD MMM YYYY")}</td>
                                        <td>{moment(transaction?.created_date).format("hh:mm A")}</td>
                                        <td style={{ width: "45%" }}>{transaction?.remark}</td>
                                        <td >{transaction.type === "Credit" ? "+" : "-"} ₹ {addLeadingZero(transaction.amount)}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </div>
                    </table>
                </div> : !tableLoading && transactions.length == 0 ?
                    <div className='wallet_loader_wrapper'>
                        <NotFounds imgUrl={emptyTransactions} hideBtn />
                    </div>
                    : <div className='wallet_loader_wrapper' >
                        <LoaderComponent width={"2%"} height={"2%"} />
                    </div>}

                {transactions.length > 0 &&
                    <div className='flex w-full justify-end mt-5'>
                        <TableViewPagination
                            isLoading={false}
                            currentPage={filters?.pageNumber || 1}
                            totalCount={transactions[0]?.transaction_count}
                            pageSize={filters.pageSize}
                            padding="10px 5px"
                            onPageChange={(page) => {
                                setFilters((prev) => ({ ...prev, pageNumber: page }))
                            }}
                        />
                    </div>
                }
            </div>
        </>
    )
}

