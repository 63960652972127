import { useEffect, useState } from "react";
import MainContentWrapper from "../../../Components/MainContentWrapper";
import TableView from "../../../ReusableComponents/TableView/TableView";
import {getDrones } from "../../../apis/BhuMeetTeams/Oparations/droneStore";
import Popup from "../../../ReusableFunctions/Popup";
import { PDRL_ADMIN_ROUTES, PDRL_SUCCESS_ENGI_ROUTES } from "../../../routes";
import { useNavigate } from 'react-router'
import { useLocation } from "react-router-dom";
import Search from '../../../ReusableComponents/Search'
import DroneModels from './droneModel.js'
import DroneEnquiries from './droneEnquiry.js'


const ManufactureDetails = () => {
    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [path,setPath] = useState('')
    const [count,setcount] = useState(0)
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 15,
        fromDate: undefined,
        toDate: undefined,
    })
    const [activeTab, setActiveTab] = useState('details');
    const location = useLocation();
    const [store, setStore] = useState(location.state?.store)
    const tabs = ['Models',"Enquiries"];

    const [searchString, setSearchString] = useState("")


    const Location = useLocation()


    const getAlldroneModelFunc = async () => {
        try {
            setLoading(true)
            const { fromDate, toDate, pageSize, pageNumber } = filters
            let stores = await getDrones(pageSize,pageNumber,Location.state.store.id)
            // console.log(stores)
            setcount(stores[0].count)
            setStores(stores);
            // setStores({...stores,toShow:Location.pathname})
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        // console.log(Location)
        setActiveTab('Models')
        // getAlldroneModelFunc();
        
    }, [filters])

    useEffect(()=>{
        // console.log(Location)
        // getAlldroneModelFunc()
    },[searchString])



    return (
        <div style={{ backgroundColor: "rgb(236, 239, 243)", overflow: "hidden" }} className='flex flex-col gap-5 h-[100%]  px-[20px] py-[50px]'>
            <div className="drone-tabs ">
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
            </div>
            <div className="flex-grow overflow-y-auto">
                {activeTab === "Models" ? (
                    <DroneModels hideBankDetails={true} store={store} callback={(store) => {
                        setStore(store);
                    }} />
                
                ) : activeTab === "Enquiries" ? (
                    <DroneEnquiries hideBankDetails={true} store={store} callback={(store) => {
                        setStore(store);
                    }} />
                ): null}
            </div>
        </div>
    )
}

export default ManufactureDetails