import { useEffect, useState } from 'react';
import MainContentWrapper from '../../../Components/MainContentWrapper';
import TableView from '../../../ReusableComponents/TableView/TableView';
import Popup from '../../../ReusableFunctions/Popup';
import { getDSPInvoicesStats, getDSPSettlementInvoices } from '../../../apis/adminAPIs/finance/dspInvoices';
import DashBoardCard from '../../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard';
import { PDRL_ADMIN_ROUTES } from '../../../routes';
import { useLocation, useNavigate } from 'react-router';
import SelectComponent from '../../../ReusableComponents/SelectComponent';
import { getAllDSPs } from '../../../apis/adminAPIs/dsp';
import { PAYMENT_STATUSES, SETTELEMENT_STATUSES } from '../../../appConstants';
import formatNumber from '../../../ReusableFunctions/convertLongNumToShort';

const InvoicesStatCard = ({ title, statNumber, isSelected, onClick, isDisabled }) => {
    return <DashBoardCard isDisabled={isDisabled} title={title} data={statNumber} onClick={onClick} backGround={isSelected ? "green" : undefined} />
}

const InvoiceStats = ({ invoicesStats, filters, changeStatusFilter }) => {
    return <div style={{ display: "flex", columnGap: "2%" }}>
        <InvoicesStatCard
            title={"Total Invoices"}
            statNumber={invoicesStats.total_invoices_count}
            isSelected={filters.statuses.length === 0}
            onClick={() => changeStatusFilter([])}
        />
        <InvoicesStatCard
            title={"Paid Invoice"}
            statNumber={invoicesStats.paid_invoices_count}
            isSelected={filters.statuses.length > 0 && filters.statuses[0] === SETTELEMENT_STATUSES.SETTELED}
            onClick={() => changeStatusFilter([SETTELEMENT_STATUSES.SETTELED])}
        />
        <InvoicesStatCard
            title={"Unpaid Invoices"}
            statNumber={invoicesStats.unpaid_invoices_count}
            isSelected={filters.statuses.length > 0 && filters.statuses[0] === SETTELEMENT_STATUSES.UNSETTLED}
            onClick={() => changeStatusFilter([SETTELEMENT_STATUSES.UNSETTLED])}
        />
        <InvoicesStatCard
            title={"Paid Amount"}
            statNumber={formatNumber(Number(invoicesStats.paid_invoices_amount))}
            isDisabled={true}
        />
        <InvoicesStatCard
            title={"Unpaid Amount"}
            statNumber={formatNumber(Number(invoicesStats.unpaid_invoices_amount))}
            isDisabled={true}
        />
    </div>
}

const DspInvoices = () => {
    const urlData = useLocation()?.state
    const isFromTds = urlData?.isFromTds
    const isFromIncentive = urlData?.isFromIncentive
    const isFromPlatformFee = urlData?.isFromPlatformFee
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [dspInvoices, setDspInvoices] = useState([])
    const [dsps, setDsps] = useState([{ dsp_id: null, dsp_name: "All" }])
    const [invoicesStats, setInvoicesStats] = useState({
        total_invoices_count_for_pagination: 0,
        total_invoices_count: 0,
        paid_invoices_count: 0,
        unpaid_invoices_count: 0,
        paid_invoices_amount: 0,
        unpaid_invoices_amount: 0
    })
    const [filters, setFilters] = useState(()=>{
     let savedFilters=localStorage.getItem("admin_dspInvoice");
            if(savedFilters){
                let parsedFilters = JSON.parse(savedFilters);
                return parsedFilters;
            }
        return {
                pageNumber: 1,
                pageSize: 20,
                orderBy: "generated_for_date",
                orderType: "DESC",
                statuses: (isFromIncentive || isFromPlatformFee || isFromTds) ? [] : [SETTELEMENT_STATUSES.UNSETTLED],
                selectedDSP: { value: null, label: "All" }
            }
        
    })
    const [tableColumns, setTableColumns] = useState([
        { Header: "Invoice No.", accessor: "invoice_number" },
        { Header: isFromTds ? "TDS Amount (₹)" : isFromIncentive ? "Incentive Paid (₹)" : isFromPlatformFee ? "Platform Fee (₹)" : "Amount (₹)", accessor: isFromTds ? "tds_charges" : isFromIncentive ? "incentive_from_bhumeet_amount" : isFromPlatformFee ? "platform_fees_amount" : "total_dsp_amount_to_be_paid" },
        { Header: "Generated for date", accessor: "generated_for_date", isSortable: true, isDate: true, format: "dd/mm/yyyy" },
        (isFromIncentive || isFromPlatformFee || isFromTds) ? { Header: "DSP Name", accessor: "dsp_name" } : { Header: "status", accessor: "status" }
    ])

    const getActiveDSPs = async () => {
        try {
            const data = await getAllDSPs(null, null, null, null, null, true)
            setDsps([...dsps, ...data.dsps])
        } catch (e) {
            console.error(e)
            Popup.alert("ERROR", "We cant load invoices right now, Please try again later.")
        }
    }

    const getDSPInvoices = async () => {
        try {
            setIsLoading(true)
            const invoices = await getDSPSettlementInvoices(filters.selectedDSP?.value, filters.fromDate, filters.toDate, filters.pageSize, filters.pageNumber, filters.orderBy, filters.orderType, filters.statuses, isFromTds, isFromIncentive, isFromPlatformFee)
            const invoicesStats = await getDSPInvoicesStats(filters.selectedDSP?.value, filters.fromDate, filters.toDate)
            setInvoicesStats({
                total_invoices_count_for_pagination: invoices[0]?.settlement_invoices_count ? Number(invoices[0].settlement_invoices_count) : 0,
                total_invoices_count: (invoicesStats.settlement_invoices_count && Number(invoicesStats.settlement_invoices_count)) ?? 0,
                paid_invoices_count: invoicesStats.settled_invoices_count || 0,
                unpaid_invoices_count: invoicesStats.unsettled_invoices_count || 0,
                paid_invoices_amount: invoicesStats.settled_amount || 0,
                unpaid_invoices_amount: invoicesStats.unsettled_amount || 0
            })
            setDspInvoices(invoices)
            setIsLoading(false)
        } catch (e) {
            console.error(e)
            Popup.alert("ERROR", "We cant load invoices right now, Please try again later.")
        }
    }

    useEffect(() => {
        getActiveDSPs()

        let getfilters = localStorage.getItem("dspInvoicesFilters")

        if(getfilters){
            setFilters(JSON.parse(getfilters))
        }

        if (isFromIncentive || isFromPlatformFee || isFromTds) {
            const newColumn = {
                Header: isFromTds
                    ? "TDS Percentage (%)"
                    : isFromIncentive
                        ? "Total Sprayed Area (Acre)"
                        : isFromPlatformFee
                            ? "Platform Fee Percentage (%)"
                            : "",
                accessor: isFromTds
                    ? "tds_charges_percentages"
                    : isFromIncentive
                        ? "total_sprayed_acres"
                        : isFromPlatformFee
                            ? "platform_fees_percentage"
                            : ""
            };

            const updatedColumns = [...tableColumns];

            const insertAtIndex = 2;
            updatedColumns.splice(insertAtIndex, 0, newColumn);

            setTableColumns(updatedColumns);
        }
    }, [])

    useEffect(() => {
        getDSPInvoices()
        localStorage.setItem("admin_dspInvoice",JSON.stringify(filters));
    }, [filters])

    return <>
        <MainContentWrapper header={`DSP Invoices (${filters.selectedDSP.label})`} rightContent={<div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>
            <SelectComponent
                controlStyle={{ height: "40px", width: "200px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                isSearchable={true}
                placeholder={"Select DSP..."}
                menuPlacement={"bottom"}
                list={dsps.map(dsp => {
                    return ({
                        value: dsp.dsp_id,
                        label: dsp.dsp_name
                    })
                })}
                value={filters.selectedDSP}
                onChange={(dsp) => {
                    setFilters({ ...filters, selectedDSP: dsp,pageNumber:1 })
                }}
            />
        </div>
        }>
            {(isFromIncentive || isFromPlatformFee || isFromTds) ?
                <></> :
                <InvoiceStats
                    invoicesStats={invoicesStats}
                    filters={filters}
                    changeStatusFilter={(statusFiler) => {  
                        setFilters({ ...filters,pageNumber:1, statuses: statusFiler })
                    }}
                />
            }
            <div style={{
                margin: "20px 0",
                height: "72%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"500px"}
                    isLoading={isLoading}
                    onStateChange={async (changedState) => {
                        const { pageNumber, orderBy, orderType } = changedState;
                        setFilters({ ...filters, pageNumber, orderBy, orderType });
                    }}
                    onClickRow={(invoice) => {
                        navigate(`${PDRL_ADMIN_ROUTES.PAYMENTINVOICE}`, {
                            state: {
                                invoice
                            }
                        })
                    }}
                    columns={tableColumns}
                    count={invoicesStats.total_invoices_count_for_pagination}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={dspInvoices.map((d) => {
                        return {
                            ...d,
                            status: d.status === SETTELEMENT_STATUSES.SETTELED ? "Paid" : "Unpaid"
                        }
                    })}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                />
            </div>
        </MainContentWrapper>
    </>
}
export default DspInvoices