import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { DSP_INVOICES_BASE_URL } from "../../adminAPIs/finance/dspInvoices";
import { getToken } from "../../authentication";

export const getFinanaceDashboardOverview = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(

                SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/finance/dsp/overviewData",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting dashboard data."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting dashboard data.");
        }
    });
};

export const getAllDebitNotes = async ({dspId,pageNumber,pageSize,fromDate,toDate,statuses,searchQuery,orderBy,orderType}) => {
    try {
            const token = getToken();
            const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/finance/dsp/invoices/debit/notes`, {
                fromDate:fromDate ?fromDate:undefined,
                toDate:toDate ?toDate:undefined,
                pageSize: pageSize && pageNumber ? pageSize : undefined,
                pageNumber: pageSize && pageNumber ? pageNumber : undefined,
                dspId: dspId ? dspId : undefined,
                orderBy: orderBy ? orderBy : undefined,
                orderType: orderType? orderType : undefined,
                statuses: statuses ? statuses.toString() : undefined,
                searchQuery: searchQuery?searchQuery : undefined,
            });
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token} `,
                    },
                }
            );
    
            const res = await response.json();
            if (!response.ok) {
                throw (res.message || "Something went wrong while getting coupons");
            }
            return res?.debitNotes;
    } catch (err) {
        throw (err)
    }
}


export const getDebitStats = async (dspId, fromDate, toDate,searchQuery) => {
    try {
        const token = getToken();
        const url = buildUrl(DSP_INVOICES_BASE_URL + '/debit/notes/stats', {
            fromDate: fromDate ? fromDate : undefined,
            toDate: toDate ? toDate : undefined,
            dspId: dspId ? dspId : undefined,
            searchQuery: searchQuery? searchQuery : undefined,
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.debitNotesStats;
    } catch (err) {
        throw err;
    }
}
