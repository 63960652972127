import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import LoaderComponent from '../../ReusableComponents/LoaderComponent'
import { Table, TableHeader } from '../../ReusableComponents/Table'
import Tippy from '@tippyjs/react'
import { Button, OptionMenu } from '../../Components/Helper'
import more from "../../assets/more.png"
import { addCrop, deleteCrop, getAllCrops, updateCrop } from '../../apis/adminAPIs/crops'
import Loading from '../../ReusableFunctions/Loader'
import Popup from '../../ReusableFunctions/Popup'
import Plus from "../../assets/Plus.svg";
import AddCrop from './AddCrop'

const Crops = () => {
    let srNo = 0;
    const [loading, setLoading] = useState(false);
    const [cropsList, setCropsList] = useState([])
    const [openCropPopup, setOpenCropPopup] = useState(false)
    const [selectedCrop, setSelectedCrop] = useState({})

    const getCropsFunc = async () => {
        try {
            const crops = await getAllCrops();
            setCropsList(crops.crops);
            setLoading(false);
            Loading.set(false);
        } catch (err) {
            setLoading(false);
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const addNewCrop = async (crop) => {
        try {
            setLoading(true);
            Loading.set(true);
            const newCrop = await addCrop(crop)
            setCropsList((prev) => ([
                newCrop.addedCrop,
                ...prev
            ]))
            setLoading(false);
            Loading.set(false);
            Popup.alert("Crop", newCrop.message, 'SUCCESS')
        } catch (err) {
            setLoading(false);
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const updateCropName = async (cropInfo) => {
        try {
            setLoading(true);
            Loading.set(true);
            const crop = await updateCrop(cropInfo)
            const filtedCrops = cropsList.filter((c) => c.id !== cropInfo?.id)
            setCropsList([
                crop.updatedCrop,
                ...filtedCrops,
            ])
            setSelectedCrop({})
            setLoading(false);
            Loading.set(false);
            Popup.alert("Crop", crop.message, 'SUCCESS')
        } catch (err) {
            setLoading(false);
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const deleteCropById = async (cropId) => {
        try {
            setLoading(true);
            Loading.set(true);
            const crop = await deleteCrop(cropId)
            const filtedCrops = cropsList.filter((c) => c.id !== crop.deletedCrop?.id)
            setCropsList(filtedCrops)
            setLoading(false);
            Loading.set(false);
            Popup.alert("Crop", crop.message, 'SUCCESS')
        } catch (err) {
            setLoading(false);
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        Loading.set(true);
        getCropsFunc();
    }, [])

    return (
        <>
            {openCropPopup &&
                <AddCrop
                    open={openCropPopup}
                    onClose={() => setOpenCropPopup(false)}
                    crop={selectedCrop}
                    onCropAdd={(crop) => {
                        selectedCrop?.id ?
                            updateCropName(crop) :
                            addNewCrop(crop)
                        setOpenCropPopup(false)
                    }}
                />
            }
            <MainContentWrapper
                header={"Crops"}
                rightContent={
                    <Button
                        type="submit"
                        icon={Plus}
                        text={"Add New"}
                        onClick={() => {
                            setOpenCropPopup(true)
                        }}
                    />
                }
            >
                {loading ?
                    <LoaderComponent
                        height={'2%'}
                        width={'2%'}
                    /> :
                    <>
                        <Table>
                            <col style={{ width: "15%", }} />
                            <col style={{ width: "70%", }} />
                            <col style={{ width: "3%", }} />

                            <TableHeader>
                                <th style={{ textAlign: "center" }}>Sr No.</th>
                                <th>Crop Name</th>
                                <th>Activity</th>
                            </TableHeader>
                            {(cropsList && cropsList.length > 0) && cropsList.map((crop, i) => (
                                <tr className='table-row-hover' key={crop.id} >
                                    <td style={{ textAlign: "center" }}>{++srNo}</td>
                                    <td>{crop.name}</td>
                                    <td style={{ textAlign: "right" }}>
                                        <Tippy interactive onShown={(instance) => {
                                            document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                                                instance.hide();
                                            })
                                        }} className='tippy' trigger='click' arrow={false} content={
                                            <OptionMenu
                                                isLeft
                                                onClickDelete={() => {
                                                    Popup.alert("Crops", "Are you sure, you want to delete this crop ?", "DELETE", () => {
                                                        deleteCropById(crop.id)
                                                    });
                                                }}
                                                onClickEdit={() => {
                                                    setSelectedCrop(crop)
                                                    setOpenCropPopup(true)
                                                }}
                                            />
                                        } >
                                            <img id='menu' src={more} alt='more' style={{ width: "20px", cursor: "pointer" }} onClick={(e) => {
                                                e.preventDefault()
                                            }} />
                                        </Tippy>
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </>
                }
            </MainContentWrapper>
        </>
    )
}

export default Crops