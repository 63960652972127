import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getDspLocations = async ({dspId,pageNumber,pageSize}) => {
    try {
            const token = getToken();
            const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/operations/locations`, {
                pageSize: pageSize && pageNumber ? pageSize : undefined,
                pageNumber: pageSize && pageNumber ? pageNumber : undefined,
                dspId: dspId ? dspId : undefined,
            });
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token} `,
                    },
                }
            );
    
            const res = await response.json();
            if (!response.ok) {
                throw (res.message || "Something went wrong while getting coupons");
            }
            return res?.locations;
    } catch (err) {
        throw (err)
    }
}

export const updateLocation = (location) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = getToken();
        const response = await fetch(
          SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/operations/locations",
          {
            method: "PUT",
            body: JSON.stringify({ location }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          resolve(data);
        }
        else if (response.status === 409) {
          reject(
            data?.message || "The location your are trying to update is already added to your locations."
          );
        }
        else {
          reject(
            data?.message || "Something went wrong while updating location."
          );
        }
      } catch (error) {
        reject("Something went wrong while updating location.");
      }
    });
  };