
import Modal from "../../../ReusableComponents/Modal";
import useWindowSize from "../../../hooks/useWindowSize";
import { Button } from "../../../Components/Helper";
import React, { useEffect, useState } from "react";
import FileUploader from "../../../ReusableComponents/FileUploader";
import InputError from '../../../ReusableComponents/InputError';
import { PostManufacture, updateManufacture } from '../../../apis/BhuMeetTeams/Oparations/droneStore'
import InputField from "../../../ReusableComponents/InputField";
import { fileValidation, nameValidation } from "../../../utils/inputValidations";
import Form from "../../../ReusableComponents/Form";
import SelectField from "../../../ReusableComponents/SelectField";
import Popup from '../../../ReusableFunctions/Popup';

const AddDroneManufacture = ({ open, onClose, modelId, onModelAdd, data }) => {

    const { currentHeight, currentWidth } = useWindowSize();

    const [Manufacture, setmanufacture] = useState({
        // id: pilotId ? pilotId : uuid(),
        Name: "",
        LogoUrl: '',
        isSubmitted: false

    });

    useEffect(() => {
        if (data) {
            const filename = data.logo_url.split('/').pop().split('?')[0];

            console.log(filename);
            setmanufacture((prevState) => ({
                ...prevState, // Spread the previous state to keep existing properties
                Name: data.name,
                LogoUrl: filename // Update only the `Name` property
            }));
        }
    }, [])







    return (
        <Modal
            header={data ? "Edit Manufacturer" : "Add Manufacturer"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "640px", height: "auto", borderRadius: "10px" }}
        >

            <Form
                onSubmitForm={async (pilot) => {

                    var formData = new FormData();


                    if(data){
                        if (Manufacture.LogoUrl.name) {



                            formData.append("file", Manufacture.LogoUrl);
                            formData.append("Name", Manufacture.Name)
                            formData.append("id", data.id)
    
                            await updateManufacture(formData).then(res => {
                                // console.log(res)
                                Popup.alert("Success", res?.message, "SUCCESS");
    
                                onClose()
    
                            }).catch(err => {
                                Popup.alert("Warning", "Something went wrong while updating Manufacture", "Warning");
    
                                console.log(err)
                            })
    
    
    
                        } else {
                            formData.append("Name", Manufacture.Name)
                            formData.append("id", data.id)
    
                            await updateManufacture(formData).then(res => {
                                // console.log(res)
                                Popup.alert("Success", res?.message, "SUCCESS");
    
                                onClose()
    
                            }).catch(err => {
                                Popup.alert("Warning", "Something went wrong while updating Manufacture", "Warning");
    
                                console.log(err)
                            })
                        }
                    }

                    if (!data && Manufacture.LogoUrl.name) {

                        formData.append("file", Manufacture.LogoUrl);
                        formData.append("Name", Manufacture.Name)

                        await PostManufacture(formData).then(res => {
                            // console.log(res)
                            Popup.alert("Success", res?.message, "SUCCESS");

                            onClose()

                        }).catch(err => {
                            Popup.alert("Warning", "Something went wrong while Adding New Manufacture", "Warning");

                            console.log(err)
                        })
                    } else {
                        setmanufacture((prev) => ({
                            ...prev,
                            isSubmitted: true,
                        }));
                    }






                    //   pilot = { ...pilot, city: pilot.city.label, district: pilot.district.label, state: pilot.state.label, location_id: pilot.location_id.value };
                    //   pilotId ? handleUpdateDronePilot(pilot) : handleAddDronePilot(pilot)
                }}
                initialValues={Manufacture}
            >
                {(onsubmit) => (
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gap: currentHeight < 695 ? "2px" : "5px",
                        padding: currentHeight < 695 ? "15px 20px" : "20px",

                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: "0px", maxWidth: "100%" }}>
                            <InputField
                                defaultValue={Manufacture.Name}
                                label={"Manufacturer Name"}
                                type={"text"}
                                id={"type"}
                                placeholder={"Name"}
                                name={"Name"}
                                validation={nameValidation}
                                containerStyle={{
                                    width: "40%",
                                    maxWidth: "80%",
                                    minHeight: "80px",
                                    rowGap: "0px"
                                }}
                                handleChange={(value) => {
                                    setmanufacture((prev) => ({ ...prev, Name: value }))
                                    // console.log(Manufacture, value)
                                }}
                            />
                            <div style={{ width: "297px" }}>
                                <div style={{ fontSize: "12px", color: "rgb(59, 59, 59)" }}>
                                    Manufacturer Logo

                                </div>
                                <FileUploader
                                    selectedFileName={Manufacture.LogoUrl}
                                    fileInputRef={null}
                                    uploaderId={"logo"}
                                    placeholder={Manufacture.LogoUrl != '' ? Manufacture.LogoUrl : "upload Logo"}
                                    onFileSelect={(value) => {
                                        setmanufacture((prev) => ({
                                            ...prev,
                                            LogoUrl: value,
                                        }));
                                    }}
                                    accept={"image/jpeg, image/jpg, image/png"}
                                    onClear={() => {
                                        setmanufacture((prev) => ({
                                            ...prev,
                                            LogoUrl: '',
                                        }));
                                    }}
                                />
                                {
                                    (Manufacture.LogoUrl === '' && Manufacture.isSubmitted) &&
                                    <InputError
                                        inputError={{ error: { message: "File Required" } }}
                                    />
                                }


                            </div>

                        </div>

                        {/* <div style={{ display: 'flex', justifyContent: 'space-between',width:"50%" }}>
              
            

             

              
            </div> */}




                        <div style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "20px",
                            padding: currentHeight < 695 ? "10px" : "20px",
                            height: "70px"
                        }}>
                            <Button
                                text={data ? "Update" : "Add"}
                                onClick={onsubmit}
                                className={"btn-large"}
                            />
                            <Button
                                className={"btn-large lg-cancel-btn"}
                                text={"Cancel"}
                                onClick={onClose}
                                isBtnNotActive={true}
                            />
                        </div>
                    </div>
                )}
            </Form>


        </Modal>
    )
}

export default AddDroneManufacture