import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper';
import Loading from '../../ReusableFunctions/Loader';
import { deleteCoupon, getAllCoupons, getAllCouponsCount } from '../../apis/coupons';
import AddCouponForm from './AddCouponForm';
import Popup from '../../ReusableFunctions/Popup';
import _debounce from 'lodash/debounce';
import './AllCoupons.css'
import CouponCard from './subComponents/CouponCard';
import CouponInfo from './subComponents/CouponInfo';
import CouponFilters from './subComponents/CouponFilters';
import LoaderComponent from '../../ReusableComponents/LoaderComponent';
import NotFounds from '../../ReusableComponents/NotFounds';
import Coupon from '../../assets/coupon1.png'
import { clearProfileFilters } from '../../ReusableFunctions/reusableFunctions';

const PAGE_SIZE = 15
export default function ManageCoupons() {
    const [currentPage, setCurrentPage] = useState(1)
    const [coupons, setCoupons] = useState([]);
    const [couponsCount, setCouponsCount] = useState(0)
    const [loading, setLoading] = useState(false);
    const [openCouponPopup, setopenCouponPopup] = useState(false);
    const [couponId, setCouponId] = useState(null)
    const [searchString, setSearchString] = useState("")
    const [couponInfo, setCouponInfo] = useState([])
    const [filters, setFilters] = useState({
        from_date: null,
        to_date: null,
        is_public: undefined,
        type: undefined,
    })
    let srNo = (currentPage - 1) * PAGE_SIZE

    const onEdit = (coupon) => {
        setCouponId(coupon.id)
        setopenCouponPopup(true)
    }

    const getCountByCategory = (coupons) => {
        let total_coupons;
        total_coupons = coupons?.filter(c => filters.is_public != null ? c.is_public === filters.is_public : true);
        if (filters.from_date || filters.to_date) {
            total_coupons = total_coupons?.filter(c => {
                if (filters.from_date && filters.to_date) {
                    return new Date(c.from_date) >= new Date(filters.from_date).setHours(0, 0, 0, 0) && new Date(c.expiry_date) <= new Date(filters.to_date).setHours(23, 59, 59, 999)
                }
                if (filters.from_date) {
                    return new Date(c.from_date) >= new Date(filters.from_date).setHours(0, 0, 0, 0)
                }
                if (filters.to_date) {
                    return new Date(c.expiry_date) <= new Date(filters.to_date).setHours(23, 59, 59, 999)
                }
                return false

            })

        };
        let live_coupons = total_coupons?.filter((c) => c.coupon_status === "LIVE").length;
        let expire_coupons = total_coupons?.filter((c) => c.coupon_status == "EXPIRED").length;
        let redeemed_coupons = total_coupons?.filter((c) => c.redeemed_count > 0).length;

        const categorywiseCount = [
            { name: "Total Coupons", count: total_coupons.length || 0 },
            { name: "Live Coupons", count: live_coupons || 0 },
            { name: "Redeemed Coupons", count: redeemed_coupons || 0 },
            { name: "Expired Coupons", count: expire_coupons || 0 },
            // { name: "Public Coupons", count: public_coupons },
            // { name: "Private Coupons", count: private_coupons }
        ]
        return categorywiseCount;
    }
    const deleteCouponFunc = async (coupon) => {
        Loading.set(true)
        try {
            await deleteCoupon(coupon.id)
            let updatedCoupons = coupons.filter((c) => c.id != coupon.id);
            setCoupons(() => updatedCoupons)
            let couponInfo = getCountByCategory(updatedCoupons)
            setCouponInfo((prev) => couponInfo)
            Popup.alert("Coupon deleted", "Coupon deleted successfully", "SUCCESS")
            Loading.set(false)
        } catch (e) {
            Loading.set(false)
            console.log(e)
            Popup.alert("ERROR", "Something went wrong while deleting coupon", "ERROR")
        }
    }
    const initData = _debounce(async () => {
        await getCoupons();
        await getCouponsCount()
    }, 500)
    useEffect(() => {
        setLoading(true)
       clearProfileFilters();
        initData()
    }, [filters?.from_date, filters?.to_date, filters?.type, filters?.is_public])

    async function getCouponsCount() {
        try {
            const res = await getAllCouponsCount(searchString)
            setCouponsCount(Number(res.coupons_count))
            Loading.set(false);
            setLoading(false)
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }
    async function getCoupons() {
        setLoading(true);
        try {
            let from_date = filters?.from_date
            let to_date = filters?.to_date
            if (from_date) {
                from_date = new Date(from_date);
                from_date.setHours(0, 0, 0, 0);
            }
            if (to_date) {
                to_date = new Date(to_date);
                to_date.setHours(23, 59, 59, 999)
            }
            const res = await getAllCoupons(
                undefined,
                undefined,
                searchString,
                filters?.type?.value === "All" ? undefined : filters?.type?.value,
                filters?.from_date ? new Date(from_date).toISOString() : undefined,
                filters?.to_date ? new Date(to_date).toISOString() : undefined,
                filters?.is_public
            );
            let coupons = res.coupons;
            setCoupons(() => coupons);
            await getAllCouponStats()
            setLoading(false);
        } catch (err) {
            setLoading(false);
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const getAllCouponStats = async () => {
        const res = await getAllCoupons(
            filters
        );
        const couponInfo = getCountByCategory(res.coupons)
        setCouponInfo(couponInfo)
    }
    return (
        <>

            {
                openCouponPopup &&
                <AddCouponForm
                    onClose={() => {
                        setCouponId(null)
                        setopenCouponPopup(false)
                        getCoupons()
                        getAllCouponStats()
                    }}
                    couponId={couponId || null}
                />
            }
            <MainContentWrapper
                subHeaderStyles={{ fontSize: "13px", color: "#3B3B3B", marginTop: "-3px" }}
                subheader={`Up your sales with exclusive coupons`}
                header={`Manage Coupons(${couponInfo[0]?.count > 0 ? couponInfo[0]?.count : 0})`} >
                <div className='coupons_wrapper'>
                    <CouponFilters setCouponId={setCouponId} setopenCouponPopup={setopenCouponPopup} onfilterChanged={(filters) => {
                        setFilters(filters)
                    }} />
                    <div className='section_divider'></div>
                    <CouponInfo appliedFilter={filters} setFilters={setFilters} couponInfo={couponInfo} />
                    {!loading ? (
                        coupons.length > 0 ? (
                            <div className='third_section_wrapper scroll-container'>
                                {coupons.map((coupon, i) => (
                                    <CouponCard
                                        key={i}
                                        coupon={coupon}
                                        onCouponEdit={(coupon) => onEdit(coupon)}
                                        onCouponDelete={(coupon) => {
                                            Popup.alert("WARNING", "Are you sure , you want to delete this coupon?", "WARNING", () => deleteCouponFunc(coupon))
                                        }}
                                    />
                                ))}
                            </div>
                        ) : (
                            <NotFounds imgStyle={{ opacity: "0.8" }} msg={"No coupons found "} imgUrl={Coupon} hideBtn={true} />
                        )
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: '100%', position: 'relative' }}>
                            <LoaderComponent width={"2%"} height={"2%"} />
                        </div>
                    )}

                </div>
            </MainContentWrapper>
        </>
    )
}