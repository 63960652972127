import React, { useEffect, useState } from "react";
import MainContentWrapper from "../../Components/MainContentWrapper";
import DurationFilters from "./SubComponents/DurationFilters";
import TotalRevenueGraph from "./SubComponents/TotalRevenueGraph";
import SelectComponent from "../../ReusableComponents/SelectComponent";
import PilotRevenue from "./SubComponents/PilotRevenue";
import LocationRevenue from "./SubComponents/LocationRevenue";
import CropRevenue from "./SubComponents/CropRevenue";
import DroneRevenue from "./SubComponents/DroneRevenue";
import Popup from "../../ReusableFunctions/Popup";
import { getRevenueGraphData } from "../../apis/revenue";
import { formatToIndianRupees } from "../../ReusableFunctions/reusableFunctions";
const durationOptions = [
  { id: 1, placeholder: "Today", interval:"day" },
  { id: 2, placeholder: "This Week",interval:"week" },
  { id: 3, placeholder: "This Month", interval:"month" },
  { id: 4, placeholder: "This Year", interval:"year" },
];
const Revenue = () => {
  const now = new Date();
  const startOfDay = new Date(now.setHours(0, 0, 0, 0));
  const endOfDay = new Date(now.setHours(23, 59, 59, 999));
  const [filters, setFilters] = useState({
    interval: "day",
    fromDate: startOfDay,
    toDate: endOfDay,
  });
  const [revenueData, setRevenueData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);

  const handleFilterChanged = (filterType, value) => {
    const now = new Date();
    let fromDate = new Date();
    let toDate = new Date();

    switch (value) {
      case "day":
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(23, 59, 59, 999);
        break;
      case "week":
        fromDate = new Date(now.setDate(now.getDate() - now.getDay()));
        fromDate.setHours(0, 0, 0, 0);
        toDate = new Date(now.setDate(now.getDate() - now.getDay() + 6));
        toDate.setHours(23, 59, 59, 999);
        break;
      case "month":
        fromDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 1, 0, 0);
        toDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        toDate.setHours(23, 59, 59, 999);
        break;
      case "year":
        fromDate = new Date(now.getFullYear(), 0, 1, 0, 1, 0, 0);
        toDate = new Date(now.getFullYear(), 11, 31);
        toDate.setHours(23, 59, 59, 999);
        break;
    }

    console.log("fromDate ,toDate", fromDate, toDate);

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
      fromDate,
      toDate,
    }));
  };

  const getRevenueFunc = async () => {
    try {
      setGraphLoading(true);
      const data = await getRevenueGraphData({
        fromDate: filters?.fromDate?.toISOString(),
        toDate: filters?.toDate?.toISOString(),
        interval: filters?.interval?.toLowerCase(),
      });
      setRevenueData(data);
      setGraphLoading(false);
    } catch (err) {
      setGraphLoading(false);
      Popup.alert("Error", err.message, "ERROR");
    }
  };

  useEffect(() => {
    getRevenueFunc();
  }, [filters.interval]);

  return (
    <MainContentWrapper
      padding=""
      header={"Revenue"}
      rightContent={
        <>
          <DurationFilters
          options={durationOptions}
            interval={filters?.interval}
            handleFilterChanged={({fromDate,toDate,interval})=>{
              setFilters((prevFilters) => ({
                ...prevFilters,
                interval: interval,
                fromDate,
                toDate,
              }));
            }}
          />
        </>
      }
    >
      <div className="graph_conatiner">
        <TotalRevenueGraph
          style={{ minHeight: "500px" }}
          headingContent={
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                height: "35px",
                paddingBottom: "35px",
              }}
            >
              <h3>Total Revenue - </h3>
              <span
                style={{
                  fontSize: "20px", color: "blue", 
                  fontWeight: 700,
                  marginTop: "2px",
                }}
              >
                ₹ {formatToIndianRupees(revenueData[0]?.total_revenue)}
              </span>
            </div>
          }
          showZoom={true}
          title={"total Revenue "}
          lineType={2}
          interval={filters?.interval?.toLowerCase()}
          loading={graphLoading}
          data={revenueData}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 49%)",
            justifyContent: "space-between",
            rowGap: "20px",
          }}
        >
          <PilotRevenue
            fromDate={filters?.fromDate}
            toDate={filters?.toDate}
            interval={filters?.interval}
          />
          <LocationRevenue
            fromDate={filters?.fromDate}
            toDate={filters?.toDate}
            interval={filters?.interval}
          />
          <CropRevenue
            fromDate={filters?.fromDate}
            toDate={filters?.toDate}
            interval={filters?.interval}
          />
          <DroneRevenue
            fromDate={filters?.fromDate}
            toDate={filters?.toDate}
            interval={filters?.interval}
          />
        </div>
      </div>
    </MainContentWrapper>
  );
};

export default Revenue;
