import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getBesInvoiceDetails = async (id,endpoint) => {
    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/finance/bes/invoices/details/${id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.invoiceDetails;
    } catch (err) {
        throw err;
    }
}

export const settleBesInvoice = async (invoice) => {
    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/finance/bes/invoices/settle`,
            {
                method: "POST",
                body: JSON.stringify(invoice),
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while setteling the transaction");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while setteling the transaction");
    }
}


export const getBesInvoiceStats=async(fromDate,toDate)=>{
    try{
        const token = getToken();
        const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/bhumeet_team/finance/bes/invoices/stats', {
            fromDate: fromDate ? fromDate : undefined,
            toDate: toDate ? toDate : undefined,
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.invoicesStats;
    }catch(err){
        throw err;
    }
}


export const getBesSettlementInvoices = async ( fromDate, toDate, pageSize, pageNumber, orderBy, orderType, statuses) => {
    try {
        const token = getToken();
        const url = buildUrl(SERVER_URLS.BACKEND_SERVER + '/bhumeet_team/finance/bes/invoices', {
            fromDate: fromDate ? fromDate : undefined,
            toDate: toDate ? toDate : undefined,
            pageSize: pageSize && pageNumber ? pageSize : undefined,
            pageNumber: pageSize && pageNumber ? pageNumber : undefined,
            orderBy: orderBy ? orderBy : undefined,
            orderType: orderType ? orderType : undefined,
            statuses: statuses ? statuses.toString() : undefined,
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.dspInvoices;
    } catch (err) {
        throw err;
    }
}