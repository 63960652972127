import React, { useEffect, useState } from 'react'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import { INECNTIVE_INVOICES_STATUS } from '../../appConstants'
import MainContentWrapper from '../../Components/MainContentWrapper'
import formatNumber from '../../ReusableFunctions/convertLongNumToShort'
import { getBesInvoiceStats, getBesSettlementInvoices } from '../../apis/BhuMeetTeams/BhuMeetFinanace/besIncentiveInvoices'
import Popup from '../../ReusableFunctions/Popup'
import { PDRL_FINANCE_ROUTES } from '../../routes'
import TableView from '../../ReusableComponents/TableView/TableView'
import { useNavigate } from 'react-router'

const InvoicesStatCard = ({ title, statNumber, isSelected, onClick, isDisabled }) => {
    return <DashBoardCard isDisabled={isDisabled} title={title} data={statNumber} onClick={onClick} backGround={isSelected ? "green" : undefined} />
}

const InvoiceStats = ({ invoicesStats, filters, changeStatusFilter }) => {
    return <div style={{ display: "flex", columnGap: "2%" }}>
        <InvoicesStatCard
            title={"Total Invoices"}
            statNumber={invoicesStats.total_invoices_count}
            isSelected={filters.statuses.length === 0}
            onClick={() => changeStatusFilter([])}
        />
        <InvoicesStatCard
            title={"Paid Invoice"}
            statNumber={invoicesStats.paid_invoices_count}
            isSelected={filters.statuses.length > 0 && filters.statuses[0] === INECNTIVE_INVOICES_STATUS.PAID}
            onClick={() => changeStatusFilter([INECNTIVE_INVOICES_STATUS.PAID])}
        />
        <InvoicesStatCard
            title={"Unpaid Invoices"}
            statNumber={invoicesStats.unpaid_invoices_count}
            isSelected={filters.statuses.length > 0 && filters.statuses[0] === INECNTIVE_INVOICES_STATUS.UNPAID}
            onClick={() => changeStatusFilter([INECNTIVE_INVOICES_STATUS.UNPAID])}
        />
        <InvoicesStatCard
            title={"Paid Amount"}
            statNumber={formatNumber(Number(invoicesStats.paid_invoices_amount))}
            isDisabled={true}
        />
        <InvoicesStatCard
            title={"Unpaid Amount"}
            statNumber={formatNumber(Number(invoicesStats.unpaid_invoices_amount))}
            isDisabled={true}
        />
    </div>
}

export default function FinanceBesDashBoard() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate=useNavigate();
    const [filters, setFilters] = useState(()=>{
        let savedFilters=localStorage.getItem("bes_invoices_filters");
        if(savedFilters){
            let parsedFilters = JSON.parse(savedFilters);
            return parsedFilters;
        }
           return {
                   pageNumber: 1,
                   pageSize: 15,
                   orderBy: "generated_time",
                   orderType: "DESC",
                   statuses:  [INECNTIVE_INVOICES_STATUS.UNPAID],
               }
           
       })
    const [besInvoiceStats, setBesInvoiceStats] = useState({
        total_invoices_count_for_pagination: 0,
        total_invoices_count: 0,
        paid_invoices_count: 0,
        unpaid_invoices_count: 0,
        paid_invoices_amount: 0,
        unpaid_invoices_amount: 0
    })
    const [besInvoices, setBesInvoices] = useState([])

    const getBesInvoice = async () => {
        try {
            setIsLoading(true)
            // const invoices = await getDSPSettlementInvoices(filters.selectedDSP?.value, filters.fromDate, filters.toDate, filters.pageSize, filters.pageNumber, filters.orderBy, filters.orderType, filters.statuses, isFromTds, isFromIncentive, isFromPlatformFee)
            const invoices = await getBesSettlementInvoices( filters.fromDate, filters.toDate, filters.pageSize, filters.pageNumber, filters.orderBy, filters.orderType, filters.statuses)
            const invoicesStats = await getBesInvoiceStats(filters.fromDate, filters.toDate)
            setBesInvoiceStats({
                total_invoices_count_for_pagination: invoices[0]?.settlement_invoices_count ? Number(invoices[0].settlement_invoices_count) : 0,
                total_invoices_count: (invoicesStats.settlement_invoices_count && Number(invoicesStats.settlement_invoices_count)) ?? 0,
                paid_invoices_count: invoicesStats.settled_invoices_count || 0,
                unpaid_invoices_count: invoicesStats.unsettled_invoices_count || 0,
                paid_invoices_amount: invoicesStats.settled_amount || 0,
                unpaid_invoices_amount: invoicesStats.unsettled_amount || 0
            })
            setBesInvoices(invoices)
            setIsLoading(false)
        } catch (e) {
            console.error(e)
            Popup.alert("ERROR", "We cant load invoices right now, Please try again later.")
        }
    }

    useEffect(()=>{
        getBesInvoice()
        localStorage.setItem("bes_invoices_filters",JSON.stringify(filters))
    },[filters])
  return (
   <MainContentWrapper header={`BES Invoices `} rightContent={<div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>
        </div>
        }>
            
                
                <InvoiceStats
                    invoicesStats={besInvoiceStats}
                    filters={filters}
                    changeStatusFilter={(statusFiler) => {  
                        setFilters({ ...filters,pageNumber:1, statuses: statusFiler })
                    }}
                />
            
            <div style={{
                margin: "20px 0",
                height: "72%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"500px"}
                    isLoading={isLoading}
                    onStateChange={async (changedState) => {
                        const { pageNumber, orderBy, orderType } = changedState;
                        setFilters({ ...filters, pageNumber, orderBy, orderType });
                    }}
                    onClickRow={(invoice) => {
                        navigate(`${PDRL_FINANCE_ROUTES.INCENTIVEINVOICE}`, {
                            state: {
                                invoice
                            }
                        })
                    }}
                    columns={[
                        { Header: "Invoice No.", accessor: "invoice_number" },
                        { Header: "Total amount", accessor: "incentive_amount"},
                        { Header: "Paid amount", accessor: "total_amount"},
                        { Header: "Status", accessor: "status"},
                        { Header: "Generated for date", accessor: "generated_time", isSortable: true, isDate: true, format: "dd/mm/yyyy",width:"20%" },
                    ]}
                    count={besInvoiceStats.total_invoices_count_for_pagination}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={besInvoices}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                />
            </div>
        </MainContentWrapper>
  )
}
