import React, { useEffect, useState } from "react";
import { getRequests } from "../../apis/requests";
import { useLocation, useNavigate } from "react-router";
import Popup from "../../ReusableFunctions/Popup";
import Loading from "../../ReusableFunctions/Loader";
import MainContentWrapper from "../../Components/MainContentWrapper";
import RequestOverView from "./SubComponents/RequestOverView/RequestOverView";
import calender from "../../assets/calender-new.svg";
import calenderActive from "../../assets/calender-active.svg";
import lines from "../../assets/lines.png";
import lineActive from "../../assets/lineActive.png";
import CalenderComponent from "./Calender/CalenderComponent";
import RequestTable from "./SubComponents/RequestTable/RequestTable";
import CalenderViewRequestTable from "./SubComponents/CalenderViewRequestTable/CalenderViewRequestTable";
import { toDate } from "date-fns";
import { Button } from "../../Components/Helper";
import CreateRequest from "./CreateRequest";
import ViewPilotHistoryOnMap from "./SubComponents/MapViewPilotLocation/ViewPilotHistoryOnMap";
import { getPilotLocationsHistoryDSP } from "../../apis/dronePilots";
import { getServices } from "../../apis/services";
import { clearProfileFilters } from "../../ReusableFunctions/reusableFunctions";

const MyRequests = () => {
  const { state } = useLocation();
  const { search ,pathname} = useLocation();
  const params = new URLSearchParams(search);
  const requestPerPage = 6;
  const [requests, setRequests] = useState([]);
  const [isSparyingRequestAvailable, setisSparyingRequestAvailable] = useState(false);
  const [isCalenderView, setIsCalenderView] = useState(()=>{
    const savedFilters = localStorage.getItem('isCalenderView')
    if (savedFilters) {
      const view = JSON.parse(savedFilters)
      return view
    }
    return state?.isCalenderView || params?.get("isCalenderView")
  }
  );
  const [pilotId, setPilotId] = useState(
    state?.pilotId || params?.get("pilotId")
  );
  const [pilotName, setPilotName] = useState(
    state?.pilotName || params?.get("pilotName")
  );
  const [isGraphView, setIsGraphView] = useState(()=>{
    const savedFilters = localStorage.getItem('isCalenderView')
    if (savedFilters) {
      const view = JSON.parse(savedFilters)
      return !view
    }
    return !(state?.isCalenderView || params?.get("isCalenderView"))
  }
  );
  const [selectedDate, setSelectedDate] = useState(()=>{
    const savedSelectedDate = localStorage.getItem('currentCalenderDate')
    if (savedSelectedDate) {
      const date = JSON.parse(savedSelectedDate)
      return new Date(date)
    }
    new Date()
  })
  const [loading, setLoading] = useState(true);
  const [filetrs, setFilters] = useState(()=>{
    const savedFilters = localStorage.getItem('requestFilters')
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters)
      return {
          ...parsedFilters,
          statuses:parsedFilters.statuses,
          locations: parsedFilters.locations,
          services: parsedFilters.services,
      }
  }
  return {
      services: [],
      statuses: [],
      locations: [],
  }
  });
  const [pageNumber, setpageNumber] = useState(()=>{
    const savedPage = localStorage.getItem('currentRequestPage')
    if (savedPage) {
      return parseInt(savedPage)
    }
    return 1
  });
  
  const [filterDateTime, setFilterDateTime] = useState(() => {
    const savedFilterDateTime = localStorage.getItem("filterDateTime");
    if (savedFilterDateTime) {
      try {
        return JSON.parse(savedFilterDateTime);
      } catch {
        console.warn("Error parsing filterDateTime from localStorage");
        return { fromDate: undefined, toDate: undefined };
      }
    }
    return { fromDate: undefined, toDate: undefined };
  });
  
  const [searchString, setSearchString] = useState(()=>{
    // const savedSearchString = localStorage.getItem("SearchQuery");
    // if (savedSearchString) {
    //   return JSON.parse(savedSearchString);
    // }
    return "";
  });

  const [openCreateRequestPopup, setOpenCreateRequestPopup] = useState(false);
  const [pilotLocations, setpilotLocations] = useState([])

  const getRequestsAPI = async (
    pageSize,
    pageNumber,
    filters,
    searchQuery,
    fromDate,
    toDate
  ) => {

    console.log("called with filters:",  pageSize,
      pageNumber,
      filters,
      searchQuery,
      fromDate,
      toDate);
    try {
      setLoading(true);
      const data = await getRequests(
        state?.userId,
        filters,
        pageSize,
        pageNumber,
        searchQuery,
        fromDate,
        toDate
      );
      setRequests(data.requests);
      setLoading(false);
    } catch (err) {
      console.log("errr:", err);
      setLoading(false);
      Popup.alert("Error", err, "ERROR");
    }
  };

  const getRequest = async () => {  
    Loading.set(true);
    setLoading(true);
    if(isCalenderView){
      await getRequestsAPI(
        undefined, 
        undefined, 
        undefined,
        undefined,
        selectedDate? new Date(new Date(selectedDate).setHours(0,0,0)).toISOString(): undefined,
      selectedDate ? new Date(new Date(selectedDate).setHours(23,59,999)).toISOString(): undefined);
    }else {
      await getRequestsAPI(
        requestPerPage, 
        pageNumber, 
        filetrs,
        undefined,
        filterDateTime && filterDateTime.fromDate
        ? new Date(filterDateTime.fromDate).toISOString()
        : undefined,
      filterDateTime && filterDateTime.toDate
        ? new Date(filterDateTime.toDate).toISOString()
        : undefined)
    }
    Loading.set(false);
    setLoading(false);
  };

  const getPilotLocationsHistory = async () => {
    try {
      setLoading(true);
      let pilotsLocations = await getPilotLocationsHistoryDSP(
        pilotId,
        selectedDate?new Date(new Date(selectedDate).setHours(0,0,0,0)).toISOString():new Date(new Date().setHours(0,0,0,0)).toISOString(),
        selectedDate?new Date(new Date(selectedDate).setHours(23,59,59,999)).toISOString():new Date(new Date().setHours(23,59,59,999)).toISOString()
      );
      setpilotLocations(pilotsLocations);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Popup.alert("Error", err, "ERROR");
    }
  };

  useEffect(() => { 
    document.title = "BhuMeet - My Requests";
    clearProfileFilters();
    if (!pilotId && !isCalenderView) getRequest();
    if(isCalenderView && pilotId){
      getPilotLocationsHistory()
    }
  }, [state?.userId, isGraphView, isCalenderView, pilotId,selectedDate]);

  const filterChanged = async () => { 
    try {
        setLoading(true)
        await getRequestsAPI(
          requestPerPage,
          pageNumber,
          filetrs,
          searchString,
          filterDateTime && filterDateTime.fromDate
          ? new Date(filterDateTime.fromDate).toISOString()
          : undefined,
        filterDateTime && filterDateTime.toDate
          ? new Date(filterDateTime.toDate).toISOString()
          : undefined
         )
        setLoading(false)
    } catch (e) {
        setLoading(false)
        Popup.alert("Error", e, "ERROR")
    }
}

useEffect(()=>{
  getServicesFunc()
},[])


const getServicesFunc = async () => {
  try {
    let services = await getServices();
    // Check if no service has service_type as "sparying"
    const hasSparyingService = services.some(service => service.service_type === "Spraying service");
    // Set the state based on whether sparying service is available
    setisSparyingRequestAvailable(hasSparyingService);
  } catch (err) {
    console.error("Error fetching services:", err);
  }
};


  useEffect(() => {
    filterChanged()
    localStorage.setItem("requestFilters", JSON.stringify(filetrs))
    localStorage.setItem("currentRequestPage", JSON.stringify(pageNumber))
    localStorage.setItem("filterDateTime", JSON.stringify(filterDateTime));
    localStorage.setItem("SearchQuery", JSON.stringify(searchString));
    localStorage.setItem("isCalenderView", JSON.stringify(isCalenderView));
}, [filetrs,pageNumber,filterDateTime,searchString,isCalenderView])


  return (
    <>
      <MainContentWrapper
        header={pilotId ? "" : "My Requests"}
        rightContent={
          pilotId ? (
            <></>
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Button
                style={{ height: "33px", fontSize: "14px" }}
                text={"Create Request"}
                onClick={() => {
                  setOpenCreateRequestPopup(true);
                }}
              />
              <div
                style={{
                  display: "flex",
                  gap: "",
                  alignItems: "center",
                  border: "1px solid rgb(132 132 132 / 39%)",
                  borderRadius: "3px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    background: isGraphView ? "" : "rgba(15, 94, 255, 0.1)",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={isCalenderView ? calenderActive : calender}
                    alt="calender icon"
                    style={{ width: "16px", cursor: "pointer" }}
                    onClick={() => {
                      if (!isCalenderView) {
                        setIsCalenderView(true);
                        setRequests([]);
                        setIsGraphView(false);
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "1px",
                    background: "rgb(132 132 132 / 39%)",
                    height: "100%",
                  }}
                ></div>
                <div
                  style={{
                    background: isCalenderView ? "" : "rgba(15, 94, 255, 0.1)",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    width: "30px",
                    justifyContent: "center",
                    height: "30px",
                  }}
                >
                  {isCalenderView ? (
                    <div style={{ margin: 0, padding: 0 }}>
                      <img
                        src={isGraphView ? lineActive : lines}
                        alt="lines icon"
                        style={{ width: "18px", cursor: "pointer" }}
                        onClick={() => {
                          setIsGraphView(true);
                          setIsCalenderView(false);
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={isGraphView ? lineActive : lines}
                      alt="lines icon"
                      style={{ width: "18px", cursor: "pointer", padding: "" }}
                      onClick={() => {
                        setIsGraphView(true);
                        setIsCalenderView(false);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        }
      >
        {(openCreateRequestPopup && isSparyingRequestAvailable)  ?(
          <CreateRequest
            onClose={() => {
              setOpenCreateRequestPopup(false);
              getRequest();
            }}
          />
        ): (openCreateRequestPopup && !isSparyingRequestAvailable) ? Popup.alert("WARNING", "First create the sparying service , then place request!", "SUCCESS",()=>{setOpenCreateRequestPopup(false)},null,"Ok",null):null}
        <div
          style={{
            width: "100%",
            maxWidth: "100%",
            height: "93%",
            display: "flex",
            flexDirection: isGraphView ? "column" : "row",
            gap: "20px",
          }}
        >
          {state?.userId ? (
            ""
          ) : isGraphView ? (
            <RequestOverView />
          ) : (
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "35px",
              }}
            >
              <div style={{ width: "100%", height: "max-content" }}>
                <CalenderComponent
                  user={{ ...state, pilotId, pilotName, isCalenderView: true }}
                  onDateChange={(req, date, requestCount) => {
                    setRequests(req);
                    localStorage.setItem("currentCalenderDate", JSON.stringify(date));
                    setSelectedDate(date);
                  }}
                />
              </div>
              {(isCalenderView && pilotId ) &&(
                <ViewPilotHistoryOnMap
                  pilotLocations={pilotLocations}
                />
              )}
            </div>
          )}
          <div
            style={{
              height: isGraphView ? "calc(100% - 390px)" : "100%",
              width: isGraphView ? "100%" : "50%",
            }}
          >
            {isGraphView ? (
              <RequestTable
                pageNumber={pageNumber}
                requests={requests}
                dateTime={filterDateTime}
                onFilterChange={(filters, dateTime) => {
                  setFilters(filters);
                  setFilterDateTime(dateTime);
                  setpageNumber(1); 
                  getRequestsAPI(
                    requestPerPage,
                    1,
                    filters,
                    searchString,
                    dateTime
                      ? new Date(dateTime.fromDate).toISOString()
                      : undefined,
                    dateTime
                      ? new Date(dateTime.toDate).toISOString()
                      : undefined
                  );
                }}
                loading={loading}
                setLoading={setLoading}
                onSearch={(searchQuery) => {
                  setSearchString(searchQuery);
                  getRequestsAPI(
                    requestPerPage,
                    1,
                    filetrs,
                    searchQuery,
                    filterDateTime && filterDateTime.fromDate
                      ? new Date(filterDateTime.fromDate).toISOString()
                      : undefined,
                    filterDateTime && filterDateTime.toDate
                      ? new Date(filterDateTime.toDate).toISOString()
                      : undefined
                  );
                }}
                searchQuery={searchString}
                tabelContainerHeight={"401px"}
                onCurrentPageChange={(pageNumber) => {
                  setpageNumber(pageNumber);
                  getRequestsAPI(
                    requestPerPage,
                    pageNumber,
                    filetrs,
                    searchString,
                    filterDateTime && filterDateTime.fromDate
                      ? new Date(filterDateTime.fromDate).toISOString()
                      : undefined,
                    filterDateTime && filterDateTime.toDate
                      ? new Date(filterDateTime.toDate).toISOString()
                      : undefined
                  );
                }}
                totalCount={requests[0]?.total_count || 0}
                pageSize={requestPerPage}
              />
            ) : (
              <CalenderViewRequestTable
                user={{ ...state, pilotId, pilotName, isCalenderView: true }}
                requests={requests}
                date={selectedDate}
              />
            )}
          </div>
        </div>
      </MainContentWrapper>
    </>
  );
};

export default MyRequests;
