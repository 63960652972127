import React, { useEffect, useState } from 'react'
import LocationCard from "../../BhuMeetTeams/Operations/Locations/LocationCard";
import { GLOBAL_STYLES } from '../../appConstants'
import { getAllLocations, getAllLocationsCount } from '../../apis/adminAPIs/locations'
import Popup from '../../ReusableFunctions/Popup'
import NotFounds from '../../ReusableComponents/NotFounds'
import Pagination from '../../ReusableComponents/Pagination'
import LoaderComponent from '../../ReusableComponents/LoaderComponent'
import Loading from '../../ReusableFunctions/Loader'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { Button } from '../../Components/Helper'
import { useNavigate } from 'react-router'

const Locations = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [locationCount, setLocationCount] = useState(0)
    const [locations, setLocations] = useState()

    const getLocationsCountFunc = async () => {
        try {
            const locationsCount = await getAllLocationsCount(true);
            setLocationCount(locationsCount);
            getLocationsFunc(currentPage);
        } catch (e) {
            Popup.alert("Error", e, "ERROR")
        }
    }
    const getLocationsFunc = async (page) => {
        try {
            const locations = await getAllLocations(15, page)
            setLoading(false)
            Loading.set(false)
            setLocations(locations)
        } catch (e) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", e, "ERROR")
        }
    }

    const updateLocation=(location)=>{
            setLocations(() => locations.map((l) => (l.id === location.id ? location : l)));
    }
    useEffect(() => {
        Loading.set(true)
        getLocationsCountFunc()
    }, [])
    return (

        <MainContentWrapper
            header={`My Locations (${locationCount})`}
            rightContent={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button text={"View on map"} 
                    className={"btn-large"}
                    style={{width:'max-content'}}
                    onClick={() => {
                        navigate("/locations/map")
                    }} />
                </div>
            }
        >

            <div
                style={{
                    width: "100%",
                    height: "calc( 100% - 10px)",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
                }}
            >
                {locations && locations.length > 0
                    ? <>
                        {loading ? <div style={{ width: "100%", height: "100%" }}><LoaderComponent height={"2%"} width={"2%"} /></div> : <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(auto-fill,325px)",
                                // gridTemplateRows: "repeat(auto-fill,380px)",
                                gap: "10px",
                                overflowY: "auto",
                                height: "100%",
                            }}
                        >
                            {locations.map((location) => (
                                <LocationCard
                                    isEditDisabled={false}
                                    key={location.id}
                                    location={location}
                                    onUpdateLocation={updateLocation}
                                />
                            ))}
                        </div>}

                        {/* {filterLocations && filterLocations.length > 0 ?
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(auto-fill,325px)",
                                    gridTemplateRows: "repeat(auto-fill,380px)",
                                    gap: "13px 16px",
                                    overflowY: "auto",
                                    height: "100%",
                                }}
                            >
                                {filterLocations.map((location) => (
                                    <LocationCard
                                        isEditDisabled={!user.is_admin}
                                        key={location.id}
                                        location={location}
                                        onUpdateLocation={onUpdateLocation}
                                    />
                                ))}
                            </div>
                            : filterLocations && filterLocations.length === 0 && <NotFounds msg={"OOPS,No Locations Found!"} hideBtn={!user.is_admin} onBtnClick={() => setOpenAddLocationPopup(true)} />} */}

                        <Pagination
                            className="pagination-bar pagination-right"
                            currentPage={currentPage}
                            totalCount={locationCount}
                            pageSize={15}
                            onPageChange={(page) => {
                                setLoading(true);
                                setCurrentPage(page)
                                getLocationsFunc(page)
                            }}
                        />
                    </>
                    : locations && locations.length === 0 && <NotFounds msg={"OOPS,No Locations Found!"} hideBtn />}
            </div>

        </MainContentWrapper>

    )
}

export default Locations