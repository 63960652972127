import { useState, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Modal from "../../ReusableComponents/Modal";
import Form from "../../ReusableComponents/Form";
import InputField from "../../ReusableComponents/InputField";
import { nameValidation } from "../../utils/inputValidations";
import InputError from "../../ReusableComponents/InputError";
import { Button } from "../../Components/Helper";
import SelectField from "../../ReusableComponents/SelectField";
import { selectFieldValidation } from "../../utils/inputValidations";
import { postCallingHistory, updateCallingHistory } from "../../apis/BhuMeetTeams/Telecaller/callingHistory";
import TextAreaField from "../../ReusableComponents/TextAreaInput";
import { set } from "lodash";
import { useNavigate } from "react-router";

export const AddcallHistory = ({ open, onClose, data, isEdit,lastRecord }) => {

    const navigate = useNavigate()

    const { currentHeight, currentWidth } = useWindowSize();
    const [historyStatus, setHistoryStatus] = useState([])
    const [lastRecordStatus,setlastRecord] = useState(lastRecord)

    const [callHistory, setcallHistory] = useState({
        // id: pilotId ? pilotId : uuid(),
        assignee: data?.assignee,
        queueid: data?.queueid,
        reqid: data?.reqid,
        note: data?.note || '',
        prevStatus: data?.status,
        status: "",
        isMarked: false,
        queueID: data?.callingQueueID

    });

    useEffect(() => {
        console.log(lastRecordStatus)
        let status = [{ label: "Completed", value: "Completed" }, { label: "Disconnected", value: "Disconnected" }
            , { label: "Incomplete", value: "Incomplete" }, { label: "No Response", value: "No Response" }, { label: "Couldn't connect", value: "Couldn't connect" }
        ]

        setHistoryStatus(status)
        setcallHistory((prev)=>({...prev,prevStatus:data.status}))
        
    }, [])

    useEffect(() => {
        // console.log(callHistory)
    }, [callHistory])



    return (
        <Modal
            header={lastRecordStatus ? "Update Existing Call History" :"Add Call History"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "640px", height: "auto", borderRadius: "10px" }}
        >

            <Form
                onSubmitForm={async (form) => {
                    // console.log(form)
                    if (isEdit) {
                        let finalObj = callHistory

                        if(lastRecordStatus){
                            
                            finalObj.isMarked = true
                        }
                        await updateCallingHistory(finalObj, data.id)
                        if(finalObj.isMarked){
                            navigate('/telecallerdashboard')
                        }
                    } else {
                        await postCallingHistory(callHistory);
                    }

                    onClose()
                }}
                initialValues={callHistory}
            >
                {(onsubmit) => (
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gap: currentHeight < 695 ? "2px" : "5px",
                        padding: currentHeight < 695 ? "15px 20px" : "20px",

                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: "0px", maxWidth: "100%" }}>
                            
                            <TextAreaField
                                
                                // label={"Note"}
                                value={callHistory.note}
                                // isMandotory
                                name={"English"}
                                onChange={(e)=>{
                                    let value = e.target.value
                                    setcallHistory((prev)=>({...prev,note:value}))
                                }}
                                placeholder={lastRecordStatus ? "Enter your Note and close call" :`Enter Your Note`}
                                
                                error={callHistory.note == ''}
                                height={currentHeight < 850 ? "60px" : ""}
                                marginTop={"11px"}
                                width = {"150%"}

                            />
                            <div style={{ width: "297px", marginTop: callHistory.prevStatus && callHistory.prevStatus == 'Completed' ? "0px" : "16px" }}>
                                {callHistory.prevStatus == 'Completed' && callHistory.prevStatus &&

                                    <>  <div style={{display:"flex",flexDirection:"row",gap:"10px"}}>
                                        <label style={{ fontSize: "13px", display: "flex", alignItems: "center", gap: "10px",marginBottom:"15px" }}>Status</label>
                                        <label style={{ marginLeft: "56%",marginBottom:"18px", fontSize: "13px", display: "flex", alignItems: "center", gap: "10px" }}>
                                            <input
                                                type="checkbox"
                                                name="dspStatus"
                                                disabled={false}
                                                value={callHistory.isMarked}
                                                // checked={activePaymentMethods?.length === 1 && activePaymentMethods[0] === PAYMENT_METHODS.UPI}
                                                onChange={(e) => {
                                                    // console.log(e)
                                                    setcallHistory((prev) => ({ ...prev, isMarked: !callHistory.isMarked }))
                                                }}
                                                style={{ cursor: false ? "not-allowed" : "pointer" }}
                                            />
                                            {"Close call"}
                                        </label>
                                        </div>


                                    </>
                                }
                                {data.status ? 
                                <InputField 
                                disabled={true}
                                key={"Status"}
                                type={"text"}
                                id={"Status"}
                                placeholder={data.status}
                                // label={"Status"}
                                value={data ? data.status : ""}
                                defaultValue={data ? data.status : ""}
                                handleChange={()=>{
                                    setcallHistory((prev)=>({...prev,status:data.status}))
                                }}
                                name={"status"}
                                containerStyle={{top:callHistory.prevStatus == 'Completed' ? "-23px" : "-3px"}}
                                validation={{
                                    required : false,
                                    message : "Required"
                                }}
                                
                                />
                            : null}
                                {/* <SelectField
                                    name='Status'
                                    // label = "Select Status"
                                    // isMulti
                                    isDisabled={true}
                                    styles={{ width: "215px", height: "18px", fontSize: "13px", }}
                                    list={historyStatus}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        // minHeight: "80px",
                                    }}
                                    placeholderText={"Select Status"}

                                    value={{label:callHistory.prevStatus,value:callHistory.prevStatus}}
                                    defaultValue={{label:callHistory.prevStatus,value:callHistory.prevStatus}}
                                    handleChange={(value) => {
                                        console.log(value)
                                        setcallHistory((prev) => ({ ...prev, status: value }))
                                    }}
                                    menuPortalTarget={document.getElementById('modalHolder')}
                                    validation={{
                                        required: {
                                            value: callHistory.status ? false : true,
                                            message: 'Required',
                                        }
                                    }}
                                    controlStyle={{ height: "auto" }}
                                /> */}









                            </div>



                        </div>

                        <div>


                        </div>




                        <div style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "20px",
                            padding: currentHeight < 695 ? "10px" : "20px",
                            height: "70px"
                        }}>
                            <Button
                                text={lastRecordStatus ? "Close Call" :"Add"}
                                onClick={onsubmit}
                                className={"btn-large"}
                            />
                            <Button
                                className={"btn-large lg-cancel-btn"}
                                text={"Cancel"}
                                onClick={onClose}
                                isBtnNotActive={true}
                            />
                        </div>
                    </div>
                )}
            </Form>


        </Modal>
    )
}