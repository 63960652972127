import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { findInputError } from '../utils/findInputError'
import { isFormInvalid } from '../utils/isFormValid'
import { GLOBAL_STYLES } from '../appConstants'
import Creatable from 'react-select/creatable';
import InputError from './InputError'
import { capitalizeFirstLetter } from '../ReusableFunctions/reusableFunctions'

export default function CreatableSelectField({
    name,
    validation,
    label,
    className,
    list,
    isLoading,
    isSearchable = true,
    isDisabled,
    onCreateOption,
    handleChange,
    placeholder,
    defaultValue,
    styles,
    ...restProps
}) {
    const {
        formState: { errors },
        control,
        clearErrors,
        watch,
        setError
    } = useFormContext()

    const [prevValue, setPrevValue] = useState("")

    const selectedValue = watch(name);
    const inputError = findInputError(errors, name)
    const isInvalid = isFormInvalid(inputError)

    const onInputedValueChange = (value) => {
        const transformedValue = value;

        if (transformedValue && validation?.format?.value) {
            if (validation?.format?.value.test(transformedValue)) {
                setPrevValue(transformedValue)
                clearErrors(name);
            }
            else {
                setError(name, {
                    type: 'createbleSelect',
                    message: validation?.format?.message,
                });
            }
        } else {
            clearErrors(name);
            setPrevValue(transformedValue)
        }
    }

    useEffect(() => {
        if (handleChange && selectedValue?.value) {
            handleChange(selectedValue);
        }
    }, [selectedValue?.value]);

    const defaultStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            borderRadius: "5px",
            borderColor: isInvalid ? 'red' : GLOBAL_STYLES.BORDER_COLOR,
            outline: "none",
            fontWeight: 600,
            color: "black",
            minHeight: "35px",
            height: "35px",
            background: "#F3F3F3",
            ...restProps.controlStyle
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            boxShdow: GLOBAL_STYLES.BOX_SHADOW,
            color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : state.isSelected && state.isFocused ? "white" : "black",
            backgroundColor: state.isFocused && !state.isSelected ? "rgba(0, 0, 0, 0.1)" : state.isSelected ? GLOBAL_STYLES.BG_ACTIVE : GLOBAL_STYLES.BG_INACTIVE,
            minHeight: "35px",
            ...restProps.optionStyle
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            ...restProps.valueContainerStyle
        }),
        menu: (provided, state) => ({
            ...provided,
            marginTop: isInvalid ? "25px" : "0px"
        }),
        menuList: (provided, state) => ({
            ...provided,
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            ...restProps.indicatorsContainerStyle
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            ...restProps.indicatorContainerStyle
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            ...restProps.dropdownIndicatorStyle
        }),
        singleValue: (provided, state) => ({
            ...provided,
            ...restProps.singleValueStyle
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 })
    };

    return (

        <div style={{ display: "flex", flexDirection: "column", rowGap: "5px", position: "relative", ...restProps.containerStyle }}>
            {label && (
                <div style={{ fontSize: "13px", color: "#3B3B3B" }}>
                    {capitalizeFirstLetter(label)}
                </div>
            )}
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={validation}
                render={({ field }) => (
                    <Creatable
                        name={name}
                        onCreateOption={onCreateOption}
                        menuPlacement='auto'
                        className={className || ""}
                        classNamePrefix="react-select"
                        options={list}
                        placeholder={placeholder || "Select"}
                        styles={defaultStyles}
                        isLoading={isLoading}
                        inputValue={prevValue}
                        onInputChange={(value) => {
                            const inputedValue = value?.length > validation?.maxLength?.value ?
                                value?.substring(0, validation?.maxLength?.value) :
                                value
                            onInputedValueChange(inputedValue)
                        }}
                        onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            clearErrors();
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: GLOBAL_STYLES.BG_ACTIVE,
                                primary: GLOBAL_STYLES.BG_ACTIVE
                            }
                        })}
                        {...field}
                        isSearchable={isSearchable}
                        isDisabled={isDisabled}
                        {...restProps}
                    />
                )}
            />
            {isInvalid && (
                <InputError
                    inputError={inputError}
                />
            )}
        </div>
    )
}
