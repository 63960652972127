
import Modal from "../../../ReusableComponents/Modal";
import useWindowSize from "../../../hooks/useWindowSize";
import { Button } from "../../../Components/Helper";
import React, { useEffect, useRef, useState } from "react";
import FileUploader from "../../../ReusableComponents/FileUploader";
import InputError from '../../../ReusableComponents/InputError';
import { PostDroneModel, updateDroneModel as updateDroneModels } from '../../../apis/BhuMeetTeams/Oparations/droneStore'
import InputField from "../../../ReusableComponents/InputField";
import { fileValidation, nameValidation, selectFieldValidation } from "../../../utils/inputValidations";
import Form from "../../../ReusableComponents/Form";
import SelectField from "../../../ReusableComponents/SelectField";
import Popup from '../../../ReusableFunctions/Popup';
import ImageUploader from "./ImageUploader";
import { useLocation } from "react-router-dom";
import './AddDroneModel.css'
import MainContentWrapper from "../../../Components/MainContentWrapper";

const AddDroneModel = () => {

    const { currentHeight, currentWidth } = useWindowSize();

    const [isSubmitted, setSubmitted] = useState(false)
    const [isApiCalled, setApiCalled] = useState(false)

    const [images, setImages] = useState([]);

    // const [updatedImage, setdeletedImage] = useState([])

    let catelogIndex = 2
    // Handle file input change
    const handleFileChange = (index, event, type) => {
        // console.log(event, index)
        // index = index -1
        // console.log(index)

        let newImages = [...images];

        if (newImages[index]) {

            newImages[index]['file'] = event // Get the selected file
            newImages[index]['type'] = type
            newImages[index]['status'] = event === '' ? false : true
            newImages[index]['isdeleted'] = false
            setImages(newImages);
        } else {

            newImages.push({ file: event, type: type, isdeleted: false })
            setImages(newImages);

        }
        if (images[images.length - 1].file != '') {
            addImageField()
        }

        // console.log(images)

    };


    // Add new image entry to the array
    const addImageField = () => {
        setImages([...images, { file: '', type: 'Catelogs', isdeleted: false }]);
    };
    const removeImageField = (indexid) => {
        // let img = images.filter((_, index) => index !== indexid)
        // setImages(img);
        // console.log(indexid)
        let newImages = [...images];

        newImages[indexid].isdeleted = true
        newImages[indexid].file = ""
        setImages(newImages)
        // console.log(images)

    };

    let getHistory = useLocation()
    // console.log(getHistory.state)
    let droneproperties = getHistory.state.droneproperties
    let store = getHistory.state.store

    const [droneModel, setdroneModel] = useState({
        // id: pilotId ? pilotId : uuid(),
        droneModel: {
            ManufactureId: getHistory.state.store.id,
            type: droneproperties?.type ?? '',
            name: droneproperties?.name ?? '',
            description: droneproperties?.description ?? '',
        },
        properties: {
            id: droneproperties?.properties ? getHistory.state.droneproperties?.properties?.id : '',
            drone_name: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.drone_name : '',
            drone_model: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.drone_model_id : '',
            max_range: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.max_range : '',
            flight_time: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.flight_time : '',
            maximum_speed: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.maximum_speed : '',
            max_height: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.max_height : '',
            operational_height: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.operational_height : '',
            drone_weight: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.drone_weight : '',
            tank_capacity: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.tank_capacity : '',
            category: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.category : '',
            overall_dimensions: getHistory.state.droneproperties?.properties ? getHistory.state.droneproperties?.properties?.overall_dimensions : '',
            Class: droneproperties?.properties?.class || ''
        }


    })



    useEffect(() => {
        // console.log(getHistory)

        let imagedata = []
        if (droneproperties) {
            imagedata.push({ file: droneproperties.thumbnail, type: 'Thumbnail', status: true })
            let getBrochure = droneproperties.files.filter(x => {
                return x.file_type == 'Brochure'
            })

            if (getBrochure[0]) {
                imagedata.push({ file: getBrochure[0].file_url, type: getBrochure[0].file_type, status: true })

            } else {
                imagedata.push({ file: '', type: 'Brochure', status: false })
            }
            let index = 0
            for (let i of droneproperties.files) {
                if (i.file_url && i.file_type != 'Brochure') {
                    // let generatefile = await loadURLToInputFiled(i.file_url,i.file_url.split('/').pop().split('?')[0],index)
                    // console.log(generatefile[0])

                    imagedata.push({ file: i.file_url, type: i.file_type, status: true })
                }
                index++;
            }
            imagedata.push({ file: '', type: 'Catelogs' })
            setImages(() => imagedata)
            // console.log(images)



            // updatedroneModels('name',"droneproperties.name")


        } else {
            let data = [{ file: '', type: 'Thumbnail', status: false }, { file: '', type: 'Brochure', status: false }]
            setImages(data)
            setdroneModel({
                // id: pilotId ? pilotId : uuid(),
                droneModel: {
                    ManufactureId: store.id,
                    type: '',
                    name: ''
                },
                properties: {
                    drone_name: '',
                    drone_model: '',
                    max_range: '',
                    flight_time: '',
                    maximum_speed: '',
                    max_height: '',
                    operational_height: '',
                    drone_weight: '',
                    tank_capacity: '',
                    category: '',
                    overall_dimensions: '',
                    Class: ''
                }


            })
        }

        
        // console.log(droneproperties)



    }, [])


    const updateProperty = (propertyName, value) => {
        // console.log(value)
        try {
            if(value || value == ''){
                setdroneModel(prevState => ({
                    ...prevState, // Preserve the existing state
                    properties: {
                        ...prevState.properties, // Preserve existing properties
                        [propertyName]: value // Update the specific property
                    }
                }));
            }
            
        } catch (error) {
            console.log(error)
        }
    };

    const updatedroneModels = (propertyName, value) => {
        // console.log(value)
        if(value){
            setdroneModel(prevState => ({
                ...prevState, // Preserve the existing state
                droneModel: {
                    ...prevState.droneModel, // Preserve existing model
                    [propertyName]: value // Update the specific property
                }
            }));
        }
        
    };  


    const submitPostform = async () => {
        setApiCalled(true)
        var formData = new FormData();

        let finalimage = images
        finalimage.forEach((file) => {
            if (file.file) {

                // file.file['Imagetype'] = file.type
                formData.append('file', file.file)
                formData.append('Image_type', file.type)
            }
        })

        formData.append("Data", JSON.stringify(droneModel))
        console.log(droneModel)

        await PostDroneModel(formData).then(res => {
            // console.log(res)
            Popup.alert("Success", res?.message, "SUCCESS");
            window.history.back();
            // onClose()

        }).catch(err => {
            Popup.alert("ERROR", "Something went wrong while Adding New Model", "ERROR");
            setApiCalled(false)
            console.log(err)
        })
    }




    return (

        <MainContentWrapper containerStyle={{overflow:"auto",paddingTop:"19px"}} header={droneproperties ? "Update Drone Model" : "Add Drone Model"}

        >

            <Form
                onSubmitForm={async (pilot) => {
                    console.log(pilot)
                    setSubmitted(true)
                    let isAllfileAdded = true
                    images.forEach((x, index) => {
                        // Skip the last index
                        if (index === images.length - 1) return;

                        if (!x.status) {
                            isAllfileAdded = false;
                            return;
                        }
                    });


                    if (!isAllfileAdded) {
                        return
                    }


                    if (!droneproperties) {
                        await submitPostform()
                    } else {
                        var formData = new FormData();

                        let finalimage = images

                        if (finalimage[0].file.name) {
                            formData.append('file', finalimage[0].file)
                            formData.append('Image_type', 'Thumbnail')
                        }
                        if (finalimage[1].file.name) {
                            formData.append('file', finalimage[1].file)
                            formData.append('Image_type', 'Brochure')
                        }
                        finalimage.slice(2).forEach((file, index) => {
                            if (file.file && file.file.name) {

                                // file.file['Imagetype'] = file.type

                                formData.append('file', file.file)
                                formData.append('Image_type', file.type + Number(index + 1))

                            }

                            if (file.isdeleted) {
                                formData.append('deletedImage', file.file)

                            }
                        })



                        // console.log(droneModel)
                        formData.append("Data", JSON.stringify(droneModel))

                        setApiCalled(true)

                        await updateDroneModels(formData).then(res => {
                            // console.log(res)
                            Popup.alert("Success", res?.message, "SUCCESS");
                            window.history.back();

                            // onClose()

                        }).catch(err => {
                            setApiCalled(false)
                            console.log("Error")

                            return Popup.alert("ERROR", "Something went wrong while updating Model", "ERROR");

                        })
                    }


                }}
            // initialValues={droneModel}
            >
                {(onsubmit) => (
                    <div style={{ display: "flex", "justifyContent": "space-between" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr ",
                                gap: currentHeight < 695 ? "2px" : "5px",
                                
                                padding: currentHeight < 695 ? "0px 20px" : "20px",


                            }}>

                                <InputField
                                    defaultValue={droneModel.droneModel.name}
                                    value={droneModel.droneModel.name}
                                    label={"Model Name"}
                                    type={"text"}
                                    id={"type"}
                                    placeholder={"Name"}
                                    name={"Name"}
                                    validation={{
                                        validate: (value) => {
                                            if (!value) {
                                                return "Required"
                                            } else if (value.length > 30) {
                                                return "Max 30 characters.";
                                            }
                                        },
                                    }


                                    }
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updatedroneModels("name", value)

                                    }}
                                />
                            
                            <SelectField
                                handleChange={(selectedOption) => {
                                    updatedroneModels("type",selectedOption.value)


                                }}
                                name={"Type"}
                                label={"Type"}
                                list={[{ label: "Spraying", value: "Spraying" },{label:"Survey",value:"Survey"}]}
                                styles={{ width: "515px", height: "18px", fontSize: "13px",top:"20px" }}
                                controlStyle={{top:"-5px",width:"89%"}}
                                validation={selectFieldValidation}
                                placeholderText={"Select Type"}
                                defaultValue={droneModel.droneModel.type ? {label:droneModel.droneModel.type,value:droneModel.droneModel.type} : null}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />

                                <InputField
                                    defaultValue={droneModel.droneModel.description}
                                    value={droneModel.droneModel.description}
                                    label={"Description"}
                                    type={"text"}
                                    id={"type"}
                                    placeholder={"description"}
                                    name={"description"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        }
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updatedroneModels("description", value)


                                    }}
                                />

                                <InputField
                                    defaultValue={droneModel.properties.category}
                                    value={droneModel.properties.category}
                                    label={"Category"}
                                    type={"text"}
                                    id={"type"}
                                    placeholder={"Category"}
                                    name={"category"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        }
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updateProperty("category", value)
                                    }}
                                />



                                {/* <div style={{ display: 'flex', justifyContent: 'space-between', gap: "5px", maxWidth: "100%" }}> */}
                                <InputField
                                    defaultValue={droneModel.properties.Class}
                                    value={droneModel.properties.Class}
                                    label={"Class"}
                                    type={"text"}
                                    id={"type"}
                                    placeholder={"Class"}
                                    name={"Class"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        }
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updateProperty("Class", value)
                                    }}
                                />
                                <InputField
                                    defaultValue={droneModel.properties.drone_weight}
                                    value={droneModel.properties.drone_weight}
                                    label={"Drone weight (kg)"}
                                    type={"number"}
                                    id={"type"}
                                    placeholder={"drone weight"}
                                    name={"drone_weight"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        },
                                        pattern: {
                                            value: /^\d{1,4}$/, // Regex to match up to 4-digit numbers separated by "x"
                                            message: 'Max 4 digit allowed',
                                        }
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updateProperty("drone_weight", value)
                                    }}
                                />


                                <InputField
                                    defaultValue={droneModel.properties.flight_time}
                                    value={droneModel.properties.flight_time}
                                    label={"Flight time (mins)"}
                                    type={"number"}
                                    id={"type"}
                                    placeholder={"Flight time"}
                                    name={"flight_time"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        },
                                        pattern: {
                                            value: /^\d{1,4}$/, // Regex to match up to 4-digit numbers separated by "x"
                                            message: 'Max 4 digit allowed',
                                        }
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updateProperty("flight_time", value)
                                    }}
                                />


                                {/* </div> */}


                                {/* <div style={{ display: 'flex', justifyContent: 'space-between', gap: "5px", maxWidth: "100%" }}> */}
                                <InputField
                                    defaultValue={droneModel.properties.max_height}
                                    value={droneModel.properties.max_height}
                                    label={"Max height (ft)"}
                                    type={"number"}
                                    id={"type"}
                                    placeholder={"Max Height"}
                                    name={"max_height"}
                                    validation={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Required',
                                            },
                                            pattern: {
                                                value: /^\d{1,4}$/, // Regex to match up to 4-digit numbers separated by "x"
                                                message: 'Max 4 digit allowed',
                                            }
                                        }
                                    }
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updateProperty("max_height", value)
                                    }}
                                />
                                <InputField
                                    defaultValue={droneModel.properties.max_range}
                                    value={droneModel.properties.max_range}
                                    label={"Max range(km)"}
                                    type={"number"}
                                    id={"type"}
                                    placeholder={"Max range"}
                                    name={"max_range"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        },
                                        pattern: {
                                            value: /^\d{1,4}$/, // Regex to match up to 4-digit numbers separated by "x"
                                            message: 'Max 4 digit allowed',
                                        }
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updateProperty("max_range", value)
                                    }}
                                />

                                <InputField
                                    defaultValue={droneModel.properties.maximum_speed}
                                    value={droneModel.properties.maximum_speed}
                                    label={"Maximum Speed (m/s)"}
                                    type={"number"}
                                    id={"type"}
                                    placeholder={"Maximum Speed"}
                                    name={"maximum_speed"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        },
                                        pattern: {
                                            value: /^\d{1,3}$/, // Regex to match up to 4-digit numbers separated by "x"
                                            message: 'Max 3 digit allowed',
                                        }
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updateProperty("maximum_speed", value)
                                    }}
                                />


                                {/* </div> */}


                                {/* <div style={{ display: 'flex', justifyContent: 'space-between', gap: "5px", maxWidth: "100%" }}> */}
                                <InputField
                                    defaultValue={droneModel.properties.operational_height}
                                    value={droneModel.properties.operational_height}
                                    label={"Operational height (ft)"}
                                    type={"number"}
                                    id={"type"}
                                    placeholder={"Operational height"}
                                    name={"operational_height"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        },
                                        pattern: {
                                            value: /^\d{1,4}$/, // Regex to match up to 4-digit numbers separated by "x"
                                            message: 'Max 4 digit allowed',
                                        },
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updateProperty("operational_height", value)
                                    }}
                                />


                                <InputField
                                    defaultValue={droneModel.properties.overall_dimensions
                                        ?.replace(/ /g, "")
                                        ?.replace(/, /g, "x")
                                        ?.trim()
                                      }
                                      value={droneModel.properties.overall_dimensions
                                        ?.replace(/ /g, "")
                                        ?.replace(/, /g, "x")
                                        ?.trim()
                                      }
                                    label={"Overall dimensions (mm)"}
                                    type={"text"}
                                    id={"type"}
                                    placeholder={"Enter L x B x H"}
                                    name={"overall_dimensions"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        },
                                        pattern: {
                                            value: /^\d{1,4}\s*x\s*\d{1,4}\s*x\s*\d{1,5}$/, // Regex to match up to 4-digit numbers separated by "x"
                                            message: 'Format must be Length x Breadth x Height',
                                        },
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px",
                                    }}
                                    handleChange={(value) => {
                                        // console.log(value);
                                      
                                        const parts = value?.split("x").map((part) => part.trim());
                                      
                                        // Check if the value is in the process of being typed or a complete valid input
                                        if (
                                          parts?.length === 3 && // Ensure there are 3 parts
                                          parts.every((part) => part.length <= 4 && !isNaN(part) && part !== "") // Each part is a number and up to 4 digits
                                        ) {
                                          updateProperty("overall_dimensions", value); // Valid complete input
                                        } else if (
                                          parts?.length <= 3 && // Allow partially typed input
                                          parts.every((part) => part.length <= 4 && (part === "" || !isNaN(part))) // Each part is empty or a valid number
                                        ) {
                                          updateProperty("overall_dimensions", value); // Update for partially typed input
                                        } else {
                                          console.warn("Invalid input format");
                                        }
                                      }}
                                      
                                />


                                <InputField
                                    defaultValue={droneModel.properties.tank_capacity}
                                    value={droneModel.properties.tank_capacity}
                                    label={"Tank capacity (Litres)"}
                                    type={"number"}
                                    id={"tank_capacity"}
                                    placeholder={"Tank capacity"}
                                    name={"tank_capacity"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        }
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "90%",
                                        minHeight: "80px",
                                        rowGap: "0px"
                                    }}
                                    handleChange={(value) => {
                                        updateProperty("tank_capacity", value)
                                    }}
                                />


                                {/* </div> */}






                            </div>
                            <hr />
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', gap: "5px", maxWidth: "100%" }}> */}
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px' }}>
                                {images.map((image, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: '10px', // Adjust this as needed
                                            maxWidth: '100%',
                                            flexDirection: 'column', // Keeps the content in column within each container
                                            width: '297px', // Ensures each item is the same width
                                            marginLeft: "10px"
                                        }}
                                    >
                                        {!image.isdeleted && (
                                            <div>
                                                <div >


                                                    <label>{index === 0
                                                        ? 'Thumbnail'
                                                        : index === 1
                                                            ? 'Brochure'
                                                            : ''}</label>
                                                    {/* {![0, 1].includes(index) && (
                                                    <div
                                                        onClick={() => {
                                                            removeImageField(index);
                                                            setdeletedImage([...deletedImage, image.type]);
                                                        }}
                                                    >
                                                        X
                                                    </div>
                                                )} */}
                                                </div>

                                                {[0, 1].includes(index) && (
                                                    <>
                                                        <ImageUploader
                                                            type={index === 0
                                                                ? 'Thumbnail'
                                                                : index === 1
                                                                    ? 'Brochure'
                                                                    : ''}
                                                            showDelete={false}
                                                            uploaderId={`logo-${index}`}
                                                            FileUrl={image.file}
                                                            key={index}
                                                            onfileselect={(value) => {
                                                                handleFileChange(
                                                                    index,
                                                                    value,
                                                                    index === 0 ? 'Thumbnail' : index === 1 ? 'Brochure' : ''
                                                                );
                                                            }}
                                                        />
                                                        {images[index].status === false && isSubmitted && (
                                                            <InputError inputError={{ error: { message: 'File Required' } }} />
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <hr />
                        <div style={{ "height": "100%", maxHeight: "500px", "width": "50%" }}>
                            <label style={{ "marginLeft": "10px" }}>Catalogs</label>

                            <div >
                                <div style={{ flex: 1, "height": "100%", maxHeight: "505px", "width": "100%", "overflowY": "auto" }}>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(3, 1fr)', gap: '0px'
                                    }}>
                                        {images.map((image, index) => (

                                            <>
                                                {/* {JSON.stringify(images[catelogIndex+index] ? 'found' : "not found")} */}
                                                {/* {JSON.stringify(images[catelogIndex+index]) != '' ??  */}

                                                {(images[catelogIndex + index] && !images[catelogIndex + index]?.isdeleted) ? (
                                                    <>
                                                        <div key={catelogIndex + index} style={{ width: '30%', margin: '10px' }}>
                                                            {/* {catelogIndex + index} */}
                                                            <ImageUploader
                                                                fileId={catelogIndex + index}
                                                                OnCancel={(e) => { removeImageField(e) }}
                                                                uploaderId={`logo-${catelogIndex + index}`}
                                                                FileUrl={images[catelogIndex + index]?.file}
                                                                onfileselect={(value) => {
                                                                    handleFileChange(catelogIndex + index, value, catelogIndex + index === 0 ? 'Thumbnail' : catelogIndex + index === 1 ? 'Brochure' : 'Catelog');
                                                                }}
                                                            />
                                                            {image.status === false && isSubmitted && (
                                                                <InputError inputError={{ error: { message: 'File Required' } }} />
                                                            )}
                                                        </div>




                                                    </>
                                                ) : (null)}


                                            </>


                                        ))}
                                    </div>
                                </div>
                                {/* <div style={{ width: '200px', paddingLeft: '20px' }}>
                                <button onClick={()=>{console.log("Testing")}}>Add Image Uploader</button>
                            </div> */}
                            </div>
                        </div>








                        <div style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "20px",
                            padding: currentHeight < 695 ? "10px" : "20px",
                            height: "70px",
                            justifyContent: "center"
                        }}>

                            {/* <Button style={{ width: "fit-content" }}
                                text={"Add Another Image"}
                                onClick={addImageField}
                                className={"btn-large"}
                            /> */}

                        </div>





                        <div style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "20px",
                            padding: currentHeight < 695 ? "10px" : "20px",
                            height: "70px",
                            marginLeft: "77%",
                            marginTop: "33%",
                            position: "fixed"
                        }}>
                            <Button
                                isDisabled={isApiCalled}
                                text={droneproperties ? isApiCalled ? "Updating..." : "Update" :isApiCalled ? "Adding..." :"Add"}
                                onClick={onsubmit}
                                className={"btn-large"}
                            />
                            {/* <Button
                                className={"btn-large lg-cancel-btn"}
                                text={"Cancel"}
                                onClick={onClose}
                                isBtnNotActive={true}
                            /> */}
                        </div>
                    </div>
                )}
            </Form>

        </MainContentWrapper>


    )
}

export default AddDroneModel