import { useLocation } from "react-router"
import bhumeet from "../../assets/bhumeet.svg"
import { useEffect, useState } from "react"
import Loading from "../../ReusableFunctions/Loader"
import Popup from "../../ReusableFunctions/Popup"
import dateFormat from 'dateformat';
import {  } from "../../appConstants"
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import dashLine from "../../assets/dashLine.svg"
import { getDspSettlementInvoiceDetails } from "../../apis/adminAPIs/finance/dspInvoices"
import { formatToIndianRupees, squareKilometersToAcres } from "../../ReusableFunctions/reusableFunctions"
import { DSP_INVOICES_TYPES,INECNTIVE_INVOICES_STATUS } from "../../appConstants"
import { getBesInvoiceDetails } from "../../apis/adminAPIs/BesInvoices"
import BesSettlementForm from "./BesSettlementInvoiceForm"

const BesIncentiveInvoice = () => {

    const { dsp, invoice } = useLocation().state
    const [settInvoice, setSettInvoice] = useState({})
    const [openSettlemetForm, setOpenSettlementForm] = useState(false)
    const [isDownload, setIsDownload] = useState(false)
    const [serviceInvoices, setServiceInvoices] = useState([])


    const getSettelementInvoiceDetailsFunc = async () => {
        try {
            Loading.set(true)
            const data = await getBesInvoiceDetails(invoice?.id);
            setSettInvoice({
                ...data,
                invoice_id: invoice?.id,
                generated_time: new Date(data?.generated_time),
                to_bank_account_name: data?.bes_bank_account?.bank_name,
                to_bank_account_ifsc: data?.bes_bank_account?.ifsc_code,
                to_bank_account_number: data?.bes_bank_account?.account_number,
                to_bank_account_holder_name: data?.bes_bank_account?.account_holder_name,
            });
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            Popup.alert("Error", error, "ERROR")
        }
    }

    const wait = async () => {
        return new Promise((resolve) => {
            setTimeout(resolve, 0)
        })
    }

    const downloadPDF = async () => {
        setIsDownload(true);
        Loading.set(true)
        await wait(); // Assuming wait() is some delay or other asynchronous task
        const input = document.querySelector('.myDiv');

        html2canvas(input, {
            scale: 1.5,
            useCORS: true,
            backgroundColor: null,
        }).then(canvas => {
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width; // Proportional image height
            let heightLeft = imgHeight;

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
                compress:true,
            });

            let position = 0; // Position on the first page
            let pageCount = 1;
            pdf.setFontSize(10);

            // Add the first image
            const imgData = canvas.toDataURL('image/png', 0.8); // 80% quality

            pdf.addImage(
                imgData,
                'PNG',
                0,
                position,
                imgWidth,
                imgHeight
            );
            heightLeft -= pageHeight; // Subtract page height from image height
            pdf.text(`Page ${pageCount}`, 100, 290);

            // Add new pages if there's more content
            while (heightLeft > 0) {
                position = heightLeft - imgHeight; // Update position for next page
                pdf.addPage(); // Add new page
                pdf.addImage(
                  imgData,
                    'PNG',
                    0,
                    position,
                    imgWidth,
                    imgHeight
                );
                pageCount++
                pdf.text(`Page ${pageCount}`, 100, 290);
                heightLeft -= pageHeight; // Subtract again for the next page
            }

            pdf.save('invoice.pdf');
            setIsDownload(false);
            Loading.set(false);
        });
    };

    useEffect(() => {
        getSettelementInvoiceDetailsFunc()
    }, [])


    return (
        <div style={{ overflow: "auto", position: "relative", display: "flex", flexDirection: "column", alignItems: "center", gap: '20px' }}>
            {openSettlemetForm &&
                <BesSettlementForm
                    open={openSettlemetForm}
                    onClose={() => setOpenSettlementForm(false)}
                    invoice={settInvoice}
                    onUpdate={(invoice) => {
                        setSettInvoice(invoice)
                        setOpenSettlementForm(false)
                    }}
                />
            }
            {isDownload ? (
                <div style={{
                    position: 'relative',
                    top: '10px',
                    right: '-240px',
                    fontSize: '14px',
                }}>
                    Downloading...
                </div>
            ) : (
                <a
                    onClick={downloadPDF}
                    style={{
                        position: 'relative',
                        top: '10px',
                        right: '-240px',
                        textDecoration: 'underline',
                        color: '#007BFF',
                        cursor: 'pointer',
                        fontSize: '14px',
                    }}
                >
                    Download Invoice
                </a>
            )}
            <div className='myDiv' style={{ width: "700px" }}>
                <div style={{
                    width: '100%',
                    border: isDownload ? 'none' : '1px solid rgba(215, 218, 224, 0.4)',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    color: "#3B3B3B",
                    backgroundColor: '#fff'
                }}>

                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            margin: '0px 30px',
                            padding: '20px 0px',
                            borderBottom: '1px solid rgba(215, 218, 224, 0.3)',
                        }}>
                            <div>
                                <img src={bhumeet} alt="bhumeet" width={"100px"} />
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <p style={{ margin: '0', fontSize: "13px", }}><span style={{ fontWeight: 600, color: "#1A1C21" }}>Invoice No.</span> - {settInvoice?.invoice_number || "-"}</p>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: "#727272",
                            padding: "20px 30px"
                        }}>
                            <div>
                                <div style={{ marginBottom: '20px' }}>
                                    <p style={{ margin: '0', fontWeight: 500 }}>To</p>
                                    <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{settInvoice?.to?.name}</p>
                                    <p style={{ margin: '0' }}>{settInvoice?.to?.address}</p>
                                    <p style={{ margin: '0' }}>{settInvoice?.to?.gstin}</p>
                                </div>
                                <div>
                                    <p style={{ margin: '0', fontWeight: 500 }}>From</p>
                                    <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{settInvoice?.from?.name || "-"}</p>
                                    <p style={{ margin: '0' }}>{settInvoice?.from?.address || "-"}</p>
                                    <p style={{ margin: '0' }}>{settInvoice?.from?.gstin}</p>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                            paddingTop: '10px',
                            margin: "10px 30px 30px"
                        }}>
                            <p style={{ margin: '0', fontSize: "14px" }}><span style={{ fontWeight: 600, color: "#5E6470" }}>Invoice Date - </span><span style={{ color: "#5E6470" }}>{dateFormat(settInvoice?.generated_time, "dd mmm yyyy")}</span></p>
                        </div>

                        <div style={{
                            backgroundColor: '#D9D9D9',
                            padding: '10px 25px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontWeight: 700,
                            color: '#1E1E1E',
                            height: "50px",
                            alignItems: "center",
                            fontSize: "18px",
                        }}>
                            <span>Payment Summary</span>
                            <span>Rates</span>
                        </div>

                        <div style={{ padding: '10px 30px 10px' }}>
                            <div style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <div className="flex gap-[0.5px] flex-col" >
                                    <span>Service Charges</span>
                                    <span style={{fontSize: '12px',color: "#848484"}}>
                                        {settInvoice.from_date && settInvoice.from_date ?`(${dateFormat(settInvoice?.from_date,"dd/mm/yyyy ")} to ${dateFormat(settInvoice.to_date, "dd/mm/yyyy ")})`
                                        :null}
                                        </span>
                                    </div>
                                    <span>₹ {formatToIndianRupees(settInvoice?.incentive_amount || 0)}</span>
                                </div>
                                {settInvoice?.descriptions?.service_amount &&
                                    <div style={{ fontSize: '12px', color: "#848484" }}>({settInvoice?.descriptions?.service_amount})</div>
                                }
                            </div> 
                            {Number(settInvoice?.gst_amount)<=0?null:
                            <div style={{ marginTop: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <span>GST{Number(settInvoice.gst_percentage) > 0 &&
                                            <span style={{
                                                padding: '0 2px',
                                                borderRadius: '3px',
                                                fontSize: '12px',
                                                color: "#848484"
                                            }}>
                                                ({settInvoice.gst_percentage}%)
                                            </span>
                                        }
                                    </span>
                                    <span style={{}}>+ ₹ {formatToIndianRupees(settInvoice?.gst_amount || 0)}</span>
                                </div>
                            </div>}
                            
                            <img src={dashLine} alt="dashLine" style={{ maxWidth: "103%" }} />
                            {Number(settInvoice?.gst_amount)>0?
                            <div style={{ marginTop: '5px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                     <span>SubTotal</span>
                                    <span style={{}}> ₹ {formatToIndianRupees(settInvoice?.incentive_amount_with_gst || 0)}</span>
                                </div>
                            </div> : <></>}
                            {Number(settInvoice?.tds_amount)<=0?null:
                            <div style={{ marginTop: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <span>TDS{settInvoice.tds_percentage >0 &&
                                            <span style={{
                                                padding: '2px 5px',
                                                borderRadius: '3px',
                                                fontSize: '12px',
                                                color: "#848484"
                                            }}>
                                                ({settInvoice.tds_percentage}%)
                                            </span>
                                        }
                                    </span>
                                    <span style={{}}>- ₹ {formatToIndianRupees(settInvoice?.tds_amount || 0)}</span>
                                </div>
                            </div>}
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: '10px',
                                marginTop: '10px',
                                marginBottom: '30px',
                                fontWeight: 'bold',
                                borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                                borderBottom: '1px solid rgba(215, 218, 224, 0.6)',
                                paddingBottom: '10px',
                                fontSize: "18px",
                            }}>
                                <span>Total Amount</span>
                                <span>₹ {formatToIndianRupees(settInvoice?.total_amount || 0)}</span>
                            </div>
                        </div>
                        <div style={{minHeight:isDownload?"250px":"",marginBottom:isDownload?"100px":""}}></div>
                    </div>
                    {
                        <div style={{ padding: '10px 30px 20px', display: isDownload ? "none" : 'block' }}>
                            {settInvoice?.status === INECNTIVE_INVOICES_STATUS.UNPAID &&
                                <button
                                    className="settledButton"
                                    style={{
                                        backgroundColor: '#0f5eff',
                                        color: 'white',
                                        border: 'none',
                                        padding: '10px 30px',
                                        borderRadius: '5px',
                                        fontSize: '16px',
                                        cursor: settInvoice?.status === INECNTIVE_INVOICES_STATUS.PAID ? 'not-allowed' : 'pointer',
                                        width: '100%',
                                        opacity: settInvoice?.status === INECNTIVE_INVOICES_STATUS.PAID ? 0.5 : 1
                                    }}
                                    onClick={settInvoice?.status === INECNTIVE_INVOICES_STATUS.PAID ? () => { } : () => {
                                        if (!(settInvoice?.to_bank_account_number && settInvoice?.to_bank_account_holder_name && settInvoice?.to_bank_account_ifsc && settInvoice?.to_bank_account_name)) {
                                            Popup.alert("Error", "The BES has not added their bank account yet. You can settle this invoice once they do.", "ERROR");
                                            return;
                                        }
                                        setOpenSettlementForm(true)
                                    }}
                                >
                                    Mark as Paid
                                </button>
                            }
                        </div>
                    }

            
                  </div>
                <div>
                {/* <div style={{
                    width: '620px',
                    border: '1px solid rgba(215, 218, 224, 0.4)',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    color: "#3B3B3B",
                    backgroundColor: '#fff',
                    margin: "40px 0px 10px 0px ",
                   
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px',
                        fontWeight: 'bold',
                        fontSize: "18px",
                        alignItems: "center",
                    }}>
                        <span>Total Service Charges</span>
                        <span>₹ {formatToIndianRupees(settInvoice?.incentive_amount || 0)}</span>
                    </div>
                </div> */}

                    <div style={{padding:"0px 10px "}} >
                
                        <div style={{
                            width: '100%',
                            marginTop: "40px",
                            border: '1px solid rgba(215, 218, 224, 0.4)',
                            borderRadius: '5px',
                            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                            color: "#3B3B3B",
                            backgroundColor: '#fff'
                        }}>
                            <div className={`reusable-table-containar scroll-container`} style={{ marginBottom: "20px" }}>
                                <div
                                    style={{
                                        fontWeight: "500",
                                        display: "flex",
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <table className={`reusable-table-view`}>
                                        <thead style={{ width: "100%" }}>
                                            <tr className="reusable-table-view-header-row" style={{ height:'45px', display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                <th style={{ textAlign: "left", width: "45%", display: "inline-block", fontSize: isDownload ? "11px" : '12px', }}>Note</th>
                                                <th style={{ textAlign: "left", width: "25%", display: "inline-block", fontSize: isDownload ? "11px" : '12px', }}>Date</th>
                                                <th style={{ textAlign: "center", width: "15%", display: "inline-block", fontSize: isDownload ? "11px" : '12px', }}>Charges (₹)</th>
                                                <th style={{ textAlign: "center", width: "15%", display: "inline-block", fontSize: isDownload ? "11px" : '12px', }}>Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {settInvoice?.incentive_transactions?.map((sI, index) => {
                                                return (
                                                    <>
                                                        <tr key={index} className="reusable-table-view-data-row" style={{ height:'55px', display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} align="center">
                                                            <td style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                height: "100%",
                                                                border: "none",
                                                                width: "45%"
                                                            }}>
                                                                {sI?.note}
                                                            </td>
                                                            <td style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                height: "100%",
                                                                border: "none",
                                                                width: "25%"
                                                            }}>
                                                                 {dateFormat(sI?.time, "dd mmm yyyy  HH:MM:ss TT")}
                                                            </td>
                                                            <td style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "100%",
                                                                border: "none",
                                                                width: "15%"
                                                            }}>
                                                               {Number(sI?.amount)}
                                                            </td>
                                                            <td style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "100%",
                                                                border: "none",
                                                                width: "15%"
                                                            }}>
                                                               {sI?.type === "Credit" ? "Credit" : "Debit"}
                                                            </td>
                                                        </tr>
                                                        <hr></hr>
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
              
                <div style={{
                    width: '100%',
                    border: '1px solid rgba(215, 218, 224, 0.4)',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    color: "#3B3B3B",
                    backgroundColor: '#fff',
                    margin: "40px 0px 10px 0px ",
                   
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px',
                        fontWeight: 'bold',
                        fontSize: "18px",
                        alignItems: "center",
                    }}>
                        <span>Total Service Charges</span>
                        <span>₹ {formatToIndianRupees(settInvoice?.total_amount || 0)}</span>
                    </div>
                </div>

                </div>

                </div>
                <div  style={{
                  border: isDownload ? 'none' : '1px solid rgba(215, 218, 224, 0.4)',
                  borderRadius: '5px',
                  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                  color: "#3B3B3B",
                  marginBottom:"10px",
                  backgroundColor: '#fff',
                }}>
           
            </div>
            </div >
        </div>
    )
}

export default BesIncentiveInvoice;