import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "../../apis/users";
import Popup from "../../ReusableFunctions/Popup";
import Loading from "../../ReusableFunctions/Loader";
import { reduceLongName } from "../../ReusableFunctions/reusableFunctions";
import { getRequests, getRequestsCount } from "../../apis/requests";
import RequestTable from "../../features/MyRequests/SubComponents/RequestTable/RequestTable";
import UserLogo from "../../assets/userlogo.png"
import Tippy from "@tippyjs/react";
import CustomTooltip from "../../ReusableComponents/CustomTooltip";
import MainContentWrapper from "../MainContentWrapper";
import loactionRed from "../../assets/loactionRed.svg";
import icard from "../../assets/icard.svg";
import email from "../../assets/email.svg";
import mobile from "../../assets/mobile.svg";
import hat from "../../assets/hat.svg";
import map from "../../assets/map.svg";
import dGreen from "../../assets/dGreen.svg";
import UserDetailsWrapper from "./UserDetailsWrapper";
import SingleInfo from "./SingleInfo";
import dateFormat from "dateformat";

const UserDetails = () => {
  const { state } = useLocation();
  const { search } = useLocation();
  const dataPerPage = 8
  const params = new URLSearchParams(search)
  const userId = params.get('userId') || state?.userId
  const [user, setUser] = useState(null);
  const [requests, setRequests] = useState(null);
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(()=>{
    const savedPage = localStorage.getItem('userRequestsCurrentPage')
    if (savedPage) {
      return parseInt(savedPage)
    }
    return 1
  })
  const [filterDateTime, setFilterDateTime] = useState(() => {
    const savedFilterDateTime = localStorage.getItem("filterDateTime");
    if (savedFilterDateTime) {
      try {
        return JSON.parse(savedFilterDateTime);
      } catch {
        return { fromDate: undefined, toDate: undefined };
      }
    }
    return { fromDate: undefined, toDate: undefined };
  });
  const [requestsCount, setRequestsCount] = useState({})
  const [screenWidth, setScreenWidth] = useState(1366)
  const [filters, setFilters] = useState(()=>{
    const savedFilters = localStorage.getItem('requestFilters')
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters)
      return {
          ...parsedFilters,
          statuses:parsedFilters.statuses,
          locations: parsedFilters.locations,
          services: parsedFilters.services,
      }
  }
  return {
      services: [],
      statuses: [],
      locations: [],
  }
  });
  const [searchQuery, setSearchQuery] = useState("")

  const getRequestsCountFunc = () => {
    getRequestsCount(userId)
      .then((res) => {
        const { spraying_requests_count, training_requests_count, survey_requests_count, total } = res.requestsCount
        setRequestsCount({
          spraying: spraying_requests_count,
          training: training_requests_count,
          survey: survey_requests_count,
          totalRequest: total
        });
      })
      .catch((err) => {
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  }


  const getRequestsFunc = (pageNumber, searchQuery, filters) => {
    setLoading(true)
    getRequests(
      state?.userId, 
      filters, 
      dataPerPage, 
      pageNumber, 
      searchQuery,
      filterDateTime && filterDateTime.fromDate
      ? new Date(filterDateTime.fromDate).toISOString()
      : undefined,
    filterDateTime && filterDateTime.toDate
      ? new Date(filterDateTime.toDate).toISOString()
      : undefined)
      .then((res) => {
        setRequests(res.requests);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };

  const clearLocalStorageOfRequests = () => {
    localStorage.removeItem("requestFilters");
    localStorage.removeItem("userRequestsCurrentPage");
  };

  useEffect(() => {
    setLoading(true)
    Loading.set(true);
    document.title = "BhuMeet - My Customers";
    clearLocalStorageOfRequests()
    const screenWidth = window.innerWidth;
    setScreenWidth(screenWidth)
    if (!user)
      if (userId) {
        getUserDetails(userId)
          .then((user) => {
            setUser(user);
            getRequestsCountFunc()
            Loading.set(false);
          })
          .catch((e) => {
            Popup.alert("Error", e, "ERROR");
            Loading.set(false);
          }).finally(() => {
            Loading.set(false);
            setLoading(false);
          });

      }
  }, []);

  const filterChanged = () => {
     getRequestsFunc(currentPage, searchQuery, filters);
  };

  useEffect(() => {
    filterChanged()
    localStorage.setItem("requestFilters", JSON.stringify(filters))
    localStorage.setItem("userRequestsCurrentPage", JSON.stringify(currentPage))
    localStorage.setItem("filterDateTime", JSON.stringify(filterDateTime));
}, [filters,currentPage,searchQuery,filterDateTime])

  return user && (
    <MainContentWrapper header={"Customer Details"}>
      <div style={{ width: "100%", height: "calc(100% - 56px)", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", marginBottom: "15px", alignItems: "center", gap: screenWidth == 1920 ? "40px" : '28px', width: "100%" }}>
          <div style={{ width: "50%" }}>
            <UserDetailsWrapper>
              <div style={{ display: "flex", flexDirection: "column", height: '100%', justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center", gap: screenWidth == 1920 ? "25px" : "18px" }}>
                  <img className="user-details-first-wrapper-avtar" style={{maxWidth:"20%"}} src={user.name ? `https://ui-avatars.com/api/?background=727272&color=fff&name=${user.name.split(" ").join("+")}` : UserLogo} alt="profile pic" />
                  <div style={{ height: "65px", display: "flex", justifyContent: "center", flexDirection: "column", gap: '3px' }}>
                    <div style={{ fontSize: screenWidth == "1920" ? "24px" : "17px", color: "#0845C0", fontWeight: "600" }}>
                      {
                        user.name ?
                          <Tippy allowHTML={true} content={<CustomTooltip content={user.name} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                            <span > {user.name.length > 18 ? reduceLongName(user.name, 18) : user.name}</span>
                          </Tippy>
                          : <span > {"-"}</span>
                      }
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: 'center', justifyContent: "left", width: '100%', gap: screenWidth == 1920 ? "100px" : "35px" }}>
                  <SingleInfo icon={mobile} title={"Mobile No:"} titleStyle={{ fontSize: "11px" }} infoStyle={{ fontSize: "11px" }} iconStyle={{ height: screenWidth == 1920 ? "auto" : "30px" }} info={user.mobile_number || "-"} />
                  {/* <SingleInfo icon={email} title={"Email Id:"} titleStyle={{ fontSize: "11px" }} infoStyle={{ fontSize: "11px" }} iconStyle={{ height: screenWidth == 1920 ? "auto" : "30px" }} info={user.email || "-"} /> */}
                  <SingleInfo icon={icard} title={"Occupation:"} titleStyle={{ fontSize: "11px" }} infoStyle={{ fontSize: "11px" }} iconStyle={{ height: screenWidth == 1920 ? "auto" : "30px" }} info={user.occupation ?
                    <Tippy allowHTML={true} content={<CustomTooltip content={user.occupation} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                      <span > {user.occupation.length > 30 ? reduceLongName(user.occupation, 30) : user.occupation}</span>
                    </Tippy>
                    : <span > {"-"}</span>}
                  />
                </div>
              </div>
            </UserDetailsWrapper>
          </div>
          <div style={{ width: "50%" }}>
            <UserDetailsWrapper>
              <div style={{ display: "flex", flexDirection: "column", height: '100%', justifyContent: "space-between" }}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: '3px' }}>
                  <div style={{ fontSize: "16px", color: "#3B3B3B", fontWeight: "500" }}>
                    {`${user?.name}'s total orders (${requestsCount?.totalRequest || 0})`}
                  </div>
                  <p style={{ fontSize: '12px', color: "#848484", }}>
                    {`(Since ${dateFormat(user?.date_created, 'dd mmm yyyy')})`}
                  </p>
                </div>
                <div style={{ display: "flex", justifyContent: "left", alignItems: 'center', width: '100%', gap: screenWidth == 1920 ? "100px" : "30px" }}>
                  <SingleInfo icon={dGreen} iconStyle={{ height: screenWidth == 1920 ? "auto" : "40px" }} title={"Spraying"} info={requestsCount?.spraying} titleStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#848484", fontWeight: "600" }} infoStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#1E1E1E", fontWeight: "500" }} />
                  <SingleInfo icon={map} iconStyle={{ height: screenWidth == 1920 ? "auto" : "40px" }} title={"Survey"} info={requestsCount?.survey} titleStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#848484", fontWeight: "600" }} infoStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#1E1E1E", fontWeight: "500" }} />
                  {/* <SingleInfo icon={hat} iconStyle={{ height: screenWidth == 1920 ? "auto" : "40px" }} title={"Training"} info={requestsCount?.training} titleStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#848484", fontWeight: "600" }} infoStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#1E1E1E", fontWeight: "500" }} /> */}
                </div>
              </div>
            </UserDetailsWrapper>
          </div>
        </div>
        <RequestTable
          requests={requests}
          onFilterChange={(requestFilters,dateTime) => {
            setFilterDateTime(dateTime)
            setCurrentPage(1);
            setFilters(requestFilters)
          }}
          loading={loading}
          setLoading={() => {
            Loading.set(false);
          }}
          dateTime={filterDateTime}
          pageNumber={currentPage}
          totalCount={requests ? requests[0]?.total_count : 0}
          onCurrentPageChange={(pageNumber) => {
            setCurrentPage(pageNumber)
          }}
          pageSize={dataPerPage}
          isFromUserDetails={true}
          onSearch={(searchQuery) => {
            setSearchQuery(searchQuery)
          }}
          hidePagination={false}
        />
      </div>
    </MainContentWrapper>)
};

export { UserDetails };
