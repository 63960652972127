import React, { useEffect, useState } from 'react'
import Popup from '../../../ReusableFunctions/Popup';
import Loading from '../../../ReusableFunctions/Loader';
import { getDspLocations } from '../../../apis/BhuMeetTeams/Oparations/locations';
import NotFounds from '../../../ReusableComponents/NotFounds';
import LocationCard from './LocationCard';
import Pagination from '../../../ReusableComponents/Pagination';

export default function Locations({
    dsp
}) {
    const [locations, setLocations] = useState([]);
    const [filterLocations, setFilterLocations] = useState();
    const [openAddLocationPopup, setOpenAddLocationPopup] = useState(false);
   const [filters, setFilters] = useState({
    dspId:dsp?dsp.dsp_id:undefined,
    pageNumber:1,
    pageSize:15,
   })

    const getLocationsFunc=async ()=>{

        try{
           Loading.set(true);
            const locations =await getDspLocations(filters);
            setLocations(locations);
            setFilterLocations(locations);
            Loading.set(false);
        }catch(err){
            Loading.set(false);
            Popup.alert("ERROR","Something went wrong while getting the dsp locations ","ERROR")
            console.error(err);
        }
    }

  const onUpdateLocation = (location) => {
    setLocations(() => locations.map((l) => (l.id === location.id ? location : l)));
      setFilterLocations(() => locations.map((l) => (l.id === location.id ? location : l)));
  };

    useEffect(()=>{
        getLocationsFunc();
    },[filters])

  return (
  <div style={{
    display:"flex",
    flexDirection:"column",
    height:"100%",
    overflowY:"auto",
    padding:"20px 40px 5px 40px",
  }} className='op_locations_container' >

        {filterLocations && filterLocations.length > 0 ?
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill,325px)",
              gridTemplateRows: "repeat(auto-fill,335px)",
              gap: "20px 16px",
              overflowY: "auto",
              height: "100%",
            }}
          >
            {filterLocations.map((location) => (
              <LocationCard
                isEditDisabled={false}
                key={location.id}
                location={location}
                onUpdateLocation={onUpdateLocation}
              />
            ))}
           
          </div>
            <Pagination
                className="pagination-bar pagination-right"
                currentPage={filters.pageNumber}
                totalCount={locations[0]?.locations_count || 0}
                pageSize={filters.pageSize}
                onPageChange={(page) => {
                    let updatedFilters={...filters,pageNumber:page}
                    setFilters(updatedFilters)
                }}
            />
        </>
          
          : filterLocations && filterLocations.length === 0 && <NotFounds msg={"OOPS,No Locations Found!"} hideBtn={true} onBtnClick={() => setOpenAddLocationPopup(true)} />}
       </div>  
  )
}
