import React, { useState } from 'react'
import Modal from '../../../ReusableComponents/Modal'
import { Button, CrossCloseButton } from '../../../Components/Helper'
import { GLOBAL_STYLES, REWARD_ORDERS_STATUSES } from '../../../appConstants'
import { getStatusColor } from '../../../ReusableFunctions/reusableFunctions';
import SelectComponent from '../../../ReusableComponents/SelectComponent';
import { updateRewardOrderDetails } from '../../../apis/BhuMeetTeams/Oparations/RewardOrders'
import { updateCustRewardOrderDetails } from '../../../apis/BhuMeetTeams/CustomerSuccessEngineer/rewardsOrders'
import Loading from '../../../ReusableFunctions/Loader';
import Popup from '../../../ReusableFunctions/Popup';
import { useLocation } from 'react-router';
import { PDRL_SUCCESS_ENGI_ROUTES } from '../../../routes';
export default function DroneEnquiriesDetails({
    open, onClose, order, callback,
}) {
    

    return (
        <Modal
            open={open}
            onClose={onClose}
            contentStyle={{ height: "auto", width: "820px", borderRadius: "10px", overflow: "hidden" }}>
            <div style={{ height: "100%", background: "rgb(244, 244, 244)", padding: "0px 0px 20px 0px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", background: "#D9D9D9D9", padding: "20px" }}>

                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <div style={{ fontSize: "14px" }}>Enquiry </div>
                    </div>
                    <CrossCloseButton onClick={onClose} />
                </div>
                <div style={{ overflowY: "auto", height: "calc(100% - 50px)", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "20px" }}>
                    <div style={{ fontWeight: 600, margin: "10px 0px" }}>Enquiry Detail</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Full Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.full_name || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Contact Number</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.contact_number || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Drone Model</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.drone_model || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Drone Type</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.drone_type || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Manufacturer Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.manufacture_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Comment</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.comment || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Date Created</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{new Date(order?.date_added).toDateString() || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Enquiry by role</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.enquiry_by_user_role || "-"}</div>
                        </div>

                    </div>

                </div>

            </div>
        </Modal>
    )
}
