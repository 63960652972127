import React from 'react'
import { Navigate } from "react-router-dom"
import { useSelector } from 'react-redux';

const ProtectedRoute = (props) => {
    const user = useSelector(state => state.user?.user)
    console.log(user)
    if (!user){
        console.log('user not ofund')
        return <Navigate to={"/login"} />
    }
    return user ?
        props.children :
        <Navigate to={"/notFound"} />

}

export default ProtectedRoute;