import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

const getTelecallerDashboardOverViewData = async (id,Filter) => {
    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/telecaller/overview?userId=${id}&Filters=${Filter}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res;
    } catch (err) {
        throw err;
    }
}


const getTelecallingHistory = async (id) => {
    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/telecaller/callHistory?requestID=${id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res.CallHistory;
    } catch (err) {
        throw err;
    }
}


export {getTelecallerDashboardOverViewData, getTelecallingHistory}