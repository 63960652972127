import React, { useState } from "react";
import { GLOBAL_STYLES } from "../appConstants";
import Cross from "../assets/cross.svg";
import { reduceLongName } from "../ReusableFunctions/reusableFunctions";
import Popup from "../ReusableFunctions/Popup";

const FileUploader = ({ uploaderId, onFileSelect, placeholder, onClear, accept, fileInputRef,selectedFileName }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const allowedTypes = accept || "";
    const file = e.target.files[0];

    if (file.type === "") {
      Popup.alert("Invalid File Type", `Please upload valid file!`, "ERROR")
      e.target.value = ''
      return;
    }
    if (allowedTypes.includes(file.type)) {
      setSelectedFile(file);

      if (onFileSelect) {
        onFileSelect(file);
        const el =
          document.getElementById("fname") || document.getElementById("name");
        if (el) el.focus();
      }
    } else {
      Popup.alert("Invalid File Type", `Please upload ${accept.split('/')[1]} file!`, "ERROR")
      setSelectedFile(null)
    }
    e.target.value = ''
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
          borderRadius: "5px",
          overflow: "hidden",
          padding: "5px 10px",
          alignItems: "center",
          background: "",
          fontSize: "12px",
          height: '35px'
        }}
      >
        {selectedFile || selectedFileName ? (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span style={{ color: selectedFile ? "" : "#1E1E1E80" }}>
              {reduceLongName(selectedFile?.name || selectedFileName, 20)}
            </span>
            <img
              src={Cross}
              style={{ width: "10px", cursor: "pointer" }}
              onClick={() => {
                setSelectedFile(null);
                onClear();
              }}
            />
          </div>
        ) : (
          <div>
            <span style={{ color: "#1E1E1E80" }}>{placeholder}</span>
          </div>
        )}
        <input
          ref={fileInputRef || null}
          type="file"
          id={uploaderId || 'uploader_id'}
          style={{ display: "none" }}
          accept={accept}
          onChange={handleFileChange}
        />
      </div>
      <label
        htmlFor={uploaderId || 'uploader_id'}
        style={{
          textDecoration: "underline",
          color: GLOBAL_STYLES.BG_ACTIVE,
          cursor: "pointer",
          width: "50px",
          fontSize: "12px",
        }}
      >
        Browse
      </label>
    </div>
  );
};

export default FileUploader;
