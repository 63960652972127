import React, { useEffect, useState } from 'react';
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import Modal from '../../ReusableComponents/Modal';
import { Button } from '../../Components/Helper';
import Popup from '../../ReusableFunctions/Popup';
import { sendTextNotifications } from '../../apis/BhuMeetTeams/Marketing/notifications';
import Form from '../../ReusableComponents/Form';
import InputField from '../../ReusableComponents/InputField';
import { templateIdValidation } from '../../utils/inputValidations';
import { GLOBAL_STYLES } from '../../appConstants';


const SendTextMessage = () => {
    const [isSendTextPopupOpened, setIsSendTextPopupOpened] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [templateInfo, settemplateInfo] = useState({
        id:"",
    })
    
    const sendTextMessageNotifications = async () => {
        try {
            setIsSending(true)
            const sendMessageStats = await sendTextNotifications(templateInfo?.id)
            settemplateInfo({
                id:"",
            })
            setIsSending(false)
            setIsSendTextPopupOpened(false)
            Popup.alert("Text notifications sent successfully.", `Succesfully sent : ${sendMessageStats.successfullySentCount}, Unseccesful to sent : ${sendMessageStats.unSuccessfullCount}`, "SUCCESS")
        }
        catch (e) {
            setIsSending(false)
            Popup.alert("Error while sending text notifications.", e, "ERROR")
        }
    }

    return (
        <>
            <Modal
                onClose={() => {
                    settemplateInfo({
                        id:""
                    })
                    setIsSendTextPopupOpened(false)
                }}
                open={isSendTextPopupOpened}
                header={"Send Text message notification"}
            >
                <Form initialValues={templateInfo}  onSubmitForm={() => {
                   sendTextMessageNotifications()
                }}
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: 'calc(100% - 75px)', padding: "30px" }} >
                    {(onsubmit) => (
                 <div className='w-full flex flex-col h-full justify-between '>
                     <InputField
                    label={"Template Id"}
                    width={"100%"}
                    height={"35px"}
                    name={"id"}
                    value={templateInfo?.id}
                    validation={templateIdValidation}
                    handleChange={(value)=>{
                    settemplateInfo((prev)=>({
                     ...prev,
                     id:value,
                    }))
                    }}
                />
                <div className='w-full flex justify-end gap-4 '>
                <Button 
                style={{
                    width:"90px",
                    height:"30px",
                    background:"transparent",
                    border:"1px solid gray",
                    color:GLOBAL_STYLES.BG_ACTIVE,
                    fontSize:"13px"
                }}
                text={"Cancel"}
                type={"button"}
                isDisabled={isSending}
                onClick={()=>{
                    settemplateInfo({
                        id:"",
                    })
                    setIsSendTextPopupOpened(false);
                }} 
                />
                <Button 
                style={{width:"90px",height:"30px",fontSize:"13px"}}
                 text={"Send" + (isSending ? "ing..." : "")}
                 type={"submit"} 
                 isDisabled={isSending}
                 onClick={onsubmit} 
                />
             </div>
           </div>)}
        </Form>
            </Modal>
            <DashBoardCard
                loading={false}
                onClick={() => setIsSendTextPopupOpened(true)}
                title="Text notifications"
                icon={""}
            />
        </>
    );
};

export default SendTextMessage;