import React, { useEffect, useState } from "react";
import { getDashBoardData } from "../../apis/dashboard";
import DashBoardCard from "./SubComponents/DashBoardCard/DashBoardCard";
import Popup from "../../ReusableFunctions/Popup";
import { addCommasToAmount, addLeadingZero, getDSPId, reduceLongName } from "../../ReusableFunctions/reusableFunctions";
import Orders from "./SubComponents/Graphs/Orders";
import ResourceUtilization from "./SubComponents/Graphs/ResourceUtilization";
import { ROUTES } from "../../routes";
import TotalRequestStatus from "../../Components/TotalRequestStatus";
import RecentRequests from "../../Components/RecentRequests";
import joyStickCardIcon from "../../assets/joyStickCardIcon.svg"
import droneCardIcon from "../../assets/droneCardIcon.svg"
import customersCardIcon from "../../assets/customersCardIcon.svg"
import teamsCardIcon from "../../assets/teamsCardIcon.svg"
import locationCardIcon from "../../assets/locationCardIcon.svg"
import serviceCardIcon from "../../assets/serviceIcon.svg"
import joyStickCardIconActive from "../../assets/joyStickCardIconActive.svg"
import droneCardIconActive from "../../assets/droneCardIconActive.svg"
import customersCardIconActive from "../../assets/customersCardIconActive.svg"
import teamsCardIconActive from "../../assets/teamsCardIconActive.svg"
import locationCardIconActive from "../../assets/locationCardIconActive.svg"
import serviceCardIconActive from "../../assets/serviceHover.svg"
import arrowGrp from "../../assets/arrowGrp.svg"
import thickBar1 from "../../assets/thickBar1.svg"
import DashboardMainCard from "../../ReusableComponents/DashboardMainCard";
import dateFormat from "dateformat";
import { STATUS } from "../../appConstants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const HomePage = () => {
  const user = useSelector(state => state.user.user)
  const [dashBoardData, setDashBoardData] = useState([]);
  const [dashboardDataLoading, setDashBoardDataLoading] = useState(true)
  const { pathname } = useLocation();
  let requestsStats = {
    [STATUS.PLACED]: 0,
    [STATUS.ACCEPTED]: 0,
    [STATUS.RESCHEDULED]: 0,
    [STATUS.OUT_FOR_SERVICE]: 0,
    [STATUS.INPROGRESS]: 0,
    [STATUS.COMPLETED]: 0,
    [STATUS.PAID]: 0,
    [STATUS.CANCELLED]: 0,
    total: 0
  }

  const getDashBoardDataAPI = () => {
    setDashBoardDataLoading(true)

    getDashBoardData()
      .then((res) => {
        setDashBoardData(res);
        setDashBoardDataLoading(false);
      })
      .catch((err) => {
        Popup.alert("Error", err, "ERROR");
        setDashBoardDataLoading(false);
      });
  };

  useEffect(() => {
    document.title = "BhuMeet - HomePage";
    const dsp_id = getDSPId()
    if (dsp_id !== "admin") {
      getDashBoardDataAPI();
    }
  }, []);

  const clearLocalStorageOfRequests = () => {
    localStorage.removeItem("requestFilters");
    localStorage.removeItem("currentRequestPage");
    localStorage.removeItem("fromDateTime");
    localStorage.removeItem("dspDebitNotesFilters");
    localStorage.removeItem("dspInvoicesFilters");
    localStorage.removeItem("isCalenderView");
    localStorage.removeItem("userPageFilters");
    localStorage.removeItem("currentUserPage");
    localStorage.removeItem("isCalenderView");
    localStorage.removeItem("filterDateTime");
    localStorage.removeItem("dspCustomersPage");  
    localStorage.removeItem("salesStoresFilters");
    localStorage.removeItem("adminStoresFilters");
  };

  useEffect(() => {
    if (pathname === '/') {
      clearLocalStorageOfRequests()
    }
  }, [pathname])

  return (<>
    <div className="dashBoardWrapper">
      <div className="dashboard-heading">
        <div className="dashboard-company-name">{reduceLongName(user?.dsp?.name, 20)}</div>
        <div className="dashboard-wellcome-text">{user && user.name ? `Hi, ${reduceLongName(user?.dsp?.name, 20)} Welcome back!` : ''}</div>
      </div>

      {/*----- 1st section ----------*/}
      <div className="dashboard-first-section" >
        <div className="dashboard-first-section-first-div" >
          <div style={{ width: "50%", position: "releative" }}>
            <DashboardMainCard
              view={ROUTES.REVENUE}
              height="100%"
              loading={dashboardDataLoading}
              title="Revenue"
              mainData={<div><span style={{ color: "blue" }}> ₹ </span> {dashBoardData && dashBoardData.totalRevenue ? addCommasToAmount(dashBoardData.totalRevenue) : '00'}</div>}
              subData={`Till ${dateFormat(new Date(), 'dd mmmm yyyy')}`}
              animatedElement={<img className="card-arrow" src={arrowGrp} />}
              graphData={dashBoardData.lastSevenDaysRevenueGraphData}
            />
          </div>
          <div style={{ width: "50%", position: "releative" }}>
            <DashboardMainCard
              view={ROUTES.MYREQUESTS}
              loading={dashboardDataLoading}
              height="100%"
              title="Requests"
              mainData={<div>{dashBoardData && dashBoardData.totalRequestCount ? addCommasToAmount(dashBoardData.totalRequestCount) : "00"}</div>}
              subData={`Till ${dateFormat(new Date(), 'dd mmmm yyyy')}`}
              animatedElement={<img
                className="card-arrow"
                style={{ paddingLeft: "5px", }}
                src={thickBar1}
              />}
              graphData={dashBoardData.lastSevenDaysRequestGraphData}
            />
          </div>
        </div>

        <div className="dashboard-first-section-second-div" >
          <div className="dashboard-first-section-second-div-one" >
            <DashBoardCard
              loading={dashboardDataLoading}
              view={ROUTES.DRONE_PILOTS}
              title="Pilots"
              data={dashBoardData && dashBoardData.pilots ? addLeadingZero(dashBoardData.pilots) : "00"}
              icon={joyStickCardIcon}
              hoverIcon={joyStickCardIconActive}
            />
            <DashBoardCard
              loading={dashboardDataLoading}
              view={ROUTES.MYDRONE}
              title="Drones"
              data={dashBoardData && dashBoardData.drones ? addLeadingZero(dashBoardData.drones) : "00"}
              icon={droneCardIcon}
              hoverIcon={droneCardIconActive}
            />
            <DashBoardCard
              loading={dashboardDataLoading}
              view={ROUTES.CUSTOMERS}
              title="Customers"
              data={dashBoardData && dashBoardData.users ? addLeadingZero(dashBoardData.users) : "00"}
              icon={customersCardIcon}
              hoverIcon={customersCardIconActive}
            />
          </div>
          <div className="dashboard-first-section-second-div-two" >
            <DashBoardCard
              loading={dashboardDataLoading}
              view={ROUTES.MYSERVICES}
              title="Total Services"
              data={dashBoardData && dashBoardData.services ? addLeadingZero(dashBoardData.services) : "00"}
              icon={serviceCardIcon}
              hoverIcon={serviceCardIconActive}
            />
            <DashBoardCard
              loading={dashboardDataLoading}
              view={"manageLocations"}
              title="Locations"
              data={dashBoardData && dashBoardData.locations ? addLeadingZero(dashBoardData.locations) : "00"}
              icon={locationCardIcon}
              hoverIcon={locationCardIconActive}
            />
            <DashBoardCard
              loading={dashboardDataLoading}
              view={ROUTES.MANAGE_TEAM}
              title="My Team"
              data={dashBoardData && dashBoardData.totalTeamMembers ? addLeadingZero(dashBoardData.totalTeamMembers) : "00"}
              icon={teamsCardIcon}
              hoverIcon={teamsCardIconActive}
              isDisabled={!(user?.is_admin)}
            />
          </div>
        </div>
      </div >
      {/*------ 1st section End -----*/}


      {/* ----- 2nd section --------- */}
      <div className="dashboard-second-section" >
        <div className="dashboard-second-section-first-div">

          <div style={{ height: "100%", width: "100%", }}>
            <div className="dashboard-cards-heading" >Total Request Status</div>
            {<div style={{
              height: "88%",
              marginTop: "21px",
              width: "100%",
              position: "relative"
            }}>
              <TotalRequestStatus
                isLoading={dashboardDataLoading}
                totalRequestCount={dashBoardData.totalRequestCount}
                requestStatusData={{ ...requestsStats, ...dashBoardData.statusWiseRequestsCount }}
              />
            </div>
            }
          </div>


        </div>
        <div style={{ width: "50%", borderRadius: "5px", background: "white" }} >
          <div style={{ display: "flex", width: "100%", alignItems: "", height: "100%", justifyContent: "start" }}>
            <Orders showAll filter width="100%" />
            {/* <ResourceUtilization /> */}
          </div>
        </div>
      </div>
      {/* ----- 2nd section End--------- */}


      {/* ----- 3rd section --------- */}
      <div className="dashboard-third-section" >
        <div style={{ width: "50%", borderRadius: "7px", background: "white", height: "100%", }}>
          <RecentRequests user={user} />
        </div>
        <div style={{ width: "50%", background: "white", borderRadius: "7px" }}>
          <div style={{ width: "100%", height: "100%" }}><ResourceUtilization width={"100%"} /></div>
        </div>
      </div>
      {/* ----- 3rd section end --------- */}
    </div >
  </>);
};

export default HomePage;
