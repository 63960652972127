import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper';
import ViewDSP from '../DroneServiceProviders/SubComponents/ViewDSP';
import { Button } from '../../Components/Helper';
import Plus from "../../assets/Plus.svg";
import { deleteTeamMember, getTeams } from '../../apis/adminAPIs/teams';
import Loading from '../../ReusableFunctions/Loader';
import Popup from '../../ReusableFunctions/Popup';
import TableView from '../../ReusableComponents/TableView/TableView';
import AddTeamMember from './AddTeamMember';

export default function ManageAdminTeam() {
    const [openOperatorPopup, setopenOperatorPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [operators, setOperators] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState();
    const [teamsCount, setTeamsCount] = useState(0);
    const [pageNumber, setpageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(15);

    const getTeamMembersFunc = async () => {
        try {
            const teamMembers = await getTeams(pageNumber, pageSize);
            setOperators(teamMembers);
            setTeamsCount(Number(teamMembers[0]?.team_members_count))
            setLoading(false);
            Loading.set(false);
        } catch (err) {
            setLoading(false);
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }


    const deleteTeamMemberfunc = async (operator) => {
        Loading.set(true)
        try {
            await deleteTeamMember(operator?.id)
            setOperators(prev => prev.filter(o => o.id !== operator.id))
            Popup.alert("Operator deleted", "Operator deleted successfully", "SUCCESS")
            Loading.set(false)
        } catch (err) {
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const editTeamMember = (operator) => {
        setSelectedOperator(operator)
        setopenOperatorPopup(true)
    }

    const handleOperatorAdd = (operator) => {
        if (selectedOperator?.id) {
            const updatedOperator = operators.map((o) => {
                if (o.id === selectedOperator?.id) {
                    return operator;
                }
                else {
                    return o
                }
            })
            setOperators(updatedOperator)
        }
        else {
            setOperators((prev) => ([
                operator,
                ...prev
            ]))
        }
    }


    useEffect(() => {
        Loading.set(true);
        getTeamMembersFunc();
    }, [pageNumber])

    return (
        <MainContentWrapper
            header={`Manage Teams`}
            rightContent={<Button type="submit" icon={Plus} text={"Add New"} onClick={() => {
                setopenOperatorPopup(true)
                setSelectedOperator(null);
            }} />}
        >

            {
                openOperatorPopup &&
                <AddTeamMember
                    open={openOperatorPopup}
                    onClose={() => {setopenOperatorPopup(false)}}
                    onOperatorAdd={(operator) => {
                        handleOperatorAdd(operator)
                        setopenOperatorPopup(false)
                        getTeamMembersFunc()
                    }}
                    operator={selectedOperator || null}
                />
            }

            <TableView
                isLoading={loading}
                tableContainerHeight={"calc(100% - 65px)"}
                showSrNo
                onClickLink={(request) => { }}
                columns={[
                    { Header: "Name", accessor: "name", width: "10%" },
                    { Header: "Email", accessor: "email", width: "10%" },
                    { Header: "Role", accessor: "bhumeet_role", width: "10%" },
                ]}
                count={teamsCount}
                onStateChange={(state) => {
                    setpageNumber(state.pageNumber)
                }}
                pageNumber={pageNumber}
                pageSize={pageSize}
                data={operators}
                onClickRow={(state)=>{
                    // console.log(state)
                }}
                activities={{
                    onClickDelete: (operator) => {
                        if(operator.bhumeet_role == "Telecaller"){
                            Popup.alert("WARNING","You cannot delete Telecaller role user","ERROR",() => {
                                // console.log(operator)
                                // deleteTeamMemberfunc(operator)
                            },null,'Ok',null)
                        }else{
                            Popup.alert("WARNING", "Are you sure , you want to delete this operator?",
                                "WARNING",
                                () => {
                                    // console.log(operator)
                                    deleteTeamMemberfunc(operator)
                                }
                            )
                        }
                        
                    }
                    , onClickEdit: editTeamMember, canEdit: true
                }}
            />
        </MainContentWrapper>
    )
}
