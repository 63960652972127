import React, { useEffect, useState } from 'react'
import { login } from '../../apis/authentication';
import Loading from '../../ReusableFunctions/Loader';
import Popup from '../../ReusableFunctions/Popup';
import { useNavigate } from 'react-router';
import { Button } from '../../Components/Helper';
import Plus from "../../assets/Plus.svg";
import NotFounds from '../../ReusableComponents/NotFounds';
import { deleteDSPDrone, deleteDroneUAS, getAllDSPDroneUAS, getDSPDroneUAS, getDSPDrones } from '../../apis/drones';
import AddDSPDrone from './subComponents/AddDSPDrone';
import { ROUTES } from '../../routes';
import DroneComponent from './subComponents/DroneComponent';
import MainContentWrapper from '../../Components/MainContentWrapper';
import ModelComponent from './subComponents/ModelComponent';
import AddDSPDroneModel from './subComponents/AddDSPDroneModel';
import { clearProfileFilters } from '../../ReusableFunctions/reusableFunctions';

const MyDrones = () => {
    const navigate = useNavigate();

    const [user, setUser] = useState();
    const [drones, setDrones] = useState();
    const [droneModels, setDroneModels] = useState([])
    const [openDronePopup, setOpenDronePopup] = useState(false)
    const [openModelPopup, setOpenModelPopup] = useState(false);
    const [view, setView] = useState("drones");
    const [droneId, setDroneId] = useState();
    const [modelId, setmodelId] = useState(null);

    const handleOnDroneAdd = (drone) => {
        if (droneId) {
            const updatedDrone = drones.map((d) => {
                if (d.id === droneId) {
                    return drone;
                }
                else {
                    return d
                }
            })
            setDrones(updatedDrone)
        }
        else {
            setDrones((prev) => ([
                drone,
                ...prev
            ]))
        }
    }
    const deleteDrone = (droneId) => {
        Popup.alert("Delete", "Are you sure, you want to delete this drone?", "DELETE", () => {
            deleteDSPDrone(droneId)
                .then((res) => {
                    const afterDeleteDrone = drones.filter(dn => dn.id !== droneId)
                    setDrones(afterDeleteDrone)
                    Popup.alert("Drone", res.message, "SUCCESS", () => { })
                })
                .catch((e) => {
                    console.log(e)
                    Popup.alert("Error", e, "ERROR", () => { })
                })
        });
    }

    const handleOnModelAdd = (model) => {
        if (modelId) {
            const updatedDrone = droneModels.map((m) => {
                if (m.id === modelId) {
                    return model;
                }
                else {
                    return m
                }
            })
            setDroneModels(updatedDrone)
        }
        else {
            setDroneModels((prev) => ([
                model,
                ...prev
            ]))
        }
    }

    const deleteModel = (modelId) => {
        Popup.alert("Delete", "Are you sure, you want to delete this model?", "DELETE", () => {
            deleteDroneUAS(modelId)
                .then((res) => {
                    const afterdeleteModel = droneModels.filter(dn => dn.id !== modelId)
                    setDroneModels(afterdeleteModel);
                    Popup.alert("Model", res.message, "SUCCESS", () => { })
                })
                .catch((e) => {
                    console.log(e)
                    Popup.alert("Error", e, "ERROR", () => { })
                })
        });
    }

    useEffect(() => {
        Loading.set(true);
        document.title = "BhuMeet - HomePage";
        clearProfileFilters();
        window.history.replaceState(null, null, "/");
        login()
            .then((res) => {
                const user = res.user;
                setUser(user);
                if (user) {
                    if (user?.dsp && !user?.dsp?.is_verified) {
                        navigate(ROUTES.PROFILE);
                    }
                }
            })
            .catch((err) => {
                Loading.set(false);
                console.log(err);
                Popup.alert("Error", err, "ERROR");
            });
    }, []);
    useEffect(() => {
        Loading.set(true);
        if (view === "drones") {
            getDSPDrones()
                .then((drones) => {
                    setDrones(drones)
                    Loading.set(false);
                })
                .catch((err) => {
                    Loading.set(false);
                    console.log(err);
                    Popup.alert("Error", err, "ERROR");
                })
        } else if (view === "models") {
            getAllDSPDroneUAS().then((res) => {
                Loading.set(false);
                setDroneModels(res);
            }).catch((err) => {
                Loading.set(false);
                console.log(err);
                Popup.alert("Error", err, "ERROR");
            })
        }
    }, [view])
    return (
        <>

            {
                openDronePopup &&
                <AddDSPDrone
                    open={openDronePopup}
                    onClose={() => setOpenDronePopup(false)}
                    onDroneAdd={(drone) => {
                        handleOnDroneAdd(drone)
                        setOpenDronePopup(false)
                    }}
                    droneId={droneId || null}
                />
            }
            {
                openModelPopup &&
                <AddDSPDroneModel
                    open={openModelPopup}
                    onClose={() => setOpenModelPopup(false)}
                    onModelAdd={(model) => {
                        handleOnModelAdd(model)
                        setOpenModelPopup(false)
                    }}
                    modelId={modelId || null}
                />
            }
            {
                view === "drones" ?

                    <MainContentWrapper header={`My Drones(${drones?.length > 0 ? drones?.length : 0})`} rightContent={
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <Button type="submit" text={"Manage Models"} onClick={() => {
                                setView("models")
                            }} style={{ padding: "4px, 24px, 4px, 24px", height: "35px", width: "130px", fontSize: "14px", fontWeight: 500 }} />
                            {drones && drones?.length > 0 && (
                                <Button type="submit" icon={Plus} text={"Add New"} onClick={() => {
                                    setDroneId(null)
                                    setOpenDronePopup(true)
                                }} style={{ padding: "4px, 24px, 4px, 24px", height: "35px", width: "120px", fontSize: "14px", fontWeight: 500 }} />
                            )}


                        </div>

                    }>

                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {drones && drones?.length > 0 ? (
                                <div style={{ overflow: "auto" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill,370px)", rowGap: "35px", columnGap: "40px" }}>
                                        {drones.map((drone) => {
                                            return (
                                                <DroneComponent
                                                    drone={drone}
                                                    onDroneEdit={(droneId) => {
                                                        setDroneId(droneId)
                                                        setOpenDronePopup(true)
                                                    }}
                                                    onDroneDelete={(droneId) => {
                                                        deleteDrone(droneId)
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : (
                                drones && drones.length === 0 && (
                                    <NotFounds msg={"OOPS, No Drones Found!"} onBtnClick={() => {
                                        setDroneId(null)
                                        setOpenDronePopup(true)
                                    }} />
                                )
                            )}
                        </div>
                    </MainContentWrapper>
                    : <MainContentWrapper header={`Drone Models (${droneModels?.length})`} rightContent={
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <Button type="submit" text={"Manage Drones"} onClick={() => {
                                setView("drones")
                            }} style={{ padding: "4px, 24px, 4px, 24px", height: "35px", width: "130px", fontSize: "14px", fontWeight: 500 }} />
                            {droneModels && droneModels?.length > 0 && (
                                <Button type="submit" icon={Plus} text={"Add New"} onClick={() => {
                                    setmodelId(null)
                                    setOpenModelPopup(true)
                                }} style={{ padding: "4px, 24px, 4px, 24px", height: "35px", width: "120px", fontSize: "14px", fontWeight: 500 }} />
                            )}

                        </div>
                    }>

                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {droneModels && droneModels?.length > 0 ? (
                                <div style={{ overflow: "auto" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill,370px)", rowGap: "35px", columnGap: "40px" }}>
                                        {droneModels.map((model) => {
                                            return (
                                                <ModelComponent
                                                    model={model}
                                                    onModelEdit={(modelId) => {
                                                        setmodelId(modelId)
                                                        setOpenModelPopup(true)
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : (
                                droneModels && droneModels.length === 0 && (
                                    <NotFounds msg={"OOPS, No Models Found!"} onBtnClick={() => {
                                        setmodelId(null)
                                        setOpenModelPopup(true)
                                    }} />
                                )
                            )}
                        </div>
                    </MainContentWrapper>
            }

        </>
    )
}

export default MyDrones