import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ReactComponent from "../../../assets/pdf-svgrepo-com.svg"
import Popup from "../../../ReusableFunctions/Popup";


const ImageUploader = ({ onfileselect, FileUrl, uploaderId, fileId, OnCancel, showDelete, type }) => {
  const [selectedImage, setSelectedImage] = useState(null);


  useEffect(() => {
    // console.log(showDelete);

    if (FileUrl) {
      // console.log(FileUrl)
      setSelectedImage(FileUrl)

    }
  }, [])

  const handleImageChange = (event) => {

    const file = event.target.files[0];
    // console.log(file)
    if (file) {
      const maxSize =10* 1024 * 1024; // 5MB in bytes
      if(file.size > maxSize){
        return Popup.alert("ERROR","Max File Size Allowed is 10MB","ERROR")
      }
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);

      if (onfileselect) {
        onfileselect(file);
      }
    }
  };

  return (
    <Box
      sx={{
        width: 170,
        height: 170,
        border: "2px dashed #ccc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        cursor: "pointer",
        overflow: "hidden",
      }}
      onClick={() => document.getElementById(uploaderId).click()}
    >
      {selectedImage ? (
        <>
          {type == 'Brochure' ?
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#f5f5f5",
              }}
            >
              <img
                src={ReactComponent}
                alt="PDF Preview"
                style={{
                  width: "80px",
                  height: "80px",
                }}
              />
            </Box> :

            <Box
              component="img"
              src={selectedImage}
              alt="Preview"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />

          }


          {showDelete ??
            <Box
              sx={{
                position: "absolute",
                top: 5,
                left: 5,
                cursor: "pointer",
                color: "#fff",
                background: "#000",
                borderRadius: "50%",
                padding: "4px",
                fontSize: "8px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the file input from being triggered
                setSelectedImage(null); // Clear the selected image
                OnCancel(fileId)
              }}
            >
              X
            </Box>
          }

        </>
      ) : (
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          +
        </Typography>
      )}
      <input
        id={uploaderId ?? "imageInput"}
        type="file"
        accept={type == 'Brochure' ? "application/pdf" : "image/*"}
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
    </Box>

  );
};

export default ImageUploader;
