import TableView from "../../ReusableComponents/TableView/TableView"
import { getTelecallingHistory } from "../../apis/BhuMeetTeams/Telecaller/dashboard";
import { useState,useEffect } from "react";
import { AddcallHistory } from "./createCallHistory";
import Popup from "../../ReusableFunctions/Popup";
import { set } from "lodash";

export const CallHistoryTable = ({id,campid,onClose,callhistoryData,lastRecord})=>{
    const [callHistory, setcallHistory] = useState([]);
    const [openEditform,setOpenEditForm] = useState(false)
    const [selectedCallHistory,setSelected] = useState(null)
    const [lastRecordStatus,setlastRecord] = useState(lastRecord)

    const [campids,setCampid] = useState(campid)
    
    useEffect(()=>{
        console.log(lastRecordStatus)
        getAllCallingHistory()
    },[])

    


    const getAllCallingHistory = async () => {
        // console.log(campids)
        getTelecallingHistory(id).then(res => {
            // console.log(res)
            callhistoryData(res)
            if(campids != null){
                if(res.length >= 2){
                    setlastRecord(true)
                }
                let findcampid = res.filter((e)=>e.campid == campids.sid_id)
                console.log(findcampid)
                findcampid[0]['status'] = campids.callstatus == 'ANSWER' ? 'Completed' : campids.callstatus == 'cancel-Agent' ? 'No Response' : campids.callstatus == 'cancel-Customer' ? "No Response" : campids.callstatus.split(" ").join("").toLowerCase() == 'noanswer' ? "No Response" : 'No Response'
                if(findcampid[0].note == 'NA'){
                    setOpenEditForm(!openEditform)
                    setSelected(findcampid[0])
                }
            }
            setcallHistory(res)
        })
    }



    return (
        <>
        {openEditform && selectedCallHistory && (
            <>
        <AddcallHistory 
        lastRecord = {lastRecordStatus}
        data={selectedCallHistory}
        open={openEditform}
        onClose={()=>{setOpenEditForm(!openEditform);onClose()}}
        isEdit={true}

        />
        </>
        )
        }
        
        <TableView marginBottom={"15px"}
                    showSrNo
                    loadingHeight={"500px"}
                    isLoading={false}
                    
                    columns={[
                        { Header: "Call By", accessor: "callbyname", width: "9%" },
                        { Header:"Request Note", accessor:"requestnote",width:"20%"},
                        { Header:"Call Duration", accessor:"time",width:"20%"},
                        { Header: "Note", accessor: "note", width: "9%" },
                        { Header: "Status", accessor: "status", width: "9%" },

                        { Header: "Date", accessor: "dateadded", isDate: true, format: "dd/mm/yyyy", width: "10%" },
                    ]}
                    count={0}
                    pageSize={15}
                    pageNumber={1}
                    data={callHistory}
                    tableContainerHeight={"400px"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 1 }}
                    activities={{  onClickEdit: (e)=>{
                        console.log(e)
                        if((e.reqstatus == 'open' || e.note == 'NA') || e.status == 'Completed'){
                            setOpenEditForm(!openEditform)
                            setSelected(e)

                        }else{
                            Popup.alert("ERROR","This History is already Created , You cannot edit","ERROR")
                        }
                        

                    }, canEdit: false }}

                />
                </>
    )

}