import React from 'react'
import SearchIcon from "../assets/search.svg"
const Search = ({ searchValue, onChange, width, height, placeholder }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", background: 'white', width: "350px" || width, padding: "5px 10px", borderRadius: "5px", gap: "8px", height: height }}>
            <img src={SearchIcon} style={{ width: "12px" }} />
            <input
                style={{ width: "100%", background: "transparent", fontSize: "11px", outline: "none" }}
                type="text"
                value={searchValue}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder || 'Search here...'}
            />
        </div>
    )
}

export default Search