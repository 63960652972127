import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getRequestQueue = async (filters, id, pageNumber, pageSize) => {
    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/telecaller/requestQueue?OperatorID=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&Filters=${filters}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();


        if (!response.ok) {
            throw (res.message);
        }
        return res.RequestQueue;
    } catch (err) {
        throw err;
    }
}


export const getCallreport = async () => {
    try {


        const formdata = new FormData();
        formdata.append("authcode", "6c2e0404bffc99ddb41f641b561c2205");

        const response = await fetch(`https://app.callerdesk.io/api/call_list_v2`,
            {
                method: "POST",
                body: formdata
            }
        );
        const res = await response.json();
        console.log(res)

        if (!response.ok) {
            throw (res);
        }
        return res.result;
    } catch (err) {
        throw err;
    }
}


export const reShuffleRequestQueue = async () => {
    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/telecaller/requestQueue/reshuffle`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res.getAlluser;
    } catch (err) {
        throw err;
    }
}
