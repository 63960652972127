import { SERVER_URLS } from "../appConstants";
import { buildUrl } from "../ReusableFunctions/reusableFunctions";
import { getToken } from "./authentication";

export const getAllCoupons = async (pageSize, pageNumber, searchString, type, from_date, to_date, is_public) => {
    try {
        const token = getToken();
        const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/dsp/coupons`, {
            search: searchString ? searchString : undefined,
            pageSize: pageSize && pageNumber ? pageSize : undefined,
            pageNo: pageSize && pageNumber ? pageNumber : undefined,
            type: type ? type : undefined,
            from_date: from_date ? from_date : undefined,
            to_date: to_date ? to_date : undefined,
            is_public: is_public,
        });
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token} `,
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting coupons");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while getting coupons");
    }
}

export const getAllCouponsCount = async (searchString) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/dsp/coupons/count?${searchString ? `search=${searchString}` : ""}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token} `,
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting coupons");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while getting coupons");
    }
}

export const getAllAdminCoupons = async (pageSize, pageNumber, searchString,condition="*",CreatedBy) => {
    try {
        const token = getToken();
        const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/aggregator/coupons`, {
            search: searchString ? searchString : undefined,  // Include if searchString is not empty
            pageSize: pageSize && pageNumber ? pageSize : undefined,
            pageNo: pageSize && pageNumber ? pageNumber : undefined,
            condition : condition,
            CreatedBy : CreatedBy == 'System' ? true : false
        });
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token} `,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting coupons");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while getting coupons");
    }
}


export const getAllAdminCouponsStat = async (pageSize, pageNumber, searchString,CreatedBy) => {
    try {
        const token = getToken();
        const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/aggregator/coupons/stat`, {
            search: searchString ? searchString : undefined,  // Include if searchString is not empty
            pageSize: pageSize && pageNumber ? pageSize : undefined,
            pageNo: pageSize && pageNumber ? pageNumber : undefined,
            CreatedBy : CreatedBy == 'System' ? true : false
        });
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token} `,
                },
            }
        );
        console.log(response)
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting coupons");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while getting coupons");
    }
}


export const getAllAdminCouponsCount = async (searchString) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/aggregator/coupons/count?${searchString ? `search=${searchString}` : ""}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token} `,
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting coupons");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while getting coupons");
    }
}
export const createCoupon = async (coupon) => {
    console.log('coupin in create:', coupon)
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + "/dsp/coupons",
            {
                method: "POST",
                body: JSON.stringify(coupon),
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while creating coupon");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while creating coupon");
    }
}

export const createAdminCoupon = async (coupon) => {
    console.log("coupon in crwte:", coupon)
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + "/aggregator/coupons",
            {
                method: "POST",
                body: JSON.stringify(coupon),
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while creating coupon");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while creating coupon");
    }
}
export const getCoupon = async (couponId) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + "/dsp/coupons/" + `${couponId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting coupon");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while getting coupon");
    }
}


export const getAdminCoupon = async (couponId) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + "/aggregator/coupons/" + `${couponId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting coupon");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while getting coupon");
    }
}

export const updateCoupon = async (coupon) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/dsp/coupons`,
            {
                method: "PUT",
                body: JSON.stringify(coupon),
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while updating coupon");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while updating coupon");
    }
}
export const updateAdminCoupon = async (coupon) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/aggregator/coupons/`,
            {
                method: "PUT",
                body: JSON.stringify(coupon),
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while updating coupon");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while updating coupon");
    }
}



export const deleteCoupon = async (couponId) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + "/dsp/coupons/" + `${couponId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while deleting coupon");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while deleting coupon");
    }
}
export const deleteAdminCoupon = async (couponId) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + "/aggregator/coupons/" + `${couponId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while deleting coupon");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while deleting coupon");
    }
}