import React, { useState } from 'react'
import { isEmailValid, validatePanNumber } from '../../../../../ReusableFunctions/reusableFunctions'
import { updateUserData } from '../../../../../apis/users'
import Loading from '../../../../../ReusableFunctions/Loader'
import Popup from 'reactjs-popup'
import { Button, CrossCloseButton } from '../../../../../Components/Helper'
import { GLOBAL_STYLES } from '../../../../../appConstants'
import Modal from '../../../../../ReusableComponents/Modal'
import Input from '../../../../../ReusableComponents/Input'
import _debounce from 'lodash/debounce';
import { checkDSExistWithSamePAN } from '../../../../../apis/operator'

const EditPAN = ({ user, open, onClose, onUserUpdate }) => {
    let oldPAN = user.pan_number
    const [userInfo, setUserInfo] = useState(user)
    const [checkPanNumber, setCheckPanNumber] = useState(false);
    const [error, setError] = useState({
        pan_number: '',
        existError: ''
    });
    const onChangeUserInfo = (e) => {
        const name = e.target.name
        const value = e.target.value
        checkIsValid(name, value)
        setUserInfo((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    const checkDSPAlreadyExist = _debounce(async (pan_number) => {
        try {
            const res = await checkDSExistWithSamePAN(pan_number);
            setError((prevErrors) => ({
                ...prevErrors,
                existError: '',
            }));
            setCheckPanNumber(false);
        } catch (error) {
            console.error("Error in checkDSPExist:", error);
            setCheckPanNumber(false);
            setError((prevErrors) => ({
                ...prevErrors,
                existError: "Pan number already exists.",
            }));
        }
    }, 500)
    const checkIsValid = (name, value) => {
        setError((prevErrors) => ({ ...prevErrors, [name]: '' }));

        switch (name) {
            case 'pan_number':
                if (!validatePanNumber(value)) {
                    setError((prevErrors) => ({
                        ...prevErrors,
                        pan_number: 'Please enter a valid pan number',
                    }));
                    return false;
                }
                else {
                    setCheckPanNumber(true);
                    checkDSPAlreadyExist(value);
                }
                break;
            default:
                setError((prevErrors) => ({ ...prevErrors, [name]: '' }));
                break;
        }
        return true;
    }
    const updateProfileInfo = async () => {
        Loading.set(true);
        try {
            await updateUserData(userInfo)
                .then((res) => {
                    onUserUpdate(userInfo)
                    Loading.set(false);
                })
                .catch((e) => {
                    Loading.set(false);
                    console.log(e)
                    Popup.alert(
                        "Profile",
                        e.message,
                        "ERROR",
                        () => { }
                    );
                })
        } catch (e) {
            Loading(false)
            console.log(e)
            Popup.alert(
                "Profile",
                "Something went wrong while updating email",
                "ERROR",
                () => { }
            );
        }
    }
    return (
        <Modal
            header={"Edit PAN"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "534px", height: "auto" }}
            divider={true}
            headingStyle={{ fontSize: "18px" }}
            iconSize={"18px"}
            headerContainerStyle={{ padding: '20px 40px' }}
        >
            <div style={{ padding: "15px 46px" }}>
                <p style={{ paddingBottom: "17px", fontSize: '16px', color: '#1e1e1e', fontWeight: 500 }}>
                    Enter your PAN number
                </p>
                <form>
                    <div>
                        <div style={{ paddingBottom: "7px", fontSize: '16px', color: '#3b3b3b', fontWeight: "400" }}>
                            PAN
                        </div>
                        <Input
                            onChange={onChangeUserInfo}
                            name={"pan_number"}
                            placeholder={"Enter pan number"}
                            value={userInfo?.pan_number}
                            required={true}
                            style={{ height: '51px', borderRadius: "8px", border: '1px solid #BFC8D9', padding: '5px 15px' }}
                            inputStyle={{ fontSize: "16px" }}
                        />
                        {error &&
                            <div style={{ color: "red", padding: "5px 0px 0px 0px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>
                                {error.pan_number ? error.pan_number : error.existError}
                            </div>
                        }
                    </div>
                </form>
            </div>
            <div
                style={{
                    display: "flex",
                    width:"100%",
                    justifyContent:"flex-end",
                    padding:"20px 20px"
                }}
            >
                
                <Button
                                text={"Continue"}
                                onClick={updateProfileInfo}
                                style={{ width: "100px", height: "35px", fontSize: '16px' }}
                                isDisabled={error.pan_number || oldPAN === userInfo.pan_number || error.existError || checkPanNumber}
                            />
                </div>
    
        </Modal>
    )
}

export default EditPAN