import React, { useEffect, useState } from 'react'
import SelectComponent from '../../../ReusableComponents/SelectComponent'
import TotalRevenueGraph from "../SubComponents/TotalRevenueGraph";
import Popup from '../../../ReusableFunctions/Popup';
import { getAllDSPDroneUAS, getAllDronesbyUAS } from '../../../apis/drones';
import { getRevenueGraphData } from '../../../apis/revenue';
import { formatToIndianRupees } from '../../../ReusableFunctions/reusableFunctions';
export default function DroneRevenue({
    fromDate,
    toDate,
    interval,
}) {
    const [models, setModels] = useState([]);
    const [droneUINs, setDroneUINs] = useState([])
    const [dropDownLoading, setDropDownLoading] = useState(false);
    const [selectedmodel, setSelectedmodel] = useState({label:"",value:undefined});
    const [selectedUIN, setSelectedUIN] = useState({label:"",value:undefined});
   const [loading, setLoading] = useState(false);
   const [revenueData, setRevenueData] = useState([])

    const getDronesModelsFunc = async () => {
        try {
            const droneUAS = await getAllDSPDroneUAS();
            setModels(droneUAS);
            if(droneUAS && droneUAS.length > 0) {
              setSelectedmodel({
                label: droneUAS[0].model_name,
                value: droneUAS[0].id
              })
            }
        } catch (e) {
          Popup.alert("Drones models", "Something Went wrong while getting drone models  !", 'ERROR')
        }
    }

    const fetchRevenueData=async()=>{
      try{
      setLoading(true);
      const data =await getRevenueGraphData({
        fromDate:fromDate?.toISOString(),
        toDate:toDate?.toISOString(),
        interval:interval?.toLowerCase(),
       droneId:selectedUIN?.value
      })
        setRevenueData(data);
        setLoading(false);
      }catch(err){
      console.log("error",err);
      setLoading(false);
      }
    }

    const getdroneUINs = async (uasId) => {
        try {
            setDropDownLoading(true);
            const alldrones = await getAllDronesbyUAS(uasId);
           setDroneUINs(alldrones);
           if(alldrones && alldrones.length > 0){
            setSelectedUIN({
              label:alldrones[0].drone_uin,
              value:alldrones[0].id
            })
           }else {
            setSelectedUIN({label:"",value:undefined});
            setRevenueData([]);
           }
           setDropDownLoading(false);
        } catch (err) {
            setDropDownLoading(false);
            Popup.alert("Drones", "Something Went wrong while getting Drones !", 'ERROR')
        }
    }

    useEffect(()=>{
        getDronesModelsFunc()
    },[])

    useEffect(()=>{
      if(selectedmodel?.value){
        getdroneUINs(selectedmodel?.value);
      }
    },[selectedmodel.value])

    useEffect(()=>{
      if (selectedUIN?.value) {
        fetchRevenueData();
    } else {
        setRevenueData([]);
    }
     },[interval,selectedUIN?.value])

    
 

  return (
    <div className="">
    <div
      className=""
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "2px",
      }}
    >
      <div>
        <span style={{ fontSize: "18px", fontWeight: 700 }}>
          Drone Revenue -{" "}
        </span>
        <span style={{ fontSize: "20px", color: "blue", fontWeight: 700 }}>
        ₹ {formatToIndianRupees(revenueData[0]?.total_revenue)}
        </span>
      </div>
      <div style={{display:"flex",alignItems:"center",gap:"15px",width:"45%%",justifyItems:"flex-end"}}>
        <SelectComponent
          controlStyle={{
            height: "30px",
            width: "150px",
            minHeight: "20px",
            border: "none",
            boxShadow: "0px 2px 5px 1px #6273943d",
            color: "hsl(0deg 0% 20% / 64%)",
            fontSize: "10px",
          }}
          isSearchable={true}
          placeholder={"Select Model"}
          menuPlacement={"bottom"}
          menuStyles={{
            zIndex:"1000"
         }}
          list={[...models.map(d => ({ label: d.model_name, value: d.id }))]}
          value={selectedmodel.label ? { value: selectedmodel.value, label: selectedmodel.label } : ""}
          onChange={(e) => {
            setSelectedmodel(() => ({
                label: e.label,
                value: e.value,
            }))
          }}
        />
        <SelectComponent
          controlStyle={{
            height: "30px",
            width: "150px",
            minHeight: "20px",
            border: "none",
            boxShadow: "0px 2px 5px 1px #6273943d",
            color: "hsl(0deg 0% 20% / 64%)",
            fontSize: "10px",
          }}
          menuStyles={{
            zIndex:"1000"
         }}
          isLoading={dropDownLoading}
          isSearchable={true}
          placeholder={"Select Drone UIN"}
          menuPlacement={"bottom"}
          list={[...droneUINs.map((d) => ({
            label: d.drone_uin,
            value: d.id
        }))]}
          value={selectedUIN?.label ? { value: selectedUIN?.value, label: selectedUIN?.label } : ""}
          onChange={(e) => {
            setSelectedUIN(() => ({
                label: e.label,
                value: e.value,
            }))
          }}
        />
      </div>
    </div>
  
    <TotalRevenueGraph
      showZoom={true}
      interval={interval}
      title={"total Revenue "}
      lineType={2}
      loading={loading}
      data={revenueData}
    />
  </div>
  
  )
}
