import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getBesInvoiceDetails = async (id) => {
    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/customerSuccessEngineer/stores/bes/invoice/details/${id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.invoiceDetails;
    } catch (err) {
        throw err;
    }
}

export const settleBesInvoice = async (invoice) => {
    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/customerSuccessEngineer/stores/bes/settle/invoice`,
            {
                method: "POST",
                body: JSON.stringify(invoice),
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while setteling the transaction");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while setteling the transaction");
    }
}