import React from "react";
import Cross from "../../assets/crossWhite.svg";

export default function ClearFilterButton({
  text = "Clear",
  onClick,
  isVisible,
}) {
  return (
    <>
      {isVisible ? (
        <div onClick={onClick} className="clear_filter_button">
          <p>{text}</p>
          <img
            style={{ marginTop: "2px" }}
            src={Cross}
            width={12}
            height={12}
          />
        </div>
      ) : null}
    </>
  );
}
