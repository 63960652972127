import React, { useEffect, useState } from 'react'
import SelectComponent from '../../../ReusableComponents/SelectComponent'
import TotalRevenueGraph from "../SubComponents/TotalRevenueGraph";
import { getLocations } from '../../../apis/locations';
import Popup from '../../../ReusableFunctions/Popup';
import { getRevenueGraphData } from '../../../apis/revenue';
import { formatToIndianRupees } from '../../../ReusableFunctions/reusableFunctions';
export default function PilotRevenue({
  fromDate,
  toDate,
  interval
}) {
    const [locations, setLocations] = useState([])
    const [revenueData, setRevenueData] = useState([])
    const [loading, setLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({label:"",value:undefined})
    const getLocationsFunc = async () => {
        try {
            const locations = await getLocations();
            setLocations(locations);
            if(locations && locations.length > 0) {
                setSelectedLocation({
                    label: locations[0].address,
                    value: locations[0].id,
                })
            }
        } catch (e) {
            Popup.alert("Error", e, "ERROR")
        }
    }

    useEffect(()=>{
      console.log("called LocationRevenue ",interval,fromDate,toDate);
      fetchRevenueData();
     },[interval,selectedLocation?.value])
 
 
     const fetchRevenueData=async()=>{
       try{
       setLoading(true);
       const data =await getRevenueGraphData({
        fromDate:fromDate?.toISOString(),
        toDate:toDate?.toISOString(),
        interval:interval?.toLowerCase(),
        locationId:selectedLocation.value
       })
         setRevenueData(data);
         setLoading(false);
       }catch(err){
       console.log("error",err);
       setLoading(false);
       }
     }
 

    useEffect(()=>{
        getLocationsFunc()
    },[])

  return (
    <div className="">
    <div
      className=""
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "2px",
      }}
    >
      <div>
        <span style={{ fontSize: "18px", fontWeight: 700 }}>
          Location Revenue -{" "}
        </span>
        <span style={{ fontSize: "20px", color: "blue", fontWeight: 700 }}>
        ₹ {formatToIndianRupees(revenueData[0]?.total_revenue)}
        </span>
      </div>
      <div>
        <SelectComponent
          controlStyle={{
            height: "30px",
            width: "200px",
            minHeight: "20px",
            border: "none",
            boxShadow: "0px 2px 5px 1px #6273943d",
            color: "hsl(0deg 0% 20% / 64%)",
            fontSize: "10px",
          }}
          isSearchable={true}
          placeholder={"Select Location..."}
          menuStyles={{
            zIndex:"1000"
         }}
          menuPlacement={"bottom"}
          list={[...locations.map(p => (({ label: p.address, value: p.id })))]}
          value={selectedLocation.label ? { value: selectedLocation.value, label: selectedLocation.label } : ""}
          onChange={(e) => {
            setSelectedLocation({
                label: e.label,
                value: e.value,
            })
          }}
        />
      </div>
    </div>
  
    <TotalRevenueGraph
      showZoom={true}
      interval={interval}
      title={"total Revenue "}
      lineType={2}
      loading={loading}
      data={revenueData}
    />
  </div>
  
  )
}
