import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import search from "../../../assets/search.svg"
import TableView from '../../../ReusableComponents/TableView/TableView'
import AdminFilterRequest from './AdminFilterRequest'
import ViewRequestsOnMap from '../../../BhuMeetTeams/BhuMeetCustomerSuccess/ViewRequestsOnMap'
import ListInActive from '../../../assets/listInactive.png'
import ListActive from '../../../assets/listActive1.png'
import MapActive from '../../../assets/mapActive1.png'
import MapInactive from '../../../assets/map.png'
import { formatToIndianRupees } from '../../../ReusableFunctions/reusableFunctions'
import { STATUS } from '../../../appConstants'



const getCurrentScreenSize = () => {
    const width = window.innerWidth;
    if (width >= 1860) {
        return "x-large"
    } else if (width < 1860 && width > 1620) {
        return "large";
    } else if (width <= 1620 && width > 1400) {
        return "medium";
    } else if (width > 1200 && width <= 1400) {
        return "small"
    }
}

const AdminRequestTable = ({ title, requests, isHideDSPFilter, onFilterChange, loading, filters, totalCount, isFromUserDetails, onSearch, tabelContainerHeight, hidePagination, onClickRow, view, setView, totalOrdersValue, onDeleteRequest }) => {
    const { state } = useLocation();
    const [openFilter, setOpenFilter] = useState(false)
    const [currentWidth, setcurrentWidth] = useState(getCurrentScreenSize());
    const [localRequest, setLocalRequests] = useState(requests)

    useEffect(() => {
        setLocalRequests(requests)
    }, [requests])

    useEffect(() => {
        const handleResize = () => {
            setcurrentWidth(getCurrentScreenSize())
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.addEventListener("resize", handleResize);
        }
    }, [])

    return (
        <>
            <div style={{ fontWeight: 600, display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", gap: "20px", padding: "0px 0px 15px",position:"relative" }}>
                <div className='request-table-header-title' >
                    {/* <div className='request-table-header-title-div' style={{ fontSize: "1.5rem", fontWeight: 700 }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }} >
                            <div style={{minWidth:"150px"}} >

                            {`${title ? title : 'Requests'} (${localRequest[0]?.total_count || 0})`}
                            </div>
                        </div>
                    </div> */}
                    {onSearch &&
                        <div style={{ position: "relative", height: "40px", width: "400px" }}>
                            <img src={search} alt='search icon' style={{ position: 'absolute', left: "20px", top: "10px" }} />
                            <input onChange={(e) => onSearch(e.target.value)}
                                placeholder='Search request by request no...'
                                style={{ width: "100%", height: '100%', borderRadius: "4px", border: "1px solid #BFC8D9", padding: "10px 10px 10px 60px", fontSize: "12px" }} />
                        </div>
                    }
                </div>
                <div className='flex w-[50%] gap-2 items-center justify-end'>
                  
                    <div style={{ width: "80px", display: "flex", marginLeft: "5px", borderRadius: "5px", border: "1px solid gray", alignItems: "center" }}>
                        <div
                            onClick={() => {
                                onFilterChange({ pageSize: 15, pageNumber: 1 })
                                setView("tableView")
                            }}
                            style={{ cursor: "pointer", padding: "2px 8px ", overflow: "hidden" }}
                            className={`${view === "tableView" ? 'list_view_wrapper' : ''}`}
                        >
                            <div className='img'>
                                <img color='white' width={25} src={view === "tableView" ? ListActive : ListInActive} />
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                onFilterChange({ pageSize: 10000000, pageNumber: 1 })
                                setView("map")
                            }}
                            style={{ cursor: "pointer", padding: "2px 8px ", overflow: "hidden" }}
                            className={`${view === 'map' ? 'map_view_wrapper' : ''}`}>
                            <div className='img'>
                                <img width={25} src={view === 'map' ? MapActive : MapInactive} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {view === "tableView"
                ? <TableView
                    styles={totalOrdersValue && localRequest[0]?.total_amount_filtered_requests ?{marginTop:"20px"}:null}
                    activities={onDeleteRequest ? { onClickDelete: onDeleteRequest } : undefined}
                    loadingHeight={"639px"}
                    isLoading={loading}
                    onStateChange={async (changedState) => {
                        const { pageNumber, orderBy, orderType } = changedState;
                        onFilterChange({ pageNumber, orderBy, orderType });
                    }}
                    onClickRow={(request) => {
                        onClickRow(request)
                    }}
                    columns={[
                        { Header: "Request Number", accessor: "req_number", width: "10%" },
                        { Header: "Status", accessor: "status", width: "10%" },
                        { Header: "DSP", accessor: "dsp_name", width: "15%" },
                        { Header: "Location", accessor: "location_name", width: "15%" },
                        { Header: "Total Amount", accessor: "total_amount_without_bhumeet_coupon", width: "8%" },
                        { Header: "Paid Amount", accessor: "total_amount", width: "8%" },
                        { Header: "Scheduled Date", accessor: "time", isSortable: true, isDate: true, format: "dd/mm/yyyy", width: "7%" },
                        { Header: "Date Created", accessor: "date_created", isSortable: true, isDate: true, format: "dd/mm/yyyy", width: "7%" },
                        { Header: "Completed date", accessor: "completed_time", isSortable: true, isDate: true, format: "dd/mm/yyyy", width: "7%" },
                    ]}
                    count={totalCount}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber || undefined}
                    data={localRequest?.map((req) => ({
                        ...req,
                        total_amount_without_bhumeet_coupon: formatToIndianRupees(req.total_amount_without_bhumeet_coupon),
                        total_amount: formatToIndianRupees(req.total_amount),
                        status: req.status === "Paid" ? `${req.status + " " + `(${req.payment_method ?? ""})`}` : req.status
                    }))}
                    tableContainerHeight={tabelContainerHeight || '681px'}
                    headerStyle={{marginTop:"10px"}}
                    hidePagination={hidePagination}
                />
                :
                <ViewRequestsOnMap isLoading={loading} mapData={localRequest} isExpandable height={tabelContainerHeight} />
            }

        </>

    )
}

export default AdminRequestTable