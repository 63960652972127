import { useEffect, useState } from "react";
import MainContentWrapper from "../../../Components/MainContentWrapper";
import TableView from "../../../ReusableComponents/TableView/TableView";
import {deletedroneModel, getDrones,getDronesDetails } from "../../../apis/BhuMeetTeams/Oparations/droneStore";
import Popup from "../../../ReusableFunctions/Popup";
import { PDRL_ADMIN_ROUTES, PDRL_SUCCESS_ENGI_ROUTES } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Search from '../../../ReusableComponents/Search'
import { Button } from '../../../Components/Helper';
import Plus from "../../../assets/Plus.svg";
import AddDroneModel from "./AddDroneModel";



const DroneModels = ({ store, callback, }) => {
    const [stores, setStores] = useState([])
    const [droneProperties, setdroneProperties] = useState('')

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [path,setPath] = useState('')
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 15,
        fromDate: undefined,
        toDate: undefined,
    })
    const [openAddDroneModel,setopenAddDroneModel] = useState(false)

    const [searchString, setSearchString] = useState('')

    const deleteManufacture = async(id)=>{
        await deletedroneModel(id).then(res=>{
            setStores((prevStores) => prevStores.filter((store) => store.id !== id));
            getDronesFunc()

        }).catch((err)=>{
            Popup.alert("Error", err, 'ERROR')

        })
    }



    const getDronesFunc = async () => {
        try {
            setLoading(true)
            // console.log(store)
            const { fromDate, toDate, pageSize, pageNumber } = filters
            let stores = await getDrones(pageSize,pageNumber,store.id,searchString)
            console.log(stores)
            
            setStores(stores);
            // setStores({...stores,toShow:Location.pathname})
            setLoading(false)
        } catch (err) {
            console.log(err)
            setStores([])
            setLoading(false)
            // Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        getDronesFunc(filters);
        
    }, [filters])

    useEffect(()=>{
        // console.log(Location)
        getDronesFunc()
    },[searchString])



    return (
        <MainContentWrapper header={`${store.name} Models (${stores[0]?.count ?? 0})`} 
        rightContent={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Search
                        searchValue={searchString}
                        onChange={(value) => {
                            
                            setSearchString(value)
                            
                            // setCurrentPage(1)
                        }}
                    />

                <Button
                        type={"button"}
                        text={"Add New"}
                        icon={Plus}
                        style={{ background: "#0F5EFF",marginLeft:"10px", padding: "8px, 16px, 8px, 16px", width: "110px", height: "35px", fontSize: "11px" }}
                        onClick={() => {
                            // console.log("TEst")
                            setdroneProperties('')
                            setopenAddDroneModel(true)
                            navigate(PDRL_ADMIN_ROUTES.DRONEMODELDETAIL, { state: { droneproperties : droneProperties,store } })

                            // console.log(openAddManufacture)
                        //   setOpenPesticidesPopup(true)
                        }} />
                {
                openAddDroneModel && <AddDroneModel droneproperties={droneProperties} store={store} open={openAddDroneModel}  onClose={()=>{setopenAddDroneModel(false)}}/>
            }
                    
        </div>
        }>
            
            <div style={{
                margin: "20px 0",
                height: "90%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"240px"}
                    isLoading={loading}
                    onStateChange={async (changedState) => {
                        const { pageNumber } = changedState;
                        // console.log("pageNumber", pageNumber);
                        setFilters({ ...filters, pageNumber });
                    }}
                    onClickRow={(store) => {
                        // console.log("store", store);
                        
                        // navigate(PDRL_ADMIN_ROUTES.DRONEMODELDETAIL, { state: { droneProperties : droneProperties,store } })
                    }}
                    columns={[
                        { Header: "Drone Name", accessor: "name", width: "9%" },
                        { Header: "Type", accessor: "type", width: "9%" },
                        { Header: "Manufacturer", accessor: "manufacture_name", width: "5%" },

                        { Header: "Added Date", accessor: "date_added", isDate: true, width: "10%" },
                    ]}
                    count={stores[0]?.count ?? 0}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={stores.map(st => {
                        return {
                            ...st,
                            active_label: st.is_active ? "Active" : "Inactive",
                            color: st.is_active ? 'green' : 'red'
                        }
                    })}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                    activities={{ onClickDelete: (e) => Popup.alert("WARNING", "Are you sure , you want to delete this drone Model?", "WARNING", () => {
                        deleteManufacture(e.id)
                    }), onClickEdit: (e)=>{
                        // console.log(e)
                        
                        getDronesDetails(e.id).then(res=>{
                            // console.log(res)
                            setdroneProperties(res)
                            // setopenAddDroneModel(true) 
                            navigate(PDRL_ADMIN_ROUTES.DRONEMODELDETAIL, { state: { droneproperties : res,store } })

                            // console.log(store)
                        }).catch(err=>{
                            console.log(err)
                        })
                        // setdetails(e)
                    }, canEdit: true }}
                />
            </div>
        </MainContentWrapper>
    )
}

export default DroneModels