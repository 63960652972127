import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getRequestsCountInInterval = ({ isDspActive, services, dsps, statuses, locations, orderBy, orderType, userId, fromDate, toDate, pilotId, searchQuery, pageSize, pageNumber, paymentMethods, onlyAppliedBhuMeetCouponsRequests }, isOrderByServiceTime, isFilterByDateCreated,graphType) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data;
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/customerSuccessEngineer/dsp/request/graphStats?pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""}&searchQuery=${searchQuery || ""}` +
                `${(isDspActive != null || isDspActive != undefined) ? `&isDspActive=${isDspActive}` : ""}` +
                `${services?.length > 0 ? `&services=${encodeURIComponent(JSON.stringify(services))}` : ""}` +
                `${dsps?.length > 0 ? `&dsps=${encodeURIComponent(JSON.stringify(dsps))}` : ""}` +
                `${statuses?.length > 0 ? `&statuses=${encodeURIComponent(JSON.stringify(statuses))}` : ""}` +
                `${locations?.length > 0 ? `&locations=${encodeURIComponent(JSON.stringify(locations))}` : ""}` +
                `${orderBy ? `&orderBy=${orderBy}` : ""}` +
                `${orderType ? `&orderType=${orderType}` : ""}` +
                `${userId ? `&userId=${userId}` : ""}` +
                `${fromDate ? `&fromDate=${new Date(fromDate).toISOString()}` : ""}` +
                `${toDate ? `&toDate=${new Date(toDate).toISOString()}` : ""}` +
                `${pilotId ? `&pilotId=${pilotId}` : ""}` +
                `${paymentMethods ? `&paymentMethods=${JSON.stringify(paymentMethods)}` : ""}` +
                `${onlyAppliedBhuMeetCouponsRequests ? `&onlyAppliedBhuMeetCouponsRequests=true` : ""}` +
                `${isOrderByServiceTime ? `&isOrderByServiceTime=true` : ""}` +
                `${isFilterByDateCreated ? `&isFilterByDateCreated=true` : ""}`+
                `${graphType?`&graphType=${graphType}`:""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                data = await response.json();
            }
            if (response.ok && response.status === 200) {
                resolve({ requests: data.graphStats });
            } else if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                reject(data?.message || "Something went wrong while getting requests.");
            }
        } catch (error) {
            reject("Something went wrong while getting requests.");
        }
    });
}


export const getSprayingRequestParameters = async ({dspId,locationId}) => {
    try {
            const token = getToken();
            const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/customerSuccessEngineer/dsp/request/sprayingServiceParameters`, {
                locationId:locationId?locationId:undefined,
                dspId: dspId ? dspId : undefined,
            });
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token} `,
                    },
                }
            );
    
            const res = await response.json();
            if (!response.ok) {
                throw (res.message || "Something went wrong while getting coupons");
            }
            return res?.parameters;
    } catch (err) {
        throw (err)
    }
}

export const placeRequestFromCES = async (request) => {
    try {
            const token = getToken();
            const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/customerSuccessEngineer/dsp/request`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token} `,
                        
                    },
                    body: JSON.stringify({ request })
                }
            );
    
            const res = await response.json();
            if (!response.ok) {
                throw (res.message || "Something went wrong while getting coupons");
            }
            return res?.parameters;
    } catch (err) {
        throw (err)
    }
}
