import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import SelectComponent from '../../ReusableComponents/SelectComponent'
import { getDrones } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/drones'
import Loading from '../../ReusableFunctions/Loader'
import TableView from '../../ReusableComponents/TableView/TableView'
import Popup from '../../ReusableFunctions/Popup'
import { getAllDSPs } from '../../apis/adminAPIs/dsp'
import { getDronePilots, getDronePilotsLocations } from '../../apis/BhuMeetTeams/Oparations/pilots'
import { generateCSV } from '../../utils/generateAndDownloadCsv'
import { Button } from '../../Components/Helper'
import ListInActive from '../../assets/listInactive.png'
import ListActive from '../../assets/listActive1.png'
import MapActive from '../../assets/mapActive1.png'
import MapInactive from '../../assets/map.png'
import useWindowSize from '../../hooks/useWindowSize'
import ViewPilotsOnMap from './ViewPilotsOnMap'

const csvHeader = [
    { label: "Pilot Name", key: "full_name" },
    { label: "Dsp Name", key: "dsp_name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "PAN", key: "pan_number" },
    { label: "License Id", key: "licence_id" },
    { label: "Address", key: "address" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Status", key: "pilot_active_label" },
    { label: "Date Added", key: "date_created" },
]

const OperationsPilots = ({
    dsp,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [dsps, setDsps] = useState([{ dsp_id: null, dsp_name: "All" }])
    const [dronePilots, setDronePilots] = useState([])
    const [dronePilotsLocations, setDronePilotsLocations] = useState([])
    const [view, setView] = useState("grid");
    const {currentWidth}=useWindowSize();
    const [hideInfo, setHideInfo] = useState(false);
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 15,
        selectedDSP: { value: null, label: "All" }
    })

    const getActiveDSPs = async () => {
        try {
            setIsLoading(true)
            Loading.set(true)
            const data = await getAllDSPs(null, null, null, null, null, true)
            setDsps([...dsps, ...data.dsps])
            Loading.set(false)
            setIsLoading(false)
        } catch (e) {
            console.error(e)
            setIsLoading(false)
            Loading.set(false)
            Popup.alert("ERROR", "We cant load invoices right now, Please try again later.")
        }
    }

    const getDronePilotsFunc = async () => {
        try {
            setIsLoading(true)
            Loading.set(true)
            const pilots = await getDronePilots(dsp?.dsp_id || filters.selectedDSP.value, filters.pageSize, filters.pageNumber)
            setDronePilots(pilots)
            Loading.set(false)
            setIsLoading(false)
        } catch (error) {
            console.log("error", error);
            Loading.set(false)
            setIsLoading(false)
            Popup.alert("ERROR", "We cant get drones right now, Please try again later.")
        }
    }

    const  getDronePilotsLocationsFunc = async () => {
        try {
            setIsLoading(true)
            const pilots = await getDronePilotsLocations(dsp?.dsp_id || filters.selectedDSP.value)
            console.log("pilots",pilots);
            setDronePilotsLocations(pilots)
            setIsLoading(false)
        } catch (error) {
            console.log("error", error);
            setIsLoading(false)
            Popup.alert("ERROR", "We cant get drones right now, Please try again later.")
        }
    }

    useEffect(() => {
        if (dsp?.dsp_id) {
            setHideInfo(true);
        }
            getDronePilotsFunc()
            getDronePilotsLocationsFunc()
    }, [filters.pageNumber, filters.pageSize, filters.selectedDSP,view])

    useEffect(() => { getActiveDSPs() }, [])

    const downloadCSV = async () => {
        Loading.set(true)
        const pilots = await getDronePilots(dsp?.dsp_id || filters.selectedDSP.value)
        const updatedPilots = pilots?.map((dp) => ({
            ...dp,
            full_name: dp?.first_name + " " + dp?.last_name,
            address: dp?.first_line_address + " " + dp?.second_line_address,
            pilot_active_label: dp.is_active ? "Active" : "Inactive",
            color: dp.is_active ? 'green' : 'red'
        }))
        generateCSV(csvHeader, updatedPilots, "pilots")
        Loading.set(false)
    }

    return (
        <MainContentWrapper header={`Pilots ${!hideInfo ? `(${filters.selectedDSP.label})` : `(${dronePilots[0]?.pilots_count || 0})`} `}
            rightContent={
                <div style={{ display: "flex", alignItems: "center", gap: "2rem", justifyContent: "space-between", }}>
                    <Button
                        text={"Export CSV"}
                        style={{ width: "150px", height: "38px", fontSize: "15px" }}
                        onClick={() => {
                            downloadCSV()
                        }}
                    />
                    {!hideInfo && (<SelectComponent
                        controlStyle={{ height: "40px", width: "200px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                        isSearchable={true}
                        placeholder={"Select DSP..."}
                        menuPlacement={"bottom"}
                        list={dsps.map(dsp => {
                            return ({
                                value: dsp.dsp_id,
                                label: dsp.dsp_name
                            })
                        })}
                        menuStyles={{zIndex:999}}
                        value={filters.selectedDSP}
                        onChange={(dsp) => {
                            setFilters({ ...filters, selectedDSP: dsp })
                        }}
                    />)}
                     <div style={{ width: "100px", height: currentWidth < 1367 ? "38px" : "", display: "flex", marginLeft: "5px", borderRadius: "5px", border: "1px solid gray", alignItems: "center" }}>
                    <div
                        onClick={() => {
                            setView("grid")
                        }}
                        style={{ cursor: "pointer", padding: "1px 15px ", width: "50%", overflow: "hidden" }}
                        className={`${view === "grid" ? 'pilots_list_view_wrapper' : ''}`}
                    >
                        <div className='img'>
                            <img color='white' style={{ marginBottom: "4px" }} width={20} src={view === "grid" ? ListActive : ListInActive} />
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            setView("map")
                        }}
                        style={{ cursor: "pointer", padding: "1px 15px ", width: "50%", overflow: "hidden" }}
                        className={`${view === 'map' ? 'pilot_map_view_wrapper' : ''}`}>
                        <div className='img'>
                            <img style={{ marginBottom: currentWidth > 1366 ? "6px" : "4px" }} width={20} src={view === 'map' ? MapActive : MapInactive} />
                        </div>
                    </div>
                </div>
                </div>
            }>
                {view==="grid"
                ?<div style={{
                    // margin: "20px 0",
                    height: "90%"
                }}>
                    <TableView
                        showSrNo
                        loadingHeight={"500px"}
                        isLoading={isLoading}
                        onStateChange={async (changedState) => {
                            const { pageNumber } = changedState;
                            setFilters({ ...filters, pageNumber });
                        }}
                        columns={[
                            { Header: "Name", accessor: "full_name", width: "10%" },
                            { Header: "Email", accessor: "email", width: "15%" },
                            { Header: "Phone", accessor: "phone", width: "10%" },
                            { Header: "Address", accessor: "location_name", width: "12%" },
                            { Header: "PAN", accessor: "pan_number", width: "10%" },
                            { Header: "Dsp Name", accessor: "dsp_name", width: "15%" },
                            { Header: "Date Added", accessor: "date_created", isDate: true, format: "dd/mm/yyyy", width: "10%" },
                            { Header: "Status", accessor: "pilot_active_label", showColor: true, width: "15%" }
                        ]}
                        count={dronePilots[0]?.pilots_count}
                        pageSize={filters.pageSize}
                        pageNumber={filters.pageNumber}
                        data={dronePilots.map((dp) => ({
                            ...dp,
                            full_name: dp?.first_name + " " + dp?.last_name,
                            pilot_active_label: dp.is_active ? "Active" : "Inactive",
                            color: dp.is_active ? 'green' : 'red'
                        }))}
                        tableContainerHeight={"100%"}
                        hidePagination={false}
                        headerStyle={{ zIndex: 0 }}
                    />
                </div>
                :<ViewPilotsOnMap pilotLocations={dronePilotsLocations}/>}
            
        </MainContentWrapper>
    )
}

export default OperationsPilots