import React from 'react';
import AdminStatusCountCard from './AdminStatusCountCard';

const REQUEST_STATUSES = {
    PLACED: "Placed",
    ACCEPTED: "Accepted",
    RESCHEDULED: "Rescheduled",
    OUT_FOR_SERVICE: "Out for Service",
    INPROGRESS: "InProgress",
    COMPLETED: "Completed",
    PAID: "Paid",
    CANCELLED: "Cancelled",
    ON_HOLD: "On Hold"
};

const StatusCountContainer = ({ requestsStatuses }) => {
    // Create a map with all statuses and default counts as 0
    const statusesWithCounts = Object.keys(REQUEST_STATUSES).map((statusKey) => {
        const statusInData = requestsStatuses.find((rs) => rs.status === REQUEST_STATUSES[statusKey]);
        return {
            status: REQUEST_STATUSES[statusKey],
            count: statusInData ? statusInData.count : 0
        };
    });

    return (
        <div
            style={{
                display: "grid",
                justifyContent: "space-between",
                rowGap: "15px",
                gridTemplateColumns: "repeat(auto-fill, minmax(216px, 1fr))",
                marginBottom: "25px"
            }}
        >
            {statusesWithCounts.map((rs) => (
                <AdminStatusCountCard
                    key={rs.status}
                    request={rs}
                />
            ))}
        </div>
    );
};

export default StatusCountContainer;
