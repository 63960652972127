import React, { useEffect, useState } from 'react'
import Loading from '../../ReusableFunctions/Loader'
import { getRevenueStats } from '../../apis/BhuMeetTeams/BhuMeetFinanace/revenue'
import Popup from '../../ReusableFunctions/Popup'
import MainContentWrapper from '../../Components/MainContentWrapper'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import { addLeadingZero, clearProfileFilters } from '../../ReusableFunctions/reusableFunctions'
import customersCardIcon from "../../assets/customersCardIcon.svg"
import teamsCardIcon from "../../assets/teamsCardIcon.svg"
import serviceCardIcon from "../../assets/serviceCardIcon.svg"
import SelectComponent from '../../ReusableComponents/SelectComponent'
import { getAllDSPs } from '../../apis/adminAPIs/dsp'
import UsersFilter from '../../AdminComponents/Users/SubComponents/UsersFilter'

const RevenueDashboard = () => {
    const [revenueStats, setRevenueStats] = useState({})
    const [filter, setFilter] = useState({
        fromDate: undefined,
        toDate: undefined,
        dsp: { value: undefined, label: "All" }
    })
    const [dsps, setDsps] = useState([{ dsp_id: undefined, dsp_name: "All" }])

    const getReveneuStatsFunc = async () => {
        try {
            Loading.set(true)
            const stats = await getRevenueStats(filter.fromDate, filter.toDate, filter.dsp?.dsp_id)
            console.log('stats:', stats)
            setRevenueStats(stats)
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            Popup.alert("ERROR", error?.message, "Error")
        }
    }

    const getActiveDSPs = async () => {
        try {
            const data = await getAllDSPs(null, null, null, null, null, true)
            setDsps([...dsps, ...data.dsps.map((d) => { return { label: d.dsp_name, value: d.dsp_id } })])
        } catch (e) {
            console.error(e)
            Popup.alert("ERROR", "We cant load invoices right now, Please try again later.")
        }
    }

    useEffect(() => {
        getActiveDSPs()
        getReveneuStatsFunc()
        clearProfileFilters();
    }, [])

    useEffect(() => {
        getReveneuStatsFunc()
    }, [filter.fromDate, filter.toDate, filter.dsp?.dsp_id])

    return (
        <MainContentWrapper
            headerStyle={{ gap: 0 }}
            leftContent={
                <SelectComponent
                    controlStyle={{ height: "40px", width: "200px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                    isSearchable={true}
                    placeholder={"Select DSP..."}
                    menuPlacement={"bottom"}
                    list={dsps.map(dsp => {
                        return ({
                            value: dsp.dsp_id,
                            label: dsp.dsp_name
                        })
                    })}
                    value={filter.dsp}
                    onChange={(dsp) => {
                        console.log('dsp:', dsp)
                        setFilter({ ...filter, dsp: dsp })
                    }}
                />
            }
            rightContent={
                <div style={{ display: "flex", justifyContent: "", paddingBottom: "20px" }}>
                    <UsersFilter
                        filter={filter}
                        onFilterChange={(filter) => {
                            setFilter(filter)
                        }}
                    />

                </div>
            }>
            <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
                <div className="dashboard-first-section-second-div-one" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >
                    {/* <DashBoardCard
                        loading={false}
                        title="Paid Amount"
                        icon={customersCardIcon}
                        data={addLeadingZero(revenueStats?.total_settled_amount)}
                    />

                    <DashBoardCard
                        loading={false}
                        title="Unpaid Amount"
                        data={addLeadingZero(revenueStats?.total_unsettled_amount)}
                        icon={teamsCardIcon}
                    /> */}


                    <DashBoardCard
                        loading={false}
                        title="Incentive"
                        icon={customersCardIcon}
                        data={addLeadingZero(revenueStats?.total_incentive_from_bhumeet_amount)}
                    />

                    {/* <DashBoardCard
                        loading={false}
                        title="Coupons Amount"
                        data={addLeadingZero(revenueStats?.total_bhumeet_coupons_amount)}
                        icon={serviceCardIcon}
                    />

                    <DashBoardCard
                        loading={false}
                        title="TDS Charges"
                        data={addLeadingZero(revenueStats?.total_tds_charges)}
                        icon={teamsCardIcon}
                    />

                    <DashBoardCard
                        loading={false}
                        title="Platform Fees"
                        data={`₹ ${addLeadingZero(revenueStats?.total_platform_fees_amount)}`}
                        icon={serviceCardIcon}
                    /> */}

                    <DashBoardCard
                        loading={false}
                        title="Billed to Customer"
                        icon={customersCardIcon}
                        data={revenueStats?.bhumeet_total_revenue ? `₹ ${revenueStats?.bhumeet_total_revenue > -1 ? addLeadingZero(revenueStats?.bhumeet_total_revenue) : revenueStats?.bhumeet_total_revenu}` : 0}
                    />
                </div>
            </div>
        </MainContentWrapper>
    )
}

export default RevenueDashboard