import React, { useCallback, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import calender from "../../assets/blueCalendar.svg";
import LoaderComponent from '../../ReusableComponents/LoaderComponent';
import TableViewPagination from '../../ReusableComponents/TableView/TableViewPagination';
import _debounce from 'lodash/debounce';
import dateFormat from 'dateformat';
import Popup from '../../ReusableFunctions/Popup';
import { useNavigate } from 'react-router'
import { ROUTES } from '../../routes';
import Loading from '../../ReusableFunctions/Loader';
import { getDebitNotes } from '../../apis/settlements';
import NotFounds from '../../ReusableComponents/NotFounds';
import noRequest from '../../assets/no-request.svg'
import ClearFilterButton from '../../ReusableComponents/TableView/ClearFilterButton';

const DspDebitNotes = ({ dsp }) => {
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [openDatePicker, setOpenDatePicker] = useState({
        fromDate: false,
        toDate: false,
    })
    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem('dspDebitNotesFilters');
        return savedFilters ? JSON.parse(savedFilters) : {
            fromDate: undefined,
            toDate: undefined,
            pageNumber: 1,
            pageSize: 15
        };
    });
    const [totalNotesCount, setTotalNotesCount] = useState(0)
    const [tableLoading, setTableLoading] = useState(false)
    const [debitNotes, setDebitNotes] = useState([])

    const getAllDspDebitNotesFunc = async () => {
        try {
            // Loading.set(true)
            setTableLoading(true)
            const fromDate = filters.fromDate ? dateFormat(filters.fromDate, "yyyy-mm-dd") : undefined;
            const toDate = filters.toDate ? dateFormat(filters.toDate, "yyyy-mm-dd") : undefined;
            const debitNotes = await getDebitNotes(dsp?.id, fromDate, toDate, filters.pageSize, filters.pageNumber)
            setDebitNotes(debitNotes)
            setTableLoading(false)
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            setTableLoading(false)
            Popup.alert("Error", error, 'ERROR')
        }
    }

    useEffect(() => {
        localStorage.setItem('dspDebitNotesFilters', JSON.stringify(filters));
    }, [filters]);

    const updateFilters = useCallback((newFilters) => {
        setFilters(prev => {
            const updated = { ...prev, ...newFilters };
            localStorage.setItem('dspDebitNotesFilters', JSON.stringify(updated));
            return updated;
        });
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        const debouncedGetSettelmentInvoicesDSPs = _debounce(() => {
            getAllDspDebitNotesFunc();
        }, 300);
        setOpenDatePicker((prev) => ({ ...prev, fromDate: false, toDate: false }));
        debouncedGetSettelmentInvoicesDSPs();

        return () => {
            debouncedGetSettelmentInvoicesDSPs.cancel();
        };
    }, [filters.pageNumber, filters.fromDate, filters.toDate]);

    useEffect(() => {
        getAllDspDebitNotesFunc()
    }, [])

    return (
        <>
            <div className='transaction_history_wrapper' style={{ padding: "30px" }}>
                <div className='transaction_history_wrapper_header'>
                    <h4>Debit Notes</h4>
                </div>
                <div className='filters_section_wrapper'>
                    <div>
                        <ClearFilterButton isVisible={filters.fromDate || filters.toDate} onClick={() => setFilters((prev) => ({ ...prev, fromDate: undefined, toDate: undefined, pageNumber: 1 }))} />
                    </div>
                    <div className='date_Filters'>
                        <div
                            className="input-date "
                            style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "34px", width: "150px", position: "relative", background: "transparent" }}
                            onClick={() => setOpenDatePicker((prev) => ({ ...prev, fromDate: true }))}
                        >
                            <DatePicker
                                onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, fromDate: false }))}
                                open={openDatePicker.fromDate}
                                placeholderText='From Date'
                                readOnly
                                maxDate={filters?.toDate && new Date(filters?.toDate)}
                                className="input-date-hidden"
                                selected={filters.fromDate ? new Date(filters.fromDate) : null}
                                onChange={date => {
                                    if (date) {
                                        let fromDate = new Date(date).setHours(0, 0, 0, 0)
                                        fromDate = new Date(fromDate).toISOString();
                                        updateFilters({ fromDate });
                                    }
                                }}
                            />
                            <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                        </div>
                        <div className='text-xl'>-</div>
                        <div className="input-date  "
                            style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "34px", width: "150px", position: "relative", background: "transparent" }}
                            onClick={() => {
                                if (filters?.fromDate) {
                                    setOpenDatePicker((prev) => ({ ...prev, toDate: true }))
                                }
                            }}
                        >
                            <DatePicker
                                placeholderText='To Date'
                                onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, toDate: false }))}
                                open={openDatePicker.toDate}
                                disabled={!filters.fromDate}
                                minDate={filters?.fromDate && new Date(filters?.fromDate)}
                                maxDate={new Date()}
                                readOnly
                                className="input-date-hidden "
                                selected={filters.toDate ? new Date(filters.toDate) : null}
                                onChange={date => {
                                    if (date) {
                                        let todate = new Date(date).setHours(23, 59, 59, 999)
                                        todate = new Date(todate).toISOString();
                                        updateFilters({ toDate: todate });
                                    }
                                }} />
                            <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                        </div>
                    </div>
                </div>
            </div>
            {!tableLoading ?
                <div style={{ width: "100%", padding: "0px 30px 30px" }}>
                    <table className='transactionTable'>
                        <thead className='w-full'>
                            <tr className='text-[#3B3B3B] text-[14px]'>
                                <th style={{ paddingLeft: "20px" }}>D.N. No.</th>
                                <th style={{ paddingLeft: "20px" }}>Amount</th>
                                <th style={{ paddingLeft: "20px" }}>Generated for Date</th>
                                <th style={{ paddingLeft: "20px" }}>Status</th>
                            </tr>
                        </thead>
                        <div className='invoice_transaction_table_body'>
                            <tbody >
                                {
                                    debitNotes?.length === 0 ?
                                        <tr align="center" >
                                            <td colSpan={6} style={{ height: "228px" }}>
                                                <NotFounds imgUrl={noRequest} msg={"No data available!"} hideBtn imgStyle={{ width: "100px" }} />
                                            </td>
                                        </tr> :
                                        debitNotes && debitNotes?.map((note, index) => (
                                            <tr className='row-hover' style={{ cursor: "pointer" }} key={index} onClick={() => {
                                                navigate(`${ROUTES.PAYMENTINVOICE}`, {
                                                    state: {
                                                        dsp,
                                                        invoice: note
                                                    }
                                                })
                                            }}>
                                                <td style={{ paddingLeft: "20px" }}>{note?.dn_number}</td>
                                                <td style={{ paddingLeft: "20px" }}>₹ {note?.total_amount}</td>
                                                <td style={{ paddingLeft: "20px" }}>{dateFormat(note?.generated_for_date, "dd mmm yyyy")}</td>
                                                <td style={{ paddingLeft: "20px" }}>{note?.status == 'settled' ? 'Paid' : 'Unpaid'}</td>
                                            </tr>
                                        ))
                                }
                            </tbody>

                        </div>
                    </table>
                </div> :
                <div className='dsp_invoice_loading' >
                    <LoaderComponent
                        width={"2%"}
                        height={"2%"}
                    />
                </div>
            }

            {debitNotes?.length === 0 ?
                <></> :
                <div className='flex w-full justify-end mt-5'>
                    <TableViewPagination
                        isLoading={false}
                        currentPage={filters?.pageNumber || 1}
                        totalCount={debitNotes?.[0]?.debit_note_count}
                        pageSize={filters.pageSize}
                        padding="10px 5px"
                        onPageChange={(page) => {
                            updateFilters({ pageNumber: page });
                        }}
                    />
                </div>
            }
        </>
    )
}

export default DspDebitNotes