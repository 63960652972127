import React, { useEffect, useState } from 'react'
import { PDRL_ADMIN_ROUTES, PDRL_SUCCESS_ENGI_ROUTES } from '../../routes'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { getDroneOverview } from '../../apis/BhuMeetTeams/Oparations/droneStore'
import Popup from '../../ReusableFunctions/Popup'
import { getStoresCount } from '../../apis/BhuMeetTeams/Oparations/store'
import { useLocation } from 'react-router-dom'
import { getAllOrdersCount } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/dashboard'
import { getAllProductsCount } from '../../apis/BhuMeetTeams/Oparations/RewardProducts'

export default function DroneStoreDashboard() {
    const [ordersCount, setOrdersCount] = useState(0)
    const [productsCount, setProductsCount] = useState(0)
  
    useEffect(() => {
        getOrdersCountFunc();
    }, [])


    const getOrdersCountFunc = async () => {
        try {
            let ordersCount = await getDroneOverview();
            // console.log(ordersCount)
            setOrdersCount(ordersCount);
        } catch (err) {
            Popup.alert("ERROR", "We cant get orders count now, Please try again later.")
        }
    }

    

    return (
        <MainContentWrapper>
            <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
                <div className="dashboard-first-section-second-div-one" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.DRONESTOREMANUFACTURE}
                        title="Manufactures"
                        data={ordersCount.manufacturers_count}
                        state={ordersCount.manufacturers_count}
                    />
                      <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.DRONEENQUIRY}
                        title="Enquiries"
                        data={ordersCount.inquiry_count}
                    />
                </div>
            </div>
        </MainContentWrapper>
    )
}
