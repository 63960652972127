import React, { useCallback, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import calender from "../../assets/blueCalendar.svg";
import LoaderComponent from '../../ReusableComponents/LoaderComponent';
import TableViewPagination from '../../ReusableComponents/TableView/TableViewPagination';
import _debounce from 'lodash/debounce';
import dateFormat from 'dateformat';
import Popup from '../../ReusableFunctions/Popup';
import { useNavigate } from 'react-router'
import { ROUTES } from '../../routes';
import Loading from '../../ReusableFunctions/Loader';
import { getSettlementInvoices } from '../../apis/settlements';
import NotFounds from '../../ReusableComponents/NotFounds';
import noRequest from '../../assets/no-request.svg'
import ClearFilterButton from '../../ReusableComponents/TableView/ClearFilterButton';

const DspInvoices = ({ dsp }) => {
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [openDatePicker, setOpenDatePicker] = useState({
        fromDate: false,
        toDate: false,
    })
    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem('dspInvoicesFilters');
        return savedFilters ? JSON.parse(savedFilters) : {
            fromDate: undefined,
            toDate: undefined,
            pageNumber: 1,
            pageSize: 15
        };
    });
    const [tableLoading, setTableLoading] = useState(false)
    const [settlementInvoices, setSettlementInvoices] = useState([])

    const getDspSettlementInvoicesFunc = async () => {
        try {
            // Loading.set(true)
            setTableLoading(true)
            const fromDate = filters.fromDate
                ? dateFormat(filters.fromDate, "yyyy-mm-dd")
                : undefined;
            const toDate = filters.toDate
                ? dateFormat(filters.toDate, "yyyy-mm-dd")
                : undefined;
            const invoices = await getSettlementInvoices(dsp?.id, fromDate, toDate, filters.pageSize, filters.pageNumber)
            setSettlementInvoices(invoices)
            setTableLoading(false)
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            setTableLoading(false)
            Popup.alert("Error", error, 'ERROR')
        }
    }

    useEffect(() => {
        localStorage.setItem('dspInvoicesFilters', JSON.stringify(filters));
    }, [filters]);

    const updateFilters = useCallback((newFilters) => {
        setFilters(prev => {
            const updated = { ...prev, ...newFilters };
            localStorage.setItem('dspInvoicesFilters', JSON.stringify(updated));
            return updated;
        });
    }, []);

    const clearFilters=()=>{
        setFilters((prevFilter)=>({
            ...prevFilter,
            fromDate:undefined,
            toDate:undefined,
        }))
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        setOpenDatePicker((prev) => ({ ...prev, fromDate: false, toDate: false }));
        const debouncedGetSettelmentInvoicesDSPs = _debounce(() => {
            getDspSettlementInvoicesFunc();
        }, 300);

        debouncedGetSettelmentInvoicesDSPs();

        return () => {
            debouncedGetSettelmentInvoicesDSPs.cancel();
        };
    }, [filters.pageNumber, filters.fromDate, filters.toDate]);

    useEffect(() => {
        getDspSettlementInvoicesFunc()
    }, [])

    return (
        <>
            <div className='transaction_history_wrapper' style={{ padding: "30px" }}>
                <div className='transaction_history_wrapper_header'>
                    <h4>Invoices History</h4>
                </div>
                <div  className='filters_section_wrapper'>
                    <div>
                      <ClearFilterButton  isVisible={filters.fromDate || filters.toDate} onClick={clearFilters} />
                    </div>
                    <div className='date_Filters'>
                    <div
                        className="input-date "
                        style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "34px", width: "150px", position: "relative", background: "transparent" }}
                        onClick={() => setOpenDatePicker((prev) => ({ ...prev, fromDate: true }))}
                    >
                        <DatePicker
                            onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, fromDate: false }))}
                            open={openDatePicker.fromDate}
                            placeholderText='From Date'
                            maxDate={filters?.toDate && new Date(filters?.toDate)}
                            readOnly
                            className="input-date-hidden"
                            selected={filters.fromDate ? new Date(filters.fromDate) : null}
                            onChange={date => {
                                if (date) {
                                    let fromDate = new Date(date).setHours(0, 0, 0, 0)
                                    fromDate = new Date(fromDate).toISOString();
                                    updateFilters({ fromDate });
                                }
                            }}
                        />
                        <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                    </div>
                    <div className='text-xl'>-</div>
                    <div className="input-date  "
                        style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "34px", width: "150px", position: "relative", background: "transparent" }}
                        onClick={() => {
                            if (filters?.fromDate) {
                                setOpenDatePicker((prev) => ({ ...prev, toDate: true }))
                            }
                        }}
                    >
                        <DatePicker
                            placeholderText='To Date'
                            onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, toDate: false }))}
                            open={openDatePicker.toDate}
                            disabled={!filters.fromDate}
                            minDate={filters?.fromDate && new Date(filters?.fromDate)}
                            maxDate={new Date()}
                            readOnly
                            className="input-date-hidden "
                            selected={filters.toDate ? new Date(filters.toDate) : null}
                            onChange={date => {
                                if (date) {
                                    let todate = new Date(date).setHours(23, 59, 59, 999)
                                    todate = new Date(todate).toISOString();
                                    updateFilters({ toDate: todate });
                                }
                            }} />
                        <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                    </div>
                </div>
                </div>
            </div>
            {!tableLoading ?
                <div style={{ width: "100%", padding: "0px 30px 30px" }}>
                    <table className='transactionTable'>
                        <thead className='w-full'>
                            <tr className='text-[#3B3B3B] text-[14px]'>
                                <th style={{ paddingLeft: "20px" }}>Invoice No.</th>
                                {/* <th style={{paddingLeft: "20px"}}>Service Amount</th>
                                <th style={{paddingLeft: "20px"}}>BhuMeet Coupon Total Amout</th> */}
                                {/* <th style={{paddingLeft: "20px"}}>Incentive</th>
                                <th style={{paddingLeft: "20px"}}>Total Amount</th>
                                <th style={{paddingLeft: "20px"}}>{`Platform Fee (${settlementInvoices[0]?.platform_fees_percentage}%)`}</th>
                                <th style={{paddingLeft: "20px"}}>Total Amount With Fees</th>
                                <th style={{paddingLeft: "20px"}}>{`TDS (${settlementInvoices[0]?.tds_charges_percentages}%)`}</th> */}
                                <th style={{ paddingLeft: "20px" }}>Amount</th>
                                <th style={{ paddingLeft: "20px" }}>Generated for Date</th>
                                <th style={{ paddingLeft: "20px" }}>Status</th>
                            </tr>
                        </thead>
                        <div className='invoice_transaction_table_body'>
                            <tbody >
                                {
                                    settlementInvoices?.length === 0 ?
                                        <tr align="center" >
                                            <td colSpan={6} style={{ height: "228px" }}>
                                                <NotFounds imgUrl={noRequest} msg={"No data available!"} hideBtn imgStyle={{ width: "100px" }} />
                                            </td>
                                        </tr> :
                                        settlementInvoices && settlementInvoices?.map((invoice, index) => (
                                            <tr className='row-hover' style={{ cursor: "pointer" }} key={index} onClick={() => {
                                                navigate(`${ROUTES.PAYMENTINVOICE}`, {
                                                    state: {
                                                        dsp,
                                                        invoice
                                                    }
                                                })
                                            }}>
                                                <td style={{ paddingLeft: "20px" }}>{invoice?.invoice_number}</td>
                                                {/* <td style={{paddingLeft: "20px"}}>₹ {invoice?.service_amount}</td>
                                                <td style={{paddingLeft: "20px"}}>₹ {invoice?.bhumeet_coupons_amount}</td> */}
                                                {/* <td>{invoice?.incentive_from_bhumeet_amount}</td>
                                                <td style={{paddingLeft: "20px"}}>{invoice?.total_dsp_amount}</td>
                                                <td style={{paddingLeft: "20px"}}>{invoice?.platform_fees_amount}</td>
                                                <td style={{paddingLeft: "20px"}}>{invoice?.total_dsp_amount_with_fees}</td>
                                                <td style={{paddingLeft: "20px"}}>{invoice?.tds_charges}</td> */}
                                                <td style={{ paddingLeft: "20px" }}>₹ {invoice?.total_dsp_amount_to_be_paid}</td>
                                                <td style={{ paddingLeft: "20px" }}>{dateFormat(invoice?.generated_for_date, "dd mmm yyyy")}</td>
                                                {/* <td style={{paddingLeft: "20px"}}>{dateFormat(invoice?.generated_time, "hh:MM TT")}</td> */}
                                                <td style={{ paddingLeft: "20px" }}>{invoice?.status == 'settled' ? 'Paid' : 'Unpaid'}</td>
                                            </tr>
                                        ))
                                }
                            </tbody>

                        </div>
                    </table>
                </div> :
                <div className='dsp_invoice_loading' >
                    <LoaderComponent
                        width={"2%"}
                        height={"2%"}
                    />
                </div>
            }

            {settlementInvoices?.length === 0 ?
                <></> :
                <div className='flex w-full justify-end mt-5'>
                    <TableViewPagination
                        isLoading={false}
                        currentPage={filters?.pageNumber || 1}
                        totalCount={settlementInvoices[0].settlement_invoices_count}
                        pageSize={filters.pageSize}
                        padding="10px 5px"
                        onPageChange={(page) => {
                            updateFilters({ pageNumber: page });
                        }}
                    />
                </div>
            }
        </>
    )
}

export default DspInvoices