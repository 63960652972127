import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import Search from '../../ReusableComponents/Search'
import { Button } from '@mui/material'
import Filter from "../../assets/filter.svg"
import UserInvoicesFilter from '../../AdminComponents/Finance/UserInvoices/UserInvoicesFilter'
import TableView from '../../ReusableComponents/TableView/TableView'
import { PDRL_FINANCE_ROUTES } from '../../routes'
import { useNavigate } from 'react-router'
import { SETTELEMENT_STATUSES, STATUS } from '../../appConstants'
import Loading from '../../ReusableFunctions/Loader'
import { getAllDebitNotes, getDebitStats } from '../../apis/BhuMeetTeams/BhuMeetFinanace/dashboard'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import Popup from '../../ReusableFunctions/Popup'
import { getAllDSPs } from '../../apis/adminAPIs/dsp'
import SelectComponent from '../../ReusableComponents/SelectComponent'


export default function DspDebitNotes() {
    const [isLoading, setIsLoading] = useState(false);
    const [debitNotes, setDebitNotes] = useState([])
    const [dsps, setDsps] = useState([{ dsp_id: null, dsp_name: "All" }])
    const [debitNotesStats, setDebitNotesStats] = useState({
        total_debit_note_count: 0,
        paid_debit_note_count: 0,
        unpaid_debit_note_count: 0,
    })
    const [filters, setFilters] = useState(()=>{
        let savedFilters=localStorage.getItem("admin_dedbit_filters");
               if(savedFilters){
                   let parsedFilters = JSON.parse(savedFilters);
                   return parsedFilters;
               }
           return {
            pageNumber: 1,
            pageSize: 15,
            fromDate:undefined,
            toDate: undefined,
            statuses: ["settled"],
            orderType:'generated_for_date',
            orderType:'DESC',
            searchQuery:'',
        }
           
       })
    const navigate=useNavigate();

    const getDebitNotes=async(filters)=>{
        try{
        setIsLoading(true);
         let debitNotes=await getAllDebitNotes({...filters,dspId:filters?.selectedDSP?.value});
         const debitNoteStats = await getDebitStats(filters.selectedDSP?.value, filters.fromDate, filters.toDate,filters?.searchQuery)
         setDebitNotes(debitNotes);
         setDebitNotesStats(()=>({
        total_debit_note_count:debitNoteStats?.debit_note_count? Number (debitNoteStats.debit_note_count) : 0 ,
        paid_debit_note_count: debitNoteStats?.settled_count? Number (debitNoteStats.settled_count) : 0 ,
        unpaid_debit_note_count:debitNoteStats?.unsettled_count ? Number (debitNoteStats.unsettled_count ) : 0 
         }))
         setIsLoading(false);
        }catch(err){
            Popup.alert("Error", err, 'ERROR')
          setIsLoading(false);
        }
    }
    const getActiveDSPs = async () => {
        try {
            const data = await getAllDSPs(null, null, null, null, null, true)
            setDsps([...dsps, ...data.dsps])
        } catch (e) {
            console.error(e)
            Popup.alert("ERROR", "error while getting the active dsps .")
        }
    }

    useEffect(()=>{
        getActiveDSPs();
    },[])


    useEffect(()=>{
      getDebitNotes(filters);
      localStorage.setItem("admin_dedbit_filters",JSON.stringify(filters));
    },[filters])


    const DebitNoteCard = ({ title, statNumber, isSelected, onClick, isDisabled }) => {
        return <DashBoardCard isDisabled={isDisabled} title={title} data={statNumber} onClick={onClick} backGround={isSelected ? "green" : undefined} />
    }

    const DebitNotesStats = ({ invoicesStats, filters, changeStatusFilter }) => {
        return <div style={{ display: "flex", columnGap: "2%" }}>
            <DebitNoteCard
                title={"Total Debit Notes"}
                statNumber={invoicesStats.total_debit_note_count}
                isSelected={filters.statuses.length === 0}
                onClick={() => changeStatusFilter([])}
            />
            <DebitNoteCard
                title={"Paid Debit Notes"}
                statNumber={invoicesStats.paid_debit_note_count}
                isSelected={filters.statuses.length > 0 && filters.statuses[0] === SETTELEMENT_STATUSES.SETTELED}
                onClick={() => changeStatusFilter([SETTELEMENT_STATUSES.SETTELED])}
            />
            <DebitNoteCard
                title={"Unpaid Debit Notes"}
                statNumber={invoicesStats.unpaid_debit_note_count}
                isSelected={filters.statuses.length > 0 && filters.statuses[0] === SETTELEMENT_STATUSES.UNSETTLED}
                onClick={() => changeStatusFilter([SETTELEMENT_STATUSES.UNSETTLED])}
            />
        </div>
    }

  return (
    <MainContentWrapper header={`Debit Notes`} rightContent={
        <div style={{ display: "flex", gap: '30px' }}>
            <Search
            placeholder="Search by DN Number"
                searchValue={filters.searchQuery}
                onChange={(value) => {
                    console.log("value",value);
                    setFilters({
                        ...filters,
                        pageNumber: 1,
                        searchQuery: value
                    })
                }}
            />
            <SelectComponent
                controlStyle={{ height: "40px", width: "200px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                isSearchable={true}
                placeholder={"Select DSP..."}
                menuPlacement={"bottom"}
                list={dsps.map(dsp => {
                    return ({
                        value: dsp.dsp_id,
                        label: dsp.dsp_name
                    })
                })}
                value={filters.selectedDSP}
                onChange={(dsp) => {
                    setFilters({ ...filters, selectedDSP: dsp,pageNumber:1 })
                }}
            />
        </div>}
    >
         <DebitNotesStats
                    invoicesStats={debitNotesStats}
                    filters={filters}
                    changeStatusFilter={(statusFiler) => {  
                        setFilters({ ...filters,pageNumber:1, statuses: statusFiler })
                    }}
                />
        <div style={{
            margin: "20px 0",
            height: "75%"
        }}>
            <TableView
                showSrNo
                loadingHeight={"500px"}
                isLoading={isLoading}
                onStateChange={async (changedState) => {
                    const { pageNumber, orderBy, orderType } = changedState;
                    setFilters({ ...filters, pageNumber, orderBy, orderType });
                }}
                onClickRow={(invoice) => {
                    navigate(`${PDRL_FINANCE_ROUTES.PAYMENTINVOICE}`, {
                        state: {
                            invoice
                        }
                    })
                }}
                columns={[
                    { Header: "DN Number.", accessor: "dn_number" },
                    { Header: "Invoice No", accessor: "against_invoice_number" },
                    { Header: "Dsp Name", accessor: "dsp_name" },
                    { Header: "Amount (₹)", accessor: "total_amount" },
                    { Header: "Date", accessor: "generated_for_date", isDate: true, isSortable: true, format: "dd/mm/yyyy" },
                    { Header: "Status", accessor: "debit_note_status" }
                ]}
                count={debitNotes[0]?.debit_note_count}
                pageSize={filters.pageSize}
                pageNumber={filters.pageNumber}
                data={debitNotes.map((d)=>({
                    ...d,
                    debit_note_status:d.debit_note_status=="settled"?"Paid":"UnPaid"
                }))}
                tableContainerHeight={"100%"}
                hidePagination={false}
                headerStyle={{ zIndex: 0 }}
            />
        </div>
    </MainContentWrapper>
  )
}
