import { useEffect, useState } from "react";
import MainContentWrapper from "../../../Components/MainContentWrapper";
import TableView from "../../../ReusableComponents/TableView/TableView";
import {deleteManufacturewithdrone, getDroneManufactures } from "../../../apis/BhuMeetTeams/Oparations/droneStore";
import Popup from "../../../ReusableFunctions/Popup";
import { PDRL_ADMIN_ROUTES, PDRL_SUCCESS_ENGI_ROUTES } from "../../../routes";
import { useNavigate } from 'react-router'
import { useLocation } from "react-router-dom";
import Search from '../../../ReusableComponents/Search'
import Plus from "../../../assets/Plus.svg";
import { Button } from '../../../Components/Helper';
import AddDroneManufacture from './AddDroneManufacture'



const DroneManufactures = () => {
    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [openAddManufacture,setopenAddManufacture] = useState(false)
    const [path,setPath] = useState('')
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 15,
        fromDate: undefined,
        toDate: undefined,
    })
    const [details,setdetails] = useState()
    const [searchString, setSearchString] = useState("")


    const Location = useLocation()


    const getAllManufactureFunc = async () => {
        try {
            setLoading(true)
            const { fromDate, toDate, pageSize, pageNumber } = filters
            let stores
            if(searchString !== ''){
                stores = await getDroneManufactures(pageSize,pageNumber,searchString)
            }else{
                stores = await getDroneManufactures(pageSize,pageNumber)
            }
            // console.log(Location)
            
            setStores(stores);
            // setStores({...stores,toShow:Location.pathname})
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const deleteManufacture = async(id)=>{
        await deleteManufacturewithdrone(id).then(res=>{
            getAllManufactureFunc()
        }).catch((err)=>{
            Popup.alert("Error", err, 'ERROR')

        })
    }

    useEffect(() => {
        getAllManufactureFunc(filters);
        
    }, [filters])

    useEffect(()=>{
        // console.log(Location)
        getAllManufactureFunc()
    },[searchString])



    return (
        <MainContentWrapper header={`Manufacturers (${stores[0] ? stores[0].manufacturers_count : 0})`} 
        rightContent={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Search
                        searchValue={searchString}
                        onChange={(value) => {
                            
                            setSearchString(value)
                            
                            // setCurrentPage(1)
                        }}
                    />

                    <Button
                        type={"button"}
                        text={"Add New"}
                        icon={Plus}
                        style={{ background: "#0F5EFF",marginLeft:"10px", padding: "8px, 16px, 8px, 16px", width: "110px", height: "35px", fontSize: "11px" }}
                        onClick={() => {
                            // console.log("TEst")
                            setopenAddManufacture(true)
                            // console.log(openAddManufacture)
                        //   setOpenPesticidesPopup(true)
                        }} />
        </div>

        
        }>
            
            {
                openAddManufacture && <AddDroneManufacture open={openAddManufacture} data={details}  onClose={()=>{setopenAddManufacture(false)
                    setdetails(false)
                    getAllManufactureFunc()
                }}/>
            }
            <div style={{
                margin: "20px 0",
                height: "90%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"500px"}
                    isLoading={loading}
                    onStateChange={async (changedState) => {
                        const { pageNumber } = changedState;
                        // console.log("pageNumber", pageNumber);
                        setFilters({ ...filters, pageNumber });
                    }}
                    onClickRow={(store) => {
                        // console.log("store", store);
                        navigate(PDRL_ADMIN_ROUTES.DRONEMODELS, { state: { store: store } })
                    }}
                    columns={[
                        { Header: "Manufacturer Name", accessor: "name", width: "15%" },
                        
                        { Header: "Added Date", accessor: "date_added", isDate: true, format: "dd/mm/yyyy", width: "15%" },
                        // { Header: "Status", accessor: "active_label", width: "5%", showColor: true },
                    ]}
                    count={Location.state}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={stores.map(st => {
                        return {
                            ...st,
                            active_label: st.is_active ? "Active" : "Inactive",
                            color: st.is_active ? 'green' : 'red'
                        }
                    })}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                   
                    activities={{ onClickDelete: (e) => Popup.alert("Are you sure , You want to delete this Manufacture.", "This will  delete drone model also?", "WARNING", () => {deleteManufacture(e.id)}), onClickEdit: (e)=>{
                        setopenAddManufacture(true) 
                        setdetails(e)
                    }, canEdit: true }}
                />
            </div>
        </MainContentWrapper>
    )
}

export default DroneManufactures