import React, { useState } from "react";
import Modal from "./Modal";
import { Button } from "../Components/Helper";
import { GLOBAL_STYLES } from "../appConstants";
import deleteIco from "../assets/delete.png"
import successIco from "../assets/succees.png"
import errorIco from "../assets/cancel.png"
import warningIco from "../assets/warning.png"


const ActivityPopup = (props) => {
  const [open, setOpen] = useState(true);
  return (
    <Modal
      closeOnDocumentClick={false}
      contentStyle={props.contentStyle || { width: "450px", height: "240px" }}
      open={open}
      onClose={() => setOpen(false)}
      position={props.position}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.onClickOk()
        }}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "10px",
          overflow: "hidden",
          borderRadius: "5px"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "30px", gap: "10px" }}>
          <div>
            <img src={props.type === "ERROR" ? errorIco : props.type === "WARNING" ? warningIco : props.type === "DELETE" ? deleteIco : successIco} alt="pop-up-icon" style={{ width: "50px" }} />
          </div>
          <div style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM_HEADER, fontWeight: "600" }}>
            {props.header}
          </div>
          <div
            style={{
              fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
              height: "100%",
              color: "#727272",
              width: "99%",
              textAlign: "center"
            }}
          >
            {props?.content?.message || props?.content}
          </div>
        </div>
        {props.type === "WARNING" ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <button
              style={{ width: "50%", height: "40px", borderTop: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`, outline: "none", fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR }}
              onClick={props.onClose}
              type="button"
            >
              {props.closeBtnText || "Cancel"}
            </button>

            <button
              style={{ width: "50%", background: "#FFD764", height: "40px", outline: "none", fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR }}
              type="submit"
            >
              {props.okBtnText || "Do it now !"}
            </button>
          </div>
        ) : props.type === "ERROR" ? (
          <div>
            <button id="ErrorContinue"
              style={{ width: "100%", height: "40px", background: "#E11D48", color: "white", outline: "none", fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR }}
              onClick={props.onClose}
            >
              {props.okBtnText || "Continue"}
            </button>
          </div>
        ) : props.type === "SUCCESS" ? (
          <div>
            <button
              style={{ width: "100%", height: "40px", background: "#00B358", color: "white", outline: "none", fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR }}
            // onClick={props.onClose}
            >
              {props.okBtnText || "Continue"}
            </button>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{ width: "50%", height: "40px", borderTop: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`, outline: "none", fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR }}
              onClick={props.onClose}
              type="button"
            >
              Cancel
            </button>
            <button
              style={{ width: "50%", background: "#E11D48", height: "40px", color: "white", outline: "none", fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR }}
              type="submit"
            >
              Delete
            </button>
          </div>
        )}
      </form>
    </Modal >
  );
};

export default ActivityPopup;
